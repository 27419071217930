(function () {
    'use strict';

    angular
        .module('scheduleboard.users')
        .controller('PasswordResetController', PasswordResetController);

    PasswordResetController.$inject = ['$mdDialog', '$scope', '$state', 'AuthService', 'Boarder', 'APP_GLOBALS', 'UserPrefsService', '$mdToast', '$rootScope', '$log'];
    function PasswordResetController($mdDialog, $scope, $state, AuthService, Boarder, APP_GLOBALS, UserPrefsService, $mdToast, $rootScope, $log) {

        $log.log("login dialog has been started!!!");
        var vm = this;

        vm.login = login;
        vm.close = close;

        vm.username = "";
        vm.password = "";

        vm.previousState = '';
        vm.previousStateParams = '';
        vm.hasParams = false;
        if (typeof $rootScope.previousUrl !== 'undefined') {
            vm.previousState = $rootScope.previousUrl.name;
            vm.previousStateParams = $rootScope.previousUrlParams;
            // var keys = Object.keys(vm.previousStateParams);
            // keys.splice(keys.indexOf("#"));
            delete vm.previousStateParams['#'];
            // $log.log(vm.previousStateParams);
            if (Object.keys(vm.previousStateParams).length > 0) {
                vm.hasParams = true;
            }
            // vm.previousState=$rootScope.previousUrl.name;
        }

        vm.requestPasswordReset = requestPasswordReset;

        function requestPasswordReset(){
            if (!angular.isDefined(vm.username) || vm.username === ""){
                $mdToast.show($mdToast.simple().textContent('Please Supply An Email Address!'));
            }else {
                AuthService.resetPassword(vm.username).then(function onSuccess(response) {
                   $mdToast.show($mdToast.simple().textContent('Password Reset Request Sent. Please check your email.'));
                   $state.go('login');
                    },
                    function onError(err) {
                        var actualErrorData = err.data.error;
                        if (actualErrorData.statusCode == 404) {
                           $mdToast.show($mdToast.simple().textContent('No account found for that email!'));

                        } else {
                           $mdToast.show($mdToast.simple().textContent('Error'+actualErrorData.message));

                        }
                    });
            }
        }
        function showPasswordResetDialog(){
            //$mdDialog
            var confirm = $mdDialog.prompt()
      .title('Please Enter Your Email Address')
      .textContent('Bowser is a common name.')
      .placeholder(vm.username || "Email Address")
      .ariaLabel('Dog name')
      .required(true)
      .ok('Submit')
      .cancel('Cancel');

    $mdDialog.show(confirm,{clickOutsideToClose:true}).then(function(result) {
      //$scope.status = 'You decided to name your dog ' + result + '.';
        $mdToast.show($mdToast.simple().textContent('User Edited!'));
        if(result){
            resetPassword(result);

        }
    }, function() {
        $mdToast.show($mdToast.simple().textContent('You cancelled the dialog'));
      //$scope.status = 'You didn\'t name your dog.';
    });

        }
        function resetPassword(email) {
            if (!angular.isDefined(email) || email === ""){
                $mdToast.show($mdToast.simple().textContent('Please Supply An Email Address!'));
            }else {
                AuthService.resetPassword(email).then(function onSuccess(response) {
                   $mdToast.show($mdToast.simple().textContent('Password Reset. Please check your email.'));
                    },
                    function onError(err) {
                        var actualErrorData = err.data.error;
                        if (actualErrorData.statusCode == 404) {
                            ionicToast.show('No account found for that email!', 'top', false, 2500);
                        } else {
                            ionicToast.show('Error: ' + actualErrorData.message, 'top', false, 2500);
                        }
                    });
            }
        }

        function login() {
            if (angular.isUndefined(vm.username) || angular.isUndefined(vm.password)) {
                $log.log("no data");
            } else if (vm.username === '' || vm.password === '') {
                $log.log("no data");
            } else {
                vm.username = vm.username.trim().toLowerCase();
                // $log.log("data!");

                AuthService.login({username: vm.username, password: vm.password}).then(function (authenticated) {
                    $log.log("LoginCtrl :: auth status: ", authenticated);

                    // clear the input fields
                    vm.username = '';
                    vm.password = '';

                    // $mdDialog.hide({username: dialog.username, password: dialog.password});
                    if (!vm.hasParams) {
                        // $log.log("STATE: ", vm.previousState);
                        $state.go(vm.previousState, {reload: true});
                    } else {
                        // $log.log("STATE: ", vm.previousState, "PARAMS: ", vm.previousStateParams);
                        $state.go(vm.previousState, vm.previousStateParams, {reload: true});
                    }
                    // transition to landing page
                    // $state.go('scheduleboard.resources', {}, {reload: true});
                    $mdToast.show($mdToast.simple().textContent('Login Successful!'));

                }, function (error) {
                    $mdToast.show($mdToast.simple().textContent('Login Failed.  Check your username and Password!'));
                });
            }
        }
    }
})();
