(function() {
'use strict';

    angular
        .module('scheduleboard.account')
        .controller('AccountController', AccountController);
    AccountController.inject = ['$state', '$rootScope', '$scope', 'AuthService', 'BoarderService','Boarder', 'API','$mdDialog','Upload', '$timeout', '$mdToast','stripe'];
    function AccountController($state, $rootScope, $scope, AuthService, BoarderService,Boarder, API,$mdDialog,Upload, $timeout,$mdToast,stripe) {

        var vm = this;
        vm.account = {};
        vm.user = '';
        vm.theme = vm.theme|| 'default';
        //vm.planSelected = false;
        //vm.planSelection = '';

       console.log(plans);
        vm.baseUrl = API.baseUrl;
        /// FUNCTION DECLARATIONS ///
        vm.isAuth = isAuthenticated;
        vm.logout = logout;
        vm.hasAvatar = hasAvatar;
        vm.showModalToEditUserProfile = showModalToEditUserProfile;
        vm.uploadFiles = uploadFiles;

        function isAuthenticated(){
            return Boarder.isAuthenticated();

        }

        vm.token = $rootScope.getAccessToken();
        vm.user = $rootScope.getUser();
        vm.account = $rootScope.getActiveAccount();
        console.log(vm.account);
        vm.accountName = vm.account.name;
        vm.accountUUID = vm.account.accountUUID;
        /*Boarder.getCurrent().$promise
                    .then(function(user){
                      vm.user=user;

                  var criteria = {
                        id: user.id,
                        filter: {
                                    include: "account",
                                    where: {
                                        id: $rootScope.getBusinessAccountId()
                                    }
                                }
                    };
                  Boarder.findById(criteria).$promise.then(function(user){


                  });
                  //console.log(vm.user);
                });*/
        activate();


        function uploadFiles(file,invalidFiles){
            var f = file;
            var uploadURL = encodeURI(API.baseUrl + "/Containers/" + vm.accountUUID + "/upload?access_token=" + AuthService.getAccessToken() + "&accountId=" +$rootScope.getBusinessAccountId());

            console.log(file);
            if (file) {
            file.upload = Upload.upload({
                url: uploadURL,
                data: {file: file}

            });

            file.upload.then(function (response) {
                $timeout(function () {
                    //file.result = response.data;
                    var fileObj = response.data.result.files.file[0];

                    vm.user.avatarURL = "/Containers/" + fileObj.container + "/download/" + fileObj.name;
                    //console.log(vm.user.avatarURL);

                BoarderService.updateBoarderAvatarUrl(vm.user, vm.user.avatarURL).then(function(response){
                    console.log("NEW AVATAR URL",response);
                    loadUser();
                });
                    //console.log("RESULT!!",file.result);
                });
            }, function (response) {
                if (response.status > 0){
                    $scope.errorMsg = response.status + ': ' + response.data;
                    console.log($scope.errorMsg);
                }
            }, function (evt) {
                file.progress = Math.min(100, parseInt(100.0 *
                                         evt.loaded / evt.total));
            });
        }
           //FileTransferService.uploadAvatarPhoto(file)
        }
        function saveEditedUser() {
            //vm.modal.hide();

            // perform validation etc
            var data = vm.userProfileFormData;

            if (!data.firstName) {
                $mdToast.show($mdToast.simple().textContent('Please enter a valid first name'));

            } else if (!data.lastName) {

                $mdToast.show($mdToast.simple().textContent('Please enter a valid last name'));
            } else if (!data.email) {
                $mdToast.show($mdToast.simple().textContent('Please enter a valid email'));
            } else if (!data.mobilePhone){
                $mdToast.show($mdToast.simple().textContent('Please enter a valid phone number'));
            } else if (!data.id){
                $mdToast.show($mdToast.simple().textContent('No Boarder Id Found!'));
            } else {
                if (isNaN(data.mobilePhone.trim()) || data.mobilePhone.trim().length !== 10){
                $mdToast.show($mdToast.simple().textContent('Please enter a valid Phone Number'));
                } else {
                    // trim whitespace
                    data.firstName = data.firstName.trim();
                    data.lastName = data.lastName.trim();
                    data.email = data.email.trim();
                    data.mobilePhone = data.mobilePhone.trim();

                    // save the data
                    var updatedBoarder = BoarderService.updateBoarderContactInto(data).then(function(updatedBoarder){
                        $mdDialog.cancel();
                        loadUser();
                    });
                }
            }
        };

        function showModalToEditUserProfile() {
            vm.userProfileFormData = {
                id: vm.user.id,
                firstName: vm.user.firstName,
                lastName: vm.user.lastName,
                email: vm.user.email,
                mobilePhone: vm.user.mobilePhone
            };

            $mdDialog.show({
                bindToController: true,
                locals: {userProfileFormData: vm.userProfileFormData, saveEditedUser:saveEditedUser},
                controller: angular.noop,
                controllerAs: 'vm',

                templateUrl: 'app/scheduleboard/account/edit-userprofile-dialog.html',
                clickOutsideToClose:true
            })
                .then(function() {
                $mdToast.show($mdToast.simple().textContent('User Edited!'));
                $mdDialog.cancel();
                }, function() {
                    $mdDialog.cancel();
                    $scope.status = 'You cancelled the dialog.';
                });
        };

        function hasAvatar(boarder){
            if (_.has(boarder, 'avatarUrl')){
                return ! (_.isNull(boarder.avatarUrl) || _.isEmpty(boarder.avatarUrl));
            }
            return false;
        };
     function loadUser(){
            BoarderService.getBoarderById(AuthService.getUser().id).then(function(boarder){
                // extract the numbers of jobs
                boarder.numJobs = "" + _.uniqBy(boarder.partials, 'jobId').length;

                // build the proper avatarURL by appending our current access code
                if (hasAvatar(boarder)){
                    //var fullImgUrl = "";
                    const authInfo = "?access_token=" + AuthService.getAccessToken();
                    const accountInfo = "&accountId=" + AuthService.getBusinessAccountId();
                    const fullImgUrl = API.baseUrl + boarder.avatarUrl;
                    console.log("AVATAR URL IN PROFILE PAGE",boarder.avatarUrl);
                    boarder.avatarUrl = fullImgUrl + authInfo + accountInfo;
                    boarder.avatarUrl = fullImgUrl;

                }


                vm.user = boarder;
                //console.log(vm.user);
            });
        };

    function logout() {
            AuthService.logout().then(function(msg) {
            $mdToast.show($mdToast.simple().textContent('Logout Successful!'));
            vm.accountName='';
            $state.go('scheduleboard');
            }, function(reason) {
            $mdToast.show($mdToast.simple().textContent('Logout Failed!'));
            });
        }

    function activate(){
        if(vm.isAuth()){
            vm.user = $rootScope.getUser();
            vm.username = AuthService.username();
            vm.fullname = AuthService.name();
            vm.email = AuthService.email();
            vm.mobile = AuthService.mobilePhone();
            vm.role = AuthService.role();
            vm.activeAccount = AuthService.getActiveAccount();
            vm.account = $rootScope.getActiveAccount();
            vm.accountName = vm.account.name;
            loadUser();

            }

        }


        //console.log(Boarder.isAuthenticated());
    }
})();
