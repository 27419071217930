(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('JobPocService', JobPocService);

    JobPocService.inject = ['$q', '$http', '$timeout', 'JobPoc'];
    function JobPocService($q, $http, $timeout, JobPoc) {
        var service = {
            addJobPOC: addJobPOC,
            deleteJobPOC: deleteJobPOC,
            getAllJobPOCs: getAllJobPOCs,
            getJobPOCById: getJobPOCById,
            getJobPOCsByJobId: getJobPOCsByJobId
        };
        
        return service;

        ////////////////
        function addJobPOC(JobPOCObj){
            return JobPoc.upsert(JobPOCObj).$promise;
        };

        function deleteJobPOC(JobPOCObj){
            return JobPoc.delete(JobPOCObj).$promise;
        };

        function getAllJobPOCs(){
            return JobPoc.find().$promise;
        };

        function getJobPOCById(id){
            return JobPoc.findById({ id: id }).$promise;
        };

        function getJobPOCsByJobId(jobId){
            return JobPoc.find({ filter: { where: {"jobId": jobId} } }).$promise;
        };
    }
})();