(function () {
    'use strict';

    angular
        .module('scheduleboard.schedule')
        .controller('PartialDeleteDialogController', PartialDeleteDialogController);

    /* @ngInject */
    PartialDeleteDialogController.inject = ['$scope', 'PartialsService', '$mdToast', 'EmployeesService', '$mdDialog', 'partial', 'jobList', 'reloadJobList'];

    function PartialDeleteDialogController($scope, PartialsService, $mdToast, EmployeesService, $mdDialog, partial, jobList, reloadJobList) {

        var vm = this;
        vm.partial = partial;
        vm.jobList = jobList;
        vm.reloadJobList = reloadJobList;
        vm.deleteSelection = "Instance";
        vm.deleteOptions = ["Instance", "Future"];
        vm.myDate = '';

        vm.submitDelete = submitDelete;
        vm.close = close;

        function submitDelete() {
            if (vm.deleteSelection == "Instance") {
                PartialsService.deletePartialById(vm.partial.id).then(
                    function (answer) {
                        vm.jobList[vm.partial.jobId].days[moment(vm.partial.day).format("YYYY-MM-DD")] = '';
                        // console.log(answer);
                        $mdToast.show($mdToast.simple().textContent('Deleted work for ' + moment(vm.partial.day).format("MMM DD")));
                        vm.reloadJobList();
                    }, function (error) {
                    $mdToast.show($mdToast.simple().textContent('An error occurred trying to delete a day of work on this job!'));
                });

            } else if (vm.deleteSelection == "Future") {
                PartialsService.deleteAllFuturePartials(vm.partial).then(
                    function (answer) {
                        // console.log(answer);
                        $mdToast.show($mdToast.simple().textContent('Deleted all work starting ' + moment(vm.partial.day).format("MMM DD") + ' onward'));
                        vm.reloadJobList();
                    }, function (error) {
                    $mdToast.show($mdToast.simple().textContent('An error occurred trying to delete all work on this job in the future!'));
                });

            } else if (vm.deleteSelection == "All") {
                PartialsService.deleteAllPartials(vm.partial.jobId).then(
                    function (answer) {
                        // console.log(answer);
                        $mdToast.show($mdToast.simple().textContent('Partial(s) Deleted!'));
                        vm.reloadJobList();
                    }, function (error) {
                    $mdToast.show($mdToast.simple().textContent('An error occurred trying to delete all days of work on this job!'));
                });
            }

            $mdDialog.cancel();
        }

        function close() {
            $mdDialog.cancel();
        }

        function returnDate() {
            $mdDialog.hide(vm.myDate);
        }

        function onlyWeekdaysPredicate(date) {
            var day = date.getDay();
            return day === 1 || day === 2 || day === 3 || day === 4 || day === 5;
        }
    }
})();
