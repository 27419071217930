(function () {

    'use strict';

    angular
        .module('scheduleboard.jobs')
        .controller('JobDialogController', JobDialogController);
    // ----- ControllerFunction -----
    JobDialogController.$inject = ['$mdDialog', '$scope', '$state', 'Boarder', 'Job', '$mdToast', '$mdStepper', 'GoogleService', 'callbackFn'];

    /* @ngInject */
    function JobDialogController($mdDialog, $scope, $state, Boarder, Job, $mdToast, $mdStepper, GoogleService, callbackFn) {

        var vm = this;
        vm.new = true;
        vm.map = '';

        var steppers;
        vm.job = {};
        vm.manualAddress = false;

        // function declarations

        vm.hide = hide;
        vm.submit = submit;
        vm.enterLocation = enterLocation;
        vm.enterBasic = enterBasic;
        vm.enterSchedule = enterSchedule;
        vm.previousStep = previousStep;

        // functions

        function enterLocation() {
            steppers = $mdStepper('job-stepper');
            steppers.goto(1);
        }

        function enterSchedule() {
            steppers = $mdStepper('job-stepper');
            steppers.goto(2);
        }

        function enterBasic() {
            steppers = $mdStepper('job-stepper');
            steppers.goto(0);
        }

        function previousStep() {
            steppers = $mdStepper('job-stepper');
            steppers.back();
        }

        function hide() {
            $mdDialog.hide();
        }

        vm.cancel = function () {
            vm.manualAddress = false;
            $mdDialog.cancel();
        };

        function close() {
            $mdDialog.cancel();
        }

        function submit() {

            if (hasValidAddress(vm.job)) {

                if (!vm.manualAddress) {
                    vm.job.address = GoogleService.getAddressFromPlace(vm.job.locationDetails);
                }

                if (vm.new) {
                    Boarder.getCurrent().$promise
                        .then(function (user) {
                            vm.job.accountId = parseInt(user.accountId);
                            Job.updateOrCreate(vm.job).$promise.then(function (results) {
                                $mdToast.show($mdToast.simple().textContent('Job Created!'));
                                $mdDialog.cancel();
                                // $state.reload();

                                if (callbackFn) {
                                    callbackFn();
                                }
                            });
                        });
                } else {
                    Job.updateOrCreate(vm.job).$promise.then(function (results) {
                        $mdToast.show($mdToast.simple().textContent('Job Updated!'));
                        $mdDialog.cancel();
                        // $state.reload();

                        if (callbackFn) {
                            callbackFn();
                        }
                    });
                }
            } else {
                $mdToast.show($mdToast.simple().textContent("Invalid Address"));
            }
        }

        function hasValidAddress(job) {
            if (vm.manualAddress) {
                if (_.has(job, 'address')) {
                    var a = job.address;

                    var valid = _.has(a, 'zipCode')
                        && _.has(a, 'state')
                        && _.has(a, 'city')
                        && _.has(a, 'streetAddress');

                    if (valid) {
                        valid = valid
                            && !(_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress))
                            && !(_.isNull(a.city) || _.isEmpty(a.city))
                            && !(_.isNull(a.state) || _.isEmpty(a.state))
                            && !(_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
                    }

                    return valid;
                }

                return false;
            } else if (vm.job.locationDetails) {
                return true;
            } else {
                return false;
            }
        }
    }

})();
