(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('EquipmentService', EquipmentService);

    EquipmentService.inject = ['$q', '$http', '$timeout', 'AuthService', 'API', 'Equipment'];
    function EquipmentService($q, $http, $timeout, AuthService, API, Equipment) {
        var service = {
            addEquipment: addEquipment,
            deleteEquipmentById: deleteEquipmentById,
            getAllEquipment: getAllEquipment,
            getEquipmentById: getEquipmentById
        };
        
        return service;

        ////////////////
        function addEquipment(obj){
            obj.dateCreated = moment().toISOString();
            obj.lastUpdated = moment().toISOString();
            obj.accountId = AuthService.getUser().accountId;

            return Equipment.create(obj).$promise;
        };

        function deleteEquipmentById(id){
            return Equipment.deleteById({ id: id}).$promise;
        };

        function getAllEquipment(){
            return Equipment.find().$promise;
        };

        function getEquipmentById(id){
            return Equipment.findById({ id: id}).$promise;
        };
    }
})();