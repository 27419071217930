(function () {
    'use strict';

    angular
        .module('scheduleboard.toolbar')
        .controller('ToolbarController', ToolbarController);

    ToolbarController.inject = ['$scope', '$state', '$mdToast', '$mdDialog', '$location', 'AuthService', 'Boarder', '$rootScope', 'API', 'BoarderService'];
    function ToolbarController($scope, $state, $mdToast, $mdDialog, $location, AuthService, Boarder, $rootScope, API, BoarderService) {

        var vm = this;
        vm.currentNavItem;
        vm.selectedAccountRole;
        vm.currentAccountRole;
        vm.availableAccountRoles = [];

        vm.state = $state;

        // FUNCTION DECLARATIONS //

        vm.isAuth = isAuthenticated;
        vm.openMenu = openMenu;
        vm.logout = logout;
        vm.login = login;
        vm.showLoginDialog = showLoginDialog;
        vm.showLeadDialog = showLeadDialog;
        vm.showEmployeeInvite = showEmployeeInvite;
        vm.showNewEquipment = showNewEquipment;
        vm.showNewJob = showNewJob;
        vm.showFabs = showFabs;
        vm.hasAvatar = hasAvatar;
        vm.baseUrl = API.baseUrl;
        vm.userSelectedNewAccountRole = userSelectedNewAccountRole;

        // map these functions but do not call them - they are called in the view!
        vm.isManager = AuthService.isManager;
        vm.isCustomer = AuthService.isCustomer;
        vm.isAdmin = AuthService.isAdmin;

        vm.canAddResources = canAddResources;

        function canAddResources() {
            return (vm.isCustomer() || vm.isAdmin() || vm.isManager());
        }

        activate();

        function userSelectedNewAccountRole() {
            if (vm.selectedAccountRole.account.name != vm.currentAccountRole.account.name) {
                vm.currentAccountRole = vm.selectedAccountRole;
                AuthService.setActiveAccountRole(vm.selectedAccountRole);
            }
        }

        function showEmployeeInvite(ev) {
            $mdDialog.show({
                controller: "EmployeeInviteController as vm",
                templateUrl: 'app/scheduleboard/employees/employee-invite-dialog.tmpl.html',
                clickOutsideToClose: true
            }).then(function (answer) {
                $mdToast.show($mdToast.simple().textContent('You added Employees!!!'));
                //$scope.status = 'You added Employees';
            }, function () {
                $mdToast.show($mdToast.simple().textContent('An Error Occurred While Adding Employees...'));

            });
        }

        function showNewEquipment(ev) {
            $mdDialog.show({
                controller: "EquipmentDialogController as vm",
                templateUrl: 'app/scheduleboard/equipment/equipment-dialog.tmpl.html',
                clickOutsideToClose: true,
                locals: {
                    callbackFn: activate
                }
            }).then(function (answer) {
                $scope.status = 'You said the information was "' + answer + '".';
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
        }

        function showNewJob(ev) {
            $mdDialog.show({
                controller: "JobDialogController as vm",
                templateUrl: 'app/scheduleboard/jobs/job-dialog.tmpl.html',
                clickOutsideToClose: true,
                locals: {
                    callbackFn: activate
                }
            }).then(function (answer) {
                $scope.status = 'You said the information was "' + answer + '".';
            }, function () {
                $scope.status = 'You cancelled the dialog.';
            });
        }

        function isAuthenticated() {
            return Boarder.isAuthenticated();
        }

        function logout() {
            AuthService.logout().then(function (msg) {
                $mdToast.show($mdToast.simple().textContent('Logout Successful!'));
                $state.go('scheduleboard', {}, {
                    reload: true
                });
            }, function (reason) {
                $mdToast.show($mdToast.simple().textContent('Logout Failed!'));
            });
        }

        function login(data) {
            if (angular.isUndefined(data.username) || angular.isUndefined(data.password)) {
                // $log.log("no data");
            } else if (data.username === '' || data.password === '') {
                // $log.log("no data");
            } else {
                data.username = data.username.trim().toLowerCase();
                // $log.log("data!");

                AuthService.login(data).$promise.then(function (authenticated) {
                    // clear the input fields
                    vm.data.username = '';
                    vm.data.password = '';

                    vm.user = AuthService.getUser();
                    vm.availableAccountRoles = AuthService.getAccountRoles();
                    vm.currentAccountRole = AuthService.getActiveAccountRole();
                });
            }
        }

        function showLeadDialog() {
            $mdDialog.show({
                templateUrl: 'app/scheduleboard/lead/lead.tmpl.html',
                controller: 'LeadDialogController',
                controllerAs: 'dialog',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true
            }).then(function () {
                $mdToast.show($mdToast.simple().textContent('Thank You For Contacting Us!!!'));
                $state.go('resources', {}, {
                    reload: true
                });
            });
        }

        function openMenu($mdMenu, ev) {
            var originatorEv = ev;
            $mdMenu.open(ev);
        }

        function showFabs() {
            var state = $state.current.name;
            var isLoggedIn = AuthService.isAuthenticated();
            if (!isLoggedIn && (state !== 'scheduleboard' || state !== 'signup' || state !== 'registration')) {
                return false;
            } else {
                return true;
            }
        }

        function hasAvatar(boarder) {
            if (_.has(boarder, 'avatarUrl')) {
                return !(_.isNull(boarder.avatarUrl) || _.isEmpty(boarder.avatarUrl));
            }
            return false;
        }

        function activate() {

            if (vm.isAuth()) {
                vm.token = AuthService.getAccessToken();
                vm.user = AuthService.getUser();
                vm.availableAccountRoles = AuthService.getAccountRoles();
                vm.currentAccountRole = AuthService.getActiveAccountRole();

                // reload data if the user changes their company account
                $scope.$on('user-changed-account', function (event, accountRoleSelectedByUser){
                    vm.user = AuthService.getUser();
                    vm.availableAccountRoles = AuthService.getAccountRoles();
                    vm.currentAccountRole = AuthService.getActiveAccountRole();
                });

                // reload the user if the avatar has been updated
                $scope.$on('user-avatar-updated', function (event, userObj){
                    vm.user = userObj;
                });
            } else {
                var state = $state.current.name;
                // if(!((state === 'signup')|| (state === 'registration')|| (state === 'scheduleboard') || (state === 'users.login') )){
                    // $state.go('scheduleboard', {reload: true});
                // }
            }
        }

        function showLoginDialog(event) {
            $mdDialog.show({
                templateUrl: 'app/scheduleboard/users/login-dialog.html',
                controller: 'LoginDialogController',
                controllerAs: 'dialog',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true
            }).then(function () {
                $mdToast.show($mdToast.simple().textContent('Thanks for logging in!'));
                $state.go('scheduleboard.resources', {}, {
                    reload: true
                });
            });
        }
    }
})();
