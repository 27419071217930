(function() {
    'use strict';

    angular
        .module('scheduleboard.equipment')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider) {

        $stateProvider
        .state('equipment', {
            url: '/app/equipment',
            templateUrl: 'app/scheduleboard/equipment/equipment.tmpl.html',
            // set the controller to load for this page
            controller: 'EquipmentController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        });
    }
})();
