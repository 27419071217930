(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('NoteService', NoteService);

    NoteService.inject = ['$q', '$http', '$timeout', 'Note'];
    function NoteService($q, $http, $timeout, Note) {
        var service = {
            addNote: addNote,
            deleteNote: deleteNote,
            getAllNotes: getAllNotes,
            getNoteById: getNoteById,
            getNotesByJobId: getNotesByJobId
        };
        
        return service;

        ////////////////
        function addNote(NoteObj){
            return Note.upsert(NoteObj).$promise;
        };

        function deleteNote(NoteObj){
            return Note.delete(NoteObj).$promise;
        };

        function getAllNotes(){
            return Note.find().$promise;
        };

        function getNoteById(id){
            return Note.findById({ id: id }).$promise;
        };

        function getNotesByJobId(jobId){
            return Note.find({ filter: { where: {"jobId": jobId} } }).$promise;
        };
    }
})();