(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('JobsService', JobsService);

    JobsService.inject = ['$q', '$http', '$timeout', 'AuthService', 'createChangeStream', 'LoopBackAuth', 'API', 'Job'];
    function JobsService($q, $http, $timeout, AuthService, createChangeStream, LoopBackAuth, API, Job) {
        var service = {
            addJob: addJob,
            deleteJob: deleteJob,
            getAllJobs: getAllJobs,
            getChangeStream: getChangeStream,
            getDocumentsByJobId: getDocumentsByJobId,
            getJobById: getJobById,
            getPartialsByJobId: getPartialsByJobId,
            getTasksByJobId: getTasksByJobId,
            saveEditedJob: saveEditedJob,
            setActiveStatus: setActiveStatus,
            getAllJobsWithPartialsAndRange:getAllJobsWithPartialsAndRange,
        };
        
        return service;

        ////////////////
        function addJob(jobObj){
            return Job.create(jobObj).$promise;
        };

        function deleteJob(taskObj){
            return Job.delete(taskObj).$promise;
        };

        function getAllJobsWithPartialsAndRange(loadAllRelatedModels,start,end){

            var criteria = {};

            if (loadAllRelatedModels) {
                //var today = moment().format("YYYY-MM-DD");
                //var start = moment(today).startOf('day');
                //var end = moment(today).endOf('day');

                // this filter ensures we include partials, but for today ONLY
                criteria.filter = {
                    include: [
                        "documents",
                        "tasks",
                        {
                            relation: 'notes',
                            scope: {
                                include: ["boarder"]
                            }
                        },
                        {
                            //TODO:Review why this doesn't pass back crew or equipment
                            relation: 'partials', // include the partial object
                            scope: { // further filter the partial object
                                where: {
                                    day: { between: [ start.toISOString(), end.toISOString() ] }
                                },
                                include: ["crew", "equipment"]
                            }
                        },
                    ]
                };
            }

            return Job.find(criteria).$promise;
        };

        function getAllJobs(loadAllRelatedModels){
            
            var criteria = {};
            
            if (loadAllRelatedModels) {
                var today = moment().format("YYYY-MM-DD");
                var start = moment(today).startOf('day');
                var end = moment(today).endOf('day');

                // this filter ensures we include partials, but for today ONLY
                criteria.filter = {
                    include: [
                        "documents",
                        "tasks",
                        {
                            relation: 'notes',
                            scope: {
                                include: ["boarder"]
                            }
                        },
                        {
                            relation: 'partials', // include the partial object
                            scope: { // further filter the partial object
                                where: { 
                                    day: { between: [ start.toISOString(), end.toISOString() ] }
                                },
                                include: ["crew", "equipment"]
                            }
                        },
                    ]
                };
            }

            return Job.find(criteria).$promise;
        };

        function getChangeStream(){
            // this built-in call doesn't work, as what it returns is useless
            // return Job.createChangeStream().$promise;

            // here is what we will do instead
            var changeStreamUrl = API.baseUrl + '/Jobs/change-stream?access_token=' + LoopBackAuth.accessTokenId;
            var src = new EventSource(changeStreamUrl);
            return createChangeStream(src);
        };

        function getDocumentsByJobId(jobId){
            return Job.documents({ id: jobId }).$promise;
        };

        function getJobById(jobId, loadAllRelatedModels, todayOnly){
            var criteria = { id: jobId };

            if (loadAllRelatedModels) {
                var today = moment().format("YYYY-MM-DD");
                var start = moment(today).startOf('day');
                var end = moment(today).endOf('day');

                // this filter ensures we include partials, but for today ONLY
                criteria.filter = {
                    include: [
                        "documents",
                        "tasks",
                        {
                            relation: 'notes',
                            scope: {
                                include: ["boarder"]
                            }
                        },
                        {
                            relation: 'partials', // include the partial object
                            scope: { // further filter the partial object
                                include: [ "crew", "equipment" ]
                            }
                        }
                    ]
                };

                // if todayOnly, add date filter
                if (todayOnly) {
                    criteria.filter.include[2].scope.where = { day: { between: [ start.toISOString(), end.toISOString() ] } };
                }
            }

            //var job = Job.find(criteria);
            console.log(criteria);

            //return Job.findById(criteria).$promise;
            //return true;
            //var job = Job.findById(criteria).$promise;
            //console.log(job);
            return Job.findById(criteria).$promise.then(function(job){
                return job;

            }, function(err){
                console.log(err);
            });
        };

        function getPartialsByJobId(jobId){
            return Job.partials({ id: jobId }).$promise;
        };

        function getTasksByJobId(jobId){
            return Job.tasks({ id: jobId }).$promise;
        };

        function saveEditedJob(editedJobData){
            console.log("saveEditedJob()",editedJobData);
            return Job.upsert(editedJobData).$promise;
        };

        function setActiveStatus(job, status){
            console.log(job);

            var where = {where: {"id": job.id}
            };

            return Job.upsert(job).$promise;
            //console.log(status);

            //return Job.upsert({id: job.id, active: status},function(err){console.log(err);}).$promise;
        };
    }
})();