'use strict';

    angular
        .module('scheduleboard.root')
        .controller('RootController', RootController);

    RootController.inject = ['$scope', '$state'];
    function RootController($scope, $state ) {
        $state.go('scheduleboard.landing');
    }

