/* recommended */
/* spinner.directive.js */

/**
 * @desc spinner directive that can be used anywhere across apps at a company named Acme
 * @example <div acme-shared-spinner></div>
 */
angular.module('scheduleboard.equipment', []);

angular
    .module('scheduleboard.equipment')
    .directive('equipmentAvatar', equipmentAvatar);

function equipmentAvatar() {
    return {
      restrict: 'E',
      templateUrl: 'app/scheduleboard/equipment/equipmentAvatar.tmpl.html',
      scope: {
        equipment: '=',
        theme: '@'
      },
      controller: function ($scope, API)     {
        $scope.baseUrl = API.baseUrl;
        $scope.theme = $scope.theme || 'default';
      }
    }
}