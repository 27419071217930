(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('AuthService', AuthService);

    AuthService.inject = ['$q', '$rootScope', '$state', 'USER_PREFS', 'UserPrefsService', 'LoopBackAuth', 'Boarder'];
    function AuthService($q, $rootScope, $state, USER_PREFS, UserPrefsService, LoopBackAuth, Boarder) {

        var accountRoles = {};
        var accessToken = {};
        var boarder = {};
        var currentlySelectedAccountRole = {};

        var service = {
            getBusinessAccountId: getBusinessAccountId,
            getBusinessAccountUUID: getBusinessAccountUUID,
            setActiveAccountRole: setActiveAccountRole,
            setActiveAccountRoleByName: setActiveAccountRoleByName,
            login: login,
            logout: logout,
            loadUserCredentials: loadUserCredentials,
            resetPassword: resetPassword,
            destroyUserCredentials: destroyUserCredentials,
            isAuthorized: isAuthorized,
            updateStoredUserObject: updateStoredUserObject,

            isManager: isManager,
            isScheduler: isScheduler,
            isCustomer: isCustomer,
            isAdmin: isAdmin,
            isAuthenticated: function() { return Boarder.isAuthenticated(); },

            getAccessToken: function() { return accessToken; },
            getActiveAccount: function() { return currentlySelectedAccountRole.account; },
            getActiveAccountRole: function() { return currentlySelectedAccountRole; },
            getAccountRoles: function() { return accountRoles; },
            getUser: function() { return boarder; },
            username: function() { return boarder.username; },
            name: function() { return boarder.firstName + ' ' + boarder.lastName; },
            email: function() { return boarder.email; },
            mobilePhone: function() { return boarder.mobilePhone; }
        };

        return service;

        ////////////////

        function isManager() {
            return isUserRole('MANAGER');
        }

        function isScheduler() {
            return isUserRole('SCHEDULER');
        }

        function isCustomer() {
            return isUserRole('CUSTOMER');
        }

        function isAdmin() {
            return isUserRole('ADMIN');
        }

        function isUserRole(roleToCheck) {
            if (currentlySelectedAccountRole){
                return (currentlySelectedAccountRole.role == roleToCheck);
            } else {
                return false;
            }
        }

        function getBusinessAccountId(){
            // LogService.log("auth.service.js", "getBusinessAccountId()", "looking up account id");
            if (accountRoles){
                // if not account role has been selected, then use the most recent with account activity
                if (_.isEmpty(currentlySelectedAccountRole)){
                    setSelectedAccountToMostRecentlyUsed();
                }

                return currentlySelectedAccountRole.accountId;

                // TOFIX let the user choose
            }
            return -1;
        }

        function getBusinessAccountUUID(){
            // LogService.log("auth.service.js", "getBusinessAccountUUID()", "looking up account UUID");

            var uuid = undefined;

            if (currentlySelectedAccountRole){
                if (currentlySelectedAccountRole.account){
                    uuid = currentlySelectedAccountRole.account.accountUUID;
                }
            }

            return uuid;
        }

        function setActiveAccountRole(accountRole){
            if (accountRole){
                // console.log(accountRole);
                currentlySelectedAccountRole = accountRole;
                currentlySelectedAccountRole.lastUsedAt = new moment();
                // console.log(accountRoles);
                // console.log(currentlySelectedAccountRole);
                $rootScope.$broadcast("user-changed-account", currentlySelectedAccountRole)
            }else{
                // console.log("NO ACCOUNT ROLE SENT");
            }
        }

        function setActiveAccountRoleByName(accountName){
            if (accountName){
                const match = _.find(accountRoles, function(ar){
                    return ar.account.name == accountName;
                });

                setActiveAccountRole(match);
            }
        }

        function setSelectedAccountToMostRecentlyUsed(){
            if (accountRoles){
                if (accountRoles.length > 0){
                    // NOTE: currently assumes the accountRoles are sorted in descending
                    //       order by the 'lastUsedAt' property

                    // for now, auto-select the most recent account used
                    setActiveAccountRole(accountRoles[0])
                }
            }
        }

        function loadUserCredentials() {
            var storedCredObject = UserPrefsService.getPreference(USER_PREFS.userKey, null);

            // try to get the user's info stored in local cache
            if (storedCredObject) {
                // console.log("auth.service.js", "loadUserCredentials()", "found locally stored credential object");
                useCredentials(storedCredObject);
            } else {
                // console.log("auth.service.js", "loadUserCredentials()", "found no locally stored credential object");
                destroyUserCredentials();
                //$state.go('login');

                // console.log("calling Boarder.getCurrent()...");
                // Boarder.getCurrent(
                //     function success(response){
                //         console.log("Boarder.getCurrent() success:", response);
                //         useCredentials(response);
                //     },
                //     function error(err){
                //         console.log("Boarder.getCurrent() error:", err);
                //     }
                // );
            }
        }

        function storeUserCredentials(userData) {
            // console.log("auth.service.js", "storeUserCredentials()", "storing creds: " + userData);
            UserPrefsService.setPreference(USER_PREFS.userKey, userData);
        }

        function updateStoredUserObject(updatedUserObj) {
            if (updatedUserObj){
                // grab what is currently in cache
                var storedCredObject = UserPrefsService.getPreference(USER_PREFS.userKey, null);

                if (storedCredObject) {
                    // replace the user object and save back to cache
                    storedCredObject.user = updatedUserObj;
                    storeUserCredentials(storedCredObject);
                }

                // set our in-memory copy
                boarder = updatedUserObj;
            }
        }

        function isValidUserCredentialObject(userData){
            var validAccessToken;
            var validCreated;
            var validUser;
            // technique from http://stackoverflow.com/questions/7636789/in-javascript-is-there-an-easier-way-to-check-if-a-property-of-a-property-exist
            var validAccessToken = (validAccessToken=userData) && (validAccessToken=validAccessToken.id) && (typeof validAccessToken === "string");
            var validCreated = (validCreated=userData) && (validCreated=validCreated.created) && (typeof validCreated === "string");
            var validUser = (validUser=userData) && (validUser=validUser.user) && (typeof validUser === "object");

            return validAccessToken && validCreated && validUser;
        }

        function useCredentials(userData) {
            // console.log("auth.service.js", "useCredentials()", "userData is " + JSON.stringify(userData));

            if (isValidUserCredentialObject(userData)){
                // pass the user object to logging service for more descriptve logging
                // console.log(userData);

                // console.log("auth.service.js", "useCredentials()", "sufficient user credential data available");

                // sort and store accounts by date of use and select the most recent
                accountRoles = _.sortBy(userData.accountroles, function(role){ return new Date(role.lastUsedAt); });
                accountRoles = _.reverse(accountRoles);

                // default behavior
                setSelectedAccountToMostRecentlyUsed();

                // extract the current accessToken and user
                accessToken = userData.id;
                boarder = userData.user;

                // save to local cache
                storeUserCredentials(userData);
            } else {
                console.error("auth.service.js", "useCredentials()", "insufficient user credential data available");
                destroyUserCredentials();
                $state.go('login');
            }
        }

        function destroyUserCredentials() {
            // console.log("auth.service.js", "destroyUserCredentials()", "credentials destroyed");
            LoopBackAuth.clearUser();
            LoopBackAuth.clearStorage();

            // clear all user preferences
            UserPrefsService.clearAllPreferences();

            // clear variables in memory
            accessToken = {};
            boarder = {};
        }

        function login(userNameAndPassObject) {
            return $q(function(resolve, reject) {
                // login
                Boarder.login({ rememberMe: true }, userNameAndPassObject).$promise.then(function (response) {
                    // console.log("auth.service.js", "login()", "response: " + response);

                    // included in the response are AccountRoles (there may be mutliple accounts)
                    // we will just return the response and let the UI handle account selection

                    // store credentials and resolve
                    useCredentials(response);
                    resolve('Login success.');

                    // OLD - REMOVE?
                    // fetch associated business account info because it doesn't get included
                    // Account.findById({id: response.user.accountId}).$promise.then(function(account) {
                    //     // store the account info in the boarder
                    //     response.user.account = account;

                    //     // store credentials and resolve
                    //     storeUserCredentials(response);
                    //     resolve('Login success.');
                    // }, function(err){
                    //     LogService.log("auth.service.js", "login()", "error: " + JSON.stringify(err));
                    //     // if error, then reject
                    //     reject('Login Failed.');
                    // });

                }, function(err){
                    console.error("auth.service.js", "login()", "error: " + JSON.stringify(err));
                    // if error, then reject
                    reject('Login Failed.');
                });
            });
        };

        function logout() {
            return $q(function(resolve, reject) {
                Boarder.logout().$promise.then(function (response) {
                    // if successful, then store credentials and resolve
                    destroyUserCredentials();
                    resolve('Logout success.');
                }, function(response){
                    console.error("auth.service.js", "logout()", "error: " + JSON.stringify(response));
                    // if error, then reject
                    reject('Logout Failed.');
                });
            });
        };

        function isAuthorized(authorizedRoles) {
            if (!angular.isArray(authorizedRoles)) {
                authorizedRoles = [authorizedRoles];
            }
            return (isAuthenticated && authorizedRoles.indexOf(role) !== -1);
        };

        function resetPassword(email) {
            // console.log("auth.service.js", "resetPassword()", "resetting password for " + email);
            if (angular.isDefined(email)){
                return Boarder.resetPassword({ "email" : email}).$promise;
            }
        }
    };
})();
