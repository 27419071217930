angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise('/');
  //$locationProvider.hashPrefix('');


  $stateProvider
    .state('scheduleboard', {
      url: '/',
      templateUrl: 'app/scheduleboard/landing/landing.tmpl.html',
      controller: 'LandingController',
      controllerAs: 'vm'
    });
}
