(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('BoarderService', BoarderService);

    BoarderService.inject = ['$q', 'AuthService', 'API', 'Boarder', 'AccountRole', '$log'];
    function BoarderService($q, AuthService, API, Boarder, AccountRole, $log) {
        var service = {
            deleteBoarder: deleteBoarder,
            getAllBoarders: getAllBoarders,
            getBoarderById: getBoarderById,
            updateBoarderContactInto: updateBoarderContactInto,
            updateBoarderAvatarUrl: updateBoarderAvatarUrl,
            updateBoarderRole: updateBoarderRole,
            getPartialsForBoarder: getPartialsForBoarder,
            getPartialsForecastForBoarder:getPartialsForecastForBoarder,
            getAccountRoles: getAccountRoles

        };
        
        return service;

        ////////////////

        function deleteBoarder(BoarderObj){
            return Boarder.delete(BoarderObj).$promise;
        };

        function getAllBoarders(){
            return Boarder.find().$promise;
        };

        function getAccountRoles(){
            return Boarder.accountroles().$promise;
        };

        function getPartialsForBoarder(boarderId){
            return Boarder.partials({id:boarderId,filter:{include:["job"]}}).$promise;
        }

        function getPartialsForecastForBoarder(yyyy_mm_dd,days,boarderId){
            var endDate = moment(yyyy_mm_dd).add(days,"days");
            //this doesn't function the right way.  need to do better filtering.
            return Boarder.partials({id:boarderId, filter:{include:["job"],relation:'partial',scope: {where: {
                            day: { between: [yyyy_mm_dd.toISOString(), endDate.toISOString() ] }}
                        }}}).$promise;
        }

        function getBoarderById(id){
            var criteria = {
                id: id,
                filter: {
                    include : ["invites", "partials", "notes", {
                        relation: 'accountroles',
                        scope: {
                            where: {accountId: AuthService.getBusinessAccountId()}
                        }
                    }]
                }
            };
            
            return Boarder.findById(criteria).$promise;
        };

        function updateBoarderContactInto(boarderIdNameEmailPhone){
            return Boarder.upsert({
                id: boarderIdNameEmailPhone.id,
                firstName: boarderIdNameEmailPhone.firstName,
                lastName: boarderIdNameEmailPhone.lastName,
                email: boarderIdNameEmailPhone.email,
                mobilePhone: boarderIdNameEmailPhone.mobilePhone,

                // we use email as the username, so make that change for the user
                // consider having the backend do this?
                username: boarderIdNameEmailPhone.email
            }).$promise;
        };

        function updateBoarderAvatarUrl(boarder, url){
            return Boarder.upsert({id: boarder.id, avatarUrl: url}).$promise;
        };

        function updateBoarderRole(boarder, newRole){
            const where = {where: {"and" :
                [{"boarderId": boarder.id},
                {"accountId": AuthService.getBusinessAccountId()}]
            }};

            return AccountRole.upsertWithWhere(where, {role: newRole}).$promise;
        };
    }
})();