(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('FileTransferService', FileTransferService);

    FileTransferService.inject = ['$q', 'AuthService', 'API', 'APP_GLOBALS', 'Upload'];
    function FileTransferService($q, AuthService, API, APP_GLOBALS, Upload) {
        var service = {
            abort: abort,
            init: init,
            uploadAvatarPhoto: uploadAvatarPhoto,
            uploadPhotoForJobId: uploadPhotoForJobId
        };

        return service;

        ////////////////

        // this is called remotely (in app.js) on $ionicPlatform.ready() or $ionicPlatform.on("resume")
        function init() {
            // LogService.log("filetransfer.service.js", "init()", "entered init");

            // make sure the actual functions are created, since the real device/cordova is now running

        }

        function abort() {
            // LogService.log("filetransfer.service.js", "abort()", "FileTransferService :: abort()");
        }

        function uploadAvatarPhoto(fileURL) {
            // LogService.log("filetransfer.service.js", "uploadAvatarPhoto()", "uploading avatar photo: " + fileURL);
            return uploadPhotoForJobId(file, null);
        }

        // from https://github.com/apache/cordova-plugin-file-transfer
        function uploadPhotoForJobId(file, jobId) {


            var uploadURL = encodeURI(API.baseUrl + "/Containers/" + AuthService.getBusinessAccountId() + "/upload?access_token=" + AuthService.getAccessToken());

            var options = new FileUploadOptions();
            options.fileKey = "file";
            options.fileName = fileURL.substr(fileURL.lastIndexOf('/') + 1);
            options.mimeType = "image/jpeg";

            options.headers = {
                'Accept': 'application/json, text/plain, */*',
                'X-Access-Token': AuthService.getAccessToken()
            };

            if (jobId) {
                options.headers.JobId = jobId;
            }

            var ft = new FileTransfer();

            ft.onprogress = function (progressEvent) {
                if (progressEvent.lengthComputable) {
                    var uploadProgress = Math.round(100 * progressEvent.loaded / progressEvent.total);

                    // update loading screen progress
                    $ionicLoading.show({
                        duration: APP_GLOBALS.loadingTimeoutMS,
                        template: '<ion-spinner></ion-spinner> <br> Uploading ' + uploadProgress + ' %'
                    });
                } else {
                }
            };

            // show loading screen for upload
            $ionicLoading.show({
                duration: APP_GLOBALS.loadingTimeoutMS,
                template: '<ion-spinner></ion-spinner> <br> Uploading 0 %'
            });

            ft.upload(fileURL, uploadURL, function win(r) {
                // LogService.log("filetransfer.service.js", "uploadPhotoForJobId()", "Code = " + r.responseCode);
                // LogService.log("filetransfer.service.js", "uploadPhotoForJobId()", "Response = " + r.response);
                // LogService.log("filetransfer.service.js", "uploadPhotoForJobId()", "Sent = " + r.bytesSent);
                deferred.resolve(JSON.parse(r.response));
                $ionicLoading.hide();
            }, function fail(error) {
                // LogService.log("filetransfer.service.js", "uploadPhotoForJobId()", "upload error: " + JSON.stringify(error));
                // LogService.log("filetransfer.service.js", "uploadPhotoForJobId()", "upload error source: " + error.source);
                // LogService.log("filetransfer.service.js", "uploadPhotoForJobId()", "upload error target: " + error.target);
                deferred.reject(error);
            }, options);

            return deferred.promise;
        }

    }
})();