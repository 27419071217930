angular
    .module('app', [
        'ui.router',
        'scheduleboard.core',
        'scheduleboard.services',
        'scheduleboard.landing',
        'scheduleboard.toolbar',
        'scheduleboard.footer',
        'scheduleboard.users',
        'scheduleboard.services',
        'scheduleboard.schedule',
        'scheduleboard.resources',
        'scheduleboard.toolbar',
        'scheduleboard.equipment',
        'scheduleboard.lead',
        'scheduleboard.jobs',
        'scheduleboard.employees',
        'scheduleboard.account',
        'lbServices',
        'ngAnimate',
        'ngMaterial',
        'ngMessages',
        'ngCookies',
        'ngSanitize',
        'ngAria',
        'ngResource',
        'ngStorage',
        'toastr',
        'ls.ChangeStream',
        'uiGmapgoogle-maps',
        'mdSteppers',
        'angular.img',
        'md.data.table',
        'dndLists',
        'logglyLogger',
        'ngAutocomplete',
        'bc.TelephoneFilter',
        'ngFileUpload',
        'akoenig.deckgrid',
        'ui.bootstrap',
        'ngMaterialDateRangePicker',
        'angular-loading-bar',
        'thatisuday.ng-image-gallery',
        'vsGoogleAutocomplete',
        'angular-google-analytics','materialCalendar'])

    .config(config)
    .run(['$rootScope', 'AuthService', '$transitions', '$state', '$log', 'Analytics', '$location', function ($rootScope, AuthService, $transitions, $state, $log, Analytics, $location) {

        $rootScope.previousUrl = {'name':'resources'};
        $rootScope.previousUrlParams = {};
        // AuthService.init();
        AuthService.loadUserCredentials();

        $transitions.onSuccess({}, function (transition, $rootScope) {
            // console.log($location.path());
            Analytics.trackPage($location.path());
        });
        // prevent users who are logged in from going to the login / default page
        $transitions.onEnter({entering: 'scheduleboard'}, function (transition, state) {
            // $log.log("Transition from " + transition.from().name + " to " + transition.to().name);
            if (AuthService.isAuthenticated()) {
                var fromStateName = transition.from().name;

                // if the user isn't coming from a valid previous state, send them to schedule
                if (angular.isUndefined(fromStateName) || fromStateName === "") {
                    $state.go('schedule');
                }

                return false;
            } else {
                return true;
            }
        });

        // watch state transitions and handle them appropriately
        $transitions.onSuccess({}, function (transition) {
            // $log.log(transition);

            //console.log("to:", transition.to());
            //console.log("from:", transition.from());
            //$rootScope.previousUrl ='';
            var fromState = transition.from();
            //console.log(staet);
            if(!fromState.name){
                fromState.name = 'resources';
            }
            $rootScope.previousUrl = fromState;
            //console.log($rootScope.previousUrl.name);
            //$rootScope.lastUrl = transition.from();
            $rootScope.accountRoles;
            // $log.log("Successful Transition from " + transition.from().name + " to " + transition.to().name);

            //console.log($rootScope);
            var url = transition.to().name;
            $rootScope.previousUrlParams = transition.params();
            var isUserLoggedIn = AuthService.isAuthenticated();
            if (!isUserLoggedIn && ((url !== 'scheduleboard') && (url !== 'registration') && (url !== 'signup')&&(url !== 'passwordResetRequest')&&(url !== 'passwordReset'))) {

                // $log.log(url);
                $state.go('login');
            }else
            {
                if(isUserLoggedIn){
                    if (!angular.isDefined($rootScope.accountRoles)|| $rootScope.accountRoles === ''){
                    $rootScope.accountRoles = $rootScope.getAccountRoles();
                    }
                    //console.log(accountRoles);
                    if($rootScope.accountRoles.length ==0){
                        //console.log("you aren't associated with any accounts!");
                        $state.go('noAssociatedAccount');
                    }else{
                        console.log("you are associated with account(s)");
                        var currentAccountRole = AuthService.getActiveAccountRole();
                        console.log(currentAccountRole.account);
                        if(currentAccountRole.account.subscriptionExpired) {
                            //profilePage
                            $state.go(userPage({userId:$rootScope.getUser().id}));
                        }else {
                            //console.log("subscription OK");
                            console.log(currentAccountRole);
                        }

                    }

                }
            }

        });

        // these are mapped here primarily for those services that can't use AuthService
        // (basically, any service that would have a circular dependency with AuthService)
        $rootScope.getBusinessAccountId = AuthService.getBusinessAccountId;
        $rootScope.getAccessToken = AuthService.getAccessToken;
        $rootScope.getUser = AuthService.getUser;
        $rootScope.getActiveAccount = AuthService.getActiveAccount;
        $rootScope.getAccountRoles = AuthService.getAccountRoles;

    }])
    .constant('moment', moment);

/** @ngInject */
function config($logProvider, cfpLoadingBarProvider, toastrConfig, LoopBackResourceProvider, API, $mdThemingProvider, uiGmapGoogleMapApiProvider, $httpProvider, $locationProvider, $mdIconProvider, AnalyticsProvider) {

    AnalyticsProvider.setAccount('UA-100877453-2');
    // set up a loading bar
    cfpLoadingBarProvider.parentSelector = '#loading-bar-container';
    cfpLoadingBarProvider.includeBar = true;
    cfpLoadingBarProvider.includeSpinner = false;

    // set FontAwesome as font provider
    $mdIconProvider
    .defaultFontSet('FontAwesome')
    .fontSet('fa', 'FontAwesome');

    // stripeProvider.url = "https://js.stripe.com/v3/";

    $locationProvider.html5Mode(true);

    /* LogglyLoggerProvider.inputToken( SECRETS.loggly )
                  .useHttps(true)
                  .sendConsoleErrors(true)
                  .includeUrl(true)
                  .includeTimestamp(true)
                  .deleteHeaders(true); */
    // Enable log
    $logProvider.debugEnabled(true);

    // Set options third-party lib
    toastrConfig.allowHtml = true;
    toastrConfig.timeOut = 3000;
    toastrConfig.positionClass = 'toast-top-right';
    toastrConfig.preventDuplicates = true;
    toastrConfig.progressBar = true;

    // stripe.setPublishableKey('pk_test_70NDp3bUz9NFOaqYFxcvNIqJ');

    // $mdThemingProvider.theme('default')
    //   .primaryPalette('green')
    //   .accentPalette('grey')
    //   .warnPalette('red');

    $mdThemingProvider.setDefaultTheme('default');

    var customPrimary = {
        50: '#a4dca0',
        100: '#93d58e',
        200: '#81ce7b',
        300: '#6fc868',
        400: '#5dc156',
        500: '#4cb944',
        600: '#44a63d',
        700: '#3d9436',
        800: '#35812f',
        900: '#2d6e29',
        A100: '#b6e3b3',
        A200: '#c8eac6',
        A400: '#daf1d8',
        A700: '#265c22'
    };
    $mdThemingProvider
    .definePalette('customPrimary',
      customPrimary);

    var customAccent = {
        50: '#303030',
        100: '#3d3d3d',
        200: '#4a4a4a',
        300: '#575757',
        400: '#636363',
        500: '#707070',
        600: '#8a8a8a',
        700: '#969696',
        800: '#a3a3a3',
        900: '#b0b0b0',
        A100: '#8a8a8a',
        A200: '#7d7d7d',
        A400: '#707070',
        A700: '#bdbdbd'
    };
    $mdThemingProvider
    .definePalette('customAccent',
      customAccent);

    var customWarn = {
        50: '#fbf8de',
        100: '#f8f3c7',
        200: '#f5eeb0',
        300: '#f3e999',
        400: '#f0e483',
        500: '#eddf6c',
        600: '#eada55',
        700: '#e7d53f',
        800: '#e5d028',
        900: '#d8c41b',
        A100: '#fefdf4',
        A200: '#ffffff',
        A400: '#ffffff',
        A700: '#c2af18'
    };
    $mdThemingProvider
    .definePalette('customWarn',
      customWarn);

    var customBackground = {
        50: '#ffffff',
        100: '#ffffff',
        200: '#ffffff',
        300: '#ffffff',
        400: '#ffffff',
        500: '#fbfbff',
        600: '#e1e1ff',
        700: '#c8c8ff',
        800: '#aeaeff',
        900: '#9595ff',
        A100: '#ffffff',
        A200: '#ffffff',
        A400: '#ffffff',
        A700: '#7b7bff'
    };
    $mdThemingProvider
    .definePalette('customBackground',
      customBackground);

    //  $mdThemingProvider.theme('default')
    //      .primaryPalette('customPrimary')
    //      .accentPalette('customAccent')
    //      .warnPalette('customWarn')
    //      .backgroundPalette('customBackground');

    $mdThemingProvider.definePalette('vividBlue', {
        50: 'e4e4ff',
        100: 'bcbcff',
        200: '8f90ff',
        300: '6263ff',
        400: '4041ff',
        500: '1e20ff',
        600: '1a1cff',
        700: '1618ff',
        800: '1213ff',
        900: '0a0bff',
        A100: 'ffffff',
        A200: 'f5f5ff',
        A400: 'c2c2ff',
        A700: 'a8a9ff',
        contrastDefaultColor: 'light',
        contrastDarkColors: [
            '50',
            '100',
            '200',
            'A100',
            'A200',
            'A400',
            'A700'
        ],
        contrastLightColors: [
            '300',
            '400',
            '500',
            '600',
            '700',
            '800',
            '900'
        ]
    });

    $mdThemingProvider.definePalette('dodgerBlue', {
        50: 'e4f2ff',
        100: 'bcdeff',
        200: '8fc8ff',
        300: '62b1ff',
        400: '40a1ff',
        500: '1e90ff',
        600: '1a88ff',
        700: '167dff',
        800: '1273ff',
        900: '0a61ff',
        A100: 'ffffff',
        A200: 'f5f8ff',
        A400: 'c2d5ff',
        A700: 'a8c3ff',
        contrastDefaultColor: 'light',
        contrastDarkColors: [
            '50',
            '100',
            '200',
            '300',
            '400',
            'A100',
            'A200',
            'A400',
            'A700'
        ],
        contrastLightColors: [
            '500',
            '600',
            '700',
            '800',
            '900'
        ]
    });

    $mdThemingProvider.definePalette('tarheelBlue', {
        50: 'e1f7fd',
        100: 'b3eafa',
        200: '80ddf7',
        300: '4dcff4',
        400: '27c4f1',
        500: '01baef',
        600: '01b3ed',
        700: '01abeb',
        800: '01a3e8',
        900: '0094e4',
        A100: 'ffffff',
        A200: 'd8efff',
        A400: 'a5dbff',
        A700: '8bd1ff',
        contrastDefaultColor: 'light',
        contrastDarkColors: [
            '50',
            '100',
            '200',
            '300',
            '400',
            '500',
            '600',
            'A100',
            'A200',
            'A400',
            'A700'
        ],
        contrastLightColors: [
            '700',
            '800',
            '900'
        ]
    });

    // generated @ http://mcg.mbitson.com/#!/?cyan=%2300a5ce#9e9e9e%23
    $mdThemingProvider.definePalette('customCyan', {
        50: 'e0f4f9',
        100: 'b3e4f0',
        200: '80d2e7',
        300: '4dc0dd',
        400: '26b3d5',
        500: '00a5ce',
        600: '009dc9',
        700: '0093c2',
        800: '008abc',
        900: '0079b0',
        A100: 'daf1ff',
        A200: 'a7dfff',
        A400: '74cdff',
        A700: '5ac3ff',
        contrastDefaultColor: 'light',
        contrastDarkColors: [
            '50',
            '100',
            '200',
            '300',
            '400',
            'A100',
            'A200',
            'A400',
            'A700'
        ],
        contrastLightColors: [
            '500',
            '600',
            '700',
            '800',
            '900'
        ]
    });

    // generated @ http://mcg.mbitson.com/#!/?cyan=%239e9e9e#9e9e9e%23
    $mdThemingProvider.definePalette('customGrey', {
        50: 'fbfbfb',
        100: 'f4f4f4',
        200: 'ededed',
        300: 'e6e6e6',
        400: 'e0e0e0',
        500: 'dbdbdb',
        600: 'd7d7d7',
        700: 'd2d2d2',
        800: 'cdcdcd',
        900: 'c4c4c4',
        A100: 'ffffff',
        A200: 'ffffff',
        A400: 'ffffff',
        A700: 'ffffff',
        contrastDefaultColor: 'light',
        contrastDarkColors: [
            '50',
            '100',
            '200',
            '300',
            '400',
            '500',
            '600',
            '700',
            '800',
            '900',
            'A100',
            'A200',
            'A400',
            'A700'
        ],
        contrastLightColors: []
    });

    // generated @ http://mcg.mbitson.com/#!?customcyan=%23f44336#%2F
    $mdThemingProvider.definePalette('customRed', {
        50: 'fee8e7',
        100: 'fcc7c3',
        200: 'faa19b',
        300: 'f77b72',
        400: 'f65f54',
        500: 'f44336',
        600: 'f33d30',
        700: 'f13429',
        800: 'ef2c22',
        900: 'ec1e16',
        A100: 'ffffff',
        A200: 'ffe9e9',
        A400: 'ffb8b6',
        A700: 'ff9f9c',
        contrastDefaultColor: 'light',
        contrastDarkColors: [
            '50',
            '100',
            '200',
            '300',
            '400',
            'A100',
            'A200',
            'A400',
            'A700'
        ],
        contrastLightColors: [
            '500',
            '600',
            '700',
            '800',
            '900'
        ]
    });

    // http://mcg.mbitson.com/#!?customcyan=%23ececec#%2F
    $mdThemingProvider.definePalette('customLightBackground', {
        50: 'fdfdfd',
        100: 'f9f9f9',
        200: 'f6f6f6',
        300: 'f2f2f2',
        400: 'efefef',
        500: 'ececec',
        600: 'eaeaea',
        700: 'e7e7e7',
        800: 'e4e4e4',
        900: 'dfdfdf',
        A100: 'ffffff',
        A200: 'ffffff',
        A400: 'ffffff',
        A700: 'ffffff',
        contrastDefaultColor: 'light',
        contrastDarkColors: [
            '50',
            '100',
            '200',
            '300',
            '400',
            '500',
            '600',
            '700',
            '800',
            '900',
            'A100',
            'A200',
            'A400',
            'A700'
        ],
        contrastLightColors: []
    });

    $mdThemingProvider.theme('default')
       .primaryPalette('tarheelBlue')
       .accentPalette('customGrey')
       .warnPalette('customRed');
      //  .backgroundPalette('customLightBackground');

    // For Loopback, use a custom auth header token instead of the default 'Authorization'
    LoopBackResourceProvider.setAuthHeader('X-Access-Token');

    // Change the URL where to access the LoopBack REST API server
    LoopBackResourceProvider.setUrlBase(API.baseUrl);

    /* uiGmapGoogleMapApiProvider.configure({
        key: 'AIzaSyAGSmIAVZj35KxOOpbumA3oGyuXXX5LHq8',
        v: '3.20', //defaults to latest 3.X anyhow
        libraries: 'places,weather,geometry,visualization'
    }); */

    // google maps provider

    // stripeProvider.setPublishableKey('pk_test_70NDp3bUz9NFOaqYFxcvNIqJ');

    uiGmapGoogleMapApiProvider.configure({
        key: 'AIzaSyC4KA0kxcyaiCbU12edr-EdKea1QFbh3bM',
        v: '3.exp',
        libraries: 'weather,geometry,visualization,places'
    });

    $httpProvider.interceptors.push('AuthInterceptor');

    // refresh credentials
    // AuthService.loadUserCredentials();
}
