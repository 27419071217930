(function() {
    'use strict';

    angular
        .module('scheduleboard.account')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider) {

/*
            uiGmapGoogleMapApi.configure({
            key: 'AIzaSyAGSmIAVZj35KxOOpbumA3oGyuXXX5LHq8',
            v: '3.17',
            libraries: 'weather,geometry,visualization'
        });
*/

        $stateProvider
        .state('account', {
            url: '/app/account',
            templateUrl: 'app/scheduleboard/users/user-detail.tmpl.html',
            // set the controller to load for this page
            controller: 'UserPageController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        });
    }
})();
