(function() {
'use strict';

    angular
        .module('scheduleboard.jobs')
        .controller('JobsController', JobsController);

    JobsController.inject = ['$state', '$scope', '$rootScope','$mdDialog','AuthService', 'JobsService', 'API', 'APP_GLOBALS', '$mdToast'];
    function JobsController($state, $scope, $rootScope, $mdDialog, AuthService, JobsService, API, APP_GLOBALS, $mdToast) {
        var vm = this;

        console.log("WERE IN THE JOBS CONTROLLER");
        // where our modal's form data is stored
        vm.data = {};

        // object that holds the array of jobs for the view to display
        vm.jobs = [];
        vm.jobs = loadJobs().$promise;
        vm.jobMarkers = [];
        vm.authorized = AuthService.isAuthorized;
        vm.selectedJob='';
        vm.jobFilter = '';
        vm.scheduledStartDate ='';
        vm.isDisabled=true;

        // proxy to determine if user has manager capabilities
        vm.isManager = AuthService.isManager;
        //console.log(vm.jobs);
        // FUNCTION DECLARATIONS //
        vm.toggleJobsList = toggleJobsList;
        vm.addNewJob = addNewJob;
        vm.doRefresh = doRefresh;
        vm.hasValidAddress = hasValidAddress;
        vm.showJobDialog = showJobDialog;
        vm.setActiveStatus = setActiveStatus;
        vm.selectJob = selectJob;
        vm.clearFilters = clearFilters;
        //vm.getCenter = getCenter;
        vm.toggleEditing = toggleEditing;
        vm.updateJob = updateJob;


        activate();
/*
        var bounds = {};
        uiGmapGoogleMapApi.then(function(maps) {
            bounds = {}
        });

    vm.map = {
      center: {
        latitude: 40.1451,
        longitude: -99.6680
      },
      zoom: 4,
      bounds: bounds
    };*/

    function clearFilters(){
        vm.jobFilter='';
        vm.selectedJob='';
    }

    function selectJob(job){
        vm.selectedJob = job;
        vm.selectedJob.address.center= getCenter(vm.selectedJob);
        vm.scheduledStartDate= new Date(vm.selectedJob.scheduledStartDate);
        vm.isDisabled = true;
    }

    function toggleEditing(){
        if(vm.isDisabled ==true){
            vm.isDisabled=false;
        }else {
            vm.isDisabled=true;
        }

    }

    function toggleJobsList() {
        $mdSidenav('left').toggle();
    }
        function showJobDialog(job)
        {
            $mdDialog.show({
                templateUrl: 'components/jobs/job-dialog.html',
                controller: 'JobDialogController',
                controllerAs: 'dialog',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true,
                locals: {job: job, callbackFn: loadJobs}
            })
                .then(function () {
                    $mdToast.show($mdToast.simple().textContent('Job Updated...'));
                    //$state.go('main', {}, {reload: true});

                });
    }

        function activate(){
            loadJobs();
        }
        function addNewJob() {
            vm.modal.hide();

            // sanity checks (for backend) which won't take a null address...
            vm.data.number = vm.data.number.toString();
            if (typeof vm.data.address === "undefined") { vm.data.address = {}; }
            console.log(vm.data);

            // call the service to add via backend
            JobsService.addJob(vm.data).then(function(result){
                // console.log(result);

                // WITH LiveSet
                // the vm.jobs and view should just update

                // WITHOUT LiveSet
                // loadJobs();
            });
        };

        // called by ionic's ion-refresher pull-to-refresh
        // per http://ionicframework.com/docs/api/directive/ionRefresher/
        function doRefresh(){
            loadJobs();
        };

        function hasValidAddress(job){
            if (_.has(job, 'address')){
                var a = job.address;

                var valid = _.has(a, 'zipCode')
                         && _.has(a, 'state')
                         && _.has(a, 'city')
                         && _.has(a, 'streetAddress');

                if (valid) {
                    valid = valid
                            && !(_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress))
                            && !(_.isNull(a.city) || _.isEmpty(a.city))
                            && !(_.isNull(a.state) || _.isEmpty(a.state))
                            && !(_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
                }

                return valid;
            }
            return false;
        }


        function getCenter(job){
            if(!job){
                return {lat:0, long:0};
            }else{
                console.log(job);
                return {latitude:job.address.geocoords.lat, longitude:job.address.geocoords.lng};
            }

        }

        function addMarker(job, idKey){

              if (idKey == null) {
                idKey = "id";
              }

            var ret ={
              latitude: job.address.geocoords.lat,
              longitude: job.address.geocoords.lng

            }
            ret["idKey"]="marker"+job.id;
            ret["id"]="marker"+job.id;

              return ret;

        }

        function updateJob(){
            JobsService.saveEditedJob(vm.selectedJob).then(function(){
                $mdToast.show($mdToast.simple().textContent('Job Updated...'));

            });



        }
        function loadJobs(){


            return JobsService.getAllJobs(true).then(function(jobs){
            /*    var myBounds = '';
            uiGmapGoogleMapApi.then(function(maps){
               myBounds= new maps.LatLngBounds();
            });*/
                vm.jobs = jobs;
                console.log("jobs", jobs);
                angular.forEach(vm.jobs,function(job,index){
                    //vm.jobs[index]["map"]= { center: { latitude: job.address.geocoords.lat, longitude: job.address.geocoords.lng }, zoom: 12 , dragging:false};

                    //var marker = addMarker(job,vm.map.bounds);
                    //vm.jobMarkers.push(marker);

                    //uiGmapGoogleMapApi.then(function(maps) {
                     //   myBounds.extend(new maps.LatLng(marker.latitude,marker.longitude));
                    //});



                    if(job.partials.length > 0){
                        console.log(job);
                    }

                });
               // vm.map.bounds = myBounds;


            });

        };
        vm.isOpen = false;

        function setActiveStatus(jobToUpdate, activeStatus){
            console.log("Job Controller :: setActiveStatus()");
            JobsService.setActiveStatus(jobToUpdate, activeStatus);
        };




        $scope.$on('$destroy', function() {
            if (angular.isDefined(vm.modal)){
                vm.modal.remove();
            }
        });
    }
})();