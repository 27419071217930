(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('EmployeesService', EmployeesService);

    EmployeesService.inject = ['$q', '$http', '$timeout', 'AuthService', 'createChangeStream', 'LoopBackAuth', 'API', 'Boarder', 'AccountRole'];
    function EmployeesService($q, $http, $timeout, AuthService, createChangeStream, LoopBackAuth, API, Boarder, AccountRole) {
        var service = {
            addEmployee: addEmployee,
            inviteEmployee: inviteEmployee,
            inviteEmployees: inviteEmployees,
            getAllEmployees: getAllEmployees,
            getAllEmployeesWithAllPartialsForJobDay: getAllEmployeesWithAllPartialsForJobDay,
            getChangeStream: getChangeStream,
            getEmployeeById: getEmployeeById,
            getPartialsForEmployeeByDate: getPartialsForEmployeeByDate,
            getCurrentAccountRole: getCurrentAccountRole,
            destroyAccountRoleById: destroyAccountRoleById
        };
        
        return service;

        ////////////////
        function addEmployee(obj){
            if (obj){
                // TODO sanity check the object (password must be set - do we just give them one)?

                // add the employee to the database. the backend will send them an email confirmation
                Boarder.replaceOrCreate(obj).$promise;
            }
        };

        function getAllEmployees(loadAllRelatedModels){

            var criteria = {};

            if(loadAllRelatedModels){
                var today = moment().format("YYYY-MM-DD");
                var start = moment(today).startOf('day');
                var end = moment(today).endOf('day');

                // this filter ensures we include partials (and related jobs), but for today ONLY
                criteria.filter = {
                    include: {
                        relation: 'partials', // include the partial object
                        scope: { // further filter the partial object
                            include: "job", // include the related job object
                            where: { // filter partial object by this date range
                                day: { between: [ start.toISOString(), end.toISOString() ] }
                            }
                        },
                    },
                };
            }

            return Boarder.find(criteria).$promise;
        };

        function getCurrentAccountRole(boarderId, accountId){
            var criteria= {};
            criteria.filter = {
                where: { // filter partial object by this date range
                            and: [
                                {boarderId:boarderId},
                                {accountId:accountId}
                            ]
                        }

            };


            return Boarder.accountroles({id:boarderId}, criteria).$promise;
        };

        function destroyAccountRoleById(boarderId, accountRoleId){
            return AccountRole.destroyById({id:accountRoleId}).$promise;
        }

        // this function will return all employees
        // it will include all partials that employee is on for that job on that day
        // each partial will include the job
        // OTHER JOBS THAT THE EMPLOYEE MAY BE SCHEDULED ON THE SAME DAY ARE NOT RETURNED
        function getAllEmployeesWithAllPartialsForJobDay(jobId, day){
            var criteria = {};

            var dayStr = moment(day).format("YYYY-MM-DD");
            var start = moment(dayStr).startOf('day');
            var end = moment(dayStr).endOf('day');

            // this filter ensures we include partials (and related jobs)
            criteria.filter = {
                include: {
                    relation: 'partials', // include the partial object
                    scope: { // further filter the partial object
                        include: { // include the related job object
                            relation: 'job',
                            scope: {
                                where: { jobId: jobId }
                            }
                        },
                        where: { // filter partial object by this date range
                            and: [
                                {jobId: jobId},
                                {day: { between: [ start.toISOString(), end.toISOString() ] }}
                            ]
                        }
                    }
                }
            };

            return Boarder.find(criteria).$promise;
        };

        function getChangeStream(){
            // this built-in call doesn't work, as what it returns is useless
            // return Boarder.createChangeStream().$promise;

            // here is what we will do instead
            var changeStreamUrl = API.baseUrl + '/Boarders/change-stream?access_token=' + LoopBackAuth.accessTokenId;
            var src = new EventSource(changeStreamUrl);

            src.addEventListener('message', function(e) {
                console.log("SSE MESSAGE: ", e);
            }, false);

            src.addEventListener('open', function(e) {
                // Connection was opened.
                console.log("OPENED: ", e);
            }, false);

            src.addEventListener('error', function(e) {
                console.log("ERROR: ", e);
                if (e.readyState == EventSource.CLOSED) {
                    // Connection was closed.
                    console.log("CLOSED: ", e);
                }
            }, false);

            return createChangeStream(src);
        };

        function getEmployeeById(id){
            return Boarder.findById({
                id: id,
                filter: { include: "partials" } 
            }).$promise;
        };

        function getPartialsForEmployeeByDate(id, yyyy_mm_dd){
            var start = moment(yyyy_mm_dd).startOf('day');
            var end = moment(yyyy_mm_dd).endOf('day');

            return Boarder.partials({
                id: id,
                filter: {
                    scope: { // further filter the partial object
                        include: "job", // include the related job object
                        where: { // filter partial object by this date range
                            day: { between: [ start.toISOString(), end.toISOString() ] }
                        }
                    }
                }
            }).$promise;
        };

        function inviteEmployee(emailAddress){
            if (emailAddress){
                return inviteEmployees([emailAddress]);
            }
        };

        function inviteEmployees(emailArray){
            if (emailArray){
                return Boarder.employeeinvite({
                    "id": AuthService.getUser().id,
                    "employees": emailArray
                }).$promise;
            }
        };
    }
})();