(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('TasksService', TasksService);

    TasksService.inject = ['$q', '$http', '$timeout', 'AuthService', 'API', 'Task'];
    function TasksService($q, $http, $timeout, AuthService, API, Task) {
        var service = {
            addTask: addTask,
            deleteTask: deleteTask,
            getAllTasks: getAllTasks,
            getTaskById: getTaskById,
            setTaskCompletion: setTaskCompletion
        };
        
        return service;

        ////////////////
        function addTask(taskObj){
            return Task.upsert(taskObj).$promise;
        };

        function deleteTask(taskObj){
            return Task.delete(taskObj).$promise;
        };

        function getAllTasks(){
            return Task.find().$promise;
        };

        function getTaskById(id){
            return Task.findById({ id: id }).$promise;
        };

        function setTaskCompletion(task, completion){
            return Task.upsert({id: task.id, complete: completion}).$promise;
        };
    }
})();