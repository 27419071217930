(function() {
    'use strict';

    angular
        .module('scheduleboard.resources')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider) {

/*
            uiGmapGoogleMapApi.configure({
            key: 'AIzaSyAGSmIAVZj35KxOOpbumA3oGyuXXX5LHq8',
            v: '3.17',
            libraries: 'weather,geometry,visualization'
        });
*/

        $stateProvider
        .state('resources', {
            url: '/app/resources',
            templateUrl: 'app/scheduleboard/resources/resources.tmpl.html',
            // set the controller to load for this page
            controller: 'ResourcesController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        });

        /*triMenuProvider.addMenu({
            name: 'Resources',
            icon: 'fa fa-tachomter',
            type: 'link',
            state: 'triangular.resources',
            priority: 1.1

        });*/
    }
})();
