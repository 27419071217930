(function() {
  'use strict';

  angular
    .module('scheduleboard.schedule')
    .controller('ScheduleController', ScheduleController);

  /* @ngInject */
  ScheduleController.inject = [
    '$scope',
    '$cacheFactory',
    '$timeout',
    '$interval',
    '$mdToast',
    '$rootScope',
    '$state',
    '$filter',
    '$mdDialog',
    'AuthService',
    'JobsService',
    'Boarder',
    'EmployeesService',
    'EquipmentService',
    'PartialsSevice',
    'Partial',
    'uiGmapGoogleMapApi',
    'API',
    'UserPrefsService',
    '$log',
    '$calendarConfig',
    '$calendarEventTitle'
  ];

  function ScheduleController(
    $scope,
    $cacheFactory,
    $timeout,
    $interval,
    $mdToast,
    $rootScope,
    $state,
    $filter,
    $mdDialog,
    AuthService,
    JobsService,
    Boarder,
    EmployeesService,
    EquipmentService,
    PartialsService,
    Partial,
    uiGmapGoogleMapApi,
    API,
    UserPrefsService,
    $log,
    calendarConfig,
    calendarEventTitle
  ) {
    var vm = this;

    // scope variables

    vm.selected = null;
    vm.resources = {};
    vm.resources.jobs = {};
    vm.resources.employees = {};
    vm.resources.equipment = {};
    vm.jobs = '';
    vm.jobList = {};
    vm.weekDays = [];
    vm.weekDayLength = 0;
    vm.today = '';
    vm.scheduleResourceSearch = '';
    vm.token = $rootScope.getAccessToken();
    vm.user = $rootScope.getUser();
    vm.account = $rootScope.getActiveAccount();
    vm.accountName = vm.account.name;
    vm.accountUUID = vm.account.accountUUID;
    vm.baseUrl = API.baseUrl;
    vm.activated = true;
    vm.workingJobLists = {};
    vm.activeOnly = true;
    vm.canPartialsStillBeEditedCache = $cacheFactory(
      'canPartialsStillBeEdited'
    );
    vm.hoursIntoDayWhenEditingOfPartiaIsCutsOff = 17; // 5pm local
    vm.partialEditingCacheResetTimer = undefined;
    vm.isLoadingJobs = false;
    vm.isReloadingJobs = false;
    vm.viewChoice = 'Week';
    //vm.jobMonthList = {};

    vm.calendarOptions = {
      minDate: moment(vm.today)
        .add('-1', 'day')
        .startOf('day')
        .toDate(),
      maxDate: moment(vm.today)
        .add(60, 'day')
        .startOf('day')
        .toDate(),
      selectedDate: ''
    };

    // external function definitions

    vm.fallWeek = fallWeek;
    vm.jumpWeek = jumpWeek;
    vm.openResourceDialog = openResourceDialog;
    vm.isHoveredOver = false;
    vm.scheduleLength = 6;
    vm.hasPartials = hasPartials;
    vm.isPartialDate = isPartialDate;
    vm.dropCallback = dropCallback;
    // vm.partialDropCallback = partialDropCallback;
    vm.dropDispatch = dropDispatch;
    vm.partialDropReceive = partialDropReceive;
    vm.resetResourceSearch = resetResourceSearch;
    vm.createPartial = createPartial;
    vm.hasAvatar = hasAvatar;
    //vm.getColor = getColor;
    vm.loadJobList = loadJobList;
    vm.reloadJobList = reloadJobList;
    vm.goToToday = goToToday;
    vm.openDeleteDialog = openDeleteDialog;
    vm.copyPartialForTomorrow = copyPartialForTomorrow;
    vm.showDialogToAddJobToSchedule = showDialogToAddJobToSchedule;
    vm.canPartialStillBeEdited = canPartialStillBeEdited;
    vm.openDelayDialog = openDelayDialog;
    vm.getJobCountForCurrentScheduleDates = getJobCountForCurrentScheduleDates;
    vm.haveAllPartialsBeenConfirmedForDay = haveAllPartialsBeenConfirmedForDay;
    vm.notifyEmployeesForAllPartialsOnDay = notifyEmployeesForAllPartialsOnDay;
    vm.getCountOfPeopleWorkingOnDay = getCountOfPeopleWorkingOnDay;
    vm.getCountOfEquipmentWorkingOnDay = getCountOfEquipmentWorkingOnDay;
    vm.getCountOfPeopleOnPartial = getCountOfPeopleOnPartial;
    vm.getCountOfEquipmentOnPartial = getCountOfEquipmentOnPartial;
    vm.getCrewStringForPartial = getCrewStringForPartial;
    vm.getEquipmentStringForPartial = getEquipmentStringForPartial;
    vm.getFilteredJobsForList = getFilteredJobsForList;
    vm.notifyEmployees = notifyEmployees;
    vm.getDate = getDate;
    vm.isToday = isToday;
    vm.isWorkingToday = isWorkingToday;
    vm.dateSelected = dateSelected;
    vm.viewToday = viewToday;
    vm.nextDay = nextDay;
    vm.prevDay = prevDay;
    vm.nextMonth = nextMonth;
    vm.prevMonth = prevMonth;
    vm.setToday = setToday;
    vm.checkDateForWork = checkDateForWork;
    vm.hasWorkToday = hasWorkToday;
    // map these functions but do not call them - they are called in the view!
    vm.isManager = AuthService.isManager;
    vm.isCustomer = AuthService.isCustomer;
    vm.isAdmin = AuthService.isAdmin;
    vm.isScheduler = AuthService.isScheduler;

    vm.calendarOptions = {};
    vm.canEditSchedule = canEditSchedule;
    vm.canSendNotificationsToWorkers = canSendNotificationsToWorkers;
    vm.getAllPartialsForDay = getAllPartialsForDay;
    vm.getSelectedDayPartials = getSelectedDayPartials;
    vm.goToMonthView = goToMonthView;
    vm.goToWeekView = goToWeekView;
    vm.goToDayView = goToDayView;
    vm.printPDF = printPDF;
    vm.generateMonthCalendarEvents = generateMonthCalendarEvents;

    /////////// month calendar tooltip stuff (breakpoint)
    vm.events = [];
    vm.calendarView = 'month';
    vm.viewChangeEnabled = false;
    vm.viewDate = moment()
      .startOf('month')
      .toDate();

    var originalEventTitle = angular.copy(calendarEventTitle);

    function generateMonthCalendarEvents(jobList) {
      console.log('date: ------');
      console.log(vm.viewDate);
      console.log(vm.viewToday);
      vm.events = [];
      for (var job in jobList) {
        var jobTitle = jobList[job].name;
        var partials = jobList[job].partials;
        if (typeof jobTitle !== 'undefined' && partials.length > 0) {
          partials.sort(function(a, b) {
            return new Date(a.day) - new Date(b.day);
          });
          var jobStartDate = new Date(partials[0].day);
          var lastDateIndex = partials.length - 1;
          var jobEndDate = new Date(partials[lastDateIndex].day);
          var calendarEvent = {
            title: jobList[job].name,
            color: calendarConfig.colorTypes.warning,
            startsAt: jobStartDate,
            endsAt: jobEndDate
          };

          vm.events.push(calendarEvent);
        }
      }
    }

    function sortPartials(partials) {
      partials.sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.day) - new Date(b.day);
      });
    }

    vm.viewChangeClicked = function(date, nextView) {
      return vm.viewChangeEnabled;
    };

    calendarEventTitle.monthView = function(event) {
      return event.title;
    };

    calendarEventTitle.monthViewTooltip = calendarEventTitle.weekViewTooltip = calendarEventTitle.dayViewTooltip = function(
      event
    ) {
      return event.title;
    };

    ////////////////////////////////////////
    function checkDateForWork(dateToCheck) {
      var found = false;

      if (dateToCheck) {
        var toCheck = moment(dateToCheck)
          .utc()
          .format('YYYY-MM-DD');
        if (vm.work.indexOf(toCheck) != -1) {
          found = true;
        }
      }

      if (found == true) {
        return "<div style='align-content: start'><i class='fas fa-circle fa-small-font'></i></div> ";
      } else {
        return;
      }
    }

    function dateSelected(evt, obj) {
      //console.log(vm.calendarOptions);
      vm.today = moment(vm.calendarOptions.selectedDate);
      //console.log(vm.workDays);
      //loadJobList();
      vm.viewChoice = 'Day';
      loadJobList();
      //vm.today = moment(vm.calendarOptions.selectedDate);
      //console.log(vm.calendarOptions, obj);
    }

    function nextMonth(data) {
      //console.log(data);
      var today = moment();
      today.month(data.month - 1);
      today.year(data.year);
      vm.today = today;
      setToday(vm.today);
      /*if(moment(vm.today).month() <12) {
                vm.today = moment(vm.today).add(1, 'months').startOf('month');
            }
            else{
                vm.today = moment(vm.today).subtract(11, 'months').startOf('month').add(1, 'year');
            }
            console.log(vm.today.format("MM-DD-YYYY"));
            //console.log(nextMonth);

            setToday(vm.today);
            loadJobList();*/
    }

    function prevMonth(data) {
      console.log(data);
      var today = moment();
      today.month(data.month - 1);
      today.year(data.year);
      vm.today = today;
      setToday(vm.today);
    }
    function goToMonthView(day) {
      var today = moment(day);
      vm.today = today;
      setToday(vm.today);
      vm.calendarOptions.minDate = vm.today.utc().startOf('day');
      vm.calendarOptions.maxDate = moment(vm.today)
        .add(60, 'day')
        .utc()
        .startOf('day');
      vm.calendarOptions.selectedDate = vm.today;
      loadJobList();
    }

    function goToWeekView(day) {
      vm.today = day;
      setToday(vm.today);
      loadJobList();
    }

    function goToDayView(day) {
      vm.today = day;
      setToday(vm.today);
      loadJobList();
    }

    function hasWorkToday(day) {
      //
      //console.log("HELLO");
      var hasWork = false;
      // iterate through each job
      for (var jobNumberAsKey in vm.jobList) {
        if (vm.jobList.hasOwnProperty(jobNumberAsKey)) {
          var job = vm.jobList[jobNumberAsKey];

          if (typeof job != 'undefined') {
            //console.log(day);

            var partialFound = undefined;

            // grab the partial for the given day on this job
            if (job.days) {
              //console.log(job.days);
              //console.log(day);

              partialFound = job.days[day];
              //console.log(partialFound);
            }

            if (partialFound) {
              hasWork = true;
              //partialFound['job'] = job.job;
              // if a partial was found, accumulate the value
              //console.log(partialFound);
              //results.push(partialFound);
              //console.log(partialFound);
            }
          }
        }
      }

      //console.log(results);
      console.log(hasWork);
      return hasWork;
    }
    function getSelectedDayPartials(day) {
      //
      //console.log("HELLO");
      var results = [];
      // iterate through each job
      for (var jobNumberAsKey in vm.jobList) {
        if (vm.jobList.hasOwnProperty(jobNumberAsKey)) {
          var job = vm.jobList[jobNumberAsKey];

          if (typeof job != 'undefined') {
            //console.log(day);

            var partialFound = undefined;

            // grab the partial for the given day on this job
            if (job.days) {
              //console.log(job.days);
              //console.log(day);

              partialFound = job.days[day];
              //console.log(partialFound);
            }

            if (partialFound) {
              partialFound['job'] = job.job;
              // if a partial was found, accumulate the value
              //console.log(partialFound);
              results.push(partialFound);
              //console.log(partialFound);
            }
          }
        }
      }

      console.log(results);
      return results;
    }
    function updateCalendarOptionsForJobsInMonth(partials) {
      return $q(function(resolve, reject) {
        if (partials && partials.length > 0) {
          partials.sort(function(a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.day) - new Date(b.day);
          });

          vm.calendarOptions.minDate = moment(partials[0].day)
            .utc()
            .startOf('day')
            .toDate();
          vm.calendarOptions.maxDate = moment(partials[partials.length - 1].day)
            .utc()
            .endOf('day')
            .toDate();
          resolve(vm.calendarOptions);
        } else {
          reject();
        }
      });
    }

    function canEditSchedule() {
      return (
        vm.isManager() || vm.isCustomer() || vm.isAdmin() || vm.isScheduler()
      );
    }

    function canSendNotificationsToWorkers() {
      return vm.isManager() || vm.isCustomer() || vm.isAdmin();
    }

    // this is used to determine if one can edit a partial
    // afer a certain (local) time of day, work should not change
    // and so we should disable editing
    function canPartialStillBeEdited(day) {
      var returnVal = undefined;

      // fetch from cache
      returnVal = vm.canPartialsStillBeEditedCache.get(day);

      // if it wasn't found in cache, compute it
      if (typeof returnVal === 'undefined') {
        // console.log(day + " not found in cache. computing...")
        var currentTime = moment();
        var canEditUntilThisTime = moment(day)
          .startOf('day')
          .add(vm.hoursIntoDayWhenEditingOfPartiaIsCutsOff, 'hours');

        if (+currentTime < +canEditUntilThisTime) {
          returnVal = true;
        } else {
          returnVal = false;
        }

        vm.canPartialsStillBeEditedCache.put(day, returnVal);
      }

      return returnVal;
    }

    // this clears the cache of which days in the schedule can have their partials edited
    // when it resets, the UI changes the partials cards for a given day to be un-editable
    // it also starts a timer for a day later, if the user keeps the browser open that long
    // NOTE that the timer will always be destroy()'d when leaving the scope; see active() for more
    function startTimerToResetPartialEditingCache(momentToClearCache) {
      var whenToClearCache = momentToClearCache;

      // if it's not set, then use today plus the cutoff time
      if (!whenToClearCache) {
        whenToClearCache = moment()
          .startOf('day')
          .add(vm.hoursIntoDayWhenEditingOfPartiaIsCutsOff, 'hours');
      }

      // figure out how long to wait
      var waitTimeMs = +whenToClearCache - +moment() + 1000;

      // start the timer
      vm.partialEditingCacheResetTimer = $timeout(function() {
        // clear the cache
        vm.canPartialsStillBeEditedCache.removeAll();

        // start again for the next day
        var nextClearingTime = whenToClearCache.add(1, 'days');
        startTimerToResetPartialEditingCache(nextClearingTime);
      }, waitTimeMs);
    }

    /*function removeEmployeeFromPartial(employee) {
            //vm.partial.crew.pop(employee);
            var index = vm.partial.crew.indexOf(employee);
            //vm.partial.crew.pop(index);
            console.log(index);
            var deleted = vm.partial.crew.splice(index, 1);

            PartialsService.removeAllEmployeesFromPartial(vm.partial).then(function(answer) {
                PartialsService.assignEmployeesToPartial(vm.partial.crew, vm.partial.id).then(function(newCrew) {
                    console.log(newCrew);

                });

            });

        }

        function removeEquipmentFromPartial(equipment) {
            //vm.partial.crew.pop(employee);
            var index = vm.partial.equipment.indexOf(equipment);
            //vm.partial.crew.pop(index);
            console.log(index);
            var deleted = vm.partial.equipment.splice(index, 1);

            PartialsService.removeAllEquipmentFromJobDay(vm.partial.jobId, moment(vm.partial.day)).then(function(answer) {
                PartialsService.assignEquipmentToPartial(vm.partial.equipment, vm.partial.id).then(function(newEquipment) {
                    console.log(newEquipment);
                });

            });

        }*/

    function haveAllPartialsBeenConfirmedForDay(day) {
      var trueOrFalse = false;

      var partials = getAllPartialsForDay(day);
      if (partials) {
        trueOrFalse = partials.reduce(function(allConfirmed, partial) {
          return allConfirmed && partial.confirmed;
        }, true);
      }

      return trueOrFalse;
    }

    function isWorkingToday(employee) {
      //vm.today;

      //console.log(vm.today.format("YYYY-MM-DD"));

      if (employee.workingToday == true) {
        return true;
      } else {
        if (vm.viewChoice == 'Day') {
          var partials = getAllPartialsForDay(vm.today.format('YYYY-MM-DD'));
        } else {
          var partials = getAllPartialsForDay(moment().format('YYYY-MM-DD'));
        }
        var working = false;
        if (partials) {
          partials.forEach(function(partial) {
            if (partial.crew) {
              partial.crew.forEach(function(emp) {
                if (employee.id == emp.id) {
                  //employee.workingToday = true;
                  working = true;
                }
              });
            }
          });
        }
        return working;
      }
    }
    function notifyEmployeesForAllPartialsOnDay(day) {
      var partials = getAllPartialsForDay(day);
      if (partials) {
        partials.forEach(notifyEmployees);
      }
    }

    function getCountOfEquipmentWorkingOnDay(day) {
      var count = 0;

      var partials = getAllPartialsForDay(day);
      if (partials) {
        count = partials.reduce(function(sum, partial) {
          if (partial.equipment) {
            return sum + partial.equipment.length;
          } else {
            return sum;
          }
        }, 0);
      }

      return count;
    }

    function getCountOfPeopleWorkingOnDay(day) {
      var count = 0;

      var partials = getAllPartialsForDay(day);
      if (partials) {
        count = partials.reduce(function(sum, partial) {
          if (partial.crew) {
            return sum + partial.crew.length;
          } else {
            return sum;
          }
        }, 0);
      }

      return count;
    }

    function getCountOfPeopleOnPartial(partial) {
      var count = 0;

      if (partial && partial.crew) {
        count = partial.crew.length;
      }

      return count;
    }

    function getCountOfEquipmentOnPartial(partial) {
      var count = 0;

      if (partial && partial.equipment) {
        count = partial.equipment.length;
      }

      return count;
    }

    function getAllPartialsForDay(day) {
      var results = [];

      if (day) {
        // iterate through each job
        for (var jobNumberAsKey in vm.jobList) {
          if (vm.jobList.hasOwnProperty(jobNumberAsKey)) {
            var job = vm.jobList[jobNumberAsKey];

            if (job) {
              //console.log(day);

              var partialFound = undefined;

              // grab the partial for the given day on this job
              if (job.days) {
                //console.log("YES");
                partialFound = job.days[day];
              }

              // if a partial was found, accumulate the value
              if (partialFound) {
                //console.log("FOUND!");
                results.push(partialFound);
              }
            }
          }
        }
      }

      return results;
    }

    function goToToday() {
      var today = moment();
      vm.today = today;
      //if (!vm.viewChoice == 'Day') {
        setToday(vm.today);
      //}
      loadJobList();
    }

    function createPartial(job, day) {
      PartialsService.upsertPartial({
        jobId: job.id,
        day: moment(day)
          .startOf('day')
          .toISOString()
      }).then(function(result) {
        //$mdToast.show($mdToast.simple().textContent('A partial was added!'));

        reloadJobList();
      });
    }

    function resetResourceSearch() {
      vm.scheduleResourceSearch = '';
    }

    vm.logEvent = function(job) {
      $log.log(job);
    };

    //$scope.$watchCollection('vm.jobList', function(newList, oldList) {
    //loadJobList();
    //});

    function notifyEmployees(partial) {
      if (!partial.confirmed) {
        Partial.notifyEmployees(partial).$promise.then(function(response) {
          partial.confirmed = true;
          Partial.upsert(partial).$promise.then(function(res) {
            console.log(res);
          });
        });
      }
    }

    function partialDropReceive(
      event,
      index,
      type,
      item,
      external,
      dropEffect,
      callback
    ) {
      if (type == 'partial-resource') {
        var newDate = moment(event.toElement.getAttribute('date'));
        var origDate = moment(item.day);
        var daysMoved = newDate.diff(origDate, 'days');

        //need to check to
        if (daysMoved > 0) {
          //moved partial forward
          for (var i = 0; i < daysMoved; i++) {}
          //check adjacent partials and move appropriately
        } else {
          //moved partial backwards
        }
        // (event.toElement.insert(item);
        // console.log(vm.jobList[item.jobId].days[newDate.format("YYYY-MM-DD")]);
        if (vm.jobList[item.jobId].days[newDate.format('YYYY-MM-DD')] == '') {
          //there is already a partial
          vm.jobList[item.jobId].days[origDate.format('YYYY-MM-DD')] = '';
          vm.jobList[item.jobId].days[newDate.format('YYYY-MM-DD')] = item;
          PartialsService.getPartialById(item.id).then(function(partial) {
            PartialsService.setPartialDate(
              partial,
              moment(newDate).format('YYYY-MM-DD')
            ).then(
              function(result) {
                reloadJobList();
              },
              function(err) {
                $log.log('There was an error, reverting back to original date');
                vm.jobList[item.jobId].days[origDate] = item;
              }
            );
          });
        } else {
          return false;
        }
      } else {
        //if its a job
      }
      return item;
    }

    function dropCallback(
      event,
      index,
      type,
      item,
      external,
      dropEffect,
      callback
    ) {
      $log.log(
        'dropCallback',
        event,
        index,
        type,
        item,
        external,
        dropEffect,
        callback
      );
    }

    function openResourceDialog(partial) {
      $mdDialog
        .show({
          templateUrl: 'app/scheduleboard/schedule/resource-dialog.tmpl.html',
          controller: 'ResourceDialogController as vm',
          targetEvent: event,
          clickOutsideToClose: true,
          locals: {
            partial: partial,
            canPartialStillBeEdited: canPartialStillBeEdited(partial.day),
            canEditPartials: vm.canEditSchedule()
          }
        })
        .then(function(results) {
          // dialog has been closed by user
          populatePartialWithCrewAndEquipmentStrings(partial);
        });
    }

    function jobScheduled(jobId) {
      var scheduled = false;
      angular.forEach(vm.jobList, function(job, id) {
        if (jobId == id) {
          scheduled = true;
          return scheduled;
        }
      });
      return scheduled;
    }

    function showDialogToAddJobToSchedule(jobId, event) {
      if (jobId) {
        var scheduled = jobScheduled(jobId);
        console.log(scheduled);
        if (!scheduled) {
          $mdDialog
            .show({
              templateUrl:
                'app/scheduleboard/schedule/partial-create-dialog.tmpl.html',
              controller: 'PartialCreateDialogController as vm',
              targetEvent: event,
              clickOutsideToClose: true,
              locals: {
                weekDays: vm.weekDays
              }
            })
            .then(function(workData) {
              $log.log(
                'ScheduleController :: User chose to add to the sched the following new job: ',
                workData
              );
              PartialsService.createPartialSeries(workData, jobId).then(
                function(result) {
                  // receives partials but WITHOUT associated crew or equipment, so reload
                  reloadJobList();

                  $mdToast.show(
                    $mdToast
                      .simple()
                      .textContent(
                        'Added ' +
                          result.length +
                          ' days of work to ' +
                          getJobNameFromId(jobId)
                      )
                  );
                }
              );
            });
        } else {
          console.log(vm.jobList);
          $mdToast.show(
            $mdToast
              .simple()
              .textContent(
                vm.jobList[jobId].job.name + ' is already on the schedule'
              )
          );
        }
      }
    }

    function viewToday(day) {
      console.log(vm.today);
      setToday(day);
      //vm.today = day;
    }

    function nextDay() {
      console.log(vm.today);
      vm.jobList = {};
      vm.weekDays = {};
      vm.today = moment(vm.today).add(1, 'days');
      //$log.log(vm.today);
      //setToday(vm.today);
      loadJobList();
    }
    function prevDay() {
      vm.jobList = {};
      vm.weekDays = {};
      vm.today = moment(vm.today).subtract(1, 'days');
      //$log.log(vm.today);
      //setToday(vm.today);
      loadJobList();
    }

    function getJobNameFromId(jobId) {
      var jobFound = vm.allJobs.find(function(j) {
        return j.id == jobId;
      });
      var jobName = jobFound ? jobFound.name : undefined;
      return jobName;
    }

    function dropDispatch(event, index, type, item, external, dropEffect) {
      //$log.log("job dropped", event, index,item,type,external,dropEffect, callback);
      $log.log('ScheduleController :: user drag-n-dropped job', item.id);
      if (type == 'job-resource') {
        showDialogToAddJobToSchedule(item.id, event);
      }
      //$log.log("dropDispatch",event, index,type, item, external,dropEffect,callback);
    }

    /* function jobRowDropCallback(event, index,type, item, external,dropEffect,callback){
            $log.log("jobRowDropCallback",event, index,type, item, external,dropEffect,callback);

        }*/

    /*function jobRowDragoverCallback(ev,index, item, external,type,dropEffect,callback){

            var rows = document.querySelectorAll('.jobRow');
            $log.log(rows);
            rows.forEach(function(row){
               var el = angular.element(row);
               el.removeClass('isHovering');
            });
           var element= angular.element(ev.target);

           element.addClass('isHovering');
            //$log.log("jobRowDragoverCallback",ev,index,item,type,external,dropEffect,callback);

        }*/

    /*function countCrew(){
         if(vm.workDays[0].work) {
         var todaysWork = vm.workDays[0].work;
         todaysWork.forEach(function (partial, index) {
         if (partial.crew.length > 0) {
         partial.crew.forEach(function (employee) {
         vm.crewWorkingToday += 1;
         })
         }
         });
         }

         }*/

    //external functions

    function isPartialDate(partial, index) {
      //$log.log(date);
      // $log.log(partial);
      var pDate = moment(partial.day);
      //var pDate = moment(p);
      var wDate = moment(vm.weekDays[index]);
      //var wDate = vm.weekDays[index];
      //$log.log(pDate);
      //$log.log(wDate);
      if (pDate.startOf('day').isSame(wDate.startOf('day'))) {
        return true;
      } else {
        return false;
      }
    }

    function hasPartials(day) {
      //$log.log(day);
      if (day.partials > 0) {
        return true;
      } else {
        return false;
      }
    }

    function jumpWeek() {
      vm.jobList = {};
      vm.weekDays = {};
      vm.today = moment(vm.today)
        .add(7, 'days')
        .startOf('week')
        .add(1, 'day');
      //$log.log(vm.today);
      setToday(vm.today);
      loadJobList();
    }

    function fallWeek() {
      vm.jobList = {};
      vm.weekDays = {};
      vm.today = moment(vm.today)
        .subtract(7, 'days')
        .startOf('week')
        .add(1, 'day');
      setToday(vm.today);
      loadJobList();
    }

    function getToday() {
      if (UserPrefsService.getPreference('lastToday')) {
        return moment(UserPrefsService.getPreference('lastToday'));
      } else {
        return moment().startOf('day');
      }
    }

    function setToday(today) {
      var monday = today.startOf('week').add(1, 'day');
      //console.log(monday);
      vm.calendarOptions.minDate = monday.utc().startOf('day');
      vm.calendarOptions.maxDate = moment(monday)
        .add(60, 'day')
        .utc()
        .startOf('day');
      UserPrefsService.setPreference('lastToday', monday);
      buildWorkDays(monday);
    }

    //internal functions
    function addWeekdays(date, days) {
      var dateClone = moment(date); // use a clone
      while (days > 0) {
        dateClone = dateClone.add(1, 'days');
        //$log.log(dayJobs);
        // decrease "days" only if it's a weekday.
        if (dateClone.isoWeekday() !== 6 && dateClone.isoWeekday() !== 7) {
          days -= 1;
        }
      }
      return dateClone;
    }

    function buildWorkDays(today) {
      vm.weekDays = {};
      //vm.todaysWork= [];
      for (var i = 0; i < 6; i++) {
        //vm.weekDays.push({});
        var dayData = vm.weekDays[i];
        var date = addWeekdays(today, i);
        //dayData["date"] = addWeekdays(today, i);
        vm.weekDays[i] = date.format('YYYY-MM-DD');
      }

      vm.weekDayLength = Object.keys(vm.weekDays).length;
    }

    function getDate(day) {
      return moment(day);
    }

    function isToday(momentDate) {
      var dateStrToCheck = momentDate.format('YYYY-MM-DD');
      var dateStrForToday = moment().format('YYYY-MM-DD');
      return dateStrToCheck === dateStrForToday;
    }

    function getCrewStringForPartial(partial) {
      var crewStr = 'No People';

      if (partial && partial.crew && partial.crew.length > 0) {
        crewStr = '';
        partial.crew.forEach(function(c) {
          crewStr += c.firstName + ' ' + c.lastName.charAt(0) + ', ';
        });
      }

      crewStr = _.trimEnd(crewStr, ', ');
      return crewStr;
    }

    function getFilteredJobsForList() {
      var jobs = vm.allJobs || [];

      // filter the list if the user wants to see only active jobs
      if (vm.activeOnly) {
        jobs = jobs.filter(function(job) {
          return job.active;
        });
      }

      return jobs;
    }

    function getEquipmentStringForPartial(partial) {
      var equipStr = 'No Equipment';

      if (partial && partial.equipment && partial.equipment.length > 0) {
        equipStr = '';
        partial.equipment.forEach(function(e) {
          equipStr += e.name + ', ';
        });
      }

      equipStr = _.trimEnd(equipStr, ', ');
      return equipStr;
    }

    function refreshData() {
      vm.today = getToday();
      setToday(vm.today);
      reloadJobList();
      loadAllResources();
    }

    function activate() {
      vm.activated = true;

      vm.today = getToday(); //this gets the current day or whatever was stored in local storage as the last current day
      setToday(vm.today);
      loadJobList(); //we want this to be the main function that sets up all the jobs

      loadAllResources();
      vm.activated = false;

      // refresh data in the background every N minutes
      var refreshIntervalMs = 1000 * 60 * 20;
      var dataRefreshTimerPromise = $interval(function() {
        refreshData();
      }, refreshIntervalMs);

      // at a certain time of day (like 5pm local), remove the ability to edit partials for that day
      startTimerToResetPartialEditingCache();

      // handle any cleanup we need to
      $scope.$on('$destroy', function() {
        $interval.cancel(dataRefreshTimerPromise);
        vm.canPartialsStillBeEditedCache.destroy();
        $timeout.cancel(vm.partialEditingCacheResetTimer);
      });

      // reload data if the user changes their company account
      $scope.$on('user-changed-account', function(
        event,
        accountRoleSelectedByUser
      ) {
        refreshData();
      });
    }

    function getJobCountForCurrentScheduleDates() {
      var count = 0;

      for (var prop in vm.jobList) {
        if (vm.jobList.hasOwnProperty(prop)) {
          ++count;
        }
      }

      return count;
    }

    function populatePartialWithCrewAndEquipmentStrings(partial) {
      if (partial) {
        // build and store the crew and equip string
        partial.crewString = getCrewStringForPartial(partial);
        partial.equipmentString = getEquipmentStringForPartial(partial);
      }
    }

    function reloadJobList() {
      vm.isReloadingJobs = true;
      loadJobList();
    }

    function loadJobList() {
      //get the jobs based on the week we care about
      //setToday(vm.today);
      vm.events = [];
      // notify UI we are starting to load
      vm.isLoadingJobs = true;

      // the range of days we want to pull
      // the start must include as early as the start of the day UTC
      var startDay = moment(vm.today)
        .utc()
        .startOf('day');
      // the end must incluce as late as the end of the day UTC
      var endDay = moment(vm.today)
        .utc()
        .endOf('day')
        .add(60, 'days');

      JobsService.getAllJobsWithPartialsAndRange(true, startDay, endDay).then(
        function(jobs) {
          vm.jobList = {};
          vm.jobs = jobs;

          vm.work = [];
          vm.jobs.forEach(function(job, index) {
            var myJobList = {};
            myJobList[job.id] = {
              job: job
            };

            myJobList[job.id].active = job.active;
            myJobList[job.id].partials = 0;
            var workDays = {};

            job.partials.forEach(function(partial, index) {
              var dayOfWork = moment(partial.day)
                .utc()
                .format('YYYY-MM-DD');
              vm.work.push(dayOfWork);
            });
            // sets up the object
            for (var i = 0; i < vm.scheduleLength; i++) {
              var day = addWeekdays(vm.today, i);

              var dayOfSchedStr = moment(day)
                .utc()
                .format('YYYY-MM-DD');
              workDays[dayOfSchedStr] = '';

              job.partials.forEach(function(partial, index) {
                var dayOfPartialStr = moment(partial.day)
                  .utc()
                  .format('YYYY-MM-DD');
                if (dayOfPartialStr === dayOfSchedStr) {
                  workDays[dayOfSchedStr] = partial;
                }

                // build and store the crew and equip string
                populatePartialWithCrewAndEquipmentStrings(partial);
              });
            }

            myJobList[job.id].days = workDays;

            var wD = Object.keys(workDays);
            var partLen = 0;
            wD.forEach(function(day) {
              if (workDays[day] != '') {
                partLen += 1;
              }
            });

            myJobList[job.id].partials = partLen;
            var keys = Object.keys(myJobList);
            keys.forEach(function(key) {
              var job = myJobList[key];
              if (job.partials > 0) {
                vm.jobList[key] = job;

                var color = randomColor({
                  hue: '#01baef',
                  luminosity: 'bright'
                });

                var accentColor = randomColor({
                  hue: 'monochrome',
                  luminosity: 'bright'
                });

                vm.jobList[key].color = color;
                vm.jobList[key].accentColor = accentColor;
              }
            }); // keys.forEach

            vm.activated = false;
          }); // vm.jobs.forEach

          vm.work = _.uniq(vm.work);
          //console.log(vm.work);
          vm.isLoadingJobs = false;
          vm.isReloadingJobs = false;

          console.log('jobs: ');
          console.log(jobs);

          console.log('reloading month jobs');
          vm.generateMonthCalendarEvents(jobs);
        },
        function(error) {
          vm.isLoadingJobs = false;
          vm.isReloadingJobs = false;
        }
      );
    }

    function hasAvatar(boarder) {
      if (_.has(boarder, 'avatarUrl')) {
        return !(_.isNull(boarder.avatarUrl) || _.isEmpty(boarder.avatarUrl));
      }
      return false;
    }

    $scope.hoverIn = function() {
      this.isHoveredOver = true;
    };

    $scope.hoverResourcesIn = function() {
      this.showResourcesEdit = true;
    };

    $scope.hoverResourcesOut = function() {
      this.showResourcesEdit = false;
    };

    $scope.hoverOut = function() {
      this.isHoveredOver = false;
    };

    vm.dragoverCallback = function(index, external, type, callback) {
      console.log(type + ' dragged over');
    };

    function employeeCurrentlyScheduled(employee, targetDate) {
      var scheduled = false;
      angular.forEach(
        vm.jobList,
        function(job, jobId) {
          var day = job.days[targetDate];
          console.log(day);
          if (day) {
            if (day.crew && day.crew.length > 0) {
              angular.forEach(day.crew, function(emp) {
                if (employee.id == emp.id) {
                  scheduled = true;
                }
              });
            }
          }
        },
        scheduled
      );
      //console.log(targetDate);
      //console.log(employee);
      //console.log(vm.jobList);
      return scheduled;
    }

    function showDialogToConfirmEmployeeDrop() {}

    vm.employeeDroppedOnTarget = function(
      employeeObj,
      targetDate,
      existingPartial,
      jobObj
    ) {
      if (employeeCurrentlyScheduled(employeeObj, targetDate)) {
        var confirmAdd = false;
        var confirm = $mdDialog
          .confirm()
          .title('Employee Scheduled Confirmation')
          .textContent(
            'This Employee is currently scheduled for this day,\n would you like to add them to the schedule anyway?'
          )
          .ariaLabel('Confirm Adding Employee')
          .ok('Confirm')
          .cancel('Cancel');
        $mdDialog.show(confirm).then(
          function(data) {
            confirmAdd = data;

            // case where partial already exists
            if (confirmAdd && existingPartial) {
              EmployeesService.getEmployeeById(employeeObj.id).then(function(
                employee
              ) {
                existingPartial.confirmed = false;
                PartialsService.assignEmployeesToPartial(
                  [employee],
                  existingPartial.id
                ).then(
                  function(results) {
                    // console.log("Employee Add:: Results :: ", results);
                    $mdToast.show(
                      $mdToast
                        .simple()
                        .textContent(
                          employeeObj.firstName +
                            ' added to ' +
                            jobObj.name +
                            ' for ' +
                            targetDate
                        )
                    );

                    existingPartial.crew.push(employee);

                    // build and store the crew and equip string
                    populatePartialWithCrewAndEquipmentStrings(existingPartial);
                  },
                  function(err) {
                    $mdToast.show(
                      $mdToast.simple().textContent('There Was an error!')
                    );
                  }
                );
              });
            }

            // case where no partial exists yet
            else if (confirmAdd) {
              //console.log("NOT AN EXISTING PARTIAL!!!");
              PartialsService.upsertPartial({
                jobId: jobObj.id,
                day: moment(targetDate)
                  .startOf('day')
                  .toISOString()
              }).then(function(partial) {
                EmployeesService.getEmployeeById(employeeObj.id).then(function(
                  employee
                ) {
                  PartialsService.assignEmployeesToPartial(
                    [employee],
                    partial.id
                  ).then(
                    function(results) {
                      // console.log("Employee Add:: Results :: ", results);
                      $mdToast.show(
                        $mdToast
                          .simple()
                          .textContent(
                            employeeObj.firstName +
                              ' added to ' +
                              jobObj.name +
                              ' for ' +
                              targetDate
                          )
                      );
                      partial.confirmed = false;
                      reloadJobList();
                    },
                    function(err) {
                      $mdToast.show(
                        $mdToast.simple().textContent('There Was an error!')
                      );
                    }
                  );
                });
              });
            }
          },
          function(data) {
            confirmAdd = data;

            // case where partial already exists
            if (confirmAdd && existingPartial) {
              EmployeesService.getEmployeeById(employeeObj.id).then(function(
                employee
              ) {
                existingPartial.confirmed = false;
                PartialsService.assignEmployeesToPartial(
                  [employee],
                  existingPartial.id
                ).then(
                  function(results) {
                    // console.log("Employee Add:: Results :: ", results);
                    $mdToast.show(
                      $mdToast
                        .simple()
                        .textContent(
                          employeeObj.firstName +
                            ' added to ' +
                            jobObj.name +
                            ' for ' +
                            targetDate
                        )
                    );

                    existingPartial.crew.push(employee);

                    // build and store the crew and equip string
                    populatePartialWithCrewAndEquipmentStrings(existingPartial);
                  },
                  function(err) {
                    $mdToast.show(
                      $mdToast.simple().textContent('There Was an error!')
                    );
                  }
                );
              });
            }

            // case where no partial exists yet
            else if (confirmAdd) {
              console.log('NOT AN EXISTING PARTIAL!!!');
              PartialsService.upsertPartial({
                jobId: jobObj.id,
                day: moment(targetDate)
                  .startOf('day')
                  .toISOString()
              }).then(function(partial) {
                EmployeesService.getEmployeeById(employeeObj.id).then(function(
                  employee
                ) {
                  PartialsService.assignEmployeesToPartial(
                    [employee],
                    partial.id
                  ).then(
                    function(results) {
                      // console.log("Employee Add:: Results :: ", results);
                      $mdToast.show(
                        $mdToast
                          .simple()
                          .textContent(
                            employeeObj.firstName +
                              ' added to ' +
                              jobObj.name +
                              ' for ' +
                              targetDate
                          )
                      );
                      partial.confirmed = false;
                      reloadJobList();
                    },
                    function(err) {
                      $mdToast.show(
                        $mdToast.simple().textContent('There Was an error!')
                      );
                    }
                  );
                });
              });
            }
          }
        );
      } else {
        if (existingPartial) {
          EmployeesService.getEmployeeById(employeeObj.id).then(function(
            employee
          ) {
            existingPartial.confirmed = false;
            PartialsService.assignEmployeesToPartial(
              [employee],
              existingPartial.id
            ).then(
              function(results) {
                // console.log("Employee Add:: Results :: ", results);
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent(
                      employeeObj.firstName +
                        ' added to ' +
                        jobObj.name +
                        ' for ' +
                        targetDate
                    )
                );

                existingPartial.crew.push(employee);

                // build and store the crew and equip string
                populatePartialWithCrewAndEquipmentStrings(existingPartial);
              },
              function(err) {
                $mdToast.show(
                  $mdToast.simple().textContent('There Was an error!')
                );
              }
            );
          });
        } else {
          PartialsService.upsertPartial({
            jobId: jobObj.id,
            day: moment(targetDate)
              .startOf('day')
              .toISOString()
          }).then(function(partial) {
            EmployeesService.getEmployeeById(employeeObj.id).then(function(
              employee
            ) {
              PartialsService.assignEmployeesToPartial(
                [employee],
                partial.id
              ).then(
                function(results) {
                  // console.log("Employee Add:: Results :: ", results);
                  $mdToast.show(
                    $mdToast
                      .simple()
                      .textContent(
                        employeeObj.firstName +
                          ' added to ' +
                          jobObj.name +
                          ' for ' +
                          targetDate
                      )
                  );
                  partial.confirmed = false;
                  reloadJobList();
                },
                function(err) {
                  $mdToast.show(
                    $mdToast.simple().textContent('There Was an error!')
                  );
                }
              );
            });
          });
        }
      }
    };

    vm.equipmentDroppedOnTarget = function(
      equipmentObj,
      targetDate,
      existingPartial,
      jobObj
    ) {
      console.log(
        'Equipment ${equipmentObj.name} was dropped on to ${targetDate} on job ${jobObj.name} with partial ${existingPartial}'
      );
      if (existingPartial) {
        existingPartial.confirmed = false;
        EquipmentService.getEquipmentById(equipmentObj.id).then(
          function(equipment) {
            PartialsService.assignEquipmentToPartial(
              [equipment],
              existingPartial.id
            ).then(
              function(results) {
                // console.log("Equipment Add:: Results :: ", results);
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent(
                      equipmentObj.name +
                        ' added to ' +
                        jobObj.name +
                        ' for ' +
                        targetDate
                    )
                );
                //console.log(vm.partial);
                existingPartial.equipment.push(equipment);
                existingPartial.confirmed = false;

                // build and store the crew and equip string
                populatePartialWithCrewAndEquipmentStrings(existingPartial);
              },
              function(err) {
                $mdToast.show(
                  $mdToast.simple().textContent('There Was an error!')
                );
              }
            );
          },
          function(err) {
            $mdToast.show($mdToast.simple().textContent('There Was an error!'));
          }
        );
      } else {
        PartialsService.upsertPartial({
          jobId: jobObj.id,
          day: moment(targetDate)
            .startOf('day')
            .toISOString()
        }).then(function(partial) {
          EquipmentService.getEquipmentById(equipmentObj.id).then(
            function(equipment) {
              PartialsService.assignEquipmentToPartial(
                [equipment],
                partial.id
              ).then(
                function(results) {
                  console.log('Equipment Add:: Results :: ', results);
                  $mdToast.show(
                    $mdToast
                      .simple()
                      .textContent(
                        equipmentObj.name +
                          ' added to ' +
                          jobObj.name +
                          ' for ' +
                          targetDate
                      )
                  );
                  reloadJobList();
                },
                function(err) {
                  $mdToast.show(
                    $mdToast.simple().textContent('There Was an error!')
                  );
                }
              );
            },
            function(err) {
              $mdToast.show(
                $mdToast.simple().textContent('There Was an error!')
              );
            }
          );
        });
      }
    };

    vm.partialDroppedOnTarget = function(partial, targetDate, job) {
      PartialsService.getPartialById(partial.id).then(function(part) {
        PartialsService.setPartialDate(
          part,
          moment(targetDate).format('YYYY-MM-DD')
        ).then(
          function(result) {
            reloadJobList();
          },
          function(err) {
            $log.log('There was an error, reverting back to original date');
            vm.jobList[part.jobId].days[part.day] = part;
          }
        );
      });
    };

    function openDelayDialog(partialObj) {
      console.log('called!');
      $mdDialog
        .show({
          controller: 'PartialDelayDialogController as vm',
          templateUrl:
            'app/scheduleboard/schedule/partial-delay-dialog.tmpl.html',
          clickOutsideToClose: true,
          locals: {
            partial: partialObj,
            delayDate: partialObj.day
          }
        })
        .then(
          function(date) {
            console.log(date);
            //console.log(delayDate);
            PartialsService.delayPartial(partialObj, date.dateStart).then(
              function(result) {
                console.log('delaying partial');
                reloadJobList();
              }
            );
          },
          function() {
            //$scope.status = 'You cancelled the dialog.';
          }
        );
    }

    function openDeleteDialog(partialObj) {
      $mdDialog
        .show({
          controller: 'PartialDeleteDialogController as vm',
          templateUrl:
            'app/scheduleboard/schedule/partial-delete-dialog.tmpl.html',
          clickOutsideToClose: true,
          locals: {
            reloadJobList: reloadJobList,
            partial: partialObj,
            jobList: vm.jobList
          }
        })
        .then(
          function(deleted) {
            $scope.status = 'You deleted partial(s)!';
          },
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );
    }

    function getNextDay(today) {
      var day = today.day();
      var tomorrow = '';
      if (day == 5) {
        tomorrow = moment(today).add(3, 'days');
      } else if (day == 6) {
        tomorrow = moment(today).add(2, 'days');
      } else {
        tomorrow = moment(today).add(1, 'days');
      }

      return tomorrow;
    }

    function copyPartialForTomorrow(partialObj) {
      var today = moment(partialObj.day);
      var tomorrow = getNextDay(today);

      PartialsService.getPartialsForJobAndDate(
        tomorrow.format('YYYY-MM-DD'),
        partialObj.jobId
      ).then(
        function(answer) {
          //console.log("ANSWER", answer);
          if (answer.length === 0) {
            PartialsService.getPartialById(partialObj.id).then(
              function(partial) {
                var newPart = angular.copy(partial);
                newPart.day = tomorrow.toISOString();
                newPart.confirmed = false;
                delete newPart.id;

                PartialsService.upsertPartial(newPart).then(
                  function(newPartial) {
                    if (partial.equipment.length > 0) {
                      PartialsService.assignEquipmentToPartial(
                        partial.equipment,
                        newPartial.id
                      ).then(
                        function(equipment) {
                          console.log(equipment);
                          newPartial.equipment = partial.equipment;
                        },
                        function(err) {
                          console.log('error', err);
                        }
                      );
                    }

                    if (partial.crew.length > 0) {
                      PartialsService.assignEmployeesToPartial(
                        partial.crew,
                        newPartial.id
                      ).then(
                        function(crew) {
                          console.log(crew);
                          newPartial.crew = partial.crew;
                        },
                        function(err) {
                          console.log('error', err);
                        }
                      );
                    }

                    populatePartialWithCrewAndEquipmentStrings(newPartial);

                    //vm.jobList[vm.partial.jobId].days[vm.partial.day.format("YYYY-MM-DD")]="";
                    //vm.partial=updated;
                    newPartial.confirmed = false;
                    vm.jobList[newPartial.jobId].days[
                      moment(newPartial.day).format('YYYY-MM-DD')
                    ] = newPartial;
                    console.log(vm.jobList);

                    $mdToast.show(
                      $mdToast
                        .simple()
                        .textContent(
                          'Cloned work from ' +
                            today.format('MMM DD') +
                            ' to ' +
                            tomorrow.format('MMM DD')
                        )
                    );
                  },
                  function(err) {
                    console.log('there was an error during upsert', err);
                    $mdToast.show(
                      $mdToast
                        .simple()
                        .textContent(
                          'There is already work for this job tomorrow!'
                        )
                    );
                  }
                );
              },
              function(err) {}
            );
          } else {
            $mdToast.show(
              $mdToast
                .simple()
                .textContent(
                  'Error: unable to copy work from ' +
                    today.format('MMM DD') +
                    ' to ' +
                    tomorrow.format('MMM DD')
                )
            );
          }
        },
        function(err) {
          console.log('ERROR', err);
        }
      );
    }

    function loadAllResources() {
      EmployeesService.getAllEmployees(false).then(function(results) {
        vm.allEmployees = results;
        vm.allEmployees.forEach(function(employee) {
          employee.fullName = employee.firstName + ' ' + employee.lastName;
          if (hasAvatar(employee)) {
            //var fullImgUrl = "";
            var authInfo = '?access_token=' + AuthService.getAccessToken();
            var accountInfo =
              '&accountId=' + AuthService.getBusinessAccountId();
            var fullImgUrl = vm.baseUrl + employee.avatarUrl;
            // console.log("AVATAR URL IN PROFILE PAGE", employee.avatarUrl);
            employee.avatarUrl = fullImgUrl + authInfo + accountInfo;
            employee.avatarUrl = fullImgUrl;
            // console.log(fullImgUrl);
          }
        });
      });
      EquipmentService.getAllEquipment(false).then(function(results) {
        vm.allEquipment = results;
      });
      JobsService.getAllJobs(true).then(function(results) {
        vm.allJobs = results;
      });
    }

    function printPDF() {
      // console.log('print PDF');

      $mdToast.show($mdToast.simple().textContent('Preparing to print!'));
      // dates list (mon, tue, wed...) and schedule
      var workWeek = document.getElementById('workWeek').innerHTML;
      var schedule = document.getElementById('weekCalendar').innerHTML;

      // create our new div, pop the content in it, and give it an id
      var combined = document.createElement('div');
      combined.innerHTML = workWeek + ' ' + schedule; // a little spacing
      combined.id = 'new';

      // 'container' can be whatever your containing element is
      document.getElementById('PDFCanvas').appendChild(combined);

      html2canvas(document.getElementById('PDFCanvas'), {
        onrendered: function(canvas) {
          var data = canvas.toDataURL();
          var docDefinition = {
            content: [
              {
                image: data,
                width: 500
              }
            ]
          };
          pdfMake.createPdf(docDefinition).download();
          var PDFCanvas = document.getElementById('PDFCanvas');
          PDFCanvas.innerHTML = '';
        }
      });
    }

    activate();
  }
})();
