(function() {
    'use strict';
    
        angular
            .module('scheduleboard.services')
            .factory('InvitesService', InvitesService);
    
        InvitesService.inject = ['$q', 'AuthService', 'API', 'Invite', '$log'];
        function InvitesService($q, AuthService, API, Invite, $log) {
            var service = {
                deleteInvite: deleteInvite,
                getAllInvites: getAllInvites,
                getInviteById: getInviteById,
                getInviteInfoWithCode: getInviteInfoWithCode,
                registerWithInvite: registerWithInvite,
                resendAllInvitesForEmail: resendAllInvitesForEmail,
                resendInvite: resendInvite
            };

            return service;

            ////////////////

            function deleteInvite(InviteObj){
                return Invite.delete(InviteObj).$promise;
            };

            function getAllInvites(){
                return Invite.find().$promise;
            };

            function getInviteById(id){
                var criteria = {
                    id: id
                };

                return Invite.findById(criteria).$promise;
            };

            function getInviteInfoWithCode(inviteCode){
                if (inviteCode){
                    return Invite.loadinvite({inviteCode: inviteCode}).$promise;
                }
            };

            function registerWithInvite(userRegData){
                return Invite.completeRegistration({regData:userRegData}).$promise;
            }

            function resendInvite(inviteCode){
                return Invite.resendone({ inviteCode: inviteCode}).$promise;
            };

            function resendAllInvitesForEmail(email){
                return Invite.resendall({ email: email} ).$promise;
            }
        }
    })();