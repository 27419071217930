(function () {
    'use strict';

    angular
        .module('scheduleboard.schedule')
        .controller('PartialDelayDialogController', PartialDelayDialogController);

    /* @ngInject */
    PartialDelayDialogController.inject = ['$scope', 'PartialsService','$mdToast','EmployeesService','$mdDialog','partial'];
    function PartialDelayDialogController($scope, PartialsService,$mdToast,EmployeesService, $mdDialog, partial) {

        var vm = this;
        //vm.weekDays = weekDays;
        vm.partial = partial;
        //console.log(vm.weekDays);
        //vm.maxDate = new Date(vm.weekDays[Object.keys(vm.weekDays).length-1]);
        vm.minDate = new Date(partial.day);
        vm.onlyWeekdaysPredicate = onlyWeekdaysPredicate;
        vm.returnDate = returnDate;
        vm.myDate = '';
        var tmpToday = new Date();
vm.mdLocalizationMap =  {
            'Mon':'Mon*',
            'This Week':'Current Week',
        };
        vm.selectedRange = {
            selectedTemplate: 'TW',
            selectedTemplateName: 'This Week',
            dateStart: null,
            dateEnd: null,
            showTemplate: false,
            fullscreen: false
        };
        vm.onSelect = function(scope) {
            //console.log($scope.selectedRange.selectedTemplateName);
            return $scope.selectedRange;
        };

          vm.isDisabled = function($date) {
              var day = moment($date).day();
              if(day == 6 || day == 7) {
                  return true;
              }else{
                  return false;
              }
        };
        vm.pick = function($event, showTemplate) {
            console.log('Button Fired!');
            $scope.selectedRange.showTemplate = showTemplate;
            $mdDateRangePicker.show({
                targetEvent: $event,
                model: $scope.selectedRange
            }).then(function(result) {
                if (result) $scope.selectedRange = result;
            })
        };


vm.mdCustomTemplates = [
            {   name:"Last 3 Months",
                dateStart: new Date((new Date()).setMonth(tmpToday.getMonth() - 3)),
                dateEnd : new Date()
            },
            {
                name:"Last 6 Months",
                dateStart: new Date((new Date()).setMonth(tmpToday.getMonth() - 6)),
                dateEnd : new Date()
            }
        ];
        vm.mdLocalizationMap =  {
            'Mon':'Mon*',
            'This Week':'Current Week',
        };
        vm.pick = function($event, showTemplate) {
            console.log('Button Fired!');
            $scope.selectedRange.showTemplate = showTemplate;
            $mdDateRangePicker.show({
                targetEvent: $event,
                model: $scope.selectedRange
            }).then(function(result) {
                if (result) $scope.selectedRange = result;
                //console.log("THE RESULT" ,result);
            })
        };

        vm.close = close;

        function close(){
            $mdDialog.cancel();
        }

      vm.isDisabled = function($date) {
              var day = moment($date).day();
              if(day == 6 || day == 0) {
                  return true;
              }else{
                  return false;
              }
        }
    function returnDate(){
       // console.log(vm.selectedRange);
        //console.log(vm.myDate);
        $mdDialog.hide(vm.selectedRange);
        //return vm.selectedRange;
    }
    function onlyWeekdaysPredicate(date) {
    var day = date.getDay();
    return day === 1 || day === 2||day === 3 || day ===4 || day === 5;
  };

    }
})();