(function() {

'use strict';

    angular
        .module('scheduleboard.landing')
        .controller('LeadDialogController', LeadDialogController);
        // ----- ControllerFunction -----
        LeadDialogController.$inject = ['$mdDialog', '$scope','$state','Lead','AuthService','$mdToast'];

        /* @ngInject */
        function LeadDialogController($mdDialog, $scope,$state,Lead, $mdToast) {

            var dialog = this;
            //var job = passedJob;
            //console.log();
            dialog.lead={};


            //function declarations

            dialog.close = close;
            dialog.hide = hide;
            dialog.submit = submit;

            dialog.selectedNum='';
            function hide() {
                $mdDialog.hide();
            }

            function close() {
                $mdDialog.cancel();
            }

            function submit() {
                if (!angular.isDefined(dialog.data.email) || !angular.isDefined(dialog.data.firstName)
                    || !angular.isDefined(dialog.data.lastName)
                    || !angular.isDefined(dialog.data.companyName)){
                    //$mdToast.show($mdToast.simple().textContent('not all the data'));
                    //ionicToast.show('Please supply both a name and password!', 'top', false, 2500);
                } else {
                    dialog.data['numEmployees']=dialog.data['selectedNum'];
                    console.log(dialog.data.selectedNum);
                    console.log(dialog.data.numEmployees);
                    Lead.create(dialog.data).$promise.then(function(myData){
                        console.log(myData);
                        //$mdToast.show($mdToast.simple().textContent('thank you!!!'));
                        dialog.close();

                    });
                }


            }

            function hasValidAddress(job){
            if (_.has(job, 'address')){
                var a = job.address;

                var valid = _.has(a, 'zipCode')
                         && _.has(a, 'state')
                         && _.has(a, 'city')
                         && _.has(a, 'streetAddress');

                if (valid) {
                    valid = valid
                            && !(_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress))
                            && !(_.isNull(a.city) || _.isEmpty(a.city))
                            && !(_.isNull(a.state) || _.isEmpty(a.state))
                            && !(_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
                }

                return valid;
            }
            return false;
        }
        }

})();