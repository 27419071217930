(function () {
    'use strict';

    angular
        .module('scheduleboard.users')
        .controller('UserPageController', UserPageController);

    UserPageController.inject = ['$state', '$stateParams', '$rootScope', '$scope', 'AuthService', 'BoarderService', 'Boarder', 'API', '$mdDialog', 'Upload', '$mdToast', 'Account', 'AccountRole', '$filter', '$q', '$window', '$log', 'GoogleService'];
    function UserPageController($state, $stateParams, $rootScope, $scope, AuthService, BoarderService, Boarder, API, $mdDialog, Upload, $mdToast, Account, AccountRole, $filter, $q, $window, $log, GoogleService) {

        var vm = this;

        // FUNCTION DECLARATIONS //

        vm.isAuth = isAuthenticated;
        vm.hasAvatar = hasAvatar;
        vm.uploadAvatar = uploadAvatar;
        vm.showModalToEditUserProfile = showModalToEditUserProfile;
        vm.subExpiresSoon = subExpiresSoon;
        vm.submitNewPaymentMethod = submitNewPaymentMethod;
        vm.toggleShowPayment = toggleShowPayment;
        vm.deletePaymentSource = deletePaymentSource;
        vm.setDefaultPaymentSource = setDefaultPaymentSource;
        vm.checkDateForWork = checkDateForWork;
        vm.onNewEmployeeRoleSelected = onNewEmployeeRoleSelected;
        vm.getGoogleMapsUrlForAddress = getGoogleMapsUrlForAddress;

        // permission check functions
        vm.canEditUserInfo = canEditUserInfo;
        vm.canEditUserRole = canEditUserRole;
        vm.canEditAccount = canEditAccount;

        vm.cancel = cancel;
        vm.openRolesDialog = openRolesDialog;

        var stripe = null;

        activate();

        // EXPOSED FUNCTIONS //

        function cancel(dialog){
            dialog.cancel();

        }
        function openRolesDialog(){
            $mdDialog.show({
                bindToController: true,
                controller: angular.noop,
                controllerAs: 'vm',
                templateUrl: 'app/scheduleboard/users/user-roles-dialog.html',
                clickOutsideToClose: true
            }).then(function () {
                //$mdToast.show($mdToast.simple().textContent('User Edited!'));
            }).catch(function (error) {
                //$scope.status = 'You cancelled the dialog.';
            });
        }
        // called by md-calendar for every day in the minDate - maxDate range set in vm.calendarOptions
        function checkDateForWork(dateToCheck) {
            var found = false;

            if (dateToCheck) {
                if (vm.user && vm.user.partials) {
                    var toCheck = moment(dateToCheck).utc().format('YYYY-MM-DD');

                    vm.user.partials.forEach(function (partial) {
                        if (!found) {
                            var partialDate = moment(partial.day).utc().format('YYYY-MM-DD');
                            found = Boolean(toCheck === partialDate);
                        }
                    });
                }
            }

            return found;
        }

        function canEditUserInfo() {
            return (vm.isSelf || AuthService.isManager() || AuthService.isCustomer() || AuthService.isAdmin());
        }

        function canEditUserRole() {
            console.log(AuthService.isAdmin());
            return (AuthService.isCustomer() || AuthService.isAdmin());
        }

        function canEditAccount() {
            return (AuthService.isCustomer() || AuthService.isAdmin());
        }

        function getGoogleMapsUrlForAddress(address) {
            var toReturn = 'https://www.google.com/maps/search/?api=1&query=';

            if (address) {
                if (address.streetAddress && address.city && address.state && address.zipCode) {
                    var addressStr = address.streetAddress.trim() + ',' + address.city.trim() + ',' + address.state.trim() + ',' + address.zipCode.trim();
                    var whitespaceReplaced = addressStr.replace(/\s/g, '+');
                    toReturn += $window.encodeURIComponent(whitespaceReplaced);
                }
            }

            return toReturn;
        }

        function removeCustomerRole(employee) {
            EmployeesService.destroyAccountRoleById(employee.boarderId, employee.accountRole.id).then(function (res) {
                //loadEmployees();
                $mdToast.show($mdToast.simple().textContent(employee.firstName + " " + employee.lastName + " removed."));
            });
        }

        function onNewEmployeeRoleSelected() {
            //console.log("ROLE:", vm.selectedUserRole);
            if (vm.user.role == vm.selectedUserRole) {
                // same; no change in role required
                return;
            } else {
                // update the role in the backend
                var clonedRoleData = angular.copy(vm.user.accountroles[0]);
                clonedRoleData.role = vm.selectedUserRole;
                AccountRole.upsert(clonedRoleData).$promise.then(function (result) {
                    loadUser();
                    $mdToast.show($mdToast.simple().textContent(vm.user.firstName + '\'s role set to ' + result.role).highlightClass("md-warn"));
                }, function (error) {
                    $mdToast.show($mdToast.simple().textContent('Error: Unable to set ' + vm.user.firstName + '\'s role to be ' + vm.selectedUserRole));
                });
            }
        }

        function assignDefaultSourceToList() {
            angular.forEach(vm.sources, function (source, index, sources) {
                if (source.id == vm.defaultSource.default_source) {
                    // $log.log("THE DEFAULT!!", index);
                    vm.selectedDefaultSource = index;
                }
            });
        }

        function toggleShowPayment() {
            if (vm.showPayment == false) {
                vm.card.mount('#card-element');
                vm.showPayment = true;
                assignDefaultSourceToList();
            } else {
                vm.showPayment = false;
            }
        }

        function subExpiresSoon() {
            // get the expDate from this user's current account
            vm.account = AuthService.getActiveAccount();

            // check the expiry
            if (angular.isDefined(vm.account)) {

            if (vm.account.subscriptionExpired === 'true') {
              return true;
            }/*else {
            var today = moment().utc();
            var expDate = moment(vm.account.subscriptionExpirationDate).utc();
            vm.accountExpiryDays = expDate.diff(today, "days");

            if (vm.accountExpiryDays < 7 && vm.accountExpiryDays >0) {
              return true;
            } else {
              return false;
            }
          }*/
            } else {
                $log.error("No account, or no account subscription expiration date was found. Defaulting to allow access.");
                return true;
            }
        }

        function deletePaymentSource(source) {
            Account.deletePaymentSource({
                dataObj: source
            }).$promise.then(function (response) {
                // TODO:notification
                vm.sources = Account.getPaymentSources();
                assignDefaultSourceToList();
            });
        }

        function setDefaultPaymentSource(source) {
            Account.setDefaultPaymentSource({
                source: source
            }).$promise.then(function (res) {
                $mdToast.show($mdToast.simple().textContent('Default Payment Source Set... '));

                Account.getPaymentSources().$promise.then(function (sources) {
                    vm.sources = sources;
                });
                // $log.log(vm.sources);
                Account.getDefaultPaymentSource().$promise.then(function (source) {
                    vm.defaultSource = source;
                });
                assignDefaultSourceToList();
            });
        }

        function submitNewPaymentMethod() {
            stripe.createSource(vm.card).then(function (result) {
                if (result.error) {
                    $mdToast.show($mdToast.simple().textContent('Invalid Credit Card; Please Try Again... ').highlightClass("md-warn"));

                } else {
                    Account.savePayment(result).$promise.then(function (answer) {
                        if (result.error) {
                            $mdToast.show($mdToast.simple().textContent('Invalid Credit Card; Please Try Again... ').highlightClass("md-warn"));
                        } else {
                            // TODO:notification
                            vm.sources = Account.getPaymentSources();
                            vm.defaultSource = Account.getDefaultPaymentSource();

                            assignDefaultSourceToList();
                        }
                    });
                }
            });

            /* Account.savepayment(vm.paymentForm).$promise.then(function(answer){
                    $log.log(answer);
                   /* AuthService.login({"username":vm.registerNewForm.user.username, "password":vm.registerNewForm.user.password}).then(function(authenticated) {
                         $log.log("signup.controller.js", "registerWithInvite()", "auth status: " + JSON.stringify(authenticated));
                         $state.go('resources');
                });
             }); */
        }

        function isAuthenticated() {
            return Boarder.isAuthenticated();
        }

        function uploadAvatar(file) {
            var uploadURL = encodeURI(API.baseUrl + "/Boarders/" + vm.user.id + "/upload-avatar?access_token=" + $rootScope.getAccessToken() + "&accountId=" + $rootScope.getBusinessAccountId());

            if (file) {
                file.upload = Upload.upload({
                    url: uploadURL,
                    data: {
                        file: file
                    }
                });

                file.upload.then(function (response) {
                    var url = response['data'].status.secure_url;
                    vm.user.avatarURL = url;

                    BoarderService.updateBoarderAvatarUrl(vm.user, vm.user.avatarURL).then(function (response) {
                        vm.user = response;
                        loadUser();

                        // announce that the avatar has been updated (mainly so the toolbar avatar updates)
                        AuthService.updateStoredUserObject(response);
                        $rootScope.$broadcast('user-avatar-updated', vm.user);
                    });
                }, function (response) {
                    if (response.status > 0) {
                        $scope.errorMsg = response.status + ': ' + response.data;
                    }
                }, function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                });
            }
        }

        function hasValidAddress(user) {
            if (vm.manualAddress) {
                if (_.has(user, 'address')) {
                    var a = user.address;

                    var valid = _.has(a, 'zipCode') &&
                        _.has(a, 'state') &&
                        _.has(a, 'city') &&
                        _.has(a, 'streetAddress');

                    if (valid) {
                        valid = valid &&
                            !(_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress)) &&
                            !(_.isNull(a.city) || _.isEmpty(a.city)) &&
                            !(_.isNull(a.state) || _.isEmpty(a.state)) &&
                            !(_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
                    }

                    return valid;
                }
                return false;
            } else {
                if (user.location) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        function addressEntered(user) {
            if (vm.manualAddress) {
                if (_.has(user, 'address')) {
                    var a = user.address;

                    var empty =
                        (_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress)) &&
                        (_.isNull(a.city) || _.isEmpty(a.city)) &&
                        (_.isNull(a.state) || _.isEmpty(a.state)) &&
                        (_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
                    if (empty) {
                        // $log.log("empty!!");
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                if (user.location == null) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        function saveEditedUser() {
            // perform validation etc
            var data = vm.userProfileFormData;

            if (!data.firstName) {
                $mdToast.show($mdToast.simple().textContent('Please enter a valid first name'));
            } else if (!data.lastName) {
                $mdToast.show($mdToast.simple().textContent('Please enter a valid last name'));
            } else if (!data.email) {
                $mdToast.show($mdToast.simple().textContent('Please enter a valid email'));
            } else if (!data.mobilePhone) {
                $mdToast.show($mdToast.simple().textContent('Please enter a valid phone number'));
            } else if (!data.id) {
                $mdToast.show($mdToast.simple().textContent('No Boarder Id Found!'));
            } else {
                data.mobilePhone = $filter('bcTelephone')(data.mobilePhone, 'clean');
                if (isNaN(data.mobilePhone.trim()) || data.mobilePhone.trim().length !== 10) {
                    $mdToast.show($mdToast.simple().textContent('Please enter a valid Phone Number'));
                } else {
                    // trim whitespace
                    data.firstName = data.firstName.trim();
                    data.lastName = data.lastName.trim();
                    data.email = data.email.trim();
                    if (data.mobilePhone) {
                        data.mobilePhone = data.mobilePhone.trim();
                    }
                    if (addressEntered(data)) {
                        if (hasValidAddress(data)) {
                            if (!vm.manualAddress) {
                                data.address = GoogleService.getAddressFromPlace(data.locationDetails);
                            }

                            Boarder.updateOrCreate(data).$promise.then(function (results) {
                                $mdToast.show($mdToast.simple().textContent('User Updated!'));
                                $mdDialog.cancel();
                                // $state.reload();
                                loadUser();
                            });
                        } else {
                            $mdToast.show($mdToast.simple().textContent("Invalid Address"));
                        }
                    } else {
                        if (data.address) {
                            data.address.streetAddress = '';
                            data.address.city = '';
                            data.address.zipCode = '';
                            data.address.state = '';
                            if (data.address.geocoords) {
                                data.address.geocoords.lat = '';
                                data.address.geocoords.lng = '';
                            }
                            data.address = null;
                        }

                        Boarder.updateOrCreate(data).$promise.then(function (results) {
                            $mdToast.show($mdToast.simple().textContent('User Updated!'));
                            $mdDialog.cancel();
                            // $state.reload();
                            loadUser();
                        });
                    }

                    // save the data
                    // BoarderService.updateBoarderContactInto(data).then(function(updatedBoarder){
                    //    $mdDialog.cancel();
                    // });
                }
            }
        }

        function toggleManual() {
            if (vm.manualAddress === true) {
                vm.manualAddress = false;
            } else {
                vm.manualAddress = true;
            }
        }

        function showModalToEditUserProfile() {
            vm.userProfileFormData = {
                id: vm.user.id,
                firstName: vm.user.firstName,
                lastName: vm.user.lastName,
                email: vm.user.email,
                mobilePhone: $filter('bcTelephone')(vm.user.mobilePhone, 'format'),
                address: vm.user.address
            };

            $mdDialog.show({
                bindToController: true,
                locals: {
                    newAddress: vm.newAddress,
                    manualAddress: vm.manualAddress,
                    userProfileFormData: vm.userProfileFormData,
                    saveEditedUser: saveEditedUser,
                    toggleManual: toggleManual,
                    cancel: closeEditUserDialog
                },
                controller: angular.noop,
                controllerAs: 'vm',
                templateUrl: 'app/scheduleboard/users/user-details-edit-dialog.html',
                clickOutsideToClose: true
            }).then(function () {
                $mdToast.show($mdToast.simple().textContent('User Edited!'));
            }).catch(function (error) {
                $scope.status = 'You cancelled the dialog.';
            });
        }

        function closeEditUserDialog() {
            $mdDialog.cancel();
        }

        function hasAvatar(boarder) {
            if (_.has(boarder, 'avatarUrl')) {
                return !(_.isNull(boarder.avatarUrl) || _.isEmpty(boarder.avatarUrl));
            }
            return false;
        }

        // loads the user info for the employee being viewed
        function loadUser() {
            BoarderService.getBoarderById(vm.currentUserId).then(function (boarder) {
                // NOTE: boarder includes accountroles, role, partials, invites, and notes

                // extract the numbers of jobs
                boarder.numJobs = String(_.uniqBy(boarder.partials, 'jobId').length);

                // handle address
                if (boarder.address !== null) {
                    vm.newAddress = false;
                    vm.manualAddress = true;
                } else {
                    vm.newAddress = true;
                    vm.manualAddress = false;
                }

                // is the user viewing his own page
                if (vm.currentUserId == AuthService.getUser().id) {
                    vm.isSelf = true;
                }

                // update the data for the UI
                vm.user = boarder;

                // update the selected user role for the role dropdown
                vm.selectedUserRole = boarder.role;

                // update active account
                vm.activeAccount = AuthService.getActiveAccount();

                // TODO: fix this service function to adequately do date range in the where clause.
                /* BoarderService.getPartialsForecastForBoarder(moment(),30,vm.user.id).then(function(answer){
                        vm.workForecast2=answer;
                        $log.log(answer);
                    }); */
            }).catch(function (error) {
                $state.go('scheduleboard', {});
            });
        }

        // builds work forecast for the employee being viewed
        function buildWorkForecast() {
            if (vm.currentUserId) {
                vm.isLoadingUsersPartials = true;

                BoarderService.getPartialsForBoarder(vm.currentUserId).then(function (partialsReturned) {
                    if (!partialsReturned || partialsReturned.length == 0) {
                        vm.workForecast = [];
                    } else {
                        var today = moment().startOf("day").utc();
                        partialsReturned.forEach(function (partial) {
                            var workDate = moment(partial.day).utc();
                            var duration = workDate.diff(today, "days");
                            if (duration >= 0) {
                                vm.workForecast.push(partial);
                            }
                        });
                    }

                    updateCalendarOptionsForPartials(partialsReturned).then(function () {
                        vm.allPartialsForThisUser = partialsReturned;
                        vm.isLoadingUsersPartials = false;
                    });
                }).catch(function (error) {
                    vm.isLoadingUsersPartials = false;
                    // TODO review the below re-routing...is it needed?
                    $state.go('scheduleboard', {});
                });
            }
        }

        function updateCalendarOptionsForPartials(partials) {
            return $q(function (resolve, reject) {
                if (partials && partials.length > 0) {
                    partials.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(a.day) - new Date(b.day);
                    });

                    vm.calendarOptions.minDate = moment(partials[0].day).utc().startOf('day').toDate();
                    vm.calendarOptions.maxDate = moment(partials[partials.length - 1].day).utc().endOf('day').toDate();
                    resolve(vm.calendarOptions);
                } else {
                    reject();
                }
            });
        }

        // sets up Stripe variables and fetches existing payment sources
        function initalizeStripeData() {
            console.log("initializing");
            vm.paymentForm = {};
            vm.showPayment = false;


            console.log(stripe);
            var elements = stripe.elements();
            vm.card = elements.create('card');

            vm.sources = '';
            vm.defaultSource = '';

            // load the Stripe payment sources
            Account.getPaymentSources().$promise.then(function (sources) {
                vm.sources = sources;
            });

            // set the default Stripe payment source
            Account.getDefaultPaymentSource().$promise.then(function (source) {
                vm.defaultSource = source;
            });

            vm.selectedDefaultSource = '';
        }

        // loads when controller loads
        function activate() {
            stripe = Stripe('pk_live_3glBXQ7TdYEaPG9BLj12PPTF');

            if (vm.isAuth()) {
                // the id of the user we are viewing
                vm.currentUserId = $stateParams.userId;

                // the object of the user we are viewing
                vm.user = {};

                // URL needed by the user avatar directive
                vm.baseUrl = API.baseUrl;

                // default address settings for user edit dialog
                vm.manualAddress = true;
                vm.newAddress = false;

                // used for the dropdown for those that can edit employee roles
                vm.rolesAvailToAssignUsers = [ {name: "EMPLOYEE", description: "A resource who can be added to jobs, work jobs, and add content (photos, notes, etc.) to jobs"} , {name: 'SCHEDULER', description: "An employee who can manage the schedule"},{name: 'MANAGER', description: "An employee who can manage the schedule as well as resources (employees, equipment)"}];
                vm.selectedUserRole = '';

                // all partials for this user
                vm.allPartialsForThisUser = [];

                // all parttials for this user, today or in the future
                vm.workForecast = [];

                // is the user viewing their own page? gets updated by loadUser()
                vm.isSelf = false;

                // config options for the calendar
                vm.calendarOptions = {
                    selectedDate: moment().utc().toDate(),
                    minDate: moment("2017-01-01").utc().toDate() // default; should be updated when partials finish loading
                };

                // the account that is currently active for the logged in user
                vm.activeAccount = AuthService.getActiveAccount();

                vm.isLoadingUsersPartials = false;

                // reload data if the user changes their company account
                $scope.$on('user-changed-account', function (event, accountRoleSelectedByUser) {
                    loadUser();
                });

                // load all the things
                loadUser();
                buildWorkForecast();
                initalizeStripeData();
                vm.account = $rootScope.getActiveAccount();
                console.log("The subscription status", vm.account.subscriptionExpired);
            } else {
                $state.go('scheduleboard', {});
            }
        }
    }
})();
