(function() {
    'use strict';

    angular
        .module('scheduleboard.jobs')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider) {

        $stateProvider
        .state('jobs', {
            url: 'app/jobs',
            templateUrl: 'app/scheduleboard/jobs/job-list.html',
            // set the controller to load for this page
            controller: 'JobsController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        })
            .state('jobItem', {
                url: '/app/jobs/:jobId',
                templateUrl: 'app/scheduleboard/jobs/job-detail.html',
                controller: 'JobDetailController',
                controllerAs: 'vm',
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
            });
    }
})();
