(function() {
'use strict';

    angular
        .module('scheduleboard.footer')
        .controller('FooterController', FooterController);

    FooterController.inject = ['$scope', '$state','$mdToast','$mdDialog','$location','$anchorScroll','AuthService','Boarder','$rootScope'];
    function FooterController($scope, $state, $mdToast, $mdDialog,$location, $anchorScroll, AuthService, Boarder, $rootScope) {

        var originatorEv;
        var vm = this;
        vm.user = {};

        /// FUNCTION DECLARATIONS ///
        vm.isAuth = isAuthenticated;
        vm.openMenu = openMenu;
        vm.logout = logout;
        vm.login = login;
        vm.showLoginDialog = showLoginDialog;
        vm.showLeadDialog = showLeadDialog;



        function isAuthenticated(){
            return Boarder.isAuthenticated();

        }

        vm.scrollTo = scrollTo;
        activate();


          function scrollTo(id) {
              $location.hash(id);
              $anchorScroll();
               };

    function logout() {
            AuthService.logout().then(function(msg) {
            $mdToast.show($mdToast.simple().textContent('Logout Successful!'));
            vm.accountName='';
            //$state.go('main');
            }, function(reason) {
            $mdToast.show($mdToast.simple().textContent('Logout Failed!'));
            });
        }

    function login(data){
            if (!angular.isDefined(data.username) || !angular.isDefined(data.password)){
                console.log("no data");
                //ionicToast.show('Please supply both a name and password!', 'top', false, 2500);
            } else if (data.username === '' || data.password === ''){
                                console.log("no data");

                //ionicToast.show('Please supply both a name and password!', 'top', false, 2500);
            } else {
                data.username = data.username.trim().toLowerCase();
                console.log("data!");

                AuthService.login(data).$promise.then(function (authenticated) {
                    console.log("LoginCtrl :: auth status: ", authenticated);

                    // clear the input fields
                    vm.data.username = '';
                    vm.data.password = '';

                    vm.user = $rootScope.getUser();
                    vm.account = $rootScope.getActiveAccount();
                    vm.accountName = vm.account.name;
                    /*Boarder.getCurrent().$promise
                        .then(function(user){
                          vm.user=user;

                      var criteria = {
                            id: user.id,
                            filter: {
                                        include: "account",
                                        where: {
                                            id: $rootScope.getBusinessAccountId()
                                        }
                                    }
                        };
                      Boarder.findById(criteria).$promise.then(function(user){
                         vm.accountName = user.account.name;
                         console.log(vm.accountName);

                      });
                      //console.log(vm.user);
                    });

                        // transition to landing page
                        //$state.go('scheduleboard.main', {}, {reload: true});
                    }, function(err) {
                        // hide loading modal
                        //$ionicLoading.hide();

                        //ionicToast.show('Login Failed. Check name and password!', 'top', false, 2500);
                    });*/

                });
            }
        }

    function goToAccount(user){


    }

    function showLeadDialog(){
            $mdDialog.show({
                templateUrl: 'components/main/lead.html',
                controller: 'LeadDialogController',
                controllerAs: 'dialog',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true,
            })
                .then(function () {
                    $mdToast.show($mdToast.simple().textContent('Thank You For Contacting Us!!!'));
                    //$state.go('main', {}, {reload: true});

                });
        }
    function openMenu($mdMenu, ev) {
      originatorEv = ev;
      $mdMenu.open(ev);
    };

    function activate(){
        if(vm.isAuth()){
            console.log("THE REAL USER", $rootScope.getUser());
                vm.user = $rootScope.getUser();
                vm.accountName = vm.user.accountName;
                /*Boarder.getCurrent().$promise
                    .then(function(user){
                      vm.user=user;
                      console.log("THE USER!!!", vm.user);

                  var criteria = {
                        id: user.id,
                        filter: {
                                    include: "accountroles",
                                    where: {
                                        accountId: $rootScope.getBusinessAccountId(),
                                        boarderId: vm.user.id

                                    }
                                }
                    };
                  Boarder.findById(criteria).$promise.then(function(user){
                     vm.accountName = user.account.name;
                     console.log(vm.accountName);

                  });
                  //console.log(vm.user);
                });

                    // transition to landing page
                    //$state.go('scheduleboard.resources', {}, {reload: true});
                }, function(err) {
                    // hide loading modal
                    //$ionicLoading.hide();

                    //ionicToast.show('Login Failed. Check name and password!', 'top', false, 2500);
                });*/
            }

        }



   function showLoginDialog(event)
        {
    $mdDialog.show({
                templateUrl: 'components/users/login-dialog.html',
                controller: 'LoginDialogController',
                controllerAs: 'dialog',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true
            })
    .then(function(){
        $mdToast.show($mdToast.simple().textContent('Thanks for logging in!'));
        //$state.go('main', {}, {reload: true});

    });
  }

        //console.log(Boarder.isAuthenticated());
    }
})();