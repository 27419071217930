(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('UserPrefsService', UserPrefsService);

    UserPrefsService.inject = ['$rootScope', '$localStorage', 'LOCAL_STORAGE', '_'];
    function UserPrefsService($rootScope, $localStorage, LOCAL_STORAGE, _) {
        // per https://github.com/gsklee/ngStorage
        $rootScope.$storage = $localStorage;
        // console.log("UserPrefsService :: $rootScope.$storage " , $rootScope.$storage);
        
        var service = {
            getPreference: getPreference,
            setPreference: setPreference,
            clearAllPreferences: clearAllPreferences
        };

        return service;

        ////////////////

        function getPreference(preference, defaultValue){
            var prefKey = LOCAL_STORAGE.userPrefs + '.' + preference;
            var pref = _.get($rootScope.$storage, prefKey);
            
            // if there wasn't a pref defined, go ahead and save the default
            if (!angular.isDefined(pref)){
                // console.log("UserPrefsService :: " + prefKey + " setting not found; saving default value: " + defaultValue);
                setPreference(preference, defaultValue);
                pref = defaultValue;
            }

            // console.log("UserPrefsService :: loaded " + prefKey + ": " + pref);
            return pref;
        };

        function setPreference(preference, value){
            if (angular.isDefined(preference) && angular.isDefined(value)){
                var prefKey = LOCAL_STORAGE.userPrefs + '.' + preference;
                // console.log("UserPrefsService :: setting " + prefKey + ": " + value);
                _.set($rootScope.$storage, prefKey, value);
            }
        };

        function clearAllPreferences(){
            _.set($rootScope.$storage, LOCAL_STORAGE.userPrefs, {});
        };

    }
})();