(function () {
    'use strict';

    angular
        .module('scheduleboard.landing')
        .controller('LandingController', LandingController);

    LandingController.inject = ['$rootScope', '$scope', '$location', 'AuthService', '$mdDialog', '$mdToast', '$document', '$log'];
    function LandingController($rootScope, $scope, $location, AuthService, $mdDialog, $mdToast, $document, $log) {

        var vm = this;

        // VARIABLES //

        vm.theme = vm.theme || 'default';
        vm.data = {};

        // FUNCTION DECLARATIONS //

        vm.isAuth = AuthService.isAuthenticated;
        vm.showLeadDialog = showLeadDialog;
        vm.showLoginDialog = showLoginDialog;
        vm.logout = logout;

        activate();

        // FUNCTIONS //

        // Boarder.getCurrent(function(user){console.log(user.username);}, function(err){console.log(err);});

        function activate() {}

        /**
         * Show Login Dialog
         */
        function showLoginDialog(event) {
            $mdDialog.show({
                templateUrl: 'app/scheduleboard/users/login-dialog.html',
                controller: 'LoginDialogController',
                controllerAs: 'dialog',
                parent: angular.element($document.body),
                targetEvent: event,
                clickOutsideToClose: true
            })
            .then(function () {
                $mdToast.show($mdToast.simple().textContent('Thanks for logging in!'));
            });
        }

        /**
         * Logout Function
         */
        function logout() {
            AuthService.logout().then(function (msg) {
                $mdToast.show($mdToast.simple().textContent('Logout Successful!'));
                vm.accountName = '';
                // $state.go('scheduleboard.landing', {}, {reload: true});
            }, function (reason) {
                $mdToast.show($mdToast.simple().textContent('Logout Failed!'));
            });
        }

        function showLeadDialog() {
            $mdDialog.show({
                templateUrl: 'app/scheduleboard/landing/lead.html',
                controller: 'LeadDialogController',
                controllerAs: 'dialog',
                parent: angular.element($document.body),
                targetEvent: event,
                clickOutsideToClose: true
            })
            .then(function () {
                $mdToast.show($mdToast.simple().textContent('Thank You For Contacting Us!!!'));
                // $state.go('scheduleboard.landing', {}, {reload: true});
            });
        }
    }
})();
