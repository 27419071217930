(function() {
    'use strict';
  
    angular
      .module('scheduleboard.resources')
      .controller('ResourcesController', ResourcesController);
  
    ResourcesController.inject = [
      '$scope',
      '$interval',
      '$mdToast',
      '$rootScope',
      '$state',
      '$filter',
      '$mdDialog',
      'AuthService',
      'JobsService',
      'Boarder',
      'PartialsSevice',
      'EmployeesService',
      'EquipmentService',
      'Partial',
      'API',
      '$log',
      'uiGmapGoogleMapApi'
    ];
    /* @ngInject */
    function ResourcesController(
      $scope,
      $interval,
      $mdToast,
      $rootScope,
      $state,
      $filter,
      $mdDialog,
      AuthService,
      JobsService,
      Boarder,
      PartialsService,
      EmployeesService,
      InvitesService,
      EquipmentService,
      Partial,
      API,
      $log,
      uiGmapGoogleMapApi
    ) {
      var vm = this;
  
      // VARIABLES //
  
      // jobs, employees, and equipment
      vm.job = {};
      vm.allJobs = [];
      vm.allEmployees = [];
      vm.allEquipment = [];
      vm.filteredJobs = [];
  
      // can be used by UI to display loading indicators
      vm.isLoadingJobs = false;
      vm.isLoadingEmployees = false;
      vm.isLoadingInvites = false;
      vm.isLoadingEquipment = false;
  
      vm.today = moment();
  
      vm.workDays = [];
      vm.crewWorkingToday = 0;
      vm.workingEmployees = [];
  
      // map related
      vm.mapDate = new Date();
      vm.maps = '';
      var mapOfUsa = {
        center: {
          latitude: 39.8283,
          longitude: -98.5795
        },
        zoom: 5
      };
  
      vm.map = mapOfUsa;
      vm.myBounds = '';
      vm.jobMarkers = [];
      vm.employeeMarkers = [];
      vm.equipmentMarkers = [];
      vm.resourceMarkers = 'jobs';
  
      vm.doCluster = false;
      vm.mouseovers = {};
  
      vm.currentAccountRole = '';
  
      vm.user = $rootScope.getUser();
  
      vm.activeOnly = true;
  
      // EXPOSED FUNCTION DECLARATIONS //
  
      vm.showNewJob = showNewJob;
      vm.showEmployeeInvite = showEmployeeInvite;
      vm.showNewEquipment = showNewEquipment;
  
      vm.hasAvatar = hasAvatar;
      vm.onSwitchToJobsTab = onSwitchToJobsTab;
      vm.onSwitchToEmployeesTab = onSwitchToEmployeesTab;
      vm.onSwitchToEquipmentTab = onSwitchToEquipmentTab;
      vm.onResourcesDatepickerChange = onResourcesDatepickerChange;
      vm.onCalendarBackOneDay = onCalendarBackOneDay;
      vm.onCalendarForwardOneDay = onCalendarForwardOneDay;
      vm.zoomToJobOnMap = zoomToJobOnMap;
      vm.zoomToEmployeeOnMap = zoomToEmployeeOnMap;
      vm.zoomToEquipmentOnMap = zoomToEquipmentOnMap;
      vm.resendInviteEmail = resendInviteEmail;
      vm.removeEmployeeDialog = removeEmployeeDialog;
      vm.removeInviteDialog = removeInviteDialog;
      vm.removeEquipmentDialog = removeEquipmentDialog;
      vm.hasValidLocation = hasValidLocation;
      vm.getFilteredJobsForList = getFilteredJobsForList;
      vm.toggleCluster = toggleCluster;
  
      // map these functions but do not call them - they are called in the view!
      vm.isManager = AuthService.isManager;
      vm.isCustomer = AuthService.isCustomer;
      vm.isAdmin = AuthService.isAdmin;
      //vm.isSelf = AuthService.isSelf;
  
      vm.canAddResources = canAddResources;
      vm.canEditResources = canEditResources;
      vm.canDeleteResources = canDeleteResources;
      vm.isSelf = isSelf;
      vm.isAccountHolder = isAccountHolder;
      // EXPOSED FUNCTIONS //
  
      //TEST
      vm.DisplayStatus = DisplayStatus;
  
      function canAddResources() {
        return vm.isCustomer() || vm.isAdmin() || vm.isManager();
      }
  
      function canEditResources() {
        return vm.isManager() || vm.isCustomer() || vm.isAdmin();
      }
  
      function canDeleteResources() {
        return vm.isCustomer() || vm.isAdmin() || vm.isManager();
      }
  
      //function isSelf(id){
  
      function isAccountHolder(employee) {
        // console.log(employee);
        //console.log(employee.accountRole);
        //console.log($rootScope.getActiveAccount());
        //var accountId = $rootScope.getActiveAccount().id;
        //console.log(employee);
  
        if (employee.smsOnly !== true) {
          //console.log("NOT SMS ONLY");
          //console.log(employee.accountRole);
          if (employee.accountRole) {
            if (employee.accountRole.role == 'CUSTOMER') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }
  
      function isSelf(id) {
        //console.log(id);
        var currentUserId = $rootScope.getUser().id;
        //console.log(currentUserId, id);
        if (id == currentUserId) {
          return true;
        } else {
          return false;
        }
      }
      //console.log("CURRENTLY LOGGED IN USER ID:  " ,currentUserId);
      //console.log($rootScope.getCurrentAccountRole);
      //if(id == $rootScope.getCurrentAccountRole)
      //}
      function onSwitchToJobsTab() {
        // $log.log('onSwitchToJobsTab()')
        // loadJobs()
  
        // tell the map which resource markers to use
        vm.resourceMarkers = 'jobs';
      }
  
      function onSwitchToEmployeesTab() {
        // $log.log('onSwitchToEmployeesTab()')
        // loadEmployees()
        // loadInvites()
  
        // tell the map which resource markers to use
        vm.resourceMarkers = 'employees';
      }
  
      function onSwitchToEquipmentTab() {
        // $log.log('onSwitchToEquipmentTab()')
        // loadEquipment()
  
        // tell the map which resource markers to use
        vm.resourceMarkers = 'equipment';
      }
  
      function toggleCluster() {
        if (vm.doCluster == false) {
          vm.doCluster = true;
        } else {
          vm.doCluster = false;
        }
      }
  
      function showNewJob(ev) {
        $mdDialog
          .show({
            controller: 'JobDialogController as vm',
            templateUrl: 'app/scheduleboard/jobs/job-dialog.tmpl.html',
            clickOutsideToClose: true,
            locals: { callbackFn: loadJobs }
          })
          .then(
            function(answer) {
              $scope.status = 'You said the information was "' + answer + '".';
            },
            function() {
              $scope.status = 'You cancelled the dialog.';
            }
          );
      }
  
      function showEmployeeInvite(ev) {
        $mdDialog
          .show({
            controller: 'EmployeeInviteController as vm',
            templateUrl:
              'app/scheduleboard/employees/employee-invite-dialog.tmpl.html',
            clickOutsideToClose: true,
            locals: {}
          })
          .then(
            function(invitesSent) {
              loadInvites();
              $mdToast.show($mdToast.simple().textContent('Invitations sent!'));
            },
            function(error) {
              $scope.status = 'You cancelled the dialog.';
            }
          );
      }
  
      function showNewEquipment(ev) {
        $mdDialog
          .show({
            controller: 'EquipmentDialogController as vm',
            templateUrl: 'app/scheduleboard/equipment/equipment-dialog.tmpl.html',
            clickOutsideToClose: true,
            locals: { callbackFn: loadEquipment }
          })
          .then(
            function(answer) {
              $scope.status = 'You said the information was "' + answer + '".';
            },
            function() {
              $scope.status = 'You cancelled the dialog.';
            }
          );
      }
  
      function hasAvatar(boarder) {
        if (_.has(boarder, 'avatarUrl')) {
          return !(_.isNull(boarder.avatarUrl) || _.isEmpty(boarder.avatarUrl));
        }
        return false;
      }
  
      function zoomToJobOnMap(job) {
        if (job) {
          hideAllMarkerWindows();
          showMarkerWindowForJob(job);
  
          var center = getMapCenterFromItem(job);
          zoomToLatLngDepth(center.latitude, center.longitude, 15);
        }
      }
  
      function zoomToEmployeeOnMap(employee) {
        if (employee) {
          hideAllMarkerWindows();
          showMarkerWindowForEmployee(employee);
  
          var center = getMapCenterFromItem(employee);
          zoomToLatLngDepth(center.latitude, center.longitude, 15);
        }
      }
  
      function zoomToEquipmentOnMap(equipment) {
        if (equipment) {
          hideAllMarkerWindows();
          showMarkerWindowForEquipment(equipment);
  
          var center = getMapCenterFromItem(equipment);
          zoomToLatLngDepth(center.latitude, center.longitude, 15);
        }
      }
  
      function removeEmployeeDialog(ev, employee) {
        var confirm = $mdDialog
          .confirm()
          .title(
            'Are you sure you want to remove ' +
              employee.firstName +
              ' ' +
              employee.lastName +
              '?'
          )
          .textContent('This action CANNOT be undone!')
          .ariaLabel('Remove Employee Confirmation')
          .targetEvent(ev)
          .ok('Confirm')
          .cancel('Cancel');
  
        $mdDialog.show(confirm).then(
          function() {
            removeEmployeeRole(employee);
            $scope.status = 'Employee delete confirmed';
          },
          function() {
            $scope.status = 'Employee delete cancelled';
          }
        );
      }
  
      function removeInviteDialog(ev, inviteToDelete) {
        var confirm = $mdDialog
          .confirm()
          .title(
            'Are you sure you want to delete the invite for ' +
              inviteToDelete.email +
              '?'
          )
          .textContent('This action CANNOT be undone!')
          .ariaLabel('Delete Invite Confirmation')
          .targetEvent(ev)
          .ok('Confirm')
          .cancel('Cancel');
  
        $mdDialog.show(confirm).then(
          function() {
            removeInvite(inviteToDelete);
            $scope.status = 'Invite deletion confirmed';
          },
          function() {
            $scope.status = 'Invite deletion cancelled';
          }
        );
      }
  
      function removeEquipmentDialog(ev, equipmentToDelete) {
        var confirm = $mdDialog
          .confirm()
          .title(
            'Are you sure you want to delete the ' + equipmentToDelete.name + '?'
          )
          .textContent('This action CANNOT be undone!')
          .ariaLabel('Delete Equipment Confirmation')
          .targetEvent(ev)
          .ok('Confirm')
          .cancel('Cancel');
  
        $mdDialog.show(confirm).then(
          function() {
            removeEquipment(equipmentToDelete);
            $scope.status = 'Equipment deletion confirmed';
          },
          function() {
            $scope.status = 'Equipment deletion cancelled';
          }
        );
      }
  
      function removeEmployeeRole(employee) {
        EmployeesService.destroyAccountRoleById(
          employee.boarderId,
          employee.accountRole.id
        ).then(function(res) {
          loadEmployees();
          $mdToast.show(
            $mdToast
              .simple()
              .textContent(
                employee.firstName + ' ' + employee.lastName + ' removed.'
              )
          );
        });
      }
  
      function removeInvite(inviteToRemove) {
        InvitesService.deleteInvite(inviteToRemove).then(function(res) {
          loadInvites();
          $mdToast.show(
            $mdToast
              .simple()
              .textContent('Invite for ' + inviteToRemove.email + ' removed.')
          );
        });
      }
  
      function removeEquipment(equipmentToRemove) {
        EquipmentService.deleteEquipmentById(equipmentToRemove.id).then(function(
          res
        ) {
          loadEquipment();
          $mdToast.show(
            $mdToast.simple().textContent(equipmentToRemove.name + ' removed.')
          );
        });
      }
  
      // checks whether or not an item (job, employee, equipment) has an address with valid geocoord
      function hasValidLocation(item) {
        return (
          item &&
          item.address &&
          item.address.geocoords &&
          item.address.geocoords.lat &&
          item.address.geocoords.lng
        );
      }
  
      //  INTERNAL / HELPER FUNCTIONS  //
  
      // builds a map maker from a given job
      function buildMapMarkerFromJob(job) {
        var ret;
  
        if (job && hasValidLocation(job)) {
          ret = {
            latitude: job.address.geocoords.lat,
            longitude: job.address.geocoords.lng
          };
  
          ret.idKey = getMarkerIdForJob(job);
          ret.id = getMarkerIdForJob(job);
          ret.name = job.name;
  
          var address = job.address;
          ret.streetAddress = address.streetAddress;
          ret.city = address.city;
          ret.state = address.state;
          ret.zip = address.zipCode;
  
          ret.icon = 'assets/img/font-awesome_wrench.png';
  
          ret.show = false;
        }
  
        return ret;
      }
  
      // builds a map maker from a given equipment
      function buildMapMarkerFromEmployee(employee) {
        var ret;
  
        if (employee && hasValidLocation(employee)) {
          ret = {
            latitude: employee.address.geocoords.lat,
            longitude: employee.address.geocoords.lng
          };
  
          ret.idKey = getMarkerIdForEmployee(employee);
          ret.id = getMarkerIdForEmployee(employee);
          ret.name = employee.firstName + ' ' + employee.lastName;
  
          var address = employee.address;
          ret.streetAddress = address.streetAddress;
          ret.city = address.city;
          ret.state = address.state;
          ret.zip = address.zipCode;
  
          ret.icon = 'assets/img/font-awesome-user.png';
  
          ret.show = false;
        }
  
        return ret;
      }
  
      // builds a map maker from a given employee
      function buildMapMarkerFromEquipment(equipment) {
        var ret;
  
        if (equipment && hasValidLocation(equipment)) {
          ret = {
            latitude: equipment.address.geocoords.lat,
            longitude: equipment.address.geocoords.lng
          };
  
          ret.idKey = getMarkerIdForEquipment(equipment);
          ret.id = getMarkerIdForEquipment(equipment);
          ret.name = equipment.name;
  
          var address = equipment.address;
          ret.streetAddress = address.streetAddress;
          ret.city = address.city;
          ret.state = address.state;
          ret.zip = address.zipCode;
  
          ret.icon = 'assets/img/font-awesome_truck.png';
  
          ret.show = false;
        }
  
        return ret;
      }
  
      function getMarkerIdForJob(job) {
        return 'marker-job-' + job.id;
      }
  
      function getMarkerIdForEmployee(employee) {
        return 'marker-employee-' + employee.id;
      }
  
      function getMarkerIdForEquipment(equipment) {
        return 'marker-equipment-' + equipment.id;
      }
  
      // returns the center lat/lon for a given job/employee/equipment, or 0,0 if no job
      function getMapCenterFromItem(jobEmployeeOrEquipment) {
        if (!jobEmployeeOrEquipment) {
          return {
            lat: 0,
            long: 0
          };
        } else {
          return {
            latitude: jobEmployeeOrEquipment.address.geocoords.lat,
            longitude: jobEmployeeOrEquipment.address.geocoords.lng
          };
        }
      }
  
      function loadMap() {
        uiGmapGoogleMapApi.then(function(maps) {
          maps.visualRefresh = true;
          vm.maps = maps;
          vm.myBounds = new maps.LatLngBounds();
        });
      }
  
      function addWeekdays(date, days) {
        date = moment(date); // use a clone
        while (days > 0) {
          date = date.add(1, 'days');
          // $log.log(dayJobs);
          // decrease "days" only if it's a weekday.
          if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
            days -= 1;
          }
        }
        return date;
      }
  
      function zoomToLatLngDepth(lat, lon, zoomDepth) {
        if (vm.map && lat && lon && zoomDepth) {
          vm.map.center = {
            latitude: lat,
            longitude: lon
          };
          vm.map.zoom = zoomDepth;
        }
      }
  
      function refreshData() {
        loadJobs();
        loadEmployees();
        loadInvites();
        loadEquipment();
      }
  
      function activate() {
        loadJobs();
        loadEmployees();
        loadInvites();
        loadEquipment();
  
        loadMap();
  
        // refresh the currently active account role
        EmployeesService.getCurrentAccountRole(
          vm.user.id,
          $rootScope.getBusinessAccountId
        ).then(function(roles) {
          vm.user.accountRole = roles[0];
        });
  
        // refresh data in the background every N minutes
        var refreshIntervalMs = 1000 * 60 * 20;
        var dataRefreshTimerPromise = $interval(function() {
          refreshData();
        }, refreshIntervalMs);
  
        // stop the refresh if we leave this view
        $scope.$on('$destroy', function() {
          $interval.cancel(dataRefreshTimerPromise);
        });
  
        // reload data if the user changes their company account
        $scope.$on('user-changed-account', function(
          event,
          accountRoleSelectedByUser
        ) {
          refreshData();
        });
      }
  
      function countCrew() {
        if (vm.workDays[0].work) {
          var todaysWork = vm.workDays[0].work;
          todaysWork.forEach(function(partial, index) {
            if (partial.crew.length > 0) {
              partial.crew.forEach(function(employee) {
                employee.fullName = employee.firstName + ' ' + employee.lastName;
                /* if (hasAvatar(employee)) {
                                  //var fullImgUrl = "";
                                  var authInfo = "?access_token=" + AuthService.getAccessToken();
                                  var accountInfo = "&accountId=" + AuthService.getBusinessAccountId();
                                  //var fullImgUrl = API.baseUrl + employee.avatarUrl;
                                  // $log.log("AVATAR URL IN PROFILE PAGE", employee.avatarUrl);
                                  //employee.avatarUrl = fullImgUrl + authInfo + accountInfo;
                                  //employee.avatarUrl = fullImgUrl;
                                  // $log.log(fullImgUrl);
  
                              } */
                vm.crewWorkingToday += 1;
              });
            }
          });
        }
      }
  
      function loadJobs() {
        // indicate to the UI that we are loading
        vm.isLoadingJobs = true;
  
        JobsService.getAllJobs(true).then(
          function(jobs) {
            // update the global jobs store
            vm.allJobs = jobs;
  
            // build a fresh map
            buildMapLayerForJobs(vm.allJobs);
  
            // indicate to the UI that we are done loading
            vm.isLoadingJobs = false;
  
            getFilteredJobsForList();
          },
          function(error) {
            vm.isLoadingJobs = false;
          }
        );
      }
  
      function loadEmployees() {
        // indicate to the UI that we are loading
        vm.isLoadingEmployees = true;
  
        EmployeesService.getAllEmployees(true).then(
          function(emps) {
            vm.allEmployees = emps;
  
            vm.allEmployees.forEach(function(employee) {
              // laod account role
              EmployeesService.getCurrentAccountRole(
                employee.id,
                $rootScope.getBusinessAccountId()
              ).then(function(roles) {
                employee.accountRole = roles[0];
              });
              // build the avatar URL
              // employee.avatarUrl = getAvatarUrlForEmployee(employee)
            });
  
            // build a fresh map
            buildMapLayerForEmployees(vm.allEmployees);
  
            // indicate to the UI that we are done loading
            vm.isLoadingEmployees = false;
          },
          function(error) {
            vm.isLoadingEmployees = false;
          }
        );
      }
  
      function getFilteredJobsForList() {
        var jobs = vm.allJobs || [];
  
        // filter the list if the user wants to see only active jobs
        if (vm.activeOnly) {
          jobs = jobs.filter(function(job) {
            return job.active;
          });
        }
        // return jobs;
        vm.filteredJobs = jobs;
      }
  
      function loadEquipment() {
        // indicate to the UI that we are loading
        vm.isLoadingEquipment = true;
  
        EquipmentService.getAllEquipment(true).then(
          function(equip) {
            vm.allEquipment = equip;
  
            // build a fresh map
            buildMapLayerForEquipment(vm.allEquipment);
  
            // indicate to the UI that we are done loading
            vm.isLoadingEquipment = false;
          },
          function(error) {
            vm.isLoadingEquipment = false;
          }
        );
      }
  
      function loadInvites() {
        // indicate to the UI that we are loading
        vm.isLoadingInvites = true;
  
        InvitesService.getAllInvites().then(
          function(invites) {
            vm.allInvites = invites;
  
            // indicate to the UI that we are done loading
            vm.isLoadingInvites = false;
          },
          function(error) {
            vm.isLoadingInvites = false;
          }
        );
      }
  
      function resendInviteEmail(invite) {
        if (angular.isDefined(invite)) {
          InvitesService.resendInvite(invite.inviteCode).then(
            function(response) {
              $mdToast.show($mdToast.simple().textContent('Invite Resent!'));
            },
            function(err) {
              var actualErrorData = err.data.error;
              if (Number(actualErrorData.statusCode) === 404) {
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent('No account found for that email!')
                );
              } else {
                $mdToast.show(
                  $mdToast
                    .simple()
                    .textContent('Error: ' + actualErrorData.message)
                );
              }
            }
          );
        }
      }
  
      /* function getAvatarUrlForEmployee(employee) {
              if (employee && hasAvatar(employee)) {
                  var authInfo = "?access_token=" + AuthService.getAccessToken()
                  var accountInfo = "&accountId=" + AuthService.getBusinessAccountId()
                  var fullImgUrl = API.baseUrl + employee.avatarUrl
                  return fullImgUrl + authInfo + accountInfo
              } else {
                  return ""
              }
          } */
  
      function buildMapLayerForJobs(jobs) {
        buildMapMarkerLayerForItems(jobs, vm.jobMarkers, buildMapMarkerFromJob);
      }
  
      function buildMapLayerForEmployees(employees) {
        buildMapMarkerLayerForItems(
          employees,
          vm.employeeMarkers,
          buildMapMarkerFromEmployee
        );
      }
  
      function buildMapLayerForEquipment(equipment) {
        buildMapMarkerLayerForItems(
          equipment,
          vm.equipmentMarkers,
          buildMapMarkerFromEquipment
        );
      }
  
      function buildMapMarkerLayerForItems(items, markersArray, markerBuilderFn) {
        if (items && markersArray) {
          // clear out existing markers (DO NOT SET TO A NEW ARRAY!)
          markersArray.splice(0, markersArray.length);
  
          // go through each item
          angular.forEach(items, function(item) {
            // special case - if employee object, it will have a role property; if ADMIN, skip it
            if (item.role && item.role === 'ADMIN') {
              // skip
            } else {
              // build a map marker
              var marker = markerBuilderFn.call(this, item);
  
              // add the map marker to the list of markers to visualize
              if (marker) {
                markersArray.push(marker);
              }
            }
          });
  
          // update the map's bounds
          updateMapBounds();
        }
      }
  
      function updateMapBounds() {
        // concat the arrays of markers
        var allMarkers = vm.jobMarkers.concat(
          vm.employeeMarkers,
          vm.equipmentMarkers
        );
  
        // reset the current bounds
        vm.myBounds = new vm.maps.LatLngBounds();
  
        // update the bounds from the markers
        angular.forEach(allMarkers, function(marker) {
          if (marker.latitude && marker.longitude) {
            // adjust the map's bounds to include this marker
            vm.myBounds.extend(
              new vm.maps.LatLng(marker.latitude, marker.longitude)
            );
          }
        });
  
        // udpate the map's bounds
        vm.map.bounds = vm.myBounds;
      }
  
      function showMarkerWindowForJob(job) {
        if (job) {
          showMarkerWindowForMatchedItem(vm.jobMarkers, getMarkerIdForJob(job));
        }
      }
  
      function showMarkerWindowForEmployee(employee) {
        if (employee) {
          showMarkerWindowForMatchedItem(
            vm.employeeMarkers,
            getMarkerIdForEmployee(employee)
          );
        }
      }
  
      function showMarkerWindowForEquipment(equipment) {
        if (equipment) {
          showMarkerWindowForMatchedItem(
            vm.equipmentMarkers,
            getMarkerIdForEquipment(equipment)
          );
        }
      }
  
      function showMarkerWindowForMatchedItem(markers, idToMatch) {
        var match = _.find(markers, {
          id: idToMatch
        });
        if (match) {
          match.show = true;
        }
      }
  
      function hideAllMarkerWindows(markers) {
        hideAllWindowsForMarkers(vm.jobMarkers);
        hideAllWindowsForMarkers(vm.employeeMarkers);
        hideAllWindowsForMarkers(vm.equipmentMarkers);
      }
  
      function hideAllWindowsForMarkers(markers) {
        angular.forEach(markers, function(marker) {
          marker.show = false;
        });
      }
  
      function onResourcesDatepickerChange(mapDate) {
        $log.log('onResourcesDatepickerChange() to ' + mapDate);
        if (mapDate) {
          // TODO
          // filter the map view for people or equipment, but not jobs
          // the idea being that people and equipment move day to day, but jobs don't
        }
      }
  
      function onCalendarBackOneDay(mapDate) {
        var newDate = new Date();
        newDate.setDate(mapDate.getDate() - 1);
        vm.mapDate = newDate;
        onResourcesDatepickerChange(newDate);
      }
  
      function onCalendarForwardOneDay(mapDate) {
        var newDate = new Date();
        newDate.setDate(mapDate.getDate() + 1);
        vm.mapDate = newDate;
        onResourcesDatepickerChange(newDate);
      }
  
      function DisplayStatus(jobId, status) {
        var active;
        JobsService.getJobById(jobId, true, false).then(
          function(job) {
            console.log('job: ');
            console.log(job);
            console.log('status: ');
            console.log(status);
            job.active = status;
            JobsService.setActiveStatus(job, status).then(function(result, err) {
              if (err) {
                console.log('error', err);
              } else {
                console.log('result', result);
              }
            });
          },
          function(err) {
            console.log(err);
          }
        );
      }
  
      activate();
    }
  })();
  