/* recommended */
/* spinner.directive.js */

/**
 * @desc spinner directive that can be used anywhere across apps at a company named Acme
 * @example <div acme-shared-spinner></div>
 */
angular
    .module('scheduleboard.users')
    .directive('userProfileAvatar', userProfileAvatar);

function userProfileAvatar() {
    return {
        restrict: 'E',
        templateUrl: 'app/scheduleboard/users/userProfileAvatar.tmpl.html',
        scope: {
            employee: '=',
            theme: '@?'
        },
        controller: function ($scope, ImageService) {
            function updateOptimizedAvatarUrlForEmployee(employeeObj) {
                if (employeeObj && employeeObj.avatarUrl) {
                    $scope.employee.optimizedAvatarUrl = ImageService.getUrlForLargeAvatarImage(employeeObj.avatarUrl);
                }
            }

            // set and watch for the employee changing
            updateOptimizedAvatarUrlForEmployee($scope.employee);
            $scope.$watch('employee', function (updatedEmployeeObj) {
                updateOptimizedAvatarUrlForEmployee(updatedEmployeeObj);
            });

            $scope.theme = $scope.theme || 'default';
        }
    };
}
