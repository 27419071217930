(function () {
    'use strict';

    angular
        .module('scheduleboard.users')
        .controller('RegistrationController', RegistrationController);

    RegistrationController.inject = ['$rootScope', '$scope', '$state', '$location', '$anchorScroll', 'Boarder', 'AuthService', '$mdDialog', '$mdToast', '$document', '$log', 'Account', '$mdStepper'];
    function RegistrationController($rootScope, $scope, $state, $location, Boarder, AuthService, $mdDialog, $mdToast, $document, $log, Account, $mdStepper) {
        var vm = this;

        var newStepper;
        var existingStepper;
        vm.regStatus = 'new';
        vm.uniqueCompanyName = false;
        vm.duplicateName = false;
        vm.uniqueEmail = false;
        vm.emailChecked = false;
        vm.registerNewForm = {user: {}, company: {}};
        vm.registerExistingForm = {user: {}, company: {}};

        vm.enterCustomerInfo = enterCustomerInfo;
        vm.enterSubscriptionInfo = enterSubscriptionInfo;
        vm.submitNew = submitNew;
        vm.submitExisting = submitExisting;
        vm.loginExisting = loginExisting;
        vm.validateUser = validateUser;
        vm.isDupe = isDupe;
        vm.resetCompanyErrors = resetCompanyErrors;
        vm.resetExistingCompanyErrors = resetExistingCompanyErrors;

        vm.isValid = isValid;

        function isValid() {
            // $log.log(registerForm.$invalid);
            if (vm.uniqueCompanyName) {
                return true;
            } else {
                return false;
            }
        }
        function isDupe() {
            return vm.duplicateName;
        }

        function resetCompanyErrors() {
            newStepper = $mdStepper('new-registration-stepper');
            // steppers.clearError();

        }

        function resetExistingCompanyErrors() {
            newStepper = $mdStepper('existing-registration-stepper');
            // steppers.clearError();

        }

        function submitNew() {
            vm.registerNewForm.user.username = vm.registerNewForm.user.email;
            $log.log(vm.registerNewForm);
            Account.newcustreg(vm.registerNewForm).$promise.then(function (answer) {
                // $log.log(answer);
                AuthService.login({username: vm.registerNewForm.user.username, password: vm.registerNewForm.user.password}).then(function (authenticated) {
                    $log.log("signup.controller.js", "registerWithInvite()", "auth status: " + JSON.stringify(authenticated));
                    $state.go('resources');
                });
            });
        }

        function checkCompanyName(company) {
            Account.checkname(company).$promise.then(function (answer) {
                // $log.log(answer.exists);
                return answer.exists;

            }, function (err) {
                $log.log(err);
            });
        }

        function enterCustomerInfo() {
            newStepper = $mdStepper('new-registration-stepper');
            // $log.log(steppers);

            Account.checkname(vm.registerNewForm.company).$promise.then(function (answer) {
                // $log.log(answer.exists);
                if (answer.exists === true) {
                    vm.duplicateName = true;
                    newStepper.error(vm.registerNewForm.company.name + "is already a customer! Enter a Unique Company Name");
                    // registerForm.$setValidity(false);
                    registerNewForm.companyName.value = "";
                    // registerForm.$setPristine();

                    // registerForm.enterCustomer.disabled = true;
                    // vm.$untouched;
                    // registerForm.$setValidity(false);
                    // $log.log(vm.registerForm.company.name);
                } else {
                    // vm.registerForm.company.name.$error.validationError = false;
                    vm.duplicateName = false;
                    vm.uniqueCompanyName = true;
                    newStepper.next();
                }
            }, function (err) {
                $log.log(err);
            });

            // $log.log(vm.registerFormData.company);
            // $log.log(vm.registerForm);

            // $log.log(answer.exists);
        }

        function validateUser() {
            $log.log(vm.registerForm.user);
            var email = {email: vm.registerForm.user.email};
            Boarder.checkemail(email).$promise.then(function (answer) {
                $log.log(answer);

                if (answer.exists == true) {
                    // steppers.error(vm.registerForm.user.email, "exists! Username Exists!");
                    // vm.$setValidity("unqiueEmail", false);
                    // $log.log("EXISTS!");
                    // steppers.goto(1);
                    // vm.registerFormData.company.name.$error;
                    vm.duplicateUser = true;
                    vm.uniqueEmail = false;
                    // steppers.next(2);
                } else {
                    // vm.$setValidity("unqiueEmail", true);
                    vm.uniqueEmail = true;
                    // steppers.next();
                }
            });
        }

        function enterSubscriptionInfo() {
            newStepper = $mdStepper('new-registration-stepper');
        }

        function loginExisting(){
            var loggedIn = false;
            existingStepper = $mdStepper('existing-registration-stepper');
            vm.registerExistingForm.user.username = vm.registerExistingForm.user.email;

            AuthService.login({username: vm.registerExistingForm.user.username, password: vm.registerExistingForm.user.password}).then(function (authenticated) {
                $log.log("signup.controller.js", "registerWithInvite()", "auth status: " + JSON.stringify(authenticated));

                $mdToast.show($mdToast.simple().textContent('Logged In'));
                existingStepper.next();

                var user = $rootScope.getUser();
                // $log.log(user);
                vm.registerExistingForm.user.firstName = user.firstName;
                vm.registerExistingForm.user.lastName = user.lastName;
                vm.registerExistingForm.user.email = user.email;
                vm.registerExistingForm.user.username = user.username;

            }, function (error) {
                $mdToast.show($mdToast.simple().textContent('There was a problem with Login'));
                existingStepper.error(vm.registerExistingForm.user.email + "Incorrect User Credentials, could not log in!");
            });
        }

        function submitExisting() {
            existingStepper = $mdStepper('existing-registration-stepper');
            vm.registerExistingForm.user.username = vm.registerExistingForm.user.email;

            Account.checkname(vm.registerExistingForm.company).$promise.then(function (answer) {
                $log.log(answer.exists);

                if (answer.exists == true) {
                    vm.duplicateName = true;
                    existingStepper.error(vm.registerExistingForm.company.name + "is already a customer! Enter a Unique Company Name");
                    // registerForm.$setValidity(false);
                    registerExistingForm.companyName.value = "";

                } else if (answer.exists == false) {
                    Account.newcustreg(vm.registerExistingForm).$promise.then(function (answer) {
                        $mdToast.show($mdToast.simple().textContent('New Company Account Added!!'));

                        $state.go('resources');
                        // $log.log(answer);
                    }, function (error) {
                        $mdToast.show($mdToast.simple().textContent('There was an error with the Account Registration!'));
                    });
                } else {
                }
            });
        }

        function previousStep(form) {
            $log.log(form);
            var stepper = $mdStepper(form + '-registration-stepper');
            stepper.back();
        }

        $scope.$watchCollection('vm.regStatus', function () {
            $log.log(vm.regStatus);
            if (vm.regStatus === 'new') {
                vm.registerNewForm = {company: {}, user: {}};
            } else {
                vm.registerExistingForm = {company: {}, user: {}};
            }
            return vm.regStatus;
        });

        // `data` – `{object=}` - {company:{}, user:{}}.  newcustreg
        // $log.log("here in the registration controller!");
    }
})();
