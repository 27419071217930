(function() {

'use strict';

    angular
        .module('scheduleboard.resources')
        .controller('PartialAddEmployeeController', PartialAddEmployeeController);
        // ----- ControllerFunction -----
        PartialAddEmployeeController.$inject = ['$mdDialog', '$scope','$state','Boarder','AuthService', 'EmployeesService','PartialsService','$mdToast', '$timeout', '$q', '$log','day','partialId','jobId'];

        /* @ngInject */
        function PartialAddEmployeeController($mdDialog, $scope,$state,Boarder,AuthService, EmployeesService, PartialsService, $mdToast, $timeout, $q, $log,day, partialId, jobId) {

            var vm = this;
            vm.allEmployees = '';
            vm.selectedEmployee = '';
            vm.partialId= partialId;
            vm.searchText = '';
            vm.jobId = jobId;
            vm.partialId= partialId;
            vm.day=day;
            vm.query = '';
            //console.log($mdStepper);
            //function declarations
            vm.querySearch   = querySearch;
            vm.selectedItemChange = selectedItemChange;
            vm.searchTextChange   = searchTextChange;


            vm.hide = hide;
            vm.submit = submit;
            vm.querySearch = querySearch;
            vm.searchTextChange = searchTextChange;
            vm.selectedItemChange = selectedItemChange;

            EmployeesService.getAllEmployees(true).then(function(emps){
               vm.allEmployees=emps;
               vm.allEmployees.forEach(function(employee){
                  employee.fullName = employee.firstName + " " + employee.lastName;
               });
               console.log(vm.allEmployees);
            });

    function querySearch (criteria) {
      return criteria ? vm.allEmployees.filter( createFilterFor(criteria) ) : vm.allEmployees;
    }


      function searchTextChange(text) {
      $log.info('Text changed to ' + text);
    }

    function selectedItemChange(item) {
      $log.info('Item changed to ' + JSON.stringify(item));
    }

            function createFilterFor(criteria) {
                var lowercaseQuery = angular.lowercase(criteria);
                return function filterFn(emp) {
                    return (emp.firstName.toLowerCase().indexOf(lowercaseQuery)===0) || (emp.lastName.toLowerCase().indexOf(lowercaseQuery)===0);
                };

            }
            function hide() {
                $mdDialog.hide();
            }

            vm.cancel = function () {

                $mdDialog.cancel();
            };

            function close() {
                vm.query='';
                vm.selectedEmployee='';
                $mdDialog.cancel();
            }

            function submit() {
                console.log("Employee Add :: ", vm.selectedEmployees);
                console.log(vm.jobId);
                console.log(vm.day);

                //console.log(vm.job);
                //console.log(vm.job.locationDetails);
                //console.log(vm.selectedEmployee);
                //vm.selectedEmployees = [vm.selec]
                PartialsService.assignEmployeesToJobDay([vm.selectedEmployee], jobId, day).then(function (results) {
                    console.log("Employee Add:: Results :: " , results);
                    $mdToast.show($mdToast.simple().textContent('Employee Added!'));
                                    vm.query='';
                    vm.selectedEmployee = '';
                    $mdDialog.cancel();
                    $state.reload();
                });

            }
        }

})();