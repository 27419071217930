(function () {
    'use strict';

    angular.module('scheduleboard.core')
    .constant('AUTH_EVENTS', {
        notAuthenticated: 'auth-not-authenticated',
        notAuthorized: 'auth-not-authorized'
    })
    .constant('USER_ROLES', {
        admin: 'admin_role',
        public: 'public_role'
    })
    .constant('LOCAL_STORAGE', {
        userPrefs: 'userPrefs'
    })
    .constant('USER_PREFS', {
        localNotificationsEnabled: 'localNotificationsEnabled',
        userKey: 'userKey',
        lastNotificationCheckAt: 'lastNotificationCheckAt'
    })
    .constant('API', {
        baseUrl: 'https://api.scheduleboard.io',
        stripeKey: 'live'
    })
    .constant('APP_GLOBALS', {
        loadingTimeoutMS: 10000
    });
})();
