(function () {
    'use strict';

    angular
        .module('scheduleboard.users')
        .controller('SignupController', SignupController);

    SignupController.inject = ['$rootScope', '$scope', '$timeout', '$location', '$state', '$state', '$anchorScroll', 'Boarder', 'AuthService', '$mdDialog', '$mdToast', '$document', '$log', 'InvitesService', 'Invite'];
    function SignupController($rootScope, $scope, $timeout, $location, $state, Boarder, AuthService, $mdDialog, $mdToast, $document, $log, InvitesService, Invite) {
        var vm = this;
        vm.hasInvite = false;
        vm.inviteData = {};

        vm.completeInvite = completeInvite;
        vm.getInviteData = getInviteData;

        // used to indicate that there is an async network operation taking place
        vm.isRegistering = false;
        vm.registeredSuccessfully = false;

        var params = $location.search();
        if (params['inviteCode']) {
            var inviteCode = params['inviteCode'];
            getInviteData(inviteCode);
        } else {
            vm.hasInvite = false;
        }

        function getInviteData(code) {
            InvitesService.getInviteInfoWithCode(code).then(function (response) {
                var inviteData = response.data;

                if (inviteData) {
                    vm.inviteData.email = inviteData['email'];
                    vm.inviteData.firstName = inviteData['firstName'];
                    vm.inviteData.lastName = inviteData['lastName'];
                }
                vm.hasInvite = true;
                vm.inviteData.inviteCode = code;
                $mdToast.show($mdToast.simple().textContent('Found Invite for Code ' + code));

            }, function (errorResponse) {
                var err = errorResponse.data.error;
                // inviteForm.inviteCode.$error;
                $mdToast.show($mdToast.simple().textContent('Invalid Invite Code ' + code, err));
                vm.hasInvite = false;
            });
        }

        function dataInputsAreValid(data) {
            if (vm.data) {
                return data.email && data.password && data.firstName && data.lastName && data.inviteCode;
            }
            return false;
        }

        function completeInvite(data) {
            data.fistName = data.firstName.trim();
            data.lastName = data.lastName.trim();
            data.inviteCode = data.inviteCode.trim();
            data.email = data.email.trim().toLowerCase();
            data.username = data.email;
            data.password = data.password.trim().toLowerCase();

            var registrationData = {
                inviteCode: data.inviteCode,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                username: data.username,
                mobilePhone: String(data.mobilePhone)
            };

            // sign the user up, asking backend to create new SB client account
            vm.isRegistering = true;
            InvitesService.registerWithInvite(registrationData).then(function (response) {
                var userData = response.data;
                $log.log("signup.controller.js", "registerWithInvite()", "logging in new account user: " + JSON.stringify(response));

                // log the user in
                var user = {};
                user.username = registrationData.username;
                user.password = registrationData.password;

                AuthService.login({
                    username: registrationData.username,
                    password: registrationData.password
                }).then(function (authenticated) {
                    // update states
                    vm.registeredSuccessfully = true;

                    // wait 2-3 seconds so user sees the signup success screen
                    $timeout(function () {
                        vm.isRegistering = false;

                        // transition to landing page
                        $state.go('resources', {}, {
                            reload: true
                        });
                    }, 2500);

                    $log.log("signup.controller.js", "registerWithInvite()", "auth status: " + JSON.stringify(authenticated));

                    // clear the input fields
                    vm.inviteData.username = '';
                    vm.inviteData.email = '';
                    vm.inviteData.password = '';
                    vm.inviteData.firstName = '';
                    vm.inviteData.lastName = '';
                    vm.inviteData.mobilePhone = '';

                    // hide loading modal
                    // $mdDialog.hide();
                }, function (err) {
                    // hide loading modal
                    vm.isRegistering = false;
                    // $mdDialog.hide();
                    handleLoginSigninErrors(err);
                });

            }, function (err) {
                // hide loading modal
                vm.isRegistering = false;
                $log.error(err);
                handleLoginSigninErrors(err);
            });
        }

        function handleLoginSigninErrors(err) {
            var errMessages = "";

            switch (err.status) {
                case 401: // Login error in this context
                    errMessages = " Login failed. Email or Password incorrect";
                    break;
                case 422: // Validation Error
                    var allMessages = _.concat(_.values(err.data.error.details.messages));

                    errMessages = _.reduce(allMessages, function (concatMsg, msg) {
                        return msg + ". " + concatMsg;
                    }, errMessages);

                    break;
                default:
                    break;
            }

            if (errMessages) {
                $mdToast.show($mdToast.simple().textContent('Error', errMessages));
            }
        }
    }
})();
