(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('DocumentService', DocumentService);

    DocumentService.inject = ['$q', '$http', '$timeout', 'AuthService', 'API', 'Document'];
    function DocumentService($q, $http, $timeout, AuthService, API, Document) {
        var service = {
            addDocument: addDocument,
            deleteDocumentById: deleteDocumentById,
            getAllDocuments: getAllDocuments,
            getDocumentsForJob: getDocumentsForJob,
            getDocumentById: getDocumentById,
            getImagesByJobId: getImagesByJobId
        };
        
        return service;

        ////////////////
        function addDocument(obj){
            obj.dateCreated = moment().toISOString();
            obj.lastUpdated = moment().toISOString();
            obj.accountId = AuthService.getUser().accountId;

            return Document.create(obj).$promise;
        };

        function deleteDocumentById(id){
            return Document.deleteById({ id: id}).$promise;
        };

        function getAllDocuments(){
            return Document.find().$promise;
        };

        function getDocumentsForJob(jobId){
            return Document.find({"where": {"jobId": jobId}}).$promise;
        };

        function getDocumentById(id){
            return Document.findById({ id: id}).$promise;
        };

        function getImagesByJobId(jobId){
            var criteria = {
                filter: {
                    where: {
                        and: [
                            {"jobId": jobId},
                            {"container": {"neq": null}},
                            {"type": {"regexp": "^image"}}
                        ]
                    }
                }
            };

            return Document.find(criteria).$promise;
        };
    }
})();