(function () {
    'use strict';

    angular
        .module('scheduleboard.jobs')
        .controller('JobCardController', JobCardController);

    /* @ngInject */
    JobCardController.inject = ['$scope', 'JobsService','$mdToast','EmployeesService','EquipmentService','$log','uiGmapGoogleMapApi'];
    function JobCardController($scope, JobsService,$mdToast,EmployeesService, EquipmentService, $log, uiGmapGoogleMapApi) {

        var vm = this;
        vm.job = $scope.job;


    }

})();