(function() {
    'use strict';

    angular
        .module('scheduleboard.employees')
        .controller('EmployeesController', EmployeesController);

    /* @ngInject */
    function EmployeesController() {
        var vm = this;
        vm.testData = ['employees', 'are', 'great'];
    }
})();