(function () {
    'use strict';

    angular
        .module('scheduleboard.users')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig($stateProvider) {
        $stateProvider
        .state('signup', {
            url: '/employee-register',
            templateUrl: 'app/scheduleboard/users/signup.tmpl.html',
            // set the controller to load for this page
            controller: 'SignupController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        })
        .state('registration', {
            url: '/registration',
            templateUrl: 'app/scheduleboard/users/registration.tmpl.html',
            // set the controller to load for this page
            controller: 'RegistrationController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        })
        .state('employees', {
            url: '/app/employees',
            templateUrl: 'app/scheduleboard/users/users-list.tmpl.html',
            // set the controller to load for this page
            controller: 'UserListController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        })
        .state('userPage', {
            url: '/app/users/:userId',
            templateUrl: 'app/scheduleboard/users/user-detail.tmpl.html',
            controller: 'UserPageController',
            controllerAs: 'vm',
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        })
        .state('login', {
            url: '/login',
            templateUrl: 'app/scheduleboard/users/login.html',
            // set the controller to load for this page
            controller: 'LoginController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        })
        .state('passwordResetRequest', {
            url: '/password-reset',
            templateUrl: 'app/scheduleboard/users/password-reset-request.html',
            // set the controller to load for this page
            controller: 'PasswordResetController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
})              .state('noAssociatedAccount', {
            url: '/no-associated-account',
            templateUrl: 'app/scheduleboard/users/no-associated-account.html',
            // set the controller to load for this page
            controller: 'NoAccountController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        });
    }
})();
