(function() {

'use strict';

    angular
        .module('scheduleboard.resources')
        .controller('PartialAddEquipmentController', PartialAddEquipmentController);
        // ----- ControllerFunction -----
        PartialAddEquipmentController.$inject = ['$mdDialog', '$scope','$state','Boarder','AuthService', 'EquipmentService','PartialsService','$mdToast', '$timeout', '$q', '$log','day','partialId','jobId'];

        /* @ngInject */
        function PartialAddEquipmentController($mdDialog, $scope,$state,Boarder,AuthService, EquipmentService, PartialsService, $mdToast, $timeout, $q, $log,day, partialId, jobId) {

            var vm = this;
            vm.allEquipment = '';
            vm.selectedEquipment = '';
            vm.partialId= partialId;
            vm.searchText = '';
            vm.jobId = jobId;
            vm.partialId= partialId;
            vm.day=day;
            vm.query = '';
            //console.log($mdStepper);
            //function declarations
            vm.querySearch   = querySearch;
            vm.selectedItemChange = selectedItemChange;
            vm.searchTextChange   = searchTextChange;


            vm.hide = hide;
            vm.submit = submit;
            vm.querySearch = querySearch;
            vm.searchTextChange = searchTextChange;
            vm.selectedItemChange = selectedItemChange;

            EquipmentService.getAllEquipment(true).then(function(equip){
               vm.allEquipment=equip;
               console.log(vm.allEquipment);
            });

    function querySearch (criteria) {
      return criteria ? vm.allEquipment.filter( createFilterFor(criteria) ) : vm.allEquipment;
    }


      function searchTextChange(text) {
      $log.info('Text changed to ' + text);
    }

    function selectedItemChange(item) {
      $log.info('Item changed to ' + JSON.stringify(item));
    }

            function createFilterFor(criteria) {
                var lowercaseQuery = angular.lowercase(criteria);
                return function filterFn(equip) {
                    return (equip.name.toLowerCase().indexOf(lowercaseQuery)===0);
                };

            }
            function hide() {
                $mdDialog.hide();
            }

            vm.cancel = function () {

                $mdDialog.cancel();
            };

            function close() {
                vm.query='';
                vm.selectedEquipment='';
                $mdDialog.cancel();
            }

            function submit() {
                console.log("Equipment To Add :: ", vm.selectedEquipment);
                console.log(vm.jobId);
                console.log(vm.day);

                //console.log(vm.job);
                //console.log(vm.job.locationDetails);
                //console.log(vm.selectedEmployee);
                //vm.selectedEmployees = [vm.selec]

                PartialsService.assignEquipmentToJobDay([vm.selectedEquipment], jobId, vm.day).then(function (results) {
                    console.log("Equipment Add:: Results :: " , results);
                    $mdToast.show($mdToast.simple().textContent('Equipment Added!'));
                    vm.query='';
                    vm.selectedEquipment = '';
                    $mdDialog.cancel();
                    $state.reload();
                });

            }
        }

})();