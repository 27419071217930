(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('AuthInterceptor', AuthInterceptor);

    AuthInterceptor.inject = ['$rootScope', '$q', 'AUTH_EVENTS', 'API', '_'];
    function AuthInterceptor($rootScope, $q, AUTH_EVENTS, API, _) {
        // console.log("in the interceptor!!!");
        var service = {
            request: request,
            responseError: responseError
        };
        
        return service;

        ////////////////
        function request (config) {
            // console.log(config);
            // append access token to scheduleboard requests
            if (_.includes(config.url, API.baseUrl)){
                // only set this if it isn't already
                if (!config.headers['X-Access-Token']){
                    // make sure we have a token available to set
                    if (typeof $rootScope.getAccessToken() === "string"){
                        // console.log("authinterceptor.service.js :: request() :: setting X-Access-Token to " + $rootScope.getAccessToken());
                        // set the token
                        config.headers['X-Access-Token'] = $rootScope.getAccessToken();
                    }
                }
            }


            // append account/company id to scheduleboard requests
            if (_.includes(config.url, API.baseUrl)){
                // only set this if it isn't already
                if (!config.headers['accountId']){
                    // make sure we have a user id set
                    // console.log(`accountId: ${$rootScope.getBusinessAccountId()}`);
                    if ($rootScope.getBusinessAccountId() >= 0){
                        // console.log("authinterceptor.service.js :: request() :: setting accountId to " + $rootScope.getBusinessAccountId());
                        // set the token
                        config.headers['accountId'] = $rootScope.getBusinessAccountId();
                    }
                }else{
                    if ($rootScope.getBusinessAccountId() >= 0){
                        // console.log("authinterceptor.service.js :: request() :: setting accountId to " + $rootScope.getBusinessAccountId());
                        // set the token
                        config.headers['accountId'] = $rootScope.getBusinessAccountId();
                    }
                }
            }
            //console.log($rootScope.getBusinessAccountId());
            //config.headers['accountId'] = $rootScope.getBusinessAccountId();
            // append account/company id to scheduleboard requests
            if (_.includes(config.url, API.baseUrl)){
                // only set this if it isn't already
                if (!config.headers['accountId']){
                    // make sure we have a user id set
                    if ($rootScope.getBusinessAccountId() === "string"){
                        // console.log("authinterceptor.service.js :: request() :: setting accountId to " + $rootScope.getBusinessAccountId());
                        // set the token
                        // console.log("ATTACHING THE BUSINESS ACCOUNT ID");
                        config.headers['accountId'] = $rootScope.getBusinessAccountId();
                    }
                    // console.log($rootScope.getBusinessAccountId());
                    config.headers['accountId'] = $rootScope.getBusinessAccountId();

                }
            }

            if(!config.headers['stripeKey']){
                config.headers['stripeKey'] = API.stripeKey;
            }

            // handle logzIO
            if (_.includes(config.url, API.logzIO)){
                // unset headers
                config.headers = {};
            }

            return config;
        }

        function responseError (response) {
            console.error("authinterceptor.service.js :: responseError() :: Error: " + JSON.stringify(response, null, 2));
            $rootScope.$broadcast({
                401: AUTH_EVENTS.notAuthenticated,
                403: AUTH_EVENTS.notAuthorized
            }[response.status], response);
            //$state.go('landing');
            return $q.reject(response);
        }
    }
})();