(function () {
    'use strict';

    angular
        .module('scheduleboard.services')
        .factory('GoogleService', GoogleService);

    GoogleService.inject = ['$window'];

    function GoogleService($window) {
        var service = {
            getAddressFromPlace: getAddressFromPlace,
            getMapUrlForJob: getMapUrlForJob
        };

        return service;

        // //////////////

        // returns a URL for a static google map image of the job location
        function getMapUrlForJob(jobObj) {
            var googleMapsApiKey = 'AIzaSyAGSmIAVZj35KxOOpbumA3oGyuXXX5LHq8';

            if (jobObj && jobObj.address) {
                // build the Google Map Image URL
                var width = $window.innerWidth;
                var height = 150;
                var scale = 2;
                var zoom = 14; // 1: World | 5: Continent | 10: City | 15: Streets | 20: Buildings
                var maptype = "roadmap";
                var markerSize = "big";
                var markerColor = "green";
                var markerLetterLabel = ""; // can be only a single letter or number
                var imgFormat = "jpg";

                // user textual street address by default
                var addressParameter = jobObj.address.streetAddress + "," +
                    jobObj.address.city + "," +
                    jobObj.address.state + "," +
                    jobObj.address.zipCode;

                // use geo if available
                var geo = jobObj.address.geocoords;
                if (geo && geo.lat && geo.lng) {
                    addressParameter = geo.lat + "," + geo.lng;
                }

                var mapImgUrl = "https://maps.googleapis.com/maps/api/staticmap?" +
                    "size=" + width + "x" + height +
                    "&scale=" + scale +
                    "&zoom=" + zoom +
                    "&maptype=" + maptype +
                    "&mobile=true" +
                    "&markers=size:" + markerSize + "|color:" + markerColor +
                    "|label:" + markerLetterLabel +
                    "|" + addressParameter +
                    "&format=" + imgFormat +
                    "&key=" + googleMapsApiKey;

                return mapImgUrl;
            } else {
                return "";
            }
        }

        // helper method for processing Google Places results
        // per http://stackoverflow.com/questions/8082405/parsing-address-components-in-google-maps-upon-autocomplete-select
        function getAddressComponent(address, component, type) {
            var element = null;
            angular.forEach(address.address_components, function (address_component) {
                if (address_component.types[0] == component) {
                    element = (type == 'short') ? address_component.short_name : address_component.long_name;
                }
            });

            return element;
        }

        // returns a normalized address by parsing a Google Places search result
        function getAddressFromPlace(place) {
            var result = {};

            if (angular.isDefined(place)) {
                // extract the relevant properties and build our address object
                result = {
                    name: place.name,
                    formattedAddress: place.formatted_address,
                    streetNumber: getAddressComponent(place, 'street_number', 'short'),
                    streetName: getAddressComponent(place, 'route', 'short'),
                    streetAddress: getAddressComponent(place, 'street_number', 'short')
                        + " " + getAddressComponent(place, 'route', 'short'),
                    city: getAddressComponent(place, 'locality', 'short'),
                    state: getAddressComponent(place, 'administrative_area_level_1', 'short'),
                    zipCode: getAddressComponent(place, 'postal_code', 'short'),
                    geocoords: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    }
                };
            }

            return result;
        }
    }
})();
