// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' &&
  module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = "lbServices";
}

(function(window, angular, undefined) {
  'use strict';

  var urlBase = "";
  var authHeader = 'authorization';

  function getHost(url) {
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }
  // need to use the urlBase as the base to handle multiple
  // loopback servers behind a proxy/gateway where the host
  // would be the same.
  var urlBaseHost = getHost(urlBase) ? urlBase : location.host;

/**
 * @ngdoc overview
 * @name lbServices
 * @module
 * @description
 *
 * The `lbServices` module provides services for interacting with
 * the models exposed by the LoopBack server via the REST API.
 *
 */
  var module = angular.module("lbServices", ['ngResource']);

/**
 * @ngdoc object
 * @name lbServices.Boarder
 * @header lbServices.Boarder
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Boarder` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Boarder",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Boarders/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$__findById__accessTokens
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Find a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "prototype$__findById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accessTokens/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$__destroyById__accessTokens
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Delete a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accessTokens/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$__updateById__accessTokens
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Update a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "prototype$__updateById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accessTokens/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.partials.findById() instead.
            "prototype$__findById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.partials.destroyById() instead.
            "prototype$__destroyById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.partials.updateById() instead.
            "prototype$__updateById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.partials.link() instead.
            "prototype$__link__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.partials.unlink() instead.
            "prototype$__unlink__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.partials.exists() instead.
            "prototype$__exists__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Boarder.invites.findById() instead.
            "prototype$__findById__invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/invites/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.invites.destroyById() instead.
            "prototype$__destroyById__invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/invites/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.invites.updateById() instead.
            "prototype$__updateById__invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/invites/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.notes.findById() instead.
            "prototype$__findById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/notes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.notes.destroyById() instead.
            "prototype$__destroyById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/notes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.notes.updateById() instead.
            "prototype$__updateById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/notes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.accounts.findById() instead.
            "prototype$__findById__accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accounts.destroyById() instead.
            "prototype$__destroyById__accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accounts.updateById() instead.
            "prototype$__updateById__accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.accounts.link() instead.
            "prototype$__link__accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.accounts.unlink() instead.
            "prototype$__unlink__accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accounts.exists() instead.
            "prototype$__exists__accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Boarder.accountroles.findById() instead.
            "prototype$__findById__accountroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accountroles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accountroles.destroyById() instead.
            "prototype$__destroyById__accountroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accountroles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accountroles.updateById() instead.
            "prototype$__updateById__accountroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accountroles/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$__get__accessTokens
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Queries accessTokens of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "prototype$__get__accessTokens": {
              isArray: true,
              url: urlBase + "/Boarders/:id/accessTokens",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$__create__accessTokens
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Creates a new instance in accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "prototype$__create__accessTokens": {
              url: urlBase + "/Boarders/:id/accessTokens",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$__delete__accessTokens
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Deletes all accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__accessTokens": {
              url: urlBase + "/Boarders/:id/accessTokens",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$__count__accessTokens
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Counts accessTokens of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__accessTokens": {
              url: urlBase + "/Boarders/:id/accessTokens/count",
              method: "GET",
            },

            // INTERNAL. Use Boarder.partials() instead.
            "prototype$__get__partials": {
              isArray: true,
              url: urlBase + "/Boarders/:id/partials",
              method: "GET",
            },

            // INTERNAL. Use Boarder.partials.create() instead.
            "prototype$__create__partials": {
              url: urlBase + "/Boarders/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Boarder.partials.destroyAll() instead.
            "prototype$__delete__partials": {
              url: urlBase + "/Boarders/:id/partials",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.partials.count() instead.
            "prototype$__count__partials": {
              url: urlBase + "/Boarders/:id/partials/count",
              method: "GET",
            },

            // INTERNAL. Use Boarder.invites() instead.
            "prototype$__get__invites": {
              isArray: true,
              url: urlBase + "/Boarders/:id/invites",
              method: "GET",
            },

            // INTERNAL. Use Boarder.invites.create() instead.
            "prototype$__create__invites": {
              url: urlBase + "/Boarders/:id/invites",
              method: "POST",
            },

            // INTERNAL. Use Boarder.invites.destroyAll() instead.
            "prototype$__delete__invites": {
              url: urlBase + "/Boarders/:id/invites",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.invites.count() instead.
            "prototype$__count__invites": {
              url: urlBase + "/Boarders/:id/invites/count",
              method: "GET",
            },

            // INTERNAL. Use Boarder.notes() instead.
            "prototype$__get__notes": {
              isArray: true,
              url: urlBase + "/Boarders/:id/notes",
              method: "GET",
            },

            // INTERNAL. Use Boarder.notes.create() instead.
            "prototype$__create__notes": {
              url: urlBase + "/Boarders/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use Boarder.notes.destroyAll() instead.
            "prototype$__delete__notes": {
              url: urlBase + "/Boarders/:id/notes",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.notes.count() instead.
            "prototype$__count__notes": {
              url: urlBase + "/Boarders/:id/notes/count",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accounts() instead.
            "prototype$__get__accounts": {
              isArray: true,
              url: urlBase + "/Boarders/:id/accounts",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accounts.create() instead.
            "prototype$__create__accounts": {
              url: urlBase + "/Boarders/:id/accounts",
              method: "POST",
            },

            // INTERNAL. Use Boarder.accounts.destroyAll() instead.
            "prototype$__delete__accounts": {
              url: urlBase + "/Boarders/:id/accounts",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accounts.count() instead.
            "prototype$__count__accounts": {
              url: urlBase + "/Boarders/:id/accounts/count",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accountroles() instead.
            "prototype$__get__accountroles": {
              isArray: true,
              url: urlBase + "/Boarders/:id/accountroles",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accountroles.create() instead.
            "prototype$__create__accountroles": {
              url: urlBase + "/Boarders/:id/accountroles",
              method: "POST",
            },

            // INTERNAL. Use Boarder.accountroles.destroyAll() instead.
            "prototype$__delete__accountroles": {
              url: urlBase + "/Boarders/:id/accountroles",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accountroles.count() instead.
            "prototype$__count__accountroles": {
              url: urlBase + "/Boarders/:id/accountroles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#create
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Boarders",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#patchOrCreate
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Boarders",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#replaceOrCreate
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Boarders/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#upsertWithWhere
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Boarders/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#exists
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Boarders/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#findById
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Boarders/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#replaceById
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Boarders/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#find
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Boarders",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#findOne
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Boarders/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#updateAll
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Boarders/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#deleteById
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Boarders/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#count
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Boarders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$patchAttributes
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Boarders/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#createChangeStream
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Boarders/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#login
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  return response.resource;
                },
              },
              url: urlBase + "/Boarders/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#logout
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string=}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/Boarders/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$verify
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Trigger user's identity verification with configured verifyOptions
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `verifyOptions` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$verify": {
              url: urlBase + "/Boarders/:id/verify",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#confirm
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Confirm a user registration with identity verification token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `uid` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `redirect` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "confirm": {
              url: urlBase + "/Boarders/confirm",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#resetPassword
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Reset password for a user with email.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "resetPassword": {
              url: urlBase + "/Boarders/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#changePassword
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Change a user's password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `oldPassword` – `{string}` -
             *
             *  - `newPassword` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "changePassword": {
              url: urlBase + "/Boarders/change-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#setPassword
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Reset user's password via a password-reset token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{*=}` -
             *
             *  - `newPassword` – `{string}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "setPassword": {
              url: urlBase + "/Boarders/reset-password",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#employeeinvite
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `employees` – `{*=}` - [ { firstName:"Bob", lastName:"Loblaw", email:"bob.loblaw@gmail.com" }, ...]
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` -
             */
            "employeeinvite": {
              url: urlBase + "/Boarders/employee-invite",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#uploadAvatar
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `status` – `{string=}` -
             */
            "uploadAvatar": {
              url: urlBase + "/Boarders/:id/upload-avatar",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#checkemail
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `req` – `{object=}` -
             *
             *  - `email` – `{string=}` - user@company.com
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "checkemail": {
              url: urlBase + "/Boarders/checkemail",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#createMany
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Boarders",
              method: "POST",
            },

            // INTERNAL. Use Account.boarders.findById() instead.
            "::findById::Account::boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.boarders.destroyById() instead.
            "::destroyById::Account::boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.boarders.updateById() instead.
            "::updateById::Account::boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.boarders.link() instead.
            "::link::Account::boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.boarders.unlink() instead.
            "::unlink::Account::boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.boarders.exists() instead.
            "::exists::Account::boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Account.boarders() instead.
            "::get::Account::boarders": {
              isArray: true,
              url: urlBase + "/Accounts/:id/boarders",
              method: "GET",
            },

            // INTERNAL. Use Account.boarders.create() instead.
            "::create::Account::boarders": {
              url: urlBase + "/Accounts/:id/boarders",
              method: "POST",
            },

            // INTERNAL. Use Account.boarders.createMany() instead.
            "::createMany::Account::boarders": {
              isArray: true,
              url: urlBase + "/Accounts/:id/boarders",
              method: "POST",
            },

            // INTERNAL. Use Account.boarders.destroyAll() instead.
            "::delete::Account::boarders": {
              url: urlBase + "/Accounts/:id/boarders",
              method: "DELETE",
            },

            // INTERNAL. Use Account.boarders.count() instead.
            "::count::Account::boarders": {
              url: urlBase + "/Accounts/:id/boarders/count",
              method: "GET",
            },

            // INTERNAL. Use Partial.crew.findById() instead.
            "::findById::Partial::crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/:fk",
              method: "GET",
            },

            // INTERNAL. Use Partial.crew.destroyById() instead.
            "::destroyById::Partial::crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.crew.updateById() instead.
            "::updateById::Partial::crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Partial.crew.link() instead.
            "::link::Partial::crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Partial.crew.unlink() instead.
            "::unlink::Partial::crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.crew.exists() instead.
            "::exists::Partial::crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Partial.crew() instead.
            "::get::Partial::crew": {
              isArray: true,
              url: urlBase + "/Partials/:id/crew",
              method: "GET",
            },

            // INTERNAL. Use Partial.crew.create() instead.
            "::create::Partial::crew": {
              url: urlBase + "/Partials/:id/crew",
              method: "POST",
            },

            // INTERNAL. Use Partial.crew.createMany() instead.
            "::createMany::Partial::crew": {
              isArray: true,
              url: urlBase + "/Partials/:id/crew",
              method: "POST",
            },

            // INTERNAL. Use Partial.crew.destroyAll() instead.
            "::delete::Partial::crew": {
              url: urlBase + "/Partials/:id/crew",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.crew.count() instead.
            "::count::Partial::crew": {
              url: urlBase + "/Partials/:id/crew/count",
              method: "GET",
            },

            // INTERNAL. Use Crew.boarder() instead.
            "::get::Crew::boarder": {
              url: urlBase + "/Crews/:id/boarder",
              method: "GET",
            },

            // INTERNAL. Use Invite.boarder() instead.
            "::get::Invite::boarder": {
              url: urlBase + "/Invites/:id/boarder",
              method: "GET",
            },

            // INTERNAL. Use Note.boarder() instead.
            "::get::Note::boarder": {
              url: urlBase + "/Notes/:id/boarder",
              method: "GET",
            },

            // INTERNAL. Use AccountRole.boarder() instead.
            "::get::AccountRole::boarder": {
              url: urlBase + "/AccountRoles/:id/boarder",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Boarder#getCurrent
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/Boarders" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Boarder#upsert
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Boarder#updateOrCreate
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Boarder#patchOrCreateWithWhere
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Boarder#update
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Boarder#destroyById
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Boarder#removeById
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Boarder#prototype$updateAttributes
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];

        /**
         * @ngdoc method
         * @name lbServices.Boarder#getCachedCurrent
         * @methodOf lbServices.Boarder
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.Boarder#login} or
         * {@link lbServices.Boarder#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A Boarder instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Boarder#isAuthenticated
         * @methodOf lbServices.Boarder
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Boarder#getCurrentId
         * @methodOf lbServices.Boarder
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.Boarder#modelName
        * @propertyOf lbServices.Boarder
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Boarder`.
        */
        R.modelName = "Boarder";

    /**
     * @ngdoc object
     * @name lbServices.Boarder.partials
     * @header lbServices.Boarder.partials
     * @object
     * @description
     *
     * The object `Boarder.partials` groups methods
     * manipulating `Partial` instances related to `Boarder`.
     *
     * Call {@link lbServices.Boarder#partials Boarder.partials()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Boarder#partials
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Queries partials of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::get::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#count
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Counts partials of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.partials.count = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::count::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#create
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Creates a new instance in partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.create = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::create::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#createMany
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Creates a new instance in partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.createMany = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::createMany::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#destroyAll
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Deletes all partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partials.destroyAll = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::delete::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#destroyById
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Delete a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partials.destroyById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::destroyById::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#exists
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Check the existence of partials relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.exists = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::exists::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#findById
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Find a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.findById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::findById::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#link
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Add a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.link = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::link::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#unlink
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Remove the partials relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partials.unlink = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::unlink::Boarder::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.partials#updateById
             * @methodOf lbServices.Boarder.partials
             *
             * @description
             *
             * Update a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.updateById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::updateById::Boarder::partials"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Boarder.invites
     * @header lbServices.Boarder.invites
     * @object
     * @description
     *
     * The object `Boarder.invites` groups methods
     * manipulating `Invite` instances related to `Boarder`.
     *
     * Call {@link lbServices.Boarder#invites Boarder.invites()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Boarder#invites
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Queries invites of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::get::Boarder::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.invites#count
             * @methodOf lbServices.Boarder.invites
             *
             * @description
             *
             * Counts invites of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.invites.count = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::count::Boarder::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.invites#create
             * @methodOf lbServices.Boarder.invites
             *
             * @description
             *
             * Creates a new instance in invites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites.create = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::create::Boarder::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.invites#createMany
             * @methodOf lbServices.Boarder.invites
             *
             * @description
             *
             * Creates a new instance in invites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites.createMany = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::createMany::Boarder::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.invites#destroyAll
             * @methodOf lbServices.Boarder.invites
             *
             * @description
             *
             * Deletes all invites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.invites.destroyAll = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::delete::Boarder::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.invites#destroyById
             * @methodOf lbServices.Boarder.invites
             *
             * @description
             *
             * Delete a related item by id for invites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for invites
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.invites.destroyById = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::destroyById::Boarder::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.invites#findById
             * @methodOf lbServices.Boarder.invites
             *
             * @description
             *
             * Find a related item by id for invites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for invites
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites.findById = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::findById::Boarder::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.invites#updateById
             * @methodOf lbServices.Boarder.invites
             *
             * @description
             *
             * Update a related item by id for invites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `fk` – `{*}` - Foreign key for invites
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites.updateById = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::updateById::Boarder::invites"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Boarder.notes
     * @header lbServices.Boarder.notes
     * @object
     * @description
     *
     * The object `Boarder.notes` groups methods
     * manipulating `Note` instances related to `Boarder`.
     *
     * Call {@link lbServices.Boarder#notes Boarder.notes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Boarder#notes
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Queries notes of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::get::Boarder::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.notes#count
             * @methodOf lbServices.Boarder.notes
             *
             * @description
             *
             * Counts notes of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notes.count = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::count::Boarder::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.notes#create
             * @methodOf lbServices.Boarder.notes
             *
             * @description
             *
             * Creates a new instance in notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.create = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::create::Boarder::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.notes#createMany
             * @methodOf lbServices.Boarder.notes
             *
             * @description
             *
             * Creates a new instance in notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.createMany = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::createMany::Boarder::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.notes#destroyAll
             * @methodOf lbServices.Boarder.notes
             *
             * @description
             *
             * Deletes all notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notes.destroyAll = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::delete::Boarder::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.notes#destroyById
             * @methodOf lbServices.Boarder.notes
             *
             * @description
             *
             * Delete a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notes.destroyById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::destroyById::Boarder::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.notes#findById
             * @methodOf lbServices.Boarder.notes
             *
             * @description
             *
             * Find a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.findById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::findById::Boarder::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.notes#updateById
             * @methodOf lbServices.Boarder.notes
             *
             * @description
             *
             * Update a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.updateById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::updateById::Boarder::notes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Boarder.accounts
     * @header lbServices.Boarder.accounts
     * @object
     * @description
     *
     * The object `Boarder.accounts` groups methods
     * manipulating `Account` instances related to `Boarder`.
     *
     * Call {@link lbServices.Boarder#accounts Boarder.accounts()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Boarder#accounts
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Queries accounts of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.accounts = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#count
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Counts accounts of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.accounts.count = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::count::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#create
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Creates a new instance in accounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.accounts.create = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::create::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#createMany
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Creates a new instance in accounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.accounts.createMany = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::createMany::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#destroyAll
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Deletes all accounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.accounts.destroyAll = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::delete::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#destroyById
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Delete a related item by id for accounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accounts
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.accounts.destroyById = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::destroyById::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#exists
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Check the existence of accounts relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accounts
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.accounts.exists = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::exists::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#findById
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Find a related item by id for accounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accounts
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.accounts.findById = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::findById::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#link
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Add a related item by id for accounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `fk` – `{*}` - Foreign key for accounts
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.accounts.link = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::link::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#unlink
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Remove the accounts relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accounts
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.accounts.unlink = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::unlink::Boarder::accounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accounts#updateById
             * @methodOf lbServices.Boarder.accounts
             *
             * @description
             *
             * Update a related item by id for accounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `fk` – `{*}` - Foreign key for accounts
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.accounts.updateById = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::updateById::Boarder::accounts"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Boarder.accountroles
     * @header lbServices.Boarder.accountroles
     * @object
     * @description
     *
     * The object `Boarder.accountroles` groups methods
     * manipulating `AccountRole` instances related to `Boarder`.
     *
     * Call {@link lbServices.Boarder#accountroles Boarder.accountroles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Boarder#accountroles
             * @methodOf lbServices.Boarder
             *
             * @description
             *
             * Queries accountroles of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.accountroles = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::get::Boarder::accountroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accountroles#count
             * @methodOf lbServices.Boarder.accountroles
             *
             * @description
             *
             * Counts accountroles of Boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.accountroles.count = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::count::Boarder::accountroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accountroles#create
             * @methodOf lbServices.Boarder.accountroles
             *
             * @description
             *
             * Creates a new instance in accountroles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.accountroles.create = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::create::Boarder::accountroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accountroles#createMany
             * @methodOf lbServices.Boarder.accountroles
             *
             * @description
             *
             * Creates a new instance in accountroles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.accountroles.createMany = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::createMany::Boarder::accountroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accountroles#destroyAll
             * @methodOf lbServices.Boarder.accountroles
             *
             * @description
             *
             * Deletes all accountroles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.accountroles.destroyAll = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::delete::Boarder::accountroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accountroles#destroyById
             * @methodOf lbServices.Boarder.accountroles
             *
             * @description
             *
             * Delete a related item by id for accountroles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accountroles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.accountroles.destroyById = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::destroyById::Boarder::accountroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accountroles#findById
             * @methodOf lbServices.Boarder.accountroles
             *
             * @description
             *
             * Find a related item by id for accountroles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for accountroles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.accountroles.findById = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::findById::Boarder::accountroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Boarder.accountroles#updateById
             * @methodOf lbServices.Boarder.accountroles
             *
             * @description
             *
             * Update a related item by id for accountroles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Boarder id
             *
             *  - `fk` – `{*}` - Foreign key for accountroles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.accountroles.updateById = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::updateById::Boarder::accountroles"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Account
 * @header lbServices.Account
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Account` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Account",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Accounts/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Account.addresses.findById() instead.
            "prototype$__findById__addresses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/addresses/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.addresses.destroyById() instead.
            "prototype$__destroyById__addresses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/addresses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.addresses.updateById() instead.
            "prototype$__updateById__addresses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/addresses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.jobs.findById() instead.
            "prototype$__findById__jobs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.jobs.destroyById() instead.
            "prototype$__destroyById__jobs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.jobs.updateById() instead.
            "prototype$__updateById__jobs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.partials.findById() instead.
            "prototype$__findById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partials/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.partials.destroyById() instead.
            "prototype$__destroyById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partials/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.partials.updateById() instead.
            "prototype$__updateById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partials/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.tasks.findById() instead.
            "prototype$__findById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.tasks.destroyById() instead.
            "prototype$__destroyById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.tasks.updateById() instead.
            "prototype$__updateById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.crews.findById() instead.
            "prototype$__findById__crews": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/crews/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.crews.destroyById() instead.
            "prototype$__destroyById__crews": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/crews/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.crews.updateById() instead.
            "prototype$__updateById__crews": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/crews/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.equipment.findById() instead.
            "prototype$__findById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/equipment/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.equipment.destroyById() instead.
            "prototype$__destroyById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/equipment/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.equipment.updateById() instead.
            "prototype$__updateById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/equipment/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.partialequipment.findById() instead.
            "prototype$__findById__partialequipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partialequipment/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.partialequipment.destroyById() instead.
            "prototype$__destroyById__partialequipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partialequipment/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.partialequipment.updateById() instead.
            "prototype$__updateById__partialequipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partialequipment/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.containers.findById() instead.
            "prototype$__findById__containers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/containers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.containers.destroyById() instead.
            "prototype$__destroyById__containers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/containers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.containers.updateById() instead.
            "prototype$__updateById__containers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/containers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.documents.findById() instead.
            "prototype$__findById__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/documents/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.documents.destroyById() instead.
            "prototype$__destroyById__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/documents/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.documents.updateById() instead.
            "prototype$__updateById__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/documents/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__findById__jobdocuments
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find a related item by id for jobdocuments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for jobdocuments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__findById__jobdocuments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobdocuments/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__destroyById__jobdocuments
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a related item by id for jobdocuments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for jobdocuments
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__jobdocuments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobdocuments/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__updateById__jobdocuments
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update a related item by id for jobdocuments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for jobdocuments
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__updateById__jobdocuments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobdocuments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.invites.findById() instead.
            "prototype$__findById__invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/invites/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.invites.destroyById() instead.
            "prototype$__destroyById__invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/invites/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.invites.updateById() instead.
            "prototype$__updateById__invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/invites/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.leads.findById() instead.
            "prototype$__findById__leads": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/leads/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.leads.destroyById() instead.
            "prototype$__destroyById__leads": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/leads/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.leads.updateById() instead.
            "prototype$__updateById__leads": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/leads/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.boarders.findById() instead.
            "prototype$__findById__boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.boarders.destroyById() instead.
            "prototype$__destroyById__boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.boarders.updateById() instead.
            "prototype$__updateById__boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.boarders.link() instead.
            "prototype$__link__boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.boarders.unlink() instead.
            "prototype$__unlink__boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.boarders.exists() instead.
            "prototype$__exists__boarders": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/boarders/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Account.userroles.findById() instead.
            "prototype$__findById__userroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/userroles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.userroles.destroyById() instead.
            "prototype$__destroyById__userroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/userroles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.userroles.updateById() instead.
            "prototype$__updateById__userroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/userroles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.addresses() instead.
            "prototype$__get__addresses": {
              isArray: true,
              url: urlBase + "/Accounts/:id/addresses",
              method: "GET",
            },

            // INTERNAL. Use Account.addresses.create() instead.
            "prototype$__create__addresses": {
              url: urlBase + "/Accounts/:id/addresses",
              method: "POST",
            },

            // INTERNAL. Use Account.addresses.destroyAll() instead.
            "prototype$__delete__addresses": {
              url: urlBase + "/Accounts/:id/addresses",
              method: "DELETE",
            },

            // INTERNAL. Use Account.addresses.count() instead.
            "prototype$__count__addresses": {
              url: urlBase + "/Accounts/:id/addresses/count",
              method: "GET",
            },

            // INTERNAL. Use Account.jobs() instead.
            "prototype$__get__jobs": {
              isArray: true,
              url: urlBase + "/Accounts/:id/jobs",
              method: "GET",
            },

            // INTERNAL. Use Account.jobs.create() instead.
            "prototype$__create__jobs": {
              url: urlBase + "/Accounts/:id/jobs",
              method: "POST",
            },

            // INTERNAL. Use Account.jobs.destroyAll() instead.
            "prototype$__delete__jobs": {
              url: urlBase + "/Accounts/:id/jobs",
              method: "DELETE",
            },

            // INTERNAL. Use Account.jobs.count() instead.
            "prototype$__count__jobs": {
              url: urlBase + "/Accounts/:id/jobs/count",
              method: "GET",
            },

            // INTERNAL. Use Account.partials() instead.
            "prototype$__get__partials": {
              isArray: true,
              url: urlBase + "/Accounts/:id/partials",
              method: "GET",
            },

            // INTERNAL. Use Account.partials.create() instead.
            "prototype$__create__partials": {
              url: urlBase + "/Accounts/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Account.partials.destroyAll() instead.
            "prototype$__delete__partials": {
              url: urlBase + "/Accounts/:id/partials",
              method: "DELETE",
            },

            // INTERNAL. Use Account.partials.count() instead.
            "prototype$__count__partials": {
              url: urlBase + "/Accounts/:id/partials/count",
              method: "GET",
            },

            // INTERNAL. Use Account.tasks() instead.
            "prototype$__get__tasks": {
              isArray: true,
              url: urlBase + "/Accounts/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use Account.tasks.create() instead.
            "prototype$__create__tasks": {
              url: urlBase + "/Accounts/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Account.tasks.destroyAll() instead.
            "prototype$__delete__tasks": {
              url: urlBase + "/Accounts/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use Account.tasks.count() instead.
            "prototype$__count__tasks": {
              url: urlBase + "/Accounts/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use Account.crews() instead.
            "prototype$__get__crews": {
              isArray: true,
              url: urlBase + "/Accounts/:id/crews",
              method: "GET",
            },

            // INTERNAL. Use Account.crews.create() instead.
            "prototype$__create__crews": {
              url: urlBase + "/Accounts/:id/crews",
              method: "POST",
            },

            // INTERNAL. Use Account.crews.destroyAll() instead.
            "prototype$__delete__crews": {
              url: urlBase + "/Accounts/:id/crews",
              method: "DELETE",
            },

            // INTERNAL. Use Account.crews.count() instead.
            "prototype$__count__crews": {
              url: urlBase + "/Accounts/:id/crews/count",
              method: "GET",
            },

            // INTERNAL. Use Account.equipment() instead.
            "prototype$__get__equipment": {
              isArray: true,
              url: urlBase + "/Accounts/:id/equipment",
              method: "GET",
            },

            // INTERNAL. Use Account.equipment.create() instead.
            "prototype$__create__equipment": {
              url: urlBase + "/Accounts/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Account.equipment.destroyAll() instead.
            "prototype$__delete__equipment": {
              url: urlBase + "/Accounts/:id/equipment",
              method: "DELETE",
            },

            // INTERNAL. Use Account.equipment.count() instead.
            "prototype$__count__equipment": {
              url: urlBase + "/Accounts/:id/equipment/count",
              method: "GET",
            },

            // INTERNAL. Use Account.partialequipment() instead.
            "prototype$__get__partialequipment": {
              isArray: true,
              url: urlBase + "/Accounts/:id/partialequipment",
              method: "GET",
            },

            // INTERNAL. Use Account.partialequipment.create() instead.
            "prototype$__create__partialequipment": {
              url: urlBase + "/Accounts/:id/partialequipment",
              method: "POST",
            },

            // INTERNAL. Use Account.partialequipment.destroyAll() instead.
            "prototype$__delete__partialequipment": {
              url: urlBase + "/Accounts/:id/partialequipment",
              method: "DELETE",
            },

            // INTERNAL. Use Account.partialequipment.count() instead.
            "prototype$__count__partialequipment": {
              url: urlBase + "/Accounts/:id/partialequipment/count",
              method: "GET",
            },

            // INTERNAL. Use Account.containers() instead.
            "prototype$__get__containers": {
              isArray: true,
              url: urlBase + "/Accounts/:id/containers",
              method: "GET",
            },

            // INTERNAL. Use Account.containers.create() instead.
            "prototype$__create__containers": {
              url: urlBase + "/Accounts/:id/containers",
              method: "POST",
            },

            // INTERNAL. Use Account.containers.destroyAll() instead.
            "prototype$__delete__containers": {
              url: urlBase + "/Accounts/:id/containers",
              method: "DELETE",
            },

            // INTERNAL. Use Account.containers.count() instead.
            "prototype$__count__containers": {
              url: urlBase + "/Accounts/:id/containers/count",
              method: "GET",
            },

            // INTERNAL. Use Account.documents() instead.
            "prototype$__get__documents": {
              isArray: true,
              url: urlBase + "/Accounts/:id/documents",
              method: "GET",
            },

            // INTERNAL. Use Account.documents.create() instead.
            "prototype$__create__documents": {
              url: urlBase + "/Accounts/:id/documents",
              method: "POST",
            },

            // INTERNAL. Use Account.documents.destroyAll() instead.
            "prototype$__delete__documents": {
              url: urlBase + "/Accounts/:id/documents",
              method: "DELETE",
            },

            // INTERNAL. Use Account.documents.count() instead.
            "prototype$__count__documents": {
              url: urlBase + "/Accounts/:id/documents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__get__jobdocuments
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries jobdocuments of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__get__jobdocuments": {
              isArray: true,
              url: urlBase + "/Accounts/:id/jobdocuments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__create__jobdocuments
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Creates a new instance in jobdocuments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$__create__jobdocuments": {
              url: urlBase + "/Accounts/:id/jobdocuments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__delete__jobdocuments
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Deletes all jobdocuments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__jobdocuments": {
              url: urlBase + "/Accounts/:id/jobdocuments",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$__count__jobdocuments
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Counts jobdocuments of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__jobdocuments": {
              url: urlBase + "/Accounts/:id/jobdocuments/count",
              method: "GET",
            },

            // INTERNAL. Use Account.invites() instead.
            "prototype$__get__invites": {
              isArray: true,
              url: urlBase + "/Accounts/:id/invites",
              method: "GET",
            },

            // INTERNAL. Use Account.invites.create() instead.
            "prototype$__create__invites": {
              url: urlBase + "/Accounts/:id/invites",
              method: "POST",
            },

            // INTERNAL. Use Account.invites.destroyAll() instead.
            "prototype$__delete__invites": {
              url: urlBase + "/Accounts/:id/invites",
              method: "DELETE",
            },

            // INTERNAL. Use Account.invites.count() instead.
            "prototype$__count__invites": {
              url: urlBase + "/Accounts/:id/invites/count",
              method: "GET",
            },

            // INTERNAL. Use Account.leads() instead.
            "prototype$__get__leads": {
              isArray: true,
              url: urlBase + "/Accounts/:id/leads",
              method: "GET",
            },

            // INTERNAL. Use Account.leads.create() instead.
            "prototype$__create__leads": {
              url: urlBase + "/Accounts/:id/leads",
              method: "POST",
            },

            // INTERNAL. Use Account.leads.destroyAll() instead.
            "prototype$__delete__leads": {
              url: urlBase + "/Accounts/:id/leads",
              method: "DELETE",
            },

            // INTERNAL. Use Account.leads.count() instead.
            "prototype$__count__leads": {
              url: urlBase + "/Accounts/:id/leads/count",
              method: "GET",
            },

            // INTERNAL. Use Account.boarders() instead.
            "prototype$__get__boarders": {
              isArray: true,
              url: urlBase + "/Accounts/:id/boarders",
              method: "GET",
            },

            // INTERNAL. Use Account.boarders.create() instead.
            "prototype$__create__boarders": {
              url: urlBase + "/Accounts/:id/boarders",
              method: "POST",
            },

            // INTERNAL. Use Account.boarders.destroyAll() instead.
            "prototype$__delete__boarders": {
              url: urlBase + "/Accounts/:id/boarders",
              method: "DELETE",
            },

            // INTERNAL. Use Account.boarders.count() instead.
            "prototype$__count__boarders": {
              url: urlBase + "/Accounts/:id/boarders/count",
              method: "GET",
            },

            // INTERNAL. Use Account.userroles() instead.
            "prototype$__get__userroles": {
              isArray: true,
              url: urlBase + "/Accounts/:id/userroles",
              method: "GET",
            },

            // INTERNAL. Use Account.userroles.create() instead.
            "prototype$__create__userroles": {
              url: urlBase + "/Accounts/:id/userroles",
              method: "POST",
            },

            // INTERNAL. Use Account.userroles.destroyAll() instead.
            "prototype$__delete__userroles": {
              url: urlBase + "/Accounts/:id/userroles",
              method: "DELETE",
            },

            // INTERNAL. Use Account.userroles.count() instead.
            "prototype$__count__userroles": {
              url: urlBase + "/Accounts/:id/userroles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#create
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Accounts",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#patchOrCreate
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Accounts",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#replaceOrCreate
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Accounts/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#upsertWithWhere
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Accounts/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#exists
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Accounts/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#findById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Accounts/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#replaceById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Accounts/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#find
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Accounts",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#findOne
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Accounts/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#updateAll
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Accounts/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#deleteById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Accounts/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#count
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Accounts/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$patchAttributes
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Accounts/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#createChangeStream
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Accounts/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#savePayment
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `dataObj` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` - {}
             */
            "savePayment": {
              url: urlBase + "/Accounts/save-payment",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#setDefaultPaymentSource
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `dataObj` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` - {}
             */
            "setDefaultPaymentSource": {
              url: urlBase + "/Accounts/set-default-payment-source",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getDefaultPaymentSource
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `req` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "getDefaultPaymentSource": {
              url: urlBase + "/Accounts/get-default-payment-source",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#deletePaymentSource
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `dataObj` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` - {}
             */
            "deletePaymentSource": {
              url: urlBase + "/Accounts/delete-payment-source",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#getPaymentSources
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `req` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "getPaymentSources": {
              isArray: true,
              url: urlBase + "/Accounts/get-payment-sources",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#newcustreg
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `primary` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` - {company:{}, user:{}}
             */
            "newcustreg": {
              url: urlBase + "/Accounts/new-customer-registration",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#checkname
             * @methodOf lbServices.Account
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `req` – `{object=}` -
             *
             *  - `name` – `{string=}` - Acme, Co.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "checkname": {
              url: urlBase + "/Accounts/checkname",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Account#createMany
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Accounts",
              method: "POST",
            },

            // INTERNAL. Use Boarder.accounts.findById() instead.
            "::findById::Boarder::accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accounts.destroyById() instead.
            "::destroyById::Boarder::accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accounts.updateById() instead.
            "::updateById::Boarder::accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.accounts.link() instead.
            "::link::Boarder::accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.accounts.unlink() instead.
            "::unlink::Boarder::accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accounts.exists() instead.
            "::exists::Boarder::accounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accounts/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Boarder.accounts() instead.
            "::get::Boarder::accounts": {
              isArray: true,
              url: urlBase + "/Boarders/:id/accounts",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accounts.create() instead.
            "::create::Boarder::accounts": {
              url: urlBase + "/Boarders/:id/accounts",
              method: "POST",
            },

            // INTERNAL. Use Boarder.accounts.createMany() instead.
            "::createMany::Boarder::accounts": {
              isArray: true,
              url: urlBase + "/Boarders/:id/accounts",
              method: "POST",
            },

            // INTERNAL. Use Boarder.accounts.destroyAll() instead.
            "::delete::Boarder::accounts": {
              url: urlBase + "/Boarders/:id/accounts",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accounts.count() instead.
            "::count::Boarder::accounts": {
              url: urlBase + "/Boarders/:id/accounts/count",
              method: "GET",
            },

            // INTERNAL. Use Address.account() instead.
            "::get::Address::account": {
              url: urlBase + "/Addresses/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Job.account() instead.
            "::get::Job::account": {
              url: urlBase + "/Jobs/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Partial.account() instead.
            "::get::Partial::account": {
              url: urlBase + "/Partials/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Task.account() instead.
            "::get::Task::account": {
              url: urlBase + "/Tasks/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Crew.account() instead.
            "::get::Crew::account": {
              url: urlBase + "/Crews/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Equipment.account() instead.
            "::get::Equipment::account": {
              url: urlBase + "/Equipment/:id/account",
              method: "GET",
            },

            // INTERNAL. Use PartialEquipment.account() instead.
            "::get::PartialEquipment::account": {
              url: urlBase + "/PartialEquipments/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Document.account() instead.
            "::get::Document::account": {
              url: urlBase + "/Documents/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Invite.account() instead.
            "::get::Invite::account": {
              url: urlBase + "/Invites/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Lead.account() instead.
            "::get::Lead::account": {
              url: urlBase + "/Leads/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Note.account() instead.
            "::get::Note::account": {
              url: urlBase + "/Notes/:id/account",
              method: "GET",
            },

            // INTERNAL. Use AccountRole.account() instead.
            "::get::AccountRole::account": {
              url: urlBase + "/AccountRoles/:id/account",
              method: "GET",
            },

            // INTERNAL. Use JobPhoto.account() instead.
            "::get::JobPhoto::account": {
              url: urlBase + "/JobPhotos/:id/account",
              method: "GET",
            },

            // INTERNAL. Use JobDoc.account() instead.
            "::get::JobDoc::account": {
              url: urlBase + "/JobDocs/:id/account",
              method: "GET",
            },

            // INTERNAL. Use JobPoc.account() instead.
            "::get::JobPoc::account": {
              url: urlBase + "/JobPocs/:id/account",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Account#upsert
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Account#updateOrCreate
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Account#patchOrCreateWithWhere
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Account#update
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Account#destroyById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Account#removeById
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Account#prototype$updateAttributes
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Account#modelName
        * @propertyOf lbServices.Account
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Account`.
        */
        R.modelName = "Account";

    /**
     * @ngdoc object
     * @name lbServices.Account.addresses
     * @header lbServices.Account.addresses
     * @object
     * @description
     *
     * The object `Account.addresses` groups methods
     * manipulating `Address` instances related to `Account`.
     *
     * Call {@link lbServices.Account#addresses Account.addresses()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#addresses
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries addresses of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R.addresses = function() {
          var TargetResource = $injector.get("Address");
          var action = TargetResource["::get::Account::addresses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.addresses#count
             * @methodOf lbServices.Account.addresses
             *
             * @description
             *
             * Counts addresses of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.addresses.count = function() {
          var TargetResource = $injector.get("Address");
          var action = TargetResource["::count::Account::addresses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.addresses#create
             * @methodOf lbServices.Account.addresses
             *
             * @description
             *
             * Creates a new instance in addresses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R.addresses.create = function() {
          var TargetResource = $injector.get("Address");
          var action = TargetResource["::create::Account::addresses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.addresses#createMany
             * @methodOf lbServices.Account.addresses
             *
             * @description
             *
             * Creates a new instance in addresses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R.addresses.createMany = function() {
          var TargetResource = $injector.get("Address");
          var action = TargetResource["::createMany::Account::addresses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.addresses#destroyAll
             * @methodOf lbServices.Account.addresses
             *
             * @description
             *
             * Deletes all addresses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.addresses.destroyAll = function() {
          var TargetResource = $injector.get("Address");
          var action = TargetResource["::delete::Account::addresses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.addresses#destroyById
             * @methodOf lbServices.Account.addresses
             *
             * @description
             *
             * Delete a related item by id for addresses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for addresses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.addresses.destroyById = function() {
          var TargetResource = $injector.get("Address");
          var action = TargetResource["::destroyById::Account::addresses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.addresses#findById
             * @methodOf lbServices.Account.addresses
             *
             * @description
             *
             * Find a related item by id for addresses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for addresses
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R.addresses.findById = function() {
          var TargetResource = $injector.get("Address");
          var action = TargetResource["::findById::Account::addresses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.addresses#updateById
             * @methodOf lbServices.Account.addresses
             *
             * @description
             *
             * Update a related item by id for addresses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for addresses
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R.addresses.updateById = function() {
          var TargetResource = $injector.get("Address");
          var action = TargetResource["::updateById::Account::addresses"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.jobs
     * @header lbServices.Account.jobs
     * @object
     * @description
     *
     * The object `Account.jobs` groups methods
     * manipulating `Job` instances related to `Account`.
     *
     * Call {@link lbServices.Account#jobs Account.jobs()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#jobs
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries jobs of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.jobs = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::get::Account::jobs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.jobs#count
             * @methodOf lbServices.Account.jobs
             *
             * @description
             *
             * Counts jobs of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.jobs.count = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::count::Account::jobs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.jobs#create
             * @methodOf lbServices.Account.jobs
             *
             * @description
             *
             * Creates a new instance in jobs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.jobs.create = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::create::Account::jobs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.jobs#createMany
             * @methodOf lbServices.Account.jobs
             *
             * @description
             *
             * Creates a new instance in jobs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.jobs.createMany = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::createMany::Account::jobs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.jobs#destroyAll
             * @methodOf lbServices.Account.jobs
             *
             * @description
             *
             * Deletes all jobs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.jobs.destroyAll = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::delete::Account::jobs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.jobs#destroyById
             * @methodOf lbServices.Account.jobs
             *
             * @description
             *
             * Delete a related item by id for jobs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for jobs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.jobs.destroyById = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::destroyById::Account::jobs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.jobs#findById
             * @methodOf lbServices.Account.jobs
             *
             * @description
             *
             * Find a related item by id for jobs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for jobs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.jobs.findById = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::findById::Account::jobs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.jobs#updateById
             * @methodOf lbServices.Account.jobs
             *
             * @description
             *
             * Update a related item by id for jobs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for jobs
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.jobs.updateById = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::updateById::Account::jobs"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.partials
     * @header lbServices.Account.partials
     * @object
     * @description
     *
     * The object `Account.partials` groups methods
     * manipulating `Partial` instances related to `Account`.
     *
     * Call {@link lbServices.Account#partials Account.partials()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#partials
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries partials of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::get::Account::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partials#count
             * @methodOf lbServices.Account.partials
             *
             * @description
             *
             * Counts partials of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.partials.count = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::count::Account::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partials#create
             * @methodOf lbServices.Account.partials
             *
             * @description
             *
             * Creates a new instance in partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.create = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::create::Account::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partials#createMany
             * @methodOf lbServices.Account.partials
             *
             * @description
             *
             * Creates a new instance in partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.createMany = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::createMany::Account::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partials#destroyAll
             * @methodOf lbServices.Account.partials
             *
             * @description
             *
             * Deletes all partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partials.destroyAll = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::delete::Account::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partials#destroyById
             * @methodOf lbServices.Account.partials
             *
             * @description
             *
             * Delete a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partials.destroyById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::destroyById::Account::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partials#findById
             * @methodOf lbServices.Account.partials
             *
             * @description
             *
             * Find a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.findById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::findById::Account::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partials#updateById
             * @methodOf lbServices.Account.partials
             *
             * @description
             *
             * Update a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.updateById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::updateById::Account::partials"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.tasks
     * @header lbServices.Account.tasks
     * @object
     * @description
     *
     * The object `Account.tasks` groups methods
     * manipulating `Task` instances related to `Account`.
     *
     * Call {@link lbServices.Account#tasks Account.tasks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#tasks
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries tasks of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Account::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.tasks#count
             * @methodOf lbServices.Account.tasks
             *
             * @description
             *
             * Counts tasks of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasks.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::Account::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.tasks#create
             * @methodOf lbServices.Account.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::Account::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.tasks#createMany
             * @methodOf lbServices.Account.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::Account::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.tasks#destroyAll
             * @methodOf lbServices.Account.tasks
             *
             * @description
             *
             * Deletes all tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::Account::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.tasks#destroyById
             * @methodOf lbServices.Account.tasks
             *
             * @description
             *
             * Delete a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::Account::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.tasks#findById
             * @methodOf lbServices.Account.tasks
             *
             * @description
             *
             * Find a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::Account::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.tasks#updateById
             * @methodOf lbServices.Account.tasks
             *
             * @description
             *
             * Update a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::Account::tasks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.crews
     * @header lbServices.Account.crews
     * @object
     * @description
     *
     * The object `Account.crews` groups methods
     * manipulating `Crew` instances related to `Account`.
     *
     * Call {@link lbServices.Account#crews Account.crews()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#crews
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries crews of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R.crews = function() {
          var TargetResource = $injector.get("Crew");
          var action = TargetResource["::get::Account::crews"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.crews#count
             * @methodOf lbServices.Account.crews
             *
             * @description
             *
             * Counts crews of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.crews.count = function() {
          var TargetResource = $injector.get("Crew");
          var action = TargetResource["::count::Account::crews"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.crews#create
             * @methodOf lbServices.Account.crews
             *
             * @description
             *
             * Creates a new instance in crews of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R.crews.create = function() {
          var TargetResource = $injector.get("Crew");
          var action = TargetResource["::create::Account::crews"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.crews#createMany
             * @methodOf lbServices.Account.crews
             *
             * @description
             *
             * Creates a new instance in crews of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R.crews.createMany = function() {
          var TargetResource = $injector.get("Crew");
          var action = TargetResource["::createMany::Account::crews"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.crews#destroyAll
             * @methodOf lbServices.Account.crews
             *
             * @description
             *
             * Deletes all crews of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.crews.destroyAll = function() {
          var TargetResource = $injector.get("Crew");
          var action = TargetResource["::delete::Account::crews"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.crews#destroyById
             * @methodOf lbServices.Account.crews
             *
             * @description
             *
             * Delete a related item by id for crews.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for crews
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.crews.destroyById = function() {
          var TargetResource = $injector.get("Crew");
          var action = TargetResource["::destroyById::Account::crews"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.crews#findById
             * @methodOf lbServices.Account.crews
             *
             * @description
             *
             * Find a related item by id for crews.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for crews
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R.crews.findById = function() {
          var TargetResource = $injector.get("Crew");
          var action = TargetResource["::findById::Account::crews"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.crews#updateById
             * @methodOf lbServices.Account.crews
             *
             * @description
             *
             * Update a related item by id for crews.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for crews
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R.crews.updateById = function() {
          var TargetResource = $injector.get("Crew");
          var action = TargetResource["::updateById::Account::crews"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.equipment
     * @header lbServices.Account.equipment
     * @object
     * @description
     *
     * The object `Account.equipment` groups methods
     * manipulating `Equipment` instances related to `Account`.
     *
     * Call {@link lbServices.Account#equipment Account.equipment()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#equipment
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries equipment of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::get::Account::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.equipment#count
             * @methodOf lbServices.Account.equipment
             *
             * @description
             *
             * Counts equipment of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.equipment.count = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::count::Account::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.equipment#create
             * @methodOf lbServices.Account.equipment
             *
             * @description
             *
             * Creates a new instance in equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.create = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::create::Account::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.equipment#createMany
             * @methodOf lbServices.Account.equipment
             *
             * @description
             *
             * Creates a new instance in equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.createMany = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::createMany::Account::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.equipment#destroyAll
             * @methodOf lbServices.Account.equipment
             *
             * @description
             *
             * Deletes all equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipment.destroyAll = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::delete::Account::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.equipment#destroyById
             * @methodOf lbServices.Account.equipment
             *
             * @description
             *
             * Delete a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipment.destroyById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::destroyById::Account::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.equipment#findById
             * @methodOf lbServices.Account.equipment
             *
             * @description
             *
             * Find a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.findById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::findById::Account::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.equipment#updateById
             * @methodOf lbServices.Account.equipment
             *
             * @description
             *
             * Update a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.updateById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::updateById::Account::equipment"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.partialequipment
     * @header lbServices.Account.partialequipment
     * @object
     * @description
     *
     * The object `Account.partialequipment` groups methods
     * manipulating `PartialEquipment` instances related to `Account`.
     *
     * Call {@link lbServices.Account#partialequipment Account.partialequipment()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#partialequipment
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries partialequipment of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R.partialequipment = function() {
          var TargetResource = $injector.get("PartialEquipment");
          var action = TargetResource["::get::Account::partialequipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partialequipment#count
             * @methodOf lbServices.Account.partialequipment
             *
             * @description
             *
             * Counts partialequipment of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.partialequipment.count = function() {
          var TargetResource = $injector.get("PartialEquipment");
          var action = TargetResource["::count::Account::partialequipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partialequipment#create
             * @methodOf lbServices.Account.partialequipment
             *
             * @description
             *
             * Creates a new instance in partialequipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R.partialequipment.create = function() {
          var TargetResource = $injector.get("PartialEquipment");
          var action = TargetResource["::create::Account::partialequipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partialequipment#createMany
             * @methodOf lbServices.Account.partialequipment
             *
             * @description
             *
             * Creates a new instance in partialequipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R.partialequipment.createMany = function() {
          var TargetResource = $injector.get("PartialEquipment");
          var action = TargetResource["::createMany::Account::partialequipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partialequipment#destroyAll
             * @methodOf lbServices.Account.partialequipment
             *
             * @description
             *
             * Deletes all partialequipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partialequipment.destroyAll = function() {
          var TargetResource = $injector.get("PartialEquipment");
          var action = TargetResource["::delete::Account::partialequipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partialequipment#destroyById
             * @methodOf lbServices.Account.partialequipment
             *
             * @description
             *
             * Delete a related item by id for partialequipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partialequipment
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partialequipment.destroyById = function() {
          var TargetResource = $injector.get("PartialEquipment");
          var action = TargetResource["::destroyById::Account::partialequipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partialequipment#findById
             * @methodOf lbServices.Account.partialequipment
             *
             * @description
             *
             * Find a related item by id for partialequipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partialequipment
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R.partialequipment.findById = function() {
          var TargetResource = $injector.get("PartialEquipment");
          var action = TargetResource["::findById::Account::partialequipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.partialequipment#updateById
             * @methodOf lbServices.Account.partialequipment
             *
             * @description
             *
             * Update a related item by id for partialequipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for partialequipment
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R.partialequipment.updateById = function() {
          var TargetResource = $injector.get("PartialEquipment");
          var action = TargetResource["::updateById::Account::partialequipment"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.containers
     * @header lbServices.Account.containers
     * @object
     * @description
     *
     * The object `Account.containers` groups methods
     * manipulating `Container` instances related to `Account`.
     *
     * Call {@link lbServices.Account#containers Account.containers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#containers
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries containers of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
        R.containers = function() {
          var TargetResource = $injector.get("Container");
          var action = TargetResource["::get::Account::containers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.containers#count
             * @methodOf lbServices.Account.containers
             *
             * @description
             *
             * Counts containers of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.containers.count = function() {
          var TargetResource = $injector.get("Container");
          var action = TargetResource["::count::Account::containers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.containers#create
             * @methodOf lbServices.Account.containers
             *
             * @description
             *
             * Creates a new instance in containers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
        R.containers.create = function() {
          var TargetResource = $injector.get("Container");
          var action = TargetResource["::create::Account::containers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.containers#createMany
             * @methodOf lbServices.Account.containers
             *
             * @description
             *
             * Creates a new instance in containers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
        R.containers.createMany = function() {
          var TargetResource = $injector.get("Container");
          var action = TargetResource["::createMany::Account::containers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.containers#destroyAll
             * @methodOf lbServices.Account.containers
             *
             * @description
             *
             * Deletes all containers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.containers.destroyAll = function() {
          var TargetResource = $injector.get("Container");
          var action = TargetResource["::delete::Account::containers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.containers#destroyById
             * @methodOf lbServices.Account.containers
             *
             * @description
             *
             * Delete a related item by id for containers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for containers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.containers.destroyById = function() {
          var TargetResource = $injector.get("Container");
          var action = TargetResource["::destroyById::Account::containers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.containers#findById
             * @methodOf lbServices.Account.containers
             *
             * @description
             *
             * Find a related item by id for containers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for containers
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
        R.containers.findById = function() {
          var TargetResource = $injector.get("Container");
          var action = TargetResource["::findById::Account::containers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.containers#updateById
             * @methodOf lbServices.Account.containers
             *
             * @description
             *
             * Update a related item by id for containers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for containers
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
        R.containers.updateById = function() {
          var TargetResource = $injector.get("Container");
          var action = TargetResource["::updateById::Account::containers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.documents
     * @header lbServices.Account.documents
     * @object
     * @description
     *
     * The object `Account.documents` groups methods
     * manipulating `Document` instances related to `Account`.
     *
     * Call {@link lbServices.Account#documents Account.documents()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#documents
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries documents of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::get::Account::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.documents#count
             * @methodOf lbServices.Account.documents
             *
             * @description
             *
             * Counts documents of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.documents.count = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::count::Account::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.documents#create
             * @methodOf lbServices.Account.documents
             *
             * @description
             *
             * Creates a new instance in documents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.create = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::create::Account::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.documents#createMany
             * @methodOf lbServices.Account.documents
             *
             * @description
             *
             * Creates a new instance in documents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.createMany = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::createMany::Account::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.documents#destroyAll
             * @methodOf lbServices.Account.documents
             *
             * @description
             *
             * Deletes all documents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documents.destroyAll = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::delete::Account::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.documents#destroyById
             * @methodOf lbServices.Account.documents
             *
             * @description
             *
             * Delete a related item by id for documents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documents.destroyById = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::destroyById::Account::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.documents#findById
             * @methodOf lbServices.Account.documents
             *
             * @description
             *
             * Find a related item by id for documents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.findById = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::findById::Account::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.documents#updateById
             * @methodOf lbServices.Account.documents
             *
             * @description
             *
             * Update a related item by id for documents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.updateById = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::updateById::Account::documents"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.invites
     * @header lbServices.Account.invites
     * @object
     * @description
     *
     * The object `Account.invites` groups methods
     * manipulating `Invite` instances related to `Account`.
     *
     * Call {@link lbServices.Account#invites Account.invites()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#invites
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries invites of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::get::Account::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.invites#count
             * @methodOf lbServices.Account.invites
             *
             * @description
             *
             * Counts invites of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.invites.count = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::count::Account::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.invites#create
             * @methodOf lbServices.Account.invites
             *
             * @description
             *
             * Creates a new instance in invites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites.create = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::create::Account::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.invites#createMany
             * @methodOf lbServices.Account.invites
             *
             * @description
             *
             * Creates a new instance in invites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites.createMany = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::createMany::Account::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.invites#destroyAll
             * @methodOf lbServices.Account.invites
             *
             * @description
             *
             * Deletes all invites of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.invites.destroyAll = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::delete::Account::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.invites#destroyById
             * @methodOf lbServices.Account.invites
             *
             * @description
             *
             * Delete a related item by id for invites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for invites
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.invites.destroyById = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::destroyById::Account::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.invites#findById
             * @methodOf lbServices.Account.invites
             *
             * @description
             *
             * Find a related item by id for invites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for invites
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites.findById = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::findById::Account::invites"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.invites#updateById
             * @methodOf lbServices.Account.invites
             *
             * @description
             *
             * Update a related item by id for invites.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for invites
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R.invites.updateById = function() {
          var TargetResource = $injector.get("Invite");
          var action = TargetResource["::updateById::Account::invites"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.leads
     * @header lbServices.Account.leads
     * @object
     * @description
     *
     * The object `Account.leads` groups methods
     * manipulating `Lead` instances related to `Account`.
     *
     * Call {@link lbServices.Account#leads Account.leads()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#leads
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries leads of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R.leads = function() {
          var TargetResource = $injector.get("Lead");
          var action = TargetResource["::get::Account::leads"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.leads#count
             * @methodOf lbServices.Account.leads
             *
             * @description
             *
             * Counts leads of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.leads.count = function() {
          var TargetResource = $injector.get("Lead");
          var action = TargetResource["::count::Account::leads"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.leads#create
             * @methodOf lbServices.Account.leads
             *
             * @description
             *
             * Creates a new instance in leads of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R.leads.create = function() {
          var TargetResource = $injector.get("Lead");
          var action = TargetResource["::create::Account::leads"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.leads#createMany
             * @methodOf lbServices.Account.leads
             *
             * @description
             *
             * Creates a new instance in leads of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R.leads.createMany = function() {
          var TargetResource = $injector.get("Lead");
          var action = TargetResource["::createMany::Account::leads"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.leads#destroyAll
             * @methodOf lbServices.Account.leads
             *
             * @description
             *
             * Deletes all leads of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.leads.destroyAll = function() {
          var TargetResource = $injector.get("Lead");
          var action = TargetResource["::delete::Account::leads"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.leads#destroyById
             * @methodOf lbServices.Account.leads
             *
             * @description
             *
             * Delete a related item by id for leads.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for leads
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.leads.destroyById = function() {
          var TargetResource = $injector.get("Lead");
          var action = TargetResource["::destroyById::Account::leads"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.leads#findById
             * @methodOf lbServices.Account.leads
             *
             * @description
             *
             * Find a related item by id for leads.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for leads
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R.leads.findById = function() {
          var TargetResource = $injector.get("Lead");
          var action = TargetResource["::findById::Account::leads"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.leads#updateById
             * @methodOf lbServices.Account.leads
             *
             * @description
             *
             * Update a related item by id for leads.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for leads
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R.leads.updateById = function() {
          var TargetResource = $injector.get("Lead");
          var action = TargetResource["::updateById::Account::leads"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.boarders
     * @header lbServices.Account.boarders
     * @object
     * @description
     *
     * The object `Account.boarders` groups methods
     * manipulating `Boarder` instances related to `Account`.
     *
     * Call {@link lbServices.Account#boarders Account.boarders()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#boarders
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries boarders of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarders = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::get::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#count
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Counts boarders of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.boarders.count = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::count::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#create
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Creates a new instance in boarders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarders.create = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::create::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#createMany
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Creates a new instance in boarders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarders.createMany = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::createMany::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#destroyAll
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Deletes all boarders of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.boarders.destroyAll = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::delete::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#destroyById
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Delete a related item by id for boarders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for boarders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.boarders.destroyById = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::destroyById::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#exists
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Check the existence of boarders relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for boarders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarders.exists = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::exists::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#findById
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Find a related item by id for boarders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for boarders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarders.findById = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::findById::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#link
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Add a related item by id for boarders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for boarders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarders.link = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::link::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#unlink
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Remove the boarders relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for boarders
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.boarders.unlink = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::unlink::Account::boarders"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.boarders#updateById
             * @methodOf lbServices.Account.boarders
             *
             * @description
             *
             * Update a related item by id for boarders.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for boarders
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarders.updateById = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::updateById::Account::boarders"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Account.userroles
     * @header lbServices.Account.userroles
     * @object
     * @description
     *
     * The object `Account.userroles` groups methods
     * manipulating `AccountRole` instances related to `Account`.
     *
     * Call {@link lbServices.Account#userroles Account.userroles()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Account#userroles
             * @methodOf lbServices.Account
             *
             * @description
             *
             * Queries userroles of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.userroles = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::get::Account::userroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.userroles#count
             * @methodOf lbServices.Account.userroles
             *
             * @description
             *
             * Counts userroles of Account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.userroles.count = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::count::Account::userroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.userroles#create
             * @methodOf lbServices.Account.userroles
             *
             * @description
             *
             * Creates a new instance in userroles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.userroles.create = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::create::Account::userroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.userroles#createMany
             * @methodOf lbServices.Account.userroles
             *
             * @description
             *
             * Creates a new instance in userroles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.userroles.createMany = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::createMany::Account::userroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.userroles#destroyAll
             * @methodOf lbServices.Account.userroles
             *
             * @description
             *
             * Deletes all userroles of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.userroles.destroyAll = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::delete::Account::userroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.userroles#destroyById
             * @methodOf lbServices.Account.userroles
             *
             * @description
             *
             * Delete a related item by id for userroles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for userroles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.userroles.destroyById = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::destroyById::Account::userroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.userroles#findById
             * @methodOf lbServices.Account.userroles
             *
             * @description
             *
             * Find a related item by id for userroles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for userroles
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.userroles.findById = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::findById::Account::userroles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Account.userroles#updateById
             * @methodOf lbServices.Account.userroles
             *
             * @description
             *
             * Update a related item by id for userroles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Account id
             *
             *  - `fk` – `{*}` - Foreign key for userroles
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R.userroles.updateById = function() {
          var TargetResource = $injector.get("AccountRole");
          var action = TargetResource["::updateById::Account::userroles"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Address
 * @header lbServices.Address
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Address` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Address",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Addresses/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Address.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Addresses/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#create
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Addresses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#patchOrCreate
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Addresses",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#replaceOrCreate
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Addresses/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#upsertWithWhere
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Addresses/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#exists
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Addresses/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#findById
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Addresses/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#replaceById
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Addresses/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#find
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Addresses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#findOne
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Addresses/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#updateAll
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Addresses/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#deleteById
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Addresses/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#count
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Addresses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#prototype$patchAttributes
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Address id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Addresses/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#createChangeStream
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Addresses/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Address#createMany
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Addresses",
              method: "POST",
            },

            // INTERNAL. Use Account.addresses.findById() instead.
            "::findById::Account::addresses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/addresses/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.addresses.destroyById() instead.
            "::destroyById::Account::addresses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/addresses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.addresses.updateById() instead.
            "::updateById::Account::addresses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/addresses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.addresses() instead.
            "::get::Account::addresses": {
              isArray: true,
              url: urlBase + "/Accounts/:id/addresses",
              method: "GET",
            },

            // INTERNAL. Use Account.addresses.create() instead.
            "::create::Account::addresses": {
              url: urlBase + "/Accounts/:id/addresses",
              method: "POST",
            },

            // INTERNAL. Use Account.addresses.createMany() instead.
            "::createMany::Account::addresses": {
              isArray: true,
              url: urlBase + "/Accounts/:id/addresses",
              method: "POST",
            },

            // INTERNAL. Use Account.addresses.destroyAll() instead.
            "::delete::Account::addresses": {
              url: urlBase + "/Accounts/:id/addresses",
              method: "DELETE",
            },

            // INTERNAL. Use Account.addresses.count() instead.
            "::count::Account::addresses": {
              url: urlBase + "/Accounts/:id/addresses/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Address#upsert
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Address#updateOrCreate
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Address#patchOrCreateWithWhere
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Address#update
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Address#destroyById
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Address#removeById
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Address#prototype$updateAttributes
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Address id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Address` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Address#modelName
        * @propertyOf lbServices.Address
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Address`.
        */
        R.modelName = "Address";


            /**
             * @ngdoc method
             * @name lbServices.Address#account
             * @methodOf lbServices.Address
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Address id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Address::account"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Job
 * @header lbServices.Job
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Job` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Job",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Jobs/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Job.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Jobs/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Job.partials.findById() instead.
            "prototype$__findById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/partials/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.partials.destroyById() instead.
            "prototype$__destroyById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/partials/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.partials.updateById() instead.
            "prototype$__updateById__partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/partials/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.tasks.findById() instead.
            "prototype$__findById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.tasks.destroyById() instead.
            "prototype$__destroyById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.tasks.updateById() instead.
            "prototype$__updateById__tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.documents.findById() instead.
            "prototype$__findById__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.documents.destroyById() instead.
            "prototype$__destroyById__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.documents.updateById() instead.
            "prototype$__updateById__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.documents.link() instead.
            "prototype$__link__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.documents.unlink() instead.
            "prototype$__unlink__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.documents.exists() instead.
            "prototype$__exists__documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Job.notes.findById() instead.
            "prototype$__findById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/notes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.notes.destroyById() instead.
            "prototype$__destroyById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/notes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.notes.updateById() instead.
            "prototype$__updateById__notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/notes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.photos.findById() instead.
            "prototype$__findById__photos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/photos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.photos.destroyById() instead.
            "prototype$__destroyById__photos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/photos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.photos.updateById() instead.
            "prototype$__updateById__photos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/photos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.pocs.findById() instead.
            "prototype$__findById__pocs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/pocs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.pocs.destroyById() instead.
            "prototype$__destroyById__pocs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/pocs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.pocs.updateById() instead.
            "prototype$__updateById__pocs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/pocs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.partials() instead.
            "prototype$__get__partials": {
              isArray: true,
              url: urlBase + "/Jobs/:id/partials",
              method: "GET",
            },

            // INTERNAL. Use Job.partials.create() instead.
            "prototype$__create__partials": {
              url: urlBase + "/Jobs/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Job.partials.destroyAll() instead.
            "prototype$__delete__partials": {
              url: urlBase + "/Jobs/:id/partials",
              method: "DELETE",
            },

            // INTERNAL. Use Job.partials.count() instead.
            "prototype$__count__partials": {
              url: urlBase + "/Jobs/:id/partials/count",
              method: "GET",
            },

            // INTERNAL. Use Job.tasks() instead.
            "prototype$__get__tasks": {
              isArray: true,
              url: urlBase + "/Jobs/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use Job.tasks.create() instead.
            "prototype$__create__tasks": {
              url: urlBase + "/Jobs/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Job.tasks.destroyAll() instead.
            "prototype$__delete__tasks": {
              url: urlBase + "/Jobs/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use Job.tasks.count() instead.
            "prototype$__count__tasks": {
              url: urlBase + "/Jobs/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use Job.documents() instead.
            "prototype$__get__documents": {
              isArray: true,
              url: urlBase + "/Jobs/:id/documents",
              method: "GET",
            },

            // INTERNAL. Use Job.documents.create() instead.
            "prototype$__create__documents": {
              url: urlBase + "/Jobs/:id/documents",
              method: "POST",
            },

            // INTERNAL. Use Job.documents.destroyAll() instead.
            "prototype$__delete__documents": {
              url: urlBase + "/Jobs/:id/documents",
              method: "DELETE",
            },

            // INTERNAL. Use Job.documents.count() instead.
            "prototype$__count__documents": {
              url: urlBase + "/Jobs/:id/documents/count",
              method: "GET",
            },

            // INTERNAL. Use Job.notes() instead.
            "prototype$__get__notes": {
              isArray: true,
              url: urlBase + "/Jobs/:id/notes",
              method: "GET",
            },

            // INTERNAL. Use Job.notes.create() instead.
            "prototype$__create__notes": {
              url: urlBase + "/Jobs/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use Job.notes.destroyAll() instead.
            "prototype$__delete__notes": {
              url: urlBase + "/Jobs/:id/notes",
              method: "DELETE",
            },

            // INTERNAL. Use Job.notes.count() instead.
            "prototype$__count__notes": {
              url: urlBase + "/Jobs/:id/notes/count",
              method: "GET",
            },

            // INTERNAL. Use Job.photos() instead.
            "prototype$__get__photos": {
              isArray: true,
              url: urlBase + "/Jobs/:id/photos",
              method: "GET",
            },

            // INTERNAL. Use Job.photos.create() instead.
            "prototype$__create__photos": {
              url: urlBase + "/Jobs/:id/photos",
              method: "POST",
            },

            // INTERNAL. Use Job.photos.destroyAll() instead.
            "prototype$__delete__photos": {
              url: urlBase + "/Jobs/:id/photos",
              method: "DELETE",
            },

            // INTERNAL. Use Job.photos.count() instead.
            "prototype$__count__photos": {
              url: urlBase + "/Jobs/:id/photos/count",
              method: "GET",
            },

            // INTERNAL. Use Job.pocs() instead.
            "prototype$__get__pocs": {
              isArray: true,
              url: urlBase + "/Jobs/:id/pocs",
              method: "GET",
            },

            // INTERNAL. Use Job.pocs.create() instead.
            "prototype$__create__pocs": {
              url: urlBase + "/Jobs/:id/pocs",
              method: "POST",
            },

            // INTERNAL. Use Job.pocs.destroyAll() instead.
            "prototype$__delete__pocs": {
              url: urlBase + "/Jobs/:id/pocs",
              method: "DELETE",
            },

            // INTERNAL. Use Job.pocs.count() instead.
            "prototype$__count__pocs": {
              url: urlBase + "/Jobs/:id/pocs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#create
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Jobs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#patchOrCreate
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Jobs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#replaceOrCreate
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Jobs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#upsertWithWhere
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Jobs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#exists
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Jobs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#findById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Jobs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#replaceById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Jobs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#find
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Jobs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#findOne
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Jobs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#updateAll
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Jobs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#deleteById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Jobs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#count
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Jobs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#prototype$patchAttributes
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Jobs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#createChangeStream
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Jobs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Job#createMany
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Jobs",
              method: "POST",
            },

            // INTERNAL. Use Account.jobs.findById() instead.
            "::findById::Account::jobs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.jobs.destroyById() instead.
            "::destroyById::Account::jobs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.jobs.updateById() instead.
            "::updateById::Account::jobs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/jobs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.jobs() instead.
            "::get::Account::jobs": {
              isArray: true,
              url: urlBase + "/Accounts/:id/jobs",
              method: "GET",
            },

            // INTERNAL. Use Account.jobs.create() instead.
            "::create::Account::jobs": {
              url: urlBase + "/Accounts/:id/jobs",
              method: "POST",
            },

            // INTERNAL. Use Account.jobs.createMany() instead.
            "::createMany::Account::jobs": {
              isArray: true,
              url: urlBase + "/Accounts/:id/jobs",
              method: "POST",
            },

            // INTERNAL. Use Account.jobs.destroyAll() instead.
            "::delete::Account::jobs": {
              url: urlBase + "/Accounts/:id/jobs",
              method: "DELETE",
            },

            // INTERNAL. Use Account.jobs.count() instead.
            "::count::Account::jobs": {
              url: urlBase + "/Accounts/:id/jobs/count",
              method: "GET",
            },

            // INTERNAL. Use Partial.job() instead.
            "::get::Partial::job": {
              url: urlBase + "/Partials/:id/job",
              method: "GET",
            },

            // INTERNAL. Use Task.job() instead.
            "::get::Task::job": {
              url: urlBase + "/Tasks/:id/job",
              method: "GET",
            },

            // INTERNAL. Use Document.job() instead.
            "::get::Document::job": {
              url: urlBase + "/Documents/:id/job",
              method: "GET",
            },

            // INTERNAL. Use Note.job() instead.
            "::get::Note::job": {
              url: urlBase + "/Notes/:id/job",
              method: "GET",
            },

            // INTERNAL. Use JobPhoto.job() instead.
            "::get::JobPhoto::job": {
              url: urlBase + "/JobPhotos/:id/job",
              method: "GET",
            },

            // INTERNAL. Use JobDoc.jobId() instead.
            "::get::JobDoc::jobId": {
              url: urlBase + "/JobDocs/:id/jobId",
              method: "GET",
            },

            // INTERNAL. Use JobPoc.job() instead.
            "::get::JobPoc::job": {
              url: urlBase + "/JobPocs/:id/job",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Job#upsert
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Job#updateOrCreate
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Job#patchOrCreateWithWhere
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Job#update
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Job#destroyById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Job#removeById
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Job#prototype$updateAttributes
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Job#modelName
        * @propertyOf lbServices.Job
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Job`.
        */
        R.modelName = "Job";


            /**
             * @ngdoc method
             * @name lbServices.Job#account
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Job::account"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Job.partials
     * @header lbServices.Job.partials
     * @object
     * @description
     *
     * The object `Job.partials` groups methods
     * manipulating `Partial` instances related to `Job`.
     *
     * Call {@link lbServices.Job#partials Job.partials()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Job#partials
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Queries partials of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::get::Job::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.partials#count
             * @methodOf lbServices.Job.partials
             *
             * @description
             *
             * Counts partials of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.partials.count = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::count::Job::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.partials#create
             * @methodOf lbServices.Job.partials
             *
             * @description
             *
             * Creates a new instance in partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.create = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::create::Job::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.partials#createMany
             * @methodOf lbServices.Job.partials
             *
             * @description
             *
             * Creates a new instance in partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.createMany = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::createMany::Job::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.partials#destroyAll
             * @methodOf lbServices.Job.partials
             *
             * @description
             *
             * Deletes all partials of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partials.destroyAll = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::delete::Job::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.partials#destroyById
             * @methodOf lbServices.Job.partials
             *
             * @description
             *
             * Delete a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.partials.destroyById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::destroyById::Job::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.partials#findById
             * @methodOf lbServices.Job.partials
             *
             * @description
             *
             * Find a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.findById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::findById::Job::partials"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.partials#updateById
             * @methodOf lbServices.Job.partials
             *
             * @description
             *
             * Update a related item by id for partials.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `fk` – `{*}` - Foreign key for partials
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partials.updateById = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::updateById::Job::partials"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Job.tasks
     * @header lbServices.Job.tasks
     * @object
     * @description
     *
     * The object `Job.tasks` groups methods
     * manipulating `Task` instances related to `Job`.
     *
     * Call {@link lbServices.Job#tasks Job.tasks()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Job#tasks
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Queries tasks of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::get::Job::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.tasks#count
             * @methodOf lbServices.Job.tasks
             *
             * @description
             *
             * Counts tasks of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tasks.count = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::count::Job::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.tasks#create
             * @methodOf lbServices.Job.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.create = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::create::Job::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.tasks#createMany
             * @methodOf lbServices.Job.tasks
             *
             * @description
             *
             * Creates a new instance in tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.createMany = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::createMany::Job::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.tasks#destroyAll
             * @methodOf lbServices.Job.tasks
             *
             * @description
             *
             * Deletes all tasks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyAll = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::delete::Job::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.tasks#destroyById
             * @methodOf lbServices.Job.tasks
             *
             * @description
             *
             * Delete a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tasks.destroyById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::destroyById::Job::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.tasks#findById
             * @methodOf lbServices.Job.tasks
             *
             * @description
             *
             * Find a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.findById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::findById::Job::tasks"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.tasks#updateById
             * @methodOf lbServices.Job.tasks
             *
             * @description
             *
             * Update a related item by id for tasks.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `fk` – `{*}` - Foreign key for tasks
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R.tasks.updateById = function() {
          var TargetResource = $injector.get("Task");
          var action = TargetResource["::updateById::Job::tasks"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Job.documents
     * @header lbServices.Job.documents
     * @object
     * @description
     *
     * The object `Job.documents` groups methods
     * manipulating `Document` instances related to `Job`.
     *
     * Call {@link lbServices.Job#documents Job.documents()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Job#documents
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Queries documents of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::get::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#count
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Counts documents of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.documents.count = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::count::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#create
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Creates a new instance in documents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.create = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::create::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#createMany
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Creates a new instance in documents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.createMany = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::createMany::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#destroyAll
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Deletes all documents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documents.destroyAll = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::delete::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#destroyById
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Delete a related item by id for documents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documents.destroyById = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::destroyById::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#exists
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Check the existence of documents relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.exists = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::exists::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#findById
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Find a related item by id for documents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.findById = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::findById::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#link
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Add a related item by id for documents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.link = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::link::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#unlink
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Remove the documents relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.documents.unlink = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::unlink::Job::documents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.documents#updateById
             * @methodOf lbServices.Job.documents
             *
             * @description
             *
             * Update a related item by id for documents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `fk` – `{*}` - Foreign key for documents
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R.documents.updateById = function() {
          var TargetResource = $injector.get("Document");
          var action = TargetResource["::updateById::Job::documents"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Job.notes
     * @header lbServices.Job.notes
     * @object
     * @description
     *
     * The object `Job.notes` groups methods
     * manipulating `Note` instances related to `Job`.
     *
     * Call {@link lbServices.Job#notes Job.notes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Job#notes
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Queries notes of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::get::Job::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.notes#count
             * @methodOf lbServices.Job.notes
             *
             * @description
             *
             * Counts notes of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.notes.count = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::count::Job::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.notes#create
             * @methodOf lbServices.Job.notes
             *
             * @description
             *
             * Creates a new instance in notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.create = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::create::Job::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.notes#createMany
             * @methodOf lbServices.Job.notes
             *
             * @description
             *
             * Creates a new instance in notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.createMany = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::createMany::Job::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.notes#destroyAll
             * @methodOf lbServices.Job.notes
             *
             * @description
             *
             * Deletes all notes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notes.destroyAll = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::delete::Job::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.notes#destroyById
             * @methodOf lbServices.Job.notes
             *
             * @description
             *
             * Delete a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.notes.destroyById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::destroyById::Job::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.notes#findById
             * @methodOf lbServices.Job.notes
             *
             * @description
             *
             * Find a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.findById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::findById::Job::notes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.notes#updateById
             * @methodOf lbServices.Job.notes
             *
             * @description
             *
             * Update a related item by id for notes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `fk` – `{*}` - Foreign key for notes
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R.notes.updateById = function() {
          var TargetResource = $injector.get("Note");
          var action = TargetResource["::updateById::Job::notes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Job.photos
     * @header lbServices.Job.photos
     * @object
     * @description
     *
     * The object `Job.photos` groups methods
     * manipulating `JobPhoto` instances related to `Job`.
     *
     * Call {@link lbServices.Job#photos Job.photos()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Job#photos
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Queries photos of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R.photos = function() {
          var TargetResource = $injector.get("JobPhoto");
          var action = TargetResource["::get::Job::photos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.photos#count
             * @methodOf lbServices.Job.photos
             *
             * @description
             *
             * Counts photos of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.photos.count = function() {
          var TargetResource = $injector.get("JobPhoto");
          var action = TargetResource["::count::Job::photos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.photos#create
             * @methodOf lbServices.Job.photos
             *
             * @description
             *
             * Creates a new instance in photos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R.photos.create = function() {
          var TargetResource = $injector.get("JobPhoto");
          var action = TargetResource["::create::Job::photos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.photos#createMany
             * @methodOf lbServices.Job.photos
             *
             * @description
             *
             * Creates a new instance in photos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R.photos.createMany = function() {
          var TargetResource = $injector.get("JobPhoto");
          var action = TargetResource["::createMany::Job::photos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.photos#destroyAll
             * @methodOf lbServices.Job.photos
             *
             * @description
             *
             * Deletes all photos of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.photos.destroyAll = function() {
          var TargetResource = $injector.get("JobPhoto");
          var action = TargetResource["::delete::Job::photos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.photos#destroyById
             * @methodOf lbServices.Job.photos
             *
             * @description
             *
             * Delete a related item by id for photos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for photos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.photos.destroyById = function() {
          var TargetResource = $injector.get("JobPhoto");
          var action = TargetResource["::destroyById::Job::photos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.photos#findById
             * @methodOf lbServices.Job.photos
             *
             * @description
             *
             * Find a related item by id for photos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for photos
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R.photos.findById = function() {
          var TargetResource = $injector.get("JobPhoto");
          var action = TargetResource["::findById::Job::photos"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.photos#updateById
             * @methodOf lbServices.Job.photos
             *
             * @description
             *
             * Update a related item by id for photos.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `fk` – `{*}` - Foreign key for photos
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R.photos.updateById = function() {
          var TargetResource = $injector.get("JobPhoto");
          var action = TargetResource["::updateById::Job::photos"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Job.pocs
     * @header lbServices.Job.pocs
     * @object
     * @description
     *
     * The object `Job.pocs` groups methods
     * manipulating `JobPoc` instances related to `Job`.
     *
     * Call {@link lbServices.Job#pocs Job.pocs()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Job#pocs
             * @methodOf lbServices.Job
             *
             * @description
             *
             * Queries pocs of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R.pocs = function() {
          var TargetResource = $injector.get("JobPoc");
          var action = TargetResource["::get::Job::pocs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.pocs#count
             * @methodOf lbServices.Job.pocs
             *
             * @description
             *
             * Counts pocs of Job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.pocs.count = function() {
          var TargetResource = $injector.get("JobPoc");
          var action = TargetResource["::count::Job::pocs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.pocs#create
             * @methodOf lbServices.Job.pocs
             *
             * @description
             *
             * Creates a new instance in pocs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R.pocs.create = function() {
          var TargetResource = $injector.get("JobPoc");
          var action = TargetResource["::create::Job::pocs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.pocs#createMany
             * @methodOf lbServices.Job.pocs
             *
             * @description
             *
             * Creates a new instance in pocs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R.pocs.createMany = function() {
          var TargetResource = $injector.get("JobPoc");
          var action = TargetResource["::createMany::Job::pocs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.pocs#destroyAll
             * @methodOf lbServices.Job.pocs
             *
             * @description
             *
             * Deletes all pocs of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.pocs.destroyAll = function() {
          var TargetResource = $injector.get("JobPoc");
          var action = TargetResource["::delete::Job::pocs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.pocs#destroyById
             * @methodOf lbServices.Job.pocs
             *
             * @description
             *
             * Delete a related item by id for pocs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for pocs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.pocs.destroyById = function() {
          var TargetResource = $injector.get("JobPoc");
          var action = TargetResource["::destroyById::Job::pocs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.pocs#findById
             * @methodOf lbServices.Job.pocs
             *
             * @description
             *
             * Find a related item by id for pocs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for pocs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R.pocs.findById = function() {
          var TargetResource = $injector.get("JobPoc");
          var action = TargetResource["::findById::Job::pocs"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Job.pocs#updateById
             * @methodOf lbServices.Job.pocs
             *
             * @description
             *
             * Update a related item by id for pocs.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Job id
             *
             *  - `fk` – `{*}` - Foreign key for pocs
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R.pocs.updateById = function() {
          var TargetResource = $injector.get("JobPoc");
          var action = TargetResource["::updateById::Job::pocs"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Partial
 * @header lbServices.Partial
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Partial` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Partial",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Partials/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Partial.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Partials/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Partial.job() instead.
            "prototype$__get__job": {
              url: urlBase + "/Partials/:id/job",
              method: "GET",
            },

            // INTERNAL. Use Partial.crew.findById() instead.
            "prototype$__findById__crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/:fk",
              method: "GET",
            },

            // INTERNAL. Use Partial.crew.destroyById() instead.
            "prototype$__destroyById__crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.crew.updateById() instead.
            "prototype$__updateById__crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Partial.crew.link() instead.
            "prototype$__link__crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Partial.crew.unlink() instead.
            "prototype$__unlink__crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.crew.exists() instead.
            "prototype$__exists__crew": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/crew/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Partial.equipment.findById() instead.
            "prototype$__findById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/:fk",
              method: "GET",
            },

            // INTERNAL. Use Partial.equipment.destroyById() instead.
            "prototype$__destroyById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.equipment.updateById() instead.
            "prototype$__updateById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Partial.equipment.link() instead.
            "prototype$__link__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Partial.equipment.unlink() instead.
            "prototype$__unlink__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.equipment.exists() instead.
            "prototype$__exists__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Partial.crew() instead.
            "prototype$__get__crew": {
              isArray: true,
              url: urlBase + "/Partials/:id/crew",
              method: "GET",
            },

            // INTERNAL. Use Partial.crew.create() instead.
            "prototype$__create__crew": {
              url: urlBase + "/Partials/:id/crew",
              method: "POST",
            },

            // INTERNAL. Use Partial.crew.destroyAll() instead.
            "prototype$__delete__crew": {
              url: urlBase + "/Partials/:id/crew",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.crew.count() instead.
            "prototype$__count__crew": {
              url: urlBase + "/Partials/:id/crew/count",
              method: "GET",
            },

            // INTERNAL. Use Partial.equipment() instead.
            "prototype$__get__equipment": {
              isArray: true,
              url: urlBase + "/Partials/:id/equipment",
              method: "GET",
            },

            // INTERNAL. Use Partial.equipment.create() instead.
            "prototype$__create__equipment": {
              url: urlBase + "/Partials/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Partial.equipment.destroyAll() instead.
            "prototype$__delete__equipment": {
              url: urlBase + "/Partials/:id/equipment",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.equipment.count() instead.
            "prototype$__count__equipment": {
              url: urlBase + "/Partials/:id/equipment/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#create
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Partials",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#patchOrCreate
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Partials",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#replaceOrCreate
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Partials/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#upsertWithWhere
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Partials/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#exists
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Partials/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#findById
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Partials/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#replaceById
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Partials/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#find
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Partials",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#findOne
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Partials/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#updateAll
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Partials/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#deleteById
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Partials/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#count
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Partials/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#prototype$patchAttributes
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Partials/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#createChangeStream
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Partials/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#notifyEmployeeDrop
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `partial` – `{object=}` -
             *
             *  - `employee` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` - {}
             */
            "notifyEmployeeDrop": {
              url: urlBase + "/Partials/notify-employee-work-drop",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#notifyEmployees
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `partial` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` - {}
             */
            "notifyEmployees": {
              url: urlBase + "/Partials/notify-employees",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Partial#createMany
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Partials",
              method: "POST",
            },

            // INTERNAL. Use Boarder.partials.findById() instead.
            "::findById::Boarder::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.partials.destroyById() instead.
            "::destroyById::Boarder::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.partials.updateById() instead.
            "::updateById::Boarder::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.partials.link() instead.
            "::link::Boarder::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.partials.unlink() instead.
            "::unlink::Boarder::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.partials.exists() instead.
            "::exists::Boarder::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/partials/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Boarder.partials() instead.
            "::get::Boarder::partials": {
              isArray: true,
              url: urlBase + "/Boarders/:id/partials",
              method: "GET",
            },

            // INTERNAL. Use Boarder.partials.create() instead.
            "::create::Boarder::partials": {
              url: urlBase + "/Boarders/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Boarder.partials.createMany() instead.
            "::createMany::Boarder::partials": {
              isArray: true,
              url: urlBase + "/Boarders/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Boarder.partials.destroyAll() instead.
            "::delete::Boarder::partials": {
              url: urlBase + "/Boarders/:id/partials",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.partials.count() instead.
            "::count::Boarder::partials": {
              url: urlBase + "/Boarders/:id/partials/count",
              method: "GET",
            },

            // INTERNAL. Use Account.partials.findById() instead.
            "::findById::Account::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partials/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.partials.destroyById() instead.
            "::destroyById::Account::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partials/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.partials.updateById() instead.
            "::updateById::Account::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partials/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.partials() instead.
            "::get::Account::partials": {
              isArray: true,
              url: urlBase + "/Accounts/:id/partials",
              method: "GET",
            },

            // INTERNAL. Use Account.partials.create() instead.
            "::create::Account::partials": {
              url: urlBase + "/Accounts/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Account.partials.createMany() instead.
            "::createMany::Account::partials": {
              isArray: true,
              url: urlBase + "/Accounts/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Account.partials.destroyAll() instead.
            "::delete::Account::partials": {
              url: urlBase + "/Accounts/:id/partials",
              method: "DELETE",
            },

            // INTERNAL. Use Account.partials.count() instead.
            "::count::Account::partials": {
              url: urlBase + "/Accounts/:id/partials/count",
              method: "GET",
            },

            // INTERNAL. Use Job.partials.findById() instead.
            "::findById::Job::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/partials/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.partials.destroyById() instead.
            "::destroyById::Job::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/partials/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.partials.updateById() instead.
            "::updateById::Job::partials": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/partials/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.partials() instead.
            "::get::Job::partials": {
              isArray: true,
              url: urlBase + "/Jobs/:id/partials",
              method: "GET",
            },

            // INTERNAL. Use Job.partials.create() instead.
            "::create::Job::partials": {
              url: urlBase + "/Jobs/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Job.partials.createMany() instead.
            "::createMany::Job::partials": {
              isArray: true,
              url: urlBase + "/Jobs/:id/partials",
              method: "POST",
            },

            // INTERNAL. Use Job.partials.destroyAll() instead.
            "::delete::Job::partials": {
              url: urlBase + "/Jobs/:id/partials",
              method: "DELETE",
            },

            // INTERNAL. Use Job.partials.count() instead.
            "::count::Job::partials": {
              url: urlBase + "/Jobs/:id/partials/count",
              method: "GET",
            },

            // INTERNAL. Use Crew.partial() instead.
            "::get::Crew::partial": {
              url: urlBase + "/Crews/:id/partial",
              method: "GET",
            },

            // INTERNAL. Use PartialEquipment.partial() instead.
            "::get::PartialEquipment::partial": {
              url: urlBase + "/PartialEquipments/:id/partial",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Partial#upsert
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Partial#updateOrCreate
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Partial#patchOrCreateWithWhere
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Partial#update
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Partial#destroyById
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Partial#removeById
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Partial#prototype$updateAttributes
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Partial#modelName
        * @propertyOf lbServices.Partial
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Partial`.
        */
        R.modelName = "Partial";


            /**
             * @ngdoc method
             * @name lbServices.Partial#account
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Partial::account"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial#job
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Fetches belongsTo relation job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.job = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::get::Partial::job"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Partial.crew
     * @header lbServices.Partial.crew
     * @object
     * @description
     *
     * The object `Partial.crew` groups methods
     * manipulating `Boarder` instances related to `Partial`.
     *
     * Call {@link lbServices.Partial#crew Partial.crew()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Partial#crew
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Queries crew of Partial.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.crew = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::get::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#count
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Counts crew of Partial.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.crew.count = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::count::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#create
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Creates a new instance in crew of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.crew.create = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::create::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#createMany
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Creates a new instance in crew of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.crew.createMany = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::createMany::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#destroyAll
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Deletes all crew of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.crew.destroyAll = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::delete::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#destroyById
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Delete a related item by id for crew.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for crew
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.crew.destroyById = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::destroyById::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#exists
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Check the existence of crew relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for crew
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.crew.exists = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::exists::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#findById
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Find a related item by id for crew.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for crew
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.crew.findById = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::findById::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#link
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Add a related item by id for crew.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `fk` – `{*}` - Foreign key for crew
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.crew.link = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::link::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#unlink
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Remove the crew relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for crew
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.crew.unlink = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::unlink::Partial::crew"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.crew#updateById
             * @methodOf lbServices.Partial.crew
             *
             * @description
             *
             * Update a related item by id for crew.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `fk` – `{*}` - Foreign key for crew
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.crew.updateById = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::updateById::Partial::crew"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Partial.equipment
     * @header lbServices.Partial.equipment
     * @object
     * @description
     *
     * The object `Partial.equipment` groups methods
     * manipulating `Equipment` instances related to `Partial`.
     *
     * Call {@link lbServices.Partial#equipment Partial.equipment()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Partial#equipment
             * @methodOf lbServices.Partial
             *
             * @description
             *
             * Queries equipment of Partial.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::get::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#count
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Counts equipment of Partial.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.equipment.count = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::count::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#create
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Creates a new instance in equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.create = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::create::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#createMany
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Creates a new instance in equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.createMany = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::createMany::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#destroyAll
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Deletes all equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipment.destroyAll = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::delete::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#destroyById
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Delete a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipment.destroyById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::destroyById::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#exists
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Check the existence of equipment relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.exists = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::exists::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#findById
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Find a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.findById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::findById::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#link
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Add a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.link = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::link::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#unlink
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Remove the equipment relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipment.unlink = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::unlink::Partial::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Partial.equipment#updateById
             * @methodOf lbServices.Partial.equipment
             *
             * @description
             *
             * Update a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Partial id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.updateById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::updateById::Partial::equipment"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Task
 * @header lbServices.Task
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Task` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Task",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Tasks/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Task.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Tasks/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Task.job() instead.
            "prototype$__get__job": {
              url: urlBase + "/Tasks/:id/job",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#create
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Tasks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#patchOrCreate
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Tasks",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#replaceOrCreate
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Tasks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#upsertWithWhere
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Tasks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#exists
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Tasks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#findById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Tasks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#replaceById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Tasks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#find
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Tasks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#findOne
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Tasks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#updateAll
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Tasks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#deleteById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Tasks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#count
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Tasks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#prototype$patchAttributes
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Tasks/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#createChangeStream
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Tasks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Task#createMany
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Tasks",
              method: "POST",
            },

            // INTERNAL. Use Account.tasks.findById() instead.
            "::findById::Account::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.tasks.destroyById() instead.
            "::destroyById::Account::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.tasks.updateById() instead.
            "::updateById::Account::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.tasks() instead.
            "::get::Account::tasks": {
              isArray: true,
              url: urlBase + "/Accounts/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use Account.tasks.create() instead.
            "::create::Account::tasks": {
              url: urlBase + "/Accounts/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Account.tasks.createMany() instead.
            "::createMany::Account::tasks": {
              isArray: true,
              url: urlBase + "/Accounts/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Account.tasks.destroyAll() instead.
            "::delete::Account::tasks": {
              url: urlBase + "/Accounts/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use Account.tasks.count() instead.
            "::count::Account::tasks": {
              url: urlBase + "/Accounts/:id/tasks/count",
              method: "GET",
            },

            // INTERNAL. Use Job.tasks.findById() instead.
            "::findById::Job::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/tasks/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.tasks.destroyById() instead.
            "::destroyById::Job::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/tasks/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.tasks.updateById() instead.
            "::updateById::Job::tasks": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/tasks/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.tasks() instead.
            "::get::Job::tasks": {
              isArray: true,
              url: urlBase + "/Jobs/:id/tasks",
              method: "GET",
            },

            // INTERNAL. Use Job.tasks.create() instead.
            "::create::Job::tasks": {
              url: urlBase + "/Jobs/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Job.tasks.createMany() instead.
            "::createMany::Job::tasks": {
              isArray: true,
              url: urlBase + "/Jobs/:id/tasks",
              method: "POST",
            },

            // INTERNAL. Use Job.tasks.destroyAll() instead.
            "::delete::Job::tasks": {
              url: urlBase + "/Jobs/:id/tasks",
              method: "DELETE",
            },

            // INTERNAL. Use Job.tasks.count() instead.
            "::count::Job::tasks": {
              url: urlBase + "/Jobs/:id/tasks/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Task#upsert
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Task#updateOrCreate
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Task#patchOrCreateWithWhere
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Task#update
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Task#destroyById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Task#removeById
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Task#prototype$updateAttributes
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Task` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Task#modelName
        * @propertyOf lbServices.Task
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Task`.
        */
        R.modelName = "Task";


            /**
             * @ngdoc method
             * @name lbServices.Task#account
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Task::account"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Task#job
             * @methodOf lbServices.Task
             *
             * @description
             *
             * Fetches belongsTo relation job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Task id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.job = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::get::Task::job"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Crew
 * @header lbServices.Crew
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Crew` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Crew",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Crews/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Crew.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Crews/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Crew.boarder() instead.
            "prototype$__get__boarder": {
              url: urlBase + "/Crews/:id/boarder",
              method: "GET",
            },

            // INTERNAL. Use Crew.partial() instead.
            "prototype$__get__partial": {
              url: urlBase + "/Crews/:id/partial",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#create
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Crews",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#patchOrCreate
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Crews",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#replaceOrCreate
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Crews/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#upsertWithWhere
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Crews/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#exists
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Crews/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#findById
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Crews/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#replaceById
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Crews/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#find
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Crews",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#findOne
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Crews/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#updateAll
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Crews/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#deleteById
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Crews/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#count
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Crews/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#prototype$patchAttributes
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Crew id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Crews/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#createChangeStream
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Crews/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Crew#createMany
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Crews",
              method: "POST",
            },

            // INTERNAL. Use Account.crews.findById() instead.
            "::findById::Account::crews": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/crews/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.crews.destroyById() instead.
            "::destroyById::Account::crews": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/crews/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.crews.updateById() instead.
            "::updateById::Account::crews": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/crews/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.crews() instead.
            "::get::Account::crews": {
              isArray: true,
              url: urlBase + "/Accounts/:id/crews",
              method: "GET",
            },

            // INTERNAL. Use Account.crews.create() instead.
            "::create::Account::crews": {
              url: urlBase + "/Accounts/:id/crews",
              method: "POST",
            },

            // INTERNAL. Use Account.crews.createMany() instead.
            "::createMany::Account::crews": {
              isArray: true,
              url: urlBase + "/Accounts/:id/crews",
              method: "POST",
            },

            // INTERNAL. Use Account.crews.destroyAll() instead.
            "::delete::Account::crews": {
              url: urlBase + "/Accounts/:id/crews",
              method: "DELETE",
            },

            // INTERNAL. Use Account.crews.count() instead.
            "::count::Account::crews": {
              url: urlBase + "/Accounts/:id/crews/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Crew#upsert
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Crew#updateOrCreate
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Crew#patchOrCreateWithWhere
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Crew#update
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Crew#destroyById
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Crew#removeById
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Crew#prototype$updateAttributes
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Crew id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Crew` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Crew#modelName
        * @propertyOf lbServices.Crew
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Crew`.
        */
        R.modelName = "Crew";


            /**
             * @ngdoc method
             * @name lbServices.Crew#account
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Crew id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Crew::account"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Crew#boarder
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Fetches belongsTo relation boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Crew id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarder = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::get::Crew::boarder"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Crew#partial
             * @methodOf lbServices.Crew
             *
             * @description
             *
             * Fetches belongsTo relation partial.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Crew id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partial = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::get::Crew::partial"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Equipment
 * @header lbServices.Equipment
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Equipment` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Equipment",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Equipment/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Equipment.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Equipment/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#create
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Equipment",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#patchOrCreate
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Equipment",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#replaceOrCreate
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Equipment/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#upsertWithWhere
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Equipment/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#exists
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Equipment/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#findById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Equipment/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#replaceById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Equipment/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#find
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Equipment",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#findOne
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Equipment/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#updateAll
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Equipment/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#deleteById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Equipment/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#count
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Equipment/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#prototype$patchAttributes
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Equipment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Equipment/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#createChangeStream
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Equipment/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#createMany
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Equipment",
              method: "POST",
            },

            // INTERNAL. Use Account.equipment.findById() instead.
            "::findById::Account::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/equipment/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.equipment.destroyById() instead.
            "::destroyById::Account::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/equipment/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.equipment.updateById() instead.
            "::updateById::Account::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/equipment/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.equipment() instead.
            "::get::Account::equipment": {
              isArray: true,
              url: urlBase + "/Accounts/:id/equipment",
              method: "GET",
            },

            // INTERNAL. Use Account.equipment.create() instead.
            "::create::Account::equipment": {
              url: urlBase + "/Accounts/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Account.equipment.createMany() instead.
            "::createMany::Account::equipment": {
              isArray: true,
              url: urlBase + "/Accounts/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Account.equipment.destroyAll() instead.
            "::delete::Account::equipment": {
              url: urlBase + "/Accounts/:id/equipment",
              method: "DELETE",
            },

            // INTERNAL. Use Account.equipment.count() instead.
            "::count::Account::equipment": {
              url: urlBase + "/Accounts/:id/equipment/count",
              method: "GET",
            },

            // INTERNAL. Use Partial.equipment.findById() instead.
            "::findById::Partial::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/:fk",
              method: "GET",
            },

            // INTERNAL. Use Partial.equipment.destroyById() instead.
            "::destroyById::Partial::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.equipment.updateById() instead.
            "::updateById::Partial::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Partial.equipment.link() instead.
            "::link::Partial::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Partial.equipment.unlink() instead.
            "::unlink::Partial::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.equipment.exists() instead.
            "::exists::Partial::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Partials/:id/equipment/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Partial.equipment() instead.
            "::get::Partial::equipment": {
              isArray: true,
              url: urlBase + "/Partials/:id/equipment",
              method: "GET",
            },

            // INTERNAL. Use Partial.equipment.create() instead.
            "::create::Partial::equipment": {
              url: urlBase + "/Partials/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Partial.equipment.createMany() instead.
            "::createMany::Partial::equipment": {
              isArray: true,
              url: urlBase + "/Partials/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Partial.equipment.destroyAll() instead.
            "::delete::Partial::equipment": {
              url: urlBase + "/Partials/:id/equipment",
              method: "DELETE",
            },

            // INTERNAL. Use Partial.equipment.count() instead.
            "::count::Partial::equipment": {
              url: urlBase + "/Partials/:id/equipment/count",
              method: "GET",
            },

            // INTERNAL. Use PartialEquipment.equipment() instead.
            "::get::PartialEquipment::equipment": {
              url: urlBase + "/PartialEquipments/:id/equipment",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Equipment#upsert
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#updateOrCreate
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#patchOrCreateWithWhere
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#update
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#destroyById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#removeById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#prototype$updateAttributes
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Equipment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Equipment#modelName
        * @propertyOf lbServices.Equipment
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Equipment`.
        */
        R.modelName = "Equipment";


            /**
             * @ngdoc method
             * @name lbServices.Equipment#account
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Equipment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Equipment::account"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PartialEquipment
 * @header lbServices.PartialEquipment
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PartialEquipment` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PartialEquipment",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PartialEquipments/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PartialEquipment.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/PartialEquipments/:id/account",
              method: "GET",
            },

            // INTERNAL. Use PartialEquipment.equipment() instead.
            "prototype$__get__equipment": {
              url: urlBase + "/PartialEquipments/:id/equipment",
              method: "GET",
            },

            // INTERNAL. Use PartialEquipment.partial() instead.
            "prototype$__get__partial": {
              url: urlBase + "/PartialEquipments/:id/partial",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#create
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PartialEquipments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#patchOrCreate
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/PartialEquipments",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#replaceOrCreate
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PartialEquipments/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#upsertWithWhere
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PartialEquipments/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#exists
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PartialEquipments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#findById
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PartialEquipments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#replaceById
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PartialEquipments/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#find
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PartialEquipments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#findOne
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PartialEquipments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#updateAll
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PartialEquipments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#deleteById
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PartialEquipments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#count
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PartialEquipments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#prototype$patchAttributes
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PartialEquipment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/PartialEquipments/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#createChangeStream
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PartialEquipments/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#createMany
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PartialEquipments",
              method: "POST",
            },

            // INTERNAL. Use Account.partialequipment.findById() instead.
            "::findById::Account::partialequipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partialequipment/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.partialequipment.destroyById() instead.
            "::destroyById::Account::partialequipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partialequipment/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.partialequipment.updateById() instead.
            "::updateById::Account::partialequipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/partialequipment/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.partialequipment() instead.
            "::get::Account::partialequipment": {
              isArray: true,
              url: urlBase + "/Accounts/:id/partialequipment",
              method: "GET",
            },

            // INTERNAL. Use Account.partialequipment.create() instead.
            "::create::Account::partialequipment": {
              url: urlBase + "/Accounts/:id/partialequipment",
              method: "POST",
            },

            // INTERNAL. Use Account.partialequipment.createMany() instead.
            "::createMany::Account::partialequipment": {
              isArray: true,
              url: urlBase + "/Accounts/:id/partialequipment",
              method: "POST",
            },

            // INTERNAL. Use Account.partialequipment.destroyAll() instead.
            "::delete::Account::partialequipment": {
              url: urlBase + "/Accounts/:id/partialequipment",
              method: "DELETE",
            },

            // INTERNAL. Use Account.partialequipment.count() instead.
            "::count::Account::partialequipment": {
              url: urlBase + "/Accounts/:id/partialequipment/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#upsert
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#updateOrCreate
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#patchOrCreateWithWhere
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#update
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#destroyById
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#removeById
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#prototype$updateAttributes
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PartialEquipment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PartialEquipment` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PartialEquipment#modelName
        * @propertyOf lbServices.PartialEquipment
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PartialEquipment`.
        */
        R.modelName = "PartialEquipment";


            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#account
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PartialEquipment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::PartialEquipment::account"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#equipment
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Fetches belongsTo relation equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PartialEquipment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::get::PartialEquipment::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PartialEquipment#partial
             * @methodOf lbServices.PartialEquipment
             *
             * @description
             *
             * Fetches belongsTo relation partial.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PartialEquipment id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Partial` object.)
             * </em>
             */
        R.partial = function() {
          var TargetResource = $injector.get("Partial");
          var action = TargetResource["::get::PartialEquipment::partial"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Container
 * @header lbServices.Container
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Container` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Container",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Containers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Container#getContainers
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getContainers": {
              isArray: true,
              url: urlBase + "/Containers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#createContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "createContainer": {
              url: urlBase + "/Containers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#destroyContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "destroyContainer": {
              url: urlBase + "/Containers/:container",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getContainer": {
              url: urlBase + "/Containers/:container",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getFiles
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getFiles": {
              isArray: true,
              url: urlBase + "/Containers/:container/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getFile
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             *  - `file` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getFile": {
              url: urlBase + "/Containers/:container/files/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#removeFile
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             *  - `file` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "removeFile": {
              url: urlBase + "/Containers/:container/files/:file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#upload
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `result` – `{object=}` -
             */
            "upload": {
              url: urlBase + "/Containers/:container/upload",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#download
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             *  - `file` – `{string}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "download": {
              url: urlBase + "/Containers/:container/download/:file",
              method: "GET",
            },

            // INTERNAL. Use Account.containers.findById() instead.
            "::findById::Account::containers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/containers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.containers.destroyById() instead.
            "::destroyById::Account::containers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/containers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.containers.updateById() instead.
            "::updateById::Account::containers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/containers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.containers() instead.
            "::get::Account::containers": {
              isArray: true,
              url: urlBase + "/Accounts/:id/containers",
              method: "GET",
            },

            // INTERNAL. Use Account.containers.create() instead.
            "::create::Account::containers": {
              url: urlBase + "/Accounts/:id/containers",
              method: "POST",
            },

            // INTERNAL. Use Account.containers.createMany() instead.
            "::createMany::Account::containers": {
              isArray: true,
              url: urlBase + "/Accounts/:id/containers",
              method: "POST",
            },

            // INTERNAL. Use Account.containers.destroyAll() instead.
            "::delete::Account::containers": {
              url: urlBase + "/Accounts/:id/containers",
              method: "DELETE",
            },

            // INTERNAL. Use Account.containers.count() instead.
            "::count::Account::containers": {
              url: urlBase + "/Accounts/:id/containers/count",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Container#modelName
        * @propertyOf lbServices.Container
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Container`.
        */
        R.modelName = "Container";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Document
 * @header lbServices.Document
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Document` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Document",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Documents/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Document.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Documents/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Document.job() instead.
            "prototype$__get__job": {
              url: urlBase + "/Documents/:id/job",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#create
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Documents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#patchOrCreate
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Documents",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#replaceOrCreate
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Documents/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#upsertWithWhere
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Documents/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#exists
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Documents/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#findById
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Documents/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#replaceById
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Documents/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#find
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Documents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#findOne
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Documents/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#updateAll
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Documents/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#deleteById
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Documents/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#count
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Documents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#prototype$patchAttributes
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Document id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Documents/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#createChangeStream
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Documents/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Document#createMany
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Documents",
              method: "POST",
            },

            // INTERNAL. Use Account.documents.findById() instead.
            "::findById::Account::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/documents/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.documents.destroyById() instead.
            "::destroyById::Account::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/documents/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.documents.updateById() instead.
            "::updateById::Account::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/documents/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.documents() instead.
            "::get::Account::documents": {
              isArray: true,
              url: urlBase + "/Accounts/:id/documents",
              method: "GET",
            },

            // INTERNAL. Use Account.documents.create() instead.
            "::create::Account::documents": {
              url: urlBase + "/Accounts/:id/documents",
              method: "POST",
            },

            // INTERNAL. Use Account.documents.createMany() instead.
            "::createMany::Account::documents": {
              isArray: true,
              url: urlBase + "/Accounts/:id/documents",
              method: "POST",
            },

            // INTERNAL. Use Account.documents.destroyAll() instead.
            "::delete::Account::documents": {
              url: urlBase + "/Accounts/:id/documents",
              method: "DELETE",
            },

            // INTERNAL. Use Account.documents.count() instead.
            "::count::Account::documents": {
              url: urlBase + "/Accounts/:id/documents/count",
              method: "GET",
            },

            // INTERNAL. Use Job.documents.findById() instead.
            "::findById::Job::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.documents.destroyById() instead.
            "::destroyById::Job::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.documents.updateById() instead.
            "::updateById::Job::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.documents.link() instead.
            "::link::Job::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.documents.unlink() instead.
            "::unlink::Job::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.documents.exists() instead.
            "::exists::Job::documents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/documents/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Job.documents() instead.
            "::get::Job::documents": {
              isArray: true,
              url: urlBase + "/Jobs/:id/documents",
              method: "GET",
            },

            // INTERNAL. Use Job.documents.create() instead.
            "::create::Job::documents": {
              url: urlBase + "/Jobs/:id/documents",
              method: "POST",
            },

            // INTERNAL. Use Job.documents.createMany() instead.
            "::createMany::Job::documents": {
              isArray: true,
              url: urlBase + "/Jobs/:id/documents",
              method: "POST",
            },

            // INTERNAL. Use Job.documents.destroyAll() instead.
            "::delete::Job::documents": {
              url: urlBase + "/Jobs/:id/documents",
              method: "DELETE",
            },

            // INTERNAL. Use Job.documents.count() instead.
            "::count::Job::documents": {
              url: urlBase + "/Jobs/:id/documents/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Document#upsert
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Document#updateOrCreate
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Document#patchOrCreateWithWhere
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Document#update
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Document#destroyById
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Document#removeById
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Document#prototype$updateAttributes
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Document id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Document` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Document#modelName
        * @propertyOf lbServices.Document
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Document`.
        */
        R.modelName = "Document";


            /**
             * @ngdoc method
             * @name lbServices.Document#account
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Document id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Document::account"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Document#job
             * @methodOf lbServices.Document
             *
             * @description
             *
             * Fetches belongsTo relation job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Document id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.job = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::get::Document::job"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Invite
 * @header lbServices.Invite
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Invite` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Invite",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Invites/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Invite.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Invites/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Invite.boarder() instead.
            "prototype$__get__boarder": {
              url: urlBase + "/Invites/:id/boarder",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#create
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Invites",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#patchOrCreate
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Invites",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#replaceOrCreate
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Invites/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#upsertWithWhere
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Invites/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#exists
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Invites/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#findById
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Invites/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#replaceById
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Invites/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#find
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Invites",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#findOne
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Invites/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#updateAll
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Invites/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#deleteById
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Invites/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#count
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Invites/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#prototype$patchAttributes
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Invite id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Invites/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#createChangeStream
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Invites/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#resendone
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `inviteCode` – `{string=}` - APQC
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` -
             */
            "resendone": {
              url: urlBase + "/Invites/resendone",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#resendall
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `email` – `{string=}` - "bob.loblaw@gmail.com"
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` -
             */
            "resendall": {
              url: urlBase + "/Invites/resendall",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#loadinvite
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `req` – `{object=}` -
             *
             *  - `inviteCode` – `{string=}` - ABCD
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` -
             */
            "loadinvite": {
              url: urlBase + "/Invites/loadinvite",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#completeRegistration
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `regData` – `{object=}` - {'inviteCode': 'ABCD','email':'abc@xyz.com','password':'password','firstName':'Bob','lastName':'Smith','mobilePhone':'1234567890'}
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` -
             */
            "completeRegistration": {
              url: urlBase + "/Invites/complete-registration",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Invite#createMany
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Invites",
              method: "POST",
            },

            // INTERNAL. Use Boarder.invites.findById() instead.
            "::findById::Boarder::invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/invites/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.invites.destroyById() instead.
            "::destroyById::Boarder::invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/invites/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.invites.updateById() instead.
            "::updateById::Boarder::invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/invites/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.invites() instead.
            "::get::Boarder::invites": {
              isArray: true,
              url: urlBase + "/Boarders/:id/invites",
              method: "GET",
            },

            // INTERNAL. Use Boarder.invites.create() instead.
            "::create::Boarder::invites": {
              url: urlBase + "/Boarders/:id/invites",
              method: "POST",
            },

            // INTERNAL. Use Boarder.invites.createMany() instead.
            "::createMany::Boarder::invites": {
              isArray: true,
              url: urlBase + "/Boarders/:id/invites",
              method: "POST",
            },

            // INTERNAL. Use Boarder.invites.destroyAll() instead.
            "::delete::Boarder::invites": {
              url: urlBase + "/Boarders/:id/invites",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.invites.count() instead.
            "::count::Boarder::invites": {
              url: urlBase + "/Boarders/:id/invites/count",
              method: "GET",
            },

            // INTERNAL. Use Account.invites.findById() instead.
            "::findById::Account::invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/invites/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.invites.destroyById() instead.
            "::destroyById::Account::invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/invites/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.invites.updateById() instead.
            "::updateById::Account::invites": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/invites/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.invites() instead.
            "::get::Account::invites": {
              isArray: true,
              url: urlBase + "/Accounts/:id/invites",
              method: "GET",
            },

            // INTERNAL. Use Account.invites.create() instead.
            "::create::Account::invites": {
              url: urlBase + "/Accounts/:id/invites",
              method: "POST",
            },

            // INTERNAL. Use Account.invites.createMany() instead.
            "::createMany::Account::invites": {
              isArray: true,
              url: urlBase + "/Accounts/:id/invites",
              method: "POST",
            },

            // INTERNAL. Use Account.invites.destroyAll() instead.
            "::delete::Account::invites": {
              url: urlBase + "/Accounts/:id/invites",
              method: "DELETE",
            },

            // INTERNAL. Use Account.invites.count() instead.
            "::count::Account::invites": {
              url: urlBase + "/Accounts/:id/invites/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Invite#upsert
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Invite#updateOrCreate
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Invite#patchOrCreateWithWhere
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Invite#update
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Invite#destroyById
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Invite#removeById
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Invite#prototype$updateAttributes
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Invite id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Invite` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Invite#modelName
        * @propertyOf lbServices.Invite
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Invite`.
        */
        R.modelName = "Invite";


            /**
             * @ngdoc method
             * @name lbServices.Invite#account
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Invite id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Invite::account"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Invite#boarder
             * @methodOf lbServices.Invite
             *
             * @description
             *
             * Fetches belongsTo relation boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Invite id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarder = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::get::Invite::boarder"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Lead
 * @header lbServices.Lead
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Lead` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Lead",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Leads/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Lead.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Leads/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#create
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Leads",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#patchOrCreate
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Leads",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#replaceOrCreate
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Leads/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#upsertWithWhere
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Leads/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#exists
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Leads/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#findById
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Leads/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#replaceById
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Leads/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#find
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Leads",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#findOne
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Leads/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#updateAll
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Leads/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#deleteById
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Leads/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#count
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Leads/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#prototype$patchAttributes
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Lead id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Leads/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#createChangeStream
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Leads/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lead#createMany
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Leads",
              method: "POST",
            },

            // INTERNAL. Use Account.leads.findById() instead.
            "::findById::Account::leads": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/leads/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.leads.destroyById() instead.
            "::destroyById::Account::leads": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/leads/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.leads.updateById() instead.
            "::updateById::Account::leads": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/leads/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.leads() instead.
            "::get::Account::leads": {
              isArray: true,
              url: urlBase + "/Accounts/:id/leads",
              method: "GET",
            },

            // INTERNAL. Use Account.leads.create() instead.
            "::create::Account::leads": {
              url: urlBase + "/Accounts/:id/leads",
              method: "POST",
            },

            // INTERNAL. Use Account.leads.createMany() instead.
            "::createMany::Account::leads": {
              isArray: true,
              url: urlBase + "/Accounts/:id/leads",
              method: "POST",
            },

            // INTERNAL. Use Account.leads.destroyAll() instead.
            "::delete::Account::leads": {
              url: urlBase + "/Accounts/:id/leads",
              method: "DELETE",
            },

            // INTERNAL. Use Account.leads.count() instead.
            "::count::Account::leads": {
              url: urlBase + "/Accounts/:id/leads/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Lead#upsert
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Lead#updateOrCreate
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Lead#patchOrCreateWithWhere
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Lead#update
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Lead#destroyById
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Lead#removeById
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Lead#prototype$updateAttributes
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Lead id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lead` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Lead#modelName
        * @propertyOf lbServices.Lead
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Lead`.
        */
        R.modelName = "Lead";


            /**
             * @ngdoc method
             * @name lbServices.Lead#account
             * @methodOf lbServices.Lead
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Lead id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Lead::account"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Note
 * @header lbServices.Note
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Note` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Note",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Notes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Note.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/Notes/:id/account",
              method: "GET",
            },

            // INTERNAL. Use Note.boarder() instead.
            "prototype$__get__boarder": {
              url: urlBase + "/Notes/:id/boarder",
              method: "GET",
            },

            // INTERNAL. Use Note.job() instead.
            "prototype$__get__job": {
              url: urlBase + "/Notes/:id/job",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#create
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Notes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#patchOrCreate
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/Notes",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#replaceOrCreate
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Notes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#upsertWithWhere
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Notes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#exists
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Notes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#findById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Notes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#replaceById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Notes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#find
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Notes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#findOne
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Notes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#updateAll
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Notes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#deleteById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Notes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#count
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Notes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#prototype$patchAttributes
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/Notes/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#createChangeStream
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Notes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Note#createMany
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Notes",
              method: "POST",
            },

            // INTERNAL. Use Boarder.notes.findById() instead.
            "::findById::Boarder::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/notes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.notes.destroyById() instead.
            "::destroyById::Boarder::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/notes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.notes.updateById() instead.
            "::updateById::Boarder::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/notes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.notes() instead.
            "::get::Boarder::notes": {
              isArray: true,
              url: urlBase + "/Boarders/:id/notes",
              method: "GET",
            },

            // INTERNAL. Use Boarder.notes.create() instead.
            "::create::Boarder::notes": {
              url: urlBase + "/Boarders/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use Boarder.notes.createMany() instead.
            "::createMany::Boarder::notes": {
              isArray: true,
              url: urlBase + "/Boarders/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use Boarder.notes.destroyAll() instead.
            "::delete::Boarder::notes": {
              url: urlBase + "/Boarders/:id/notes",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.notes.count() instead.
            "::count::Boarder::notes": {
              url: urlBase + "/Boarders/:id/notes/count",
              method: "GET",
            },

            // INTERNAL. Use Job.notes.findById() instead.
            "::findById::Job::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/notes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.notes.destroyById() instead.
            "::destroyById::Job::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/notes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.notes.updateById() instead.
            "::updateById::Job::notes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/notes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.notes() instead.
            "::get::Job::notes": {
              isArray: true,
              url: urlBase + "/Jobs/:id/notes",
              method: "GET",
            },

            // INTERNAL. Use Job.notes.create() instead.
            "::create::Job::notes": {
              url: urlBase + "/Jobs/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use Job.notes.createMany() instead.
            "::createMany::Job::notes": {
              isArray: true,
              url: urlBase + "/Jobs/:id/notes",
              method: "POST",
            },

            // INTERNAL. Use Job.notes.destroyAll() instead.
            "::delete::Job::notes": {
              url: urlBase + "/Jobs/:id/notes",
              method: "DELETE",
            },

            // INTERNAL. Use Job.notes.count() instead.
            "::count::Job::notes": {
              url: urlBase + "/Jobs/:id/notes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Note#upsert
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Note#updateOrCreate
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.Note#patchOrCreateWithWhere
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Note#update
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Note#destroyById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Note#removeById
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Note#prototype$updateAttributes
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Note` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Note#modelName
        * @propertyOf lbServices.Note
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Note`.
        */
        R.modelName = "Note";


            /**
             * @ngdoc method
             * @name lbServices.Note#account
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::Note::account"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Note#boarder
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Fetches belongsTo relation boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarder = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::get::Note::boarder"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Note#job
             * @methodOf lbServices.Note
             *
             * @description
             *
             * Fetches belongsTo relation job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Note id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.job = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::get::Note::job"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AccountRole
 * @header lbServices.AccountRole
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AccountRole` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AccountRole",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AccountRoles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use AccountRole.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/AccountRoles/:id/account",
              method: "GET",
            },

            // INTERNAL. Use AccountRole.boarder() instead.
            "prototype$__get__boarder": {
              url: urlBase + "/AccountRoles/:id/boarder",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#create
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AccountRoles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#patchOrCreate
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/AccountRoles",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#replaceOrCreate
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AccountRoles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#upsertWithWhere
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AccountRoles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#exists
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AccountRoles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#findById
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AccountRoles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#replaceById
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AccountRoles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#find
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AccountRoles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#findOne
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AccountRoles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#updateAll
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AccountRoles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#deleteById
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AccountRoles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#count
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AccountRoles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#prototype$patchAttributes
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/AccountRoles/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#createChangeStream
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AccountRoles/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#createMany
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AccountRoles",
              method: "POST",
            },

            // INTERNAL. Use Boarder.accountroles.findById() instead.
            "::findById::Boarder::accountroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accountroles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accountroles.destroyById() instead.
            "::destroyById::Boarder::accountroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accountroles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accountroles.updateById() instead.
            "::updateById::Boarder::accountroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Boarders/:id/accountroles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Boarder.accountroles() instead.
            "::get::Boarder::accountroles": {
              isArray: true,
              url: urlBase + "/Boarders/:id/accountroles",
              method: "GET",
            },

            // INTERNAL. Use Boarder.accountroles.create() instead.
            "::create::Boarder::accountroles": {
              url: urlBase + "/Boarders/:id/accountroles",
              method: "POST",
            },

            // INTERNAL. Use Boarder.accountroles.createMany() instead.
            "::createMany::Boarder::accountroles": {
              isArray: true,
              url: urlBase + "/Boarders/:id/accountroles",
              method: "POST",
            },

            // INTERNAL. Use Boarder.accountroles.destroyAll() instead.
            "::delete::Boarder::accountroles": {
              url: urlBase + "/Boarders/:id/accountroles",
              method: "DELETE",
            },

            // INTERNAL. Use Boarder.accountroles.count() instead.
            "::count::Boarder::accountroles": {
              url: urlBase + "/Boarders/:id/accountroles/count",
              method: "GET",
            },

            // INTERNAL. Use Account.userroles.findById() instead.
            "::findById::Account::userroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/userroles/:fk",
              method: "GET",
            },

            // INTERNAL. Use Account.userroles.destroyById() instead.
            "::destroyById::Account::userroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/userroles/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Account.userroles.updateById() instead.
            "::updateById::Account::userroles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Accounts/:id/userroles/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Account.userroles() instead.
            "::get::Account::userroles": {
              isArray: true,
              url: urlBase + "/Accounts/:id/userroles",
              method: "GET",
            },

            // INTERNAL. Use Account.userroles.create() instead.
            "::create::Account::userroles": {
              url: urlBase + "/Accounts/:id/userroles",
              method: "POST",
            },

            // INTERNAL. Use Account.userroles.createMany() instead.
            "::createMany::Account::userroles": {
              isArray: true,
              url: urlBase + "/Accounts/:id/userroles",
              method: "POST",
            },

            // INTERNAL. Use Account.userroles.destroyAll() instead.
            "::delete::Account::userroles": {
              url: urlBase + "/Accounts/:id/userroles",
              method: "DELETE",
            },

            // INTERNAL. Use Account.userroles.count() instead.
            "::count::Account::userroles": {
              url: urlBase + "/Accounts/:id/userroles/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AccountRole#upsert
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#updateOrCreate
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#patchOrCreateWithWhere
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#update
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#destroyById
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#removeById
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#prototype$updateAttributes
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AccountRole` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AccountRole#modelName
        * @propertyOf lbServices.AccountRole
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AccountRole`.
        */
        R.modelName = "AccountRole";


            /**
             * @ngdoc method
             * @name lbServices.AccountRole#account
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::AccountRole::account"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.AccountRole#boarder
             * @methodOf lbServices.AccountRole
             *
             * @description
             *
             * Fetches belongsTo relation boarder.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AccountRole id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Boarder` object.)
             * </em>
             */
        R.boarder = function() {
          var TargetResource = $injector.get("Boarder");
          var action = TargetResource["::get::AccountRole::boarder"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.JobPhoto
 * @header lbServices.JobPhoto
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `JobPhoto` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "JobPhoto",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/JobPhotos/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use JobPhoto.job() instead.
            "prototype$__get__job": {
              url: urlBase + "/JobPhotos/:id/job",
              method: "GET",
            },

            // INTERNAL. Use JobPhoto.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/JobPhotos/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#create
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/JobPhotos",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#patchOrCreate
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/JobPhotos",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#replaceOrCreate
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/JobPhotos/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#upsertWithWhere
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/JobPhotos/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#exists
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/JobPhotos/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#findById
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/JobPhotos/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#replaceById
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/JobPhotos/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#find
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/JobPhotos",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#findOne
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/JobPhotos/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#updateAll
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/JobPhotos/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#deleteById
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/JobPhotos/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#count
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/JobPhotos/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#prototype$patchAttributes
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobPhoto id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/JobPhotos/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#createChangeStream
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/JobPhotos/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#uploadPhoto
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `status` – `{string=}` -
             */
            "uploadPhoto": {
              url: urlBase + "/JobPhotos/upload-photo",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#createMany
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/JobPhotos",
              method: "POST",
            },

            // INTERNAL. Use Job.photos.findById() instead.
            "::findById::Job::photos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/photos/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.photos.destroyById() instead.
            "::destroyById::Job::photos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/photos/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.photos.updateById() instead.
            "::updateById::Job::photos": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/photos/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.photos() instead.
            "::get::Job::photos": {
              isArray: true,
              url: urlBase + "/Jobs/:id/photos",
              method: "GET",
            },

            // INTERNAL. Use Job.photos.create() instead.
            "::create::Job::photos": {
              url: urlBase + "/Jobs/:id/photos",
              method: "POST",
            },

            // INTERNAL. Use Job.photos.createMany() instead.
            "::createMany::Job::photos": {
              isArray: true,
              url: urlBase + "/Jobs/:id/photos",
              method: "POST",
            },

            // INTERNAL. Use Job.photos.destroyAll() instead.
            "::delete::Job::photos": {
              url: urlBase + "/Jobs/:id/photos",
              method: "DELETE",
            },

            // INTERNAL. Use Job.photos.count() instead.
            "::count::Job::photos": {
              url: urlBase + "/Jobs/:id/photos/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#upsert
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#updateOrCreate
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#patchOrCreateWithWhere
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#update
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#destroyById
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#removeById
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#prototype$updateAttributes
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobPhoto id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPhoto` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.JobPhoto#modelName
        * @propertyOf lbServices.JobPhoto
        * @description
        * The name of the model represented by this $resource,
        * i.e. `JobPhoto`.
        */
        R.modelName = "JobPhoto";


            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#job
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Fetches belongsTo relation job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobPhoto id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.job = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::get::JobPhoto::job"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.JobPhoto#account
             * @methodOf lbServices.JobPhoto
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobPhoto id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::JobPhoto::account"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.JobDoc
 * @header lbServices.JobDoc
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `JobDoc` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "JobDoc",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/JobDocs/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use JobDoc.jobId() instead.
            "prototype$__get__jobId": {
              url: urlBase + "/JobDocs/:id/jobId",
              method: "GET",
            },

            // INTERNAL. Use JobDoc.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/JobDocs/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#create
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/JobDocs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#patchOrCreate
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/JobDocs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#replaceOrCreate
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/JobDocs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#upsertWithWhere
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/JobDocs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#exists
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/JobDocs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#findById
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/JobDocs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#replaceById
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/JobDocs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#find
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/JobDocs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#findOne
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/JobDocs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#updateAll
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/JobDocs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#deleteById
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/JobDocs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#count
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/JobDocs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#prototype$patchAttributes
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobDoc id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/JobDocs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#createChangeStream
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/JobDocs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#deleteDocument
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `dataObj` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` - {}
             */
            "deleteDocument": {
              url: urlBase + "/JobDocs/delete-document",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#uploadDocument
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `status` – `{string=}` -
             */
            "uploadDocument": {
              url: urlBase + "/JobDocs/upload-document",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#createMany
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/JobDocs",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.JobDoc#upsert
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#updateOrCreate
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#patchOrCreateWithWhere
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#update
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#destroyById
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#removeById
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#prototype$updateAttributes
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobDoc id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobDoc` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.JobDoc#modelName
        * @propertyOf lbServices.JobDoc
        * @description
        * The name of the model represented by this $resource,
        * i.e. `JobDoc`.
        */
        R.modelName = "JobDoc";


            /**
             * @ngdoc method
             * @name lbServices.JobDoc#jobId
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Fetches belongsTo relation jobId.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobDoc id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.jobId = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::get::JobDoc::jobId"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.JobDoc#account
             * @methodOf lbServices.JobDoc
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobDoc id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::JobDoc::account"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.JobPoc
 * @header lbServices.JobPoc
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `JobPoc` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "JobPoc",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/JobPocs/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use JobPoc.job() instead.
            "prototype$__get__job": {
              url: urlBase + "/JobPocs/:id/job",
              method: "GET",
            },

            // INTERNAL. Use JobPoc.account() instead.
            "prototype$__get__account": {
              url: urlBase + "/JobPocs/:id/account",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#create
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/JobPocs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#patchOrCreate
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "patchOrCreate": {
              url: urlBase + "/JobPocs",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#replaceOrCreate
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/JobPocs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#upsertWithWhere
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/JobPocs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#exists
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/JobPocs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#findById
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/JobPocs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#replaceById
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/JobPocs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#find
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/JobPocs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#findOne
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/JobPocs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#updateAll
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/JobPocs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#deleteById
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/JobPocs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#count
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/JobPocs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#prototype$patchAttributes
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobPoc id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "prototype$patchAttributes": {
              url: urlBase + "/JobPocs/:id",
              method: "PATCH",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#createChangeStream
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/JobPocs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#createMany
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/JobPocs",
              method: "POST",
            },

            // INTERNAL. Use Job.pocs.findById() instead.
            "::findById::Job::pocs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/pocs/:fk",
              method: "GET",
            },

            // INTERNAL. Use Job.pocs.destroyById() instead.
            "::destroyById::Job::pocs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/pocs/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Job.pocs.updateById() instead.
            "::updateById::Job::pocs": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Jobs/:id/pocs/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Job.pocs() instead.
            "::get::Job::pocs": {
              isArray: true,
              url: urlBase + "/Jobs/:id/pocs",
              method: "GET",
            },

            // INTERNAL. Use Job.pocs.create() instead.
            "::create::Job::pocs": {
              url: urlBase + "/Jobs/:id/pocs",
              method: "POST",
            },

            // INTERNAL. Use Job.pocs.createMany() instead.
            "::createMany::Job::pocs": {
              isArray: true,
              url: urlBase + "/Jobs/:id/pocs",
              method: "POST",
            },

            // INTERNAL. Use Job.pocs.destroyAll() instead.
            "::delete::Job::pocs": {
              url: urlBase + "/Jobs/:id/pocs",
              method: "DELETE",
            },

            // INTERNAL. Use Job.pocs.count() instead.
            "::count::Job::pocs": {
              url: urlBase + "/Jobs/:id/pocs/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.JobPoc#upsert
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R["upsert"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#updateOrCreate
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R["updateOrCreate"] = R["patchOrCreate"];

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#patchOrCreateWithWhere
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#update
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#destroyById
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#removeById
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#prototype$updateAttributes
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobPoc id
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JobPoc` object.)
             * </em>
             */
        R["prototype$updateAttributes"] = R["prototype$patchAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.JobPoc#modelName
        * @propertyOf lbServices.JobPoc
        * @description
        * The name of the model represented by this $resource,
        * i.e. `JobPoc`.
        */
        R.modelName = "JobPoc";


            /**
             * @ngdoc method
             * @name lbServices.JobPoc#job
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Fetches belongsTo relation job.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobPoc id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Job` object.)
             * </em>
             */
        R.job = function() {
          var TargetResource = $injector.get("Job");
          var action = TargetResource["::get::JobPoc::job"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.JobPoc#account
             * @methodOf lbServices.JobPoc
             *
             * @description
             *
             * Fetches belongsTo relation account.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JobPoc id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Account` object.)
             * </em>
             */
        R.account = function() {
          var TargetResource = $injector.get("Account");
          var action = TargetResource["::get::JobPoc::account"];
          return action.apply(R, arguments);
        };


        return R;
      }]);


  module
  .factory('LoopBackAuth', function() {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function(name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function() {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function(name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function(accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function() {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function() {
      props.forEach(function(name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    // Note: LocalStorage converts the value to string
    // We are using empty string as a marker for null/undefined values.
    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
  }])
  .factory('LoopBackAuthRequestInterceptor', ['$q', 'LoopBackAuth',
    function($q, LoopBackAuth) {
      return {
        'request': function(config) {
          // filter out external requests
          var host = getHost(config.url);
          if (host && config.url.indexOf(urlBaseHost) === -1) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            var res = {
              body: { error: { status: 401 }},
              status: 401,
              config: config,
              headers: function() { return undefined; },
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        },
      };
    }])

  /**
   * @ngdoc object
   * @name lbServices.LoopBackResourceProvider
   * @header lbServices.LoopBackResourceProvider
   * @description
   * Use `LoopBackResourceProvider` to change the global configuration
   * settings used by all models. Note that the provider is available
   * to Configuration Blocks only, see
   * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
   * for more details.
   *
   * ## Example
   *
   * ```js
   * angular.module('app')
   *  .config(function(LoopBackResourceProvider) {
   *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
   *  });
   * ```
   */
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} header The header name to use, e.g. `X-Access-Token`
     * @description
     * Configure the REST transport to use a different header for sending
     * the authentication token. It is sent in the `Authorization` header
     * by default.
     */
    this.setAuthHeader = function(header) {
      authHeader = header;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the header name that is used for sending the authentication token.
     */
    this.getAuthHeader = function() {
      return authHeader;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
     * @description
     * Change the URL of the REST API server. By default, the URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.setUrlBase = function(url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the URL of the REST API server. The URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.getUrlBase = function() {
      return urlBase;
    };

    this.$get = ['$resource', function($resource) {
      var LoopBackResource = function(url, params, actions) {
        var resource = $resource(url, params, actions);

        // Angular always calls POST on $save()
        // This hack is based on
        // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
        resource.prototype.$save = function(success, error) {
          // Fortunately, LoopBack provides a convenient `upsert` method
          // that exactly fits our needs.
          var result = resource.upsert.call(this, {}, this, success, error);
          return result.$promise || result;
        };
        return resource;
      };

      LoopBackResource.getUrlBase = function() {
        return urlBase;
      };

      LoopBackResource.getAuthHeader = function() {
        return authHeader;
      };

      return LoopBackResource;
    }];
  });
})(window, window.angular);
