(function() {
    'use strict';

    angular
        .module('scheduleboard.equipment')
        .controller('EquipmentController', EquipmentController);

    /* @ngInject */
    function EquipmentController() {
        var vm = this;
        vm.testData = ['equipment', 'is', 'great'];
    }
})();