(function() {

    'use strict';

    angular
        .module('scheduleboard.employees')
        .controller('EmployeeInviteController', EmployeeInviteController);
    // ----- ControllerFunction -----
    EmployeeInviteController.$inject = ['$mdDialog', '$scope', '$state', 'Boarder', 'AuthService', '$mdToast', 'Account', '$mdConstant', 'EmployeesService','$filter'];

    /* @ngInject */
    function EmployeeInviteController($mdDialog, $scope, $state, Boarder, AuthService, $mdToast, Account, $mdConstant, EmployeesService, $filter) {

        var vm = this;

        vm.new = true;
        this.tags = [];

        // Any key code can be used to create a custom separator
        var semicolon = 186;
        vm.invites = [{}];
        vm.employeeInvites = {
            "employees": []
        };
        vm.activeRow = undefined;

        // function declarations

        vm.validateEmail = validateEmail;
        vm.hide = hide;
        vm.submit = submit;
        vm.job = {};
        vm.addInvite = addInvite;
        vm.isLastEmployee = isLastEmployee;
        vm.removeInviteAtIndex = removeInviteAtIndex;

        function removeInviteAtIndex(index) {
            vm.invites.splice(index, 1)
        }

        function isLastEmployee(index) {
            if (index == (vm.invites.length - 1)) {
                return true;
            } else {
                return false;
            }
        }

        function addInvite() {
            vm.invites.push({});
            vm.isEmailValid = false;
            console.log(vm.invites);
        }

        function validateEmail() {
            vm.invites.forEach(function(invite, index) {
                var emailValue = invite.email;
                var reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
                if (reg.test(emailValue)) {
                    vm.isEmailValid = true;
                } else {
                    vm.isEmailValid = false;
                }
            });
        };

        function hide() {
            $mdDialog.hide();
        }

        vm.cancel = function() {
            $mdDialog.cancel();
        };

        function close() {
            $mdDialog.cancel();
        }

        function submit() {

            var smsUsers = [];
            var myInvites = [];
            vm.invites.forEach(function(invite, index){
               if(invite.smsOnly == true){
                   invite.mobilePhone = $filter('bcTelephone')(invite.mobilePhone, 'clean');
                   smsUsers.push(invite);
               }else{
                   myInvites.push(invite);
               }
            });
            //vm.employeeInvites.employees = myInvites;
            //console.log(vm.employeeInvites);
            Boarder.employeeinvite({
                    "id": AuthService.getUser().id,
                    "employees": myInvites
                }).$promise.then(
                    function(response) {
                        vm.invites = [];
                        $mdDialog.hide(response);
                    }, function (error){
                        $mdDialog.cancel(error);
                    }
                );

            smsUsers.forEach(function(user){
                //console.log(user);
                Boarder.upsert(user).$promise.then(function(response){
                    //console.log("GOT RESPONSE");
                    $mdDialog.hide(response);
                }, function(error){
                    console.log(error);
                    $mdDialog.cancel(error);
                });
            });
        }

        function hasValidAddress(job) {
            if (_.has(job, 'address')) {
                var a = job.address;

                var valid = _.has(a, 'zipCode') &&
                    _.has(a, 'state') &&
                    _.has(a, 'city') &&
                    _.has(a, 'streetAddress');

                if (valid) {
                    valid = valid &&
                        !(_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress)) &&
                        !(_.isNull(a.city) || _.isEmpty(a.city)) &&
                        !(_.isNull(a.state) || _.isEmpty(a.state)) &&
                        !(_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
                }

                return valid;
            }
            return false;
        }
    }

})();