(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('PartialsService', PartialsService);

    PartialsService.inject = ['$q', '$http', '$timeout', 'AuthService', 'createChangeStream', 'LoopBackAuth', 'API', 'Partial', 'CrewService', 'PartialEquipmentService'];
    function PartialsService($q, $http, $timeout, AuthService, createChangeStream, LoopBackAuth, API, Partial, CrewService, PartialEquipmentService) {
        var service = {
            assignEmployeesToJobDay: assignEmployeesToJobDay,
            assignEquipmentToJobDay: assignEquipmentToJobDay,
            getAllPartials: getAllPartials,
            getChangeStream: getChangeStream,
            getPartialsForDate: getPartialsForDate,
            getPartialById: getPartialById,
            getWorkForecastForNextNDays: getWorkForecastForNextNDays,
            removeAllEmployeesFromPartial: removeAllEmployeesFromPartial,
            removeAllEquipmentFromPartial: removeAllEquipmentFromPartial,
            setPartialDate: setPartialDate,
            setConfirmationStatus: setConfirmationStatus,
            upsertPartial: upsertPartial,
            getPartialsForJobAndDate: getPartialsForJobAndDate,
            deletePartialById: deletePartialById,
            deleteAllFuturePartials: deleteAllFuturePartials,
            deleteAllPartials: deleteAllPartials,
            createPartialSeries: createPartialSeries,
            delayPartial: delayPartial,
            assignEmployeesToPartial: assignEmployeesToPartial,
            assignEquipmentToPartial:assignEquipmentToPartial,
            notifyEmployeeDrop : notifyEmployeeDrop
        };
        
        return service;

        ////////////////

        function assignEmployeesToPartial(employees, partialId){
            return $q( function(resolve, reject) {
                if (angular.isDefined(employees) && angular.isDefined(partialId)){

                    // build the partial we need to find or create
                    Partial.findById({id:partialId}).$promise.then(function(partial){
                        var promises = [];

                        _.forEach(employees, function(employee){
                            var newCrew = {
                                "Date": moment(partial.day).startOf('day').toISOString(),
                                "boarderId": employee.id,
                                "partialId": partial.id
                            };

                            promises.push( CrewService.upsertCrew(newCrew) );

                        });
                        partial.confirmed = false;
                        promises.push(Partial.upsert(partial));

                        $q.all(promises).then(
                            function(results){
                                resolve(results);
                            },
                            function(error){
                                reject(error);
                            }
                        )

                    });

                }
            }); // return $q

        }
        function assignEmployeesToJobDay(employees, jobId, day){
            return $q( function(resolve, reject) {
                if (angular.isDefined(employees) && angular.isDefined(jobId) && angular.isDefined(day)){

                    // build the partial we need to find or create
                    var partialToFindOrCreate = {
                        "day": moment(day).startOf('day').toISOString(),
                        "jobId": jobId
                    };

                    // find/replace or create the Partial
                    upsertPartial(partialToFindOrCreate).then(function(partial){

                        var promises = [];

                        // iterate through the employees
                        _.forEach(employees, function (employee){

                            // now create a crew for the employee (boarder) and partial
                            var newCrew = {
                                "Date": moment(partial.day).startOf('day').toISOString(),
                                "boarderId": employee.id,
                                "partialId": partial.id
                            };

                            promises.push( CrewService.upsertCrew(newCrew) );
                        });

                        // wait for all the promises to return
                        $q.all(promises).then(
                            function(results){
                                resolve(results);
                            },
                            function(error){
                                reject(error);
                            }
                        );
                    }); 
                }
            }); // return $q
        };


        function delayPartial(partial, delayDate){
            var startDate = moment(partial.day).startOf("day");
            var delayDate = moment(delayDate);

            var delayDays = moment(delayDate).diff(startDate, "days");
            var endDate = moment(startDate).add(delayDays, "days");
            var promises = [];
            var partialsToMove = [];
                var start = moment(startDate);
                var end = moment(endDate);
                console.log(end);

            return $q( function(resolve, reject) {
                if (angular.isDefined(partial) && angular.isDefined(delayDate)){

                    // build the partial we need to find or create
                    Partial.find({
                    filter: {
                        where: {
                                and:
                                [
                                    {jobId: partial.jobId},
                                    {day: {between: [start.toISOString(), end.toISOString()]}}

                                ]

                        }
                    }

                }).then(function(partials){
                        var promises = [];
                        console.log("PARTIALS TO MOVE",partials);
                    while (partials.length > 0) {
                            Partial.find({filter: {
                        where: {
                                and:
                                [
                                    {jobId: partial.jobId},
                                    {day: delayDate.toISOString()}

                                ]

                        }
                    }}).then(function (answer) {
                                console.log("HOW MANY PARTIALS ON THIS DAY", answer);
                                if (answer.length > 0) {
                                    delayDate = moment(delayDate).add(1, "day");
                                    console.log("there are days here", answer);
                                    console.log("moving delay date", delayDate.day());

                                } else {
                                    var part = partials.pop();
                                    console.log(partials.length);
                                    console.log("here!", partials, delayDate);
                                    var newDay = moment(delayDate);
                                    part.day = newDay.startOf("day").toISOString();
                                    promises.push(Partial.upsert(part));
                                }


                            });
                            delayDate = moment(delayDate).add(1, "day");
                            console.log(partials.length);

                        }

                        $q.all(promises).then(
                            function(results){
                                resolve(results);
                            },
                            function(error){
                                reject(error);
                            }
                        )

                    });

                }
            }); // return $q

        }

        function createPartialSeries(workData,jobId){
            return $q( function(resolve, reject) {
                if (angular.isDefined(workData) && angular.isDefined(jobId)){
                    var endDate = moment(workData.range.dateEnd);
                    var startDate = moment(workData.range.dateStart);
                    var durationInDays = endDate.diff(startDate, "days");
                    
                    var promises = [];

                    // handle case where it's a single day
                    if (durationInDays==0){
                        promises.push(upsertPartial({
                            "day": startDate.startOf('day').toISOString(),
                            "jobId": jobId
                        }));
                    } 
                    // handle case where it is multiple days
                    else {
                        for (var i=0; i<=durationInDays; i++){
                            // skip weekend days (day 0 is sunday, and day 6 is saturday)
                            if (startDate.day()== 6 || startDate.day()==0){
                                // do nothing
                            }
                            // all other days are weekdays (workdays)
                            else {
                                // create the partial
                                promises.push(upsertPartial({
                                    "day": startDate.startOf('day').toISOString(),
                                    "jobId": jobId
                                }))
                            }

                            // increment the date
                            startDate.add(1,"days");
                        }
                    }

                    // wait for the partials to be created
                    $q.all(promises).then(
                        function(partials){
                            var crewAndEquipPromises = []

                            // now create crew and equip assignments for each partial
                            partials.forEach(function(partial){
                                if (workData.equipment.length != 0) {
                                    crewAndEquipPromises.push(assignEquipmentToPartial(workData.equipment, partial.id));
                                } if (workData.crew.length != 0) {
                                    crewAndEquipPromises.push(assignEmployeesToPartial(workData.crew, partial.id));
                                }
                            })

                            // wait for all the crew and equipment assignments to complete
                            $q.all(crewAndEquipPromises).then(
                                function(crewAndEquipment){
                                    resolve(partials)
                                },
                                function(error){
                                    console.log(error)
                                    reject(error)
                                }
                            ) // $q.all
                        },
                        function(error){
                            console.log(error);
                            reject(error);
                        }
                    ) // $q.all
                }
            }); // return $q
        }

        function assignEquipmentToPartial(equipment, partialId){
            return $q( function(resolve, reject) {
                if (angular.isDefined(equipment) && angular.isDefined(partialId)){

                    // build the partial we need to find or create
                    Partial.findById({id:partialId}).$promise.then(function(partial){
                        var promises = [];

                        _.forEach(equipment, function (e){

                            // now create a partialequipment for the equipment piece and partial
                            var newPartialEquipment = {
                                "Date": moment(partial.day).startOf('day').toISOString(),
                                "equipmentId": e.id,
                                "partialId": partial.id
                            };

                            promises.push( PartialEquipmentService.upsertPartialEquipment(newPartialEquipment) );

                        });
                        partial.confirmed = false;
                        promises.push(Partial.upsert(partial));

                        $q.all(promises).then(
                            function(results){
                                resolve(results);
                            },
                            function(error){
                                reject(error);
                            }
                        )

                    });

                }
            }); // return $q

        }


        function assignEquipmentToJobDay(equipment, jobId, day){
            return $q( function(resolve, reject) {
                if (angular.isDefined(equipment) && angular.isDefined(jobId) && angular.isDefined(day)){

                    // build the partial we need to find or create
                    var partialToFindOrCreate = {
                        "day": moment(day).startOf('day').toISOString(),
                        "jobId": jobId
                    };

                    // find/replace or create the Partial
                    upsertPartial(partialToFindOrCreate).then(function(partial){

                        var promises = [];

                        // iterate throught the equipment
                        _.forEach(equipment, function (e){

                            // now create a partialequipment for the equipment piece and partial
                            var newPartialEquipment = {
                                "Date": moment(partial.day).startOf('day').toISOString(),
                                "equipmentId": e.id,
                                "partialId": partial.id
                            };

                            promises.push( PartialEquipmentService.upsertPartialEquipment(newPartialEquipment) );
                        });

                        // wait for all the promises to return
                        $q.all(promises).then(
                            function(results){
                                resolve(results);
                            },
                            function(error){
                                console.log(error);
                                reject(error);
                            }
                        );
                    }); 
                }
            }); // return $q
        };

        function upsertPartial(partial){
            if (angular.isDefined(partial)){
                var where = {where: { and: [{day: partial.day}, {jobId: partial.jobId}]}};
                return Partial.upsert(partial).$promise;
            }
        }

        function assignEquipment(equipment){
            if (angular.isDefined(equipment)){
                // TODO implement logic to add equipment to partial
            }
        };

        function getAllPartials(){
            return $q( function(resolve, reject) {
                return Partial.find().$promise;
            });
        };

        function deletePartialById(id){
            console.log("here's the id!!!");
            return    Partial.deleteById({id:id}).$promise;
        }

        function deleteAllFuturePartials(partial){
            return $q( function(resolve, reject) {

                //find partial
                    Partial.find({
                filter: {
                    where:
                        {and:[{day: {gte: moment(partial.day).toISOString()}},
                        {jobId:partial.jobId}]}

                }
            }).$promise.then(function(partials){

                        var promises = [];

                        // iterate throught the equipment
                        partials.forEach(function(partial){

                            promises.push( deletePartialById(partial.id) );
                        });

                        // wait for all the promises to return
                        $q.all(promises).then(
                            function(results){
                                resolve(results);
                            },
                            function(error){
                                console.log(error);
                                reject(error);
                            }
                        );
                    });
            }); // return $q

        }
        function deleteAllPartials(partial) {
        return $q( function(resolve, reject) {
                //find partial
            Partial.find({
                        jobId:partial.jobId
            }).$promise.then(function(partials){

                console.log(partials);
                        var promises = [];

                        // iterate throught the equipment
                        partials.forEach(function(partial){

                            promises.push( deletePartialById(partial.id) );
                        });

                        // wait for all the promises to return
                        $q.all(promises).then(
                            function(results){
                                resolve(results);
                            },
                            function(error){
                                console.log(error);
                                reject(error);
                            }
                        );
                    });
            }); // return $q
        }
        function getChangeStream(){
            // this built-in call doesn't work, as what it returns is useless
            // return Partial.createChangeStream().$promise;

            // here is what we will do instead
            var changeStreamUrl = API.baseUrl +'/Partials/change-stream?access_token='+ LoopBackAuth.accessTokenId;
            var src = new EventSource(changeStreamUrl);
            return createChangeStream(src);
        };

        function getWorkForecastForNextNDays(yyyy_mm_dd, numDays){
            
            return $q( function(resolve, reject) {
                var now = moment().format('YYYY-MM-DD');
                var today = moment(now).startOf('day');
                var nDaysFromNow = moment(now).add(numDays-1, 'days').endOf('day');

                var criteria = {
                    filter: {
                        where: { 
                            day: { between: [ today.toISOString(), nDaysFromNow.toISOString() ] }
                        }
                    }
                };

                Partial.find(criteria).$promise.then(
                    function(partials){

                        var toReturn = [];

                        // bin partials by day
                        for (var i=0; i<numDays; i++){
                            var curDay = moment(today).add(i, 'days').format('YYYY-MM-DD');
                            var curDayStart = moment(curDay).startOf('day');
                            var curDayEnd = moment(curDay).endOf('day');

                            var curDaysPartials = _.filter(partials, function(p){
                                return (curDayStart.valueOf() <= moment(p.day).valueOf() && moment(p.day).valueOf() <= curDayEnd.valueOf());
                            });

                            toReturn.push({ "date": curDayStart, "count": curDaysPartials.length });
                        }

                        resolve(toReturn);
                    },
                    function(error){
                        reject(error);
                    }
                );
            });
        };

        function getPartialsForDate(yyyy_mm_dd){
            var start = moment(yyyy_mm_dd);
            var end = moment(yyyy_mm_dd).add(1, 'days').subtract(1, 'milliseconds');

            return Partial.find({
                filter: {
                    scope: {
                        where: {
                            day: {between: [start.toISOString(), end.toISOString()]}
                        },
                        include: ["crew", "job", "equipment"]
                    }
                }
            }).$promise;
        };


        function getPartialsForJobAndDateRange(startDate,endDate,jobId){
            var start = moment(startDate);
            var end = moment(endDate);

            Partial.find({
                filter: {
                        where: { and: [
                            {day: {between: [start.toISOString(), end.toISOString()]}},
                            {jobId : jobId}]

                        }
                }
            }).$promise.then(function(partials){return partials}, function(err){return err});
        };


        function getPartialsForDateRangeAndBoarder(startDate,endDate,boarderId){
            var start = moment(startDate);
            var end = moment(endDate);

            return Partial.find({
                filter: {
                        where: {
                            day: {between: [start.toISOString(), end.toISOString()]}

                        },
                        include: [{relation:"crew",scope:{where:{}}}, "job", "equipment"]
                }
            }).$promise;
        };

        function getPartialsForJobAndDate(yyyy_mm_dd,jobId){
            var start = moment(yyyy_mm_dd);
            var end = moment(yyyy_mm_dd).add(1, 'days').subtract(1, 'milliseconds');

            return Partial.find({
                filter: {
                        where: { and: [
                            {day: {between: [start.toISOString(), end.toISOString()]}},
                            {jobId : jobId}]

                        }
                }
            }).$promise;
        };
        function getPartialById(partialId){
            return Partial.findById({ id: partialId ,                filter: {
                        include: ["crew", "job", "equipment"]
                }}).$promise;
        };

        // TODO tweak to not use partial object, for LiveSet compatibility?
        function setPartialDate(partial, yyyy_mm_dd){
            // verify we've been supplied a proper date
            var dateToSet = moment(yyyy_mm_dd);
            if (dateToSet.isValid()){
                partial.day = dateToSet;
                return partial.$save();
            } else {
                throw new Error("PartialsService :: setPartialDate : not setting to invalid date: " + yyyy_mm_dd);
            }
        };

        // TODO tweak to not use partial object, for LiveSet compatibility?
        function setConfirmationStatus(partial, confirmationStatus){
            // return Partial.prototype$patchAttributes({
            //     id: partial.id,
            //     confirmed: confirmationStatus
            // }).$promise;

            return Partial.upsert({id: partial.id, confirmed: confirmationStatus}).$promise;
        };

        function notifyEmployeeDrop(partial, employee){
            return Partial.notifyEmployeeDrop({partial:partial, employee:employee}).$promise
        }
        function removeAllEmployeesFromPartial(partial){

                    return Partial.crew.destroyAll({"id": partial.id}).$promise;
        };

        function removeAllEquipmentFromPartial(partial){

                    // find/replace or create the Partial
                        // delete all the equipment assignments (PartialEquipment) to this partial
                       return Partial.equipment.destroyAll({"id": partial.id}).$promise;
                }

    }
})();