(function () {
    'use strict';

    angular
        .module('scheduleboard.landing');

    /* @ngInject */
    /* function moduleConfig($stateProvider) {

        $stateProvider
        .state('scheduleboard.landing', {
            url: '/site/landing',
            templateUrl: '/app/scheduleboard/landing/landing.tmpl.html',
            // set the controller to load for this page
            controller: 'LandingController',
            controllerAs: 'vm',
            // layout-column class added to make footer move to
            // bottom of the page on short pages
            data: {
                layout: {
                    contentClass: 'layout-column'
                }
            }
        });

    } */
})();
