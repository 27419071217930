(function() {

'use strict';

    angular
        .module('scheduleboard.resources')
        .controller('ResourcesMapDialogController', ResourcesMapDialogController);
        // ----- ControllerFunction -----
        ResourcesMapDialogController.$inject = ['$mdDialog', '$scope','$state','uiGmapGoogleMapApi', 'jobs'];

        /* @ngInject */
        function ResourcesMapDialogController($mdDialog, $scope,$state,uiGmapGoogleMapApi, jobs) {


            var vm = this;
            vm.jobMarkers = [];
            //vm.map;
            vm.jobs = jobs;

            uiGmapGoogleMapApi.then(function(maps) {
            maps.visualRefresh = true;
            vm.map = {
        center: {
            latitude: -23.598763,
            longitude: -46.676547
        },
        zoom: 13
    };
            var bounds = new maps.LatLngBounds();
            //vm.map=map;
                //vm.map = map;
                angular.forEach(vm.jobs,function(job,index){
                    vm.jobs[index]["map"]= { center: { latitude: job.address.geocoords.lat, longitude: job.address.geocoords.lng }, zoom: 12 , dragging:false};
                    var marker = addMarker(job,vm.map.bounds);
                    vm.jobMarkers.push(marker);
                    bounds.extend(new maps.LatLng(marker.latitude,marker.longitude));

                    if(job.partials.length > 0){
                        console.log(job);
                    }

                });

            });
            function getCenter(job){
            if(!job){
                return {lat:0, long:0};
            }else{
                console.log(job);
                return {latitude:job.address.geocoords.lat, longitude:job.address.geocoords.lng};
            }

        }



        function addMarker(job, idKey){

            if (idKey == null) {
                idKey = "id";
            }

            var ret ={
                latitude: job.address.geocoords.lat,
                longitude: job.address.geocoords.lng

            };

            ret["idKey"]="marker"+job.id;
            ret["id"]="marker"+job.id;

            return ret;

        }


            function hide() {
                $mdDialog.hide();
            }


            vm.cancel = function () {

                $mdDialog.cancel();
            };

            function close() {
                $mdDialog.cancel();
            }

        }

})();