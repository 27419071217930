(function () {
    'use strict';

    angular
        .module('scheduleboard.users')
        .controller('LoginDialogController', LoginDialogController);

    LoginDialogController.$inject = ['$mdDialog', '$scope', '$state', 'AuthService', 'Boarder', 'APP_GLOBALS', 'UserPrefsService', '$mdToast', '$log'];

    function LoginDialogController($mdDialog, $scope, $state, AuthService, Boarder, APP_GLOBALS, UserPrefsService, $mdToast, $log) {

        var dialog = this;

        dialog.login = login;
        dialog.close = close;
        dialog.goToPasswordResetRequest = goToPasswordResetRequest;
        dialog.username = "";
        dialog.password = "";

        function hide() {
            $mdDialog.hide();
        }

        function close() {
            $mdDialog.cancel();
        }

        function goToPasswordResetRequest(){
            dialog.close();
            $state.go('passwordResetRequest');
        }

        function login() {
            if (angular.isUndefined(dialog.username) || angular.isUndefined(dialog.password)) {
                $log.log("no data");
            } else if (dialog.username === '' || dialog.password === '') {
                $log.log("no data");
            } else {
                dialog.username = dialog.username.trim().toLowerCase();
                // $log.log("data!");

                AuthService.login({username: dialog.username, password: dialog.password}).then(function (authenticated) {
                    $log.log("LoginCtrl :: auth status: ", authenticated);

                    // clear the input fields
                    dialog.username = '';
                    dialog.password = '';
                    $mdDialog.hide({username: dialog.username, password: dialog.password});
                    $state.go('resources', {reload: true});
                    // transition to landing page
                    // $state.go('scheduleboard.resources', {}, {reload: true});
                }, function (error) {
                    $mdToast.show($mdToast.simple().textContent('Login Failed.  Check your username and Password!'));
                });
            }
        }
    }
})();
