(function() {
  'use strict';

  angular
    .module('scheduleboard.jobs')
    .controller('JobDetailController', JobDetailController);

  JobDetailController.inject = [
    '$scope',
    '$rootScope',
    '$stateParams',
    'toastr',
    'PartialsService',
    'AuthService',
    'JobsService',
    'TasksService',
    'uiGmapGoogleMapApi',
    '$mdToast',
    'EmployeesService',
    'EmployeesService',
    'EquipmentService',
    'DocumentService',
    'NoteService',
    'API',
    '$log',
    'Upload',
    '$timeout',
    '$mdDialog',
    'JobPhoto',
    'JobDoc',
    'Job',
    'JobPocService',
    'Boarder',
    '$filter',
    'JobPoc',
    'ImageService'
  ];
  function JobDetailController(
    $scope,
    $rootScope,
    $stateParams,
    toastr,
    PartialsService,
    AuthService,
    JobsService,
    TasksService,
    uiGmapGoogleMapApi,
    $mdToast,
    EmployeesService,
    EquipmentService,
    DocumentService,
    NoteService,
    API,
    $log,
    Upload,
    $timeout,
    $mdDialog,
    JobPhoto,
    JobDoc,
    Job,
    JobPocService,
    Boarder,
    $filter,
    JobPoc,
    ImageService
  ) {
    var vm = this;

    var currentJobId = null;

    vm.job = {};
    vm.zoom = 12;
    vm.partials = [];
    vm.tasks = [];
    vm.documents = [];
    //vm.isManager = AuthService.isManager;
    vm.map = { center: { latitude: 45, longitude: -73 }, zoom: 10 };
    vm.map.options = { draggable: true, title: vm.job.name };
    vm.photos = [];
    vm.jobPhotos = [];
    vm.jobDocs = [];
    vm.isManager = '';
    vm.active = false;
    vm.manualAddress = false;
    /// FUNCTION DECLARATIONS ///
    vm.addNote = addNote;
    vm.addTask = addTask;
    vm.assignEmployeesToJobDay = assignEmployeesToJobDay;
    vm.assignEquipment = assignEquipment;
    vm.closeDialog = closeDialog;
    vm.createJobNote = createJobNote;
    vm.markJobComplete = markJobComplete;
    vm.hasAvatar = hasAvatar;
    vm.getAuthenticatedAvatarUrl = getAuthenticatedAvatarUrl;
    vm.hasMobileNumber = hasMobileNumber;
    vm.hasValidAddress = hasValidAddress;
    vm.isMe = isMe;
    vm.removeCrewMember = removeCrewMember;
    vm.removeEquipment = removeEquipment;
    vm.removeTask = removeTask;
    vm.removeNote = removeNote;
    vm.reportIssue = reportIssue;
    vm.saveEditedJob = saveEditedJob;
    vm.setTaskCompletion = setTaskCompletion;
    vm.showDialogToAddNote = showDialogToAddNote;
    vm.showDialogToEditNote = showDialogToEditNote;
    vm.showDialogToAddTask = showDialogToAddTask;
    vm.showDialogToEditTask = showDialogToEditTask;
    vm.setTaskCompletion = setTaskCompletion;
    vm.uploadDocument = uploadDocument;
    vm.uploadPhoto = uploadPhoto;
    vm.uploadMultiplePhotos = uploadMultiplePhotos;
    vm.deleteDocument = deleteDocument;
    vm.deletePhoto = deletePhoto;
    vm.isSelfOrManager = isSelfOrManager;
    vm.showDialogToEditJob = showDialogToEditJob;
    vm.addressSelected = addressSelected;
    vm.activeSelected = vm.job.active;
    vm.toggleJobStatus = toggleJobStatus;
    vm.toggleManual = toggleManual;
    vm.showDialogToAddJobPOC = showDialogToAddJobPOC;
    vm.showDialogToEditJobPOC = showDialogToEditJobPOC;
    vm.showDialogToViewJobPOC = showDialogToViewJobPOC;
    vm.saveEditedJobPOC = saveEditedJobPOC;
    vm.addJobPOC = addJobPOC;
    vm.deleteJobPOC = deleteJobPOC;
    vm.saveEditedJob = saveEditedJob;
    vm.canDeleteDocuments = canDeleteDocuments;
    vm.getJobPhotoThumbnailUrl = getJobPhotoThumbnailUrl;

    vm.windowOptions = { visible: false };
    vm.jobReturned = false;

    activate();

    ////////////////

    // map these functions but do not call them - they are called in the view!
    vm.isManager = AuthService.isManager;
    vm.isCustomer = AuthService.isCustomer;
    vm.isAdmin = AuthService.isAdmin;

    vm.canEditUserInfo = canEditUserInfo;
    vm.canEditUserRole = canEditUserRole;
    vm.canEditAccount = canEditAccount;
    vm.canEditJobInfo = canEditJobInfo;
    vm.canDeleteJobPhotos = canDeleteJobPhotos;

    vm.getFirstScheduledDate = getFirstScheduledDate;
    vm.getLastScheduledDate = getLastScheduledDate;
    // external-facing functions

    function getFirstScheduledDate() {
      if (vm.job.partials) {
        vm.job.partials.sort(function(a, b) {
          var dateA = new Date(a.release),
            dateB = new Date(b.release);
          return dateA - dateB;
        });

        return vm.job.partials.sort()[0].day;
      }
    }

    function getLastScheduledDate() {
      if (vm.job.partials) {
        vm.job.partials.sort(function(a, b) {
          var dateA = new Date(a.release),
            dateB = new Date(b.release);
          return dateA - dateB;
        });
        return vm.job.partials.sort()[vm.job.partials.length - 1].day;
      }
    }
    function canEditUserInfo() {
      return (
        vm.isSelf ||
        AuthService.isManager() ||
        AuthService.isCustomer() ||
        AuthService.isAdmin()
      );
    }

    function canEditUserRole() {
      return AuthService.isCustomer() || AuthService.isAdmin();
    }

    function canEditAccount() {
      return AuthService.isCustomer() || AuthService.isAdmin();
    }

    function canEditJobInfo() {
      return (
        AuthService.isManager() ||
        AuthService.isCustomer() ||
        AuthService.isAdmin()
      );
    }
function canDeleteDocuments() {
      return (
        AuthService.isManager() ||
        AuthService.isCustomer() ||
        AuthService.isAdmin()
      );
    }
    function canDeleteJobPhotos() {
      return (
        AuthService.isManager() ||
        AuthService.isCustomer() ||
        AuthService.isAdmin()
      );
    }

    function activate() {
      // grab our job id from the state
      currentJobId = $stateParams.jobId;
      //console.log(currentJobId);
      vm.token = $rootScope.getAccessToken();
      vm.user = $rootScope.getUser();
      vm.account = $rootScope.getActiveAccount();
      vm.accountId = vm.account.id;
      vm.isManager = AuthService.isManager;
      //console.log(vm.isManager);

      if (vm.account) {
        vm.accountName = vm.account.name;
        vm.accountUUID = vm.account.accountUUID;
      }
      vm.baseUrl = API.baseUrl; // load the data when this controller is fired
      loadEverything(currentJobId);
    }

    function getJobPhotoThumbnailUrl(originalPhotoUrl) {
      if (originalPhotoUrl) {
        return ImageService.getUrlForThumbnailImage(originalPhotoUrl);
      } else {
        return originalPhotoUrl;
      }
    }

    function saveEditedJobPOC(pocData) {
      //console.log(pocData);
      //vm.modal.hide();
      closeDialog();
      //console.log(pocData);
      // perform validation etc
      var data = pocData;

      //console.log(data);

      if (!data.firstName) {
        $mdToast.show(
          $mdToast.simple().textContent('Please enter a valid first name')
        );
      } else if (!data.lastName) {
        $mdToast.show(
          $mdToast.simple().textContent('Please enter a valid last name')
        );
      } else if (data.mobilePhone) {
        data.mobilePhone = $filter('bcTelephone')(data.mobilePhone, 'clean');
        if (
          isNaN(data.mobilePhone.trim()) ||
          data.mobilePhone.trim().length !== 10
        ) {
          $mdToast.show(
            $mdToast.simple().textContent('Please enter a valid Phone Number')
          );
          //return false;
        }
      } else if (data.officePhone) {
        data.officePhone = $filter('bcTelephone')(data.officePhone, 'clean');
        if (
          isNaN(data.officePhone.trim()) ||
          data.officePhone.trim().length !== 10
        ) {
          $mdToast.show(
            $mdToast.simple().textContent('Please enter a valid Phone Number')
          );
          //return false;
        }
      }
      // trim whitespace
      var d = {};
      d.id = data.id;
      d.jobid = data.jobId;

      d.firstName = data.firstName.trim();
      d.lastName = data.lastName.trim();
      if (data.email) {
        d.email = data.email.trim();
      }
      if (data.mobilePhone) {
        d.mobilePhone = data.mobilePhone.trim();
      }
      if (data.officePhone) {
        d.officePhone = data.officePhone.trim();
      }
      if (data.company) {
        d.company = data.company;
      }
      //console.log(d);
      //console.log(data);
      JobPoc.upsert(d).$promise.then(
        function(result) {
          console.log(result);
          loadEverything(currentJobId);
        },
        function(err) {
          console.log(err);
        }
      );
    }

    function deleteJobPOC(poc) {
      JobPoc.deleteById({ id: poc.id }).$promise.then(function(response) {
        //console.log("deleted!");
        //loadJobPocsByJobId(jobId);
        loadJobPocsByJobId(poc.jobId);
      });
    }
    function addJobPOC(pocData) {
      //vm.modal.hide();
      closeDialog();
      //console.log(pocData);
      // perform validation etc
      var data = vm.POCFormData;
      console.log(data);

      if (!data.firstName) {
        $mdToast.show(
          $mdToast.simple().textContent('Please enter a valid first name')
        );
      } else if (!data.lastName) {
        $mdToast.show(
          $mdToast.simple().textContent('Please enter a valid last name')
        );
      } else if (data.mobilePhone) {
        data.mobilePhone = $filter('bcTelephone')(data.mobilePhone, 'clean');
        if (
          isNaN(data.mobilePhone.trim()) ||
          data.mobilePhone.trim().length !== 10
        ) {
          $mdToast.show(
            $mdToast.simple().textContent('Please enter a valid Phone Number')
          );
          //return false;
        }
      } else if (data.officePhone) {
        data.officePhone = $filter('bcTelephone')(data.officePhone, 'clean');
        if (
          isNaN(data.officePhone.trim()) ||
          data.officePhone.trim().length !== 10
        ) {
          $mdToast.show(
            $mdToast.simple().textContent('Please enter a valid Phone Number')
          );
          //return false;
        }
      }
      // trim whitespace
      data.firstName = data.firstName.trim();
      data.lastName = data.lastName.trim();

      if (data.email) {
        data.email = data.email.trim();
      }
      if (data.mobilePhone) {
        data.mobilePhone = data.mobilePhone.trim();
      }
      if (data.officePhone) {
        data.officePhone = data.officePhone.trim();
      }
      if (data.company) {
        data.company = data.company.trim();
      }
      JobPocService.addJobPOC(data).then(function(result) {
        loadEverything(currentJobId);
      });
    }

    function addNote(noteData) {
      //vm.modal.hide();
      closeDialog();
      console.log(noteData);
      NoteService.addNote(noteData).then(function(result) {
        loadEverything(currentJobId);
      });
    }

    function isSelf(authorId) {
      //console.log(authorId);
      //console.log($rootScope.getUser());
      if ($rootScope.getUser().id == authorId) {
        return true;
      } else {
        return false;
      }
    }
    function isSelfOrManager(authorId) {
      //console.log(isSelf(authorId));
      return isSelf(authorId) || AuthService.isManager();
    }
    function addTask() {
      //vm.modal.hide();
      closeDialog();
      TasksService.addTask(vm.taskFormData).then(function(result) {
        loadEverything(currentJobId);
      });
    }

    function createJobNote() {
      // TODO show modal to get input?
      $mdToast.show(
        $mdToast.simple().textContent('createJobNote() not implemented!')
      );

      //$mdToast.show('createJobNote() not implemented!', 'top', false, 2500);
    }

    function addressSelected(data) {
      console.log(data.location);
    }

    function uploadMultiplePhotos(files, jobId, invalidFiles) {
      console.log('number of files: ' + files.length);
      if (files && files.length) {
        for (var i = 0; i < files.length; i++) {
          uploadPhoto(files[i], jobId, invalidFiles);
        }
      }
    }

    function uploadPhoto(file, jobId, invalidFiles) {
      var f = file;
      var uploadURL = encodeURI(
        API.baseUrl +
          '/JobPhotos/upload-photo?access_token=' +
          AuthService.getAccessToken() +
          '&accountId=' +
          $rootScope.getBusinessAccountId()
      );

      console.log('upload file function');
      console.log(file);
      if (file) {
        var data = { file: file, jobid: jobId, userId: $rootScope.getUser() };

        file.upload = Upload.upload({
          url: uploadURL,
          data: data,
          headers: { jobId: jobId }
        });

        file.upload.then(
          function(response) {
            $timeout(function() {
              //file.result = response.data;
              //var fileObj = response.data.result.files.file[0];
              console.log(response);
              //vm.user.avatarURL = API.baseUrl + "/Containers/" + fileObj.container + "/download/" + fileObj.name;
              //console.log(fileObj);
              //console.log(jobId);
              loadImagesByJobId(jobId);
              loadDocumentsByJobId(jobId);
              //console.log("RESULT!!",file.result);
            });
          },
          function(evt) {
            file.progress = Math.min(
              100,
              parseInt((100.0 * evt.loaded) / evt.total)
            );
          }
        );
      }
      //FileTransferService.uploadAvatarPhoto(file)
    }

    function uploadDocument(file, jobId, invalidFiles) {
      var f = file;
      var uploadURL = encodeURI(
        API.baseUrl +
          '/JobDocs/upload-document?access_token=' +
          AuthService.getAccessToken() +
          '&accountId=' +
          $rootScope.getBusinessAccountId()
      );

      //console.log(file);
      if (file) {
        var data = { file: file, jobid: jobId, userId: $rootScope.getUser() };

        file.upload = Upload.upload({
          url: uploadURL,
          data: data,
          headers: { jobId: jobId }
        });

        file.upload.then(
          function(response) {
            $timeout(function() {
              //file.result = response.data;
              //console.log(response.data.result);
              //var fileObj = response.data.result.files.file[0];

              //vm.user.avatarURL = API.baseUrl + "/Containers/" + fileObj.container + "/download/" + fileObj.name;
              //console.log(fileObj);
              loadDocumentsByJobId(jobId);
              //console.log("RESULT!!",file.result);
            });
          },
          function(response) {
            if (response.status > 0) {
              $scope.errorMsg = response.status + ': ' + response.data;
              console.log($scope.errorMsg);
            }
          },
          function(evt) {
            file.progress = Math.min(
              100,
              parseInt((100.0 * evt.loaded) / evt.total)
            );
          }
        );
      }
      //FileTransferService.uploadAvatarPhoto(file)
    }

    function assignEmployeesToJobDay() {
      //vm.modal.hide();

      var employeesToAdd = _.filter(vm.employees, { checked: true });

      // remove all existing employees
      PartialsService.removeAllEmployeesFromJobDay(
        currentJobId,
        vm.data.selectedDate
      ).then(function(result) {
        // THEN add assigned employees
        PartialsService.assignEmployeesToJobDay(
          employeesToAdd,
          currentJobId,
          vm.data.selectedDate
        ).then(function(results) {
          loadEverything(currentJobId);
        });
      });
    }

    // TO FIX
    function assignEquipment() {
      //vm.modal.hide();

      // TODO Iterate through, update partial with selected employees
      _.forEach(vm.equipment, function(item) {
        if (item.checked) {
          LogService.log(
            'job-detail.controller.js',
            'assignEquipment()',
            'assigning ' + item.name + ' to job ' + vm.partial.job.name
          );
          PartialsService.assignEquipment(item);
        }
      });
    }
    function loadEverything(jobId) {
      //console.log("LOADING EVERYTHING WITH ", jobId);
      loadJobById(jobId);
      loadImagesByJobId(jobId);
      loadDocumentsByJobId(jobId);
      loadJobPocsByJobId(jobId);

      loadMap();
    }

    function loadMap() {
      uiGmapGoogleMapApi.then(function(maps) {
        maps.visualRefresh = true;
      });
    }
    function loadJobById(jobId) {
      //console.log("load the job!");
      // load this job

      JobsService.getJobById(jobId, true, false).then(
        function(job) {
          //console.log(job);
          vm.job = job;
          console.log(job);
          vm.map.center = {
            latitude: vm.job.address.geocoords.lat,
            longitude: vm.job.address.geocoords.lng
          };
          vm.jobMarker = {
            coords: {
              latitude: vm.job.address.geocoords.lat,
              longitude: vm.job.address.geocoords.lng
            }
          };
          //console.log(vm.job);

          if (vm.job.address !== undefined) {
            vm.manualAddress = true;
          }
          //console.log("about to load partials");
          vm.job.partials.forEach(function(partial) {
            if (partial.crew) {
              partial.crew.forEach(function(employee) {
                employee.fullName =
                  employee.firstName + ' ' + employee.lastName;
                if (hasAvatar(employee)) {
                  //var fullImgUrl = "";
                  //const authInfo = "?access_token=" + AuthService.getAccessToken();
                  //const accountInfo = "&accountId=" + vm.account.id;
                  //const fullImgUrl = vm.baseUrl + employee.avatarUrl;
                  //console.log("AVATAR URL IN PROFILE PAGE", employee.avatarUrl);
                  //employee.avatarUrl = fullImgUrl + authInfo + accountInfo;
                  //employee.avatarUrl = fullImgUrl;
                }
              });
            }
          });
          vm.active = vm.job.active;
          // set some counts for convenience
          vm.job.employeeCount = _.sumBy(vm.job.partials, function(p) {
            return angular.isDefined(p.crew) ? p.crew.length : 0;
          });
          vm.job.equipmentCount = _.sumBy(vm.job.partials, function(p) {
            return angular.isDefined(p.equipment) ? p.equipment.length : 0;
          });

          //console.log("PARTIALS:", vm.job.partials);

          //vm.mapURL = getMapURL();

          // alert the ion-refesher that we're done
          //$scope.$broadcast('scroll.refreshComplete');

          //vm.map=map;
          vm.jobReturned = true;
        },
        function(err) {
          console.log(err);
          //console.log(err);
        }
      );
    }

    function deleteDocument(doc) {

            var confirm = $mdDialog.confirm()
        .title('Delete Document?')
        .textContent('Are you sure?  This action cannot be undone.')
        .ok('Confirm')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        $mdToast.show($mdToast.simple().textContent('Document Deleted!'));
         JobDoc.deleteById({ id: doc.id }).$promise.then(function(response) {
        console.log('deleted!');
        loadDocumentsByJobId(doc.jobId);
        cb();
      });
      }, function () {
        $scope.status = 'You cancelled';
      });
    }


    function showDialogToViewJobPOC(jobPoc) {
      vm.POCFormData = jobPoc;
      $mdDialog
        .show({
          bindToController: true,
          locals: {
            POCFormData: vm.POCFormData,
            currentJobId: vm.currentJobId,
            closeDialog: vm.closeDialog,
            vm: vm
          },
          controller: angular.noop,
          controllerAs: 'vm',
          templateUrl: 'app/scheduleboard/jobs/poc-view-dialog.tmpl.html',
          clickOutsideToClose: true
        })
        .then(
          function() {},
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );

      // show modal
      //openModalForTemplate('app/scheduleboard/jobs/new-job-note-modal.html');
    }

    function showDialogToEditJobPOC(jobPoc) {
      vm.POCFormData = jobPoc;
      $mdDialog
        .show({
          bindToController: true,
          locals: {
            POCFormData: vm.POCFormData,
            currentJobId: vm.currentJobId,
            saveEditedJobPOC: vm.saveEditedJobPOC,
            closeDialog: vm.closeDialog,
            vm: vm
          },
          controller: angular.noop,
          controllerAs: 'vm',
          templateUrl: 'app/scheduleboard/jobs/poc-edit-dialog.tmpl.html',
          clickOutsideToClose: true
        })
        .then(
          function() {
            $mdToast.show($mdToast.simple().textContent('Note Created!'));
          },
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );

      // show modal
      //openModalForTemplate('app/scheduleboard/jobs/new-job-note-modal.html');
    }

    function showDialogToAddJobPOC() {
      vm.POCFormData = {
        jobId: currentJobId,
        body: ''
      };
      $mdDialog
        .show({
          bindToController: true,
          locals: {
            POCFormData: vm.POCFormData,
            currentJobId: vm.currentJobId,
            addJobPOC: vm.addJobPOC,
            closeDialog: vm.closeDialog,
            vm: vm
          },
          controller: angular.noop,
          controllerAs: 'vm',
          templateUrl: 'app/scheduleboard/jobs/poc-dialog.tmpl.html',
          clickOutsideToClose: true
        })
        .then(
          function() {
            $mdToast.show($mdToast.simple().textContent('Note Created!'));
          },
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );

      // show modal
      //openModalForTemplate('app/scheduleboard/jobs/new-job-note-modal.html');
    }

    function deletePhoto(photo, cb) {
      //var dataObj = {id:doc.id, publicId: doc.publicId};
      //console.log(photo);
      var confirm = $mdDialog.confirm()
        .title('Delete Photo?')
        .textContent('Are you sure?  This action cannot be undone.')
        .targetEvent(cb)
        .ok('Confirm')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        $mdToast.show($mdToast.simple().textContent('Photo Deleted!'));
            JobPhoto.deleteById({ id: photo.id }).$promise.then(function(response) {
        //console.log("deleted!");
        loadImagesByJobId(vm.job.id);
        cb();
      });
      }, function () {
        $scope.status = 'You cancelled';
      });
    }

    function toggleJobStatus(activeState) {
      //                    console.log(vm.job.active);
      //if(vm.job.active==true){
      //vm.job.active = false;
      //}else{
      //    vm.job.active = true;
      //}
      console.log(vm.job);
      console.log(vm.job.active);
      JobsService.setActiveStatus(vm.job, vm.job.active).then(function(
        result,
        err
      ) {
        if (err) {
          console.log('error', err);
        } else {
          console.log('result', result);
        }
      });
    }

    function loadDocumentsByJobId(jobId) {
      vm.jobDocs = [];
      JobDoc.find({ filter: { where: { jobId: jobId } } }).$promise.then(
        function(jobDocs) {
          jobDocs.forEach(function(element) {
            //console.log(element);
            var doc = {
              jobId: element.jobId,
              id: element.id,
              url: element.secureUrl,
              fileName: element.fileName,
              type: element.contentType,
              format: element.format,
              publicId: element.publicId,
              downloadUrl: element.downloadUrl,
              createdAt: element.createdAt
            };
            console.log('url: ');
            console.log(element.downloadUrl);
            vm.jobDocs.push(doc);
          });
          //console.log(vm.jobPhotos);

          //vm.jobPhotos.forEach(function(photo){})
          //console.log(jobPhotos);
        }
      );
      //console.log("LOADING IMAGES!");
      // load documents for this job
    }

    function loadJobPocsByJobId(jobId) {
      vm.JobPocs = [];
      JobPocService.getJobPOCsByJobId(jobId).then(function(pocs) {
        //console.log(pocs);
        vm.JobPocs = pocs;
      });

      //console.log(vm.JobPocs);
    }

    function loadImagesByJobId(jobId) {
      //console.log(jobId);
      var jP = [];
      //vm.jobPhotos = [];
      var deletable;
      if (canDeleteJobPhotos()) {
        deletable = true;
      } else {
        deletable = false;
      }
      //console.log(jobId);
      JobPhoto.find({ filter: { where: { jobId: jobId } } }).$promise.then(
        function(jobPhotos) {
          //console.log(jobPhotos);
          jobPhotos.forEach(function(element) {
            var photo = {
              id: element.id,
              url: element.secureUrl,
              thumbUrl: getJobPhotoThumbnailUrl(element.secureUrl),
              deletable: deletable
            };
            jP.push(photo);
          });
          vm.jobPhotos = jP;
          //console.log(vm.jobPhotos);

          //vm.jobPhotos.forEach(function(photo){})
          //console.log(jobPhotos);
        }
      );
      //console.log("LOADING IMAGES!");
      // load documents for this job
      /*DocumentService.getImagesByJobId(jobId).then(function(images){
                  //console.log(images);

                  console.log("GOT IMAGES FROM DOCUMENT SERVICE!");
                  console.log(images);
                  var toSort = [];

                  images.forEach(function (i){
                      toSort.push({
                          "src" : API.baseUrl + "/Containers/" + i.container + "/download/" + i.name + "?access_token=" + vm.token +"&accountId="+vm.account.id,
                          // "thumb": "",
                          "sub" : "uploaded on " + moment(i.createdAt).format('ddd MMM Do'),
                          "createdAt": i.createdAt
                      });
                  });

                  //console.log(images);

                  vm.photos = _.orderBy(toSort, ["createdAt"], ["desc"]);
                  //console.log(vm.photos);
              });*/
    }

    function markJobComplete() {
      // TODO show modal to get input?
      //ionicToast.show('markJobComplete() not implemented!', 'top', false, 2500);
      $mdToast.show(
        $mdToast.simple().textContent('markJobComplete() not implemented!')
      );
    }

    // TO FIX
    function removeCrewMember(personToRemove) {
      JobsService.removeCrewMemberFromJob(personToRemove, vm.job).then(function(
        updatedJob
      ) {
        LogService.log(
          'job-detail.controller.js',
          'removeCrewMember()',
          'removed ' + personToRemove + ' from ' + vm.job.name
        );
        vm.job = updatedJob;

        // remove crew member from job data
        // _.remove(vm.job.crew, { id: personToRemove.id});
      });
    }

    function removeEquipment() {
      // TODO
      $mdToast.show(
        $mdToast.simple().textContent('removeEquipment() not implemented!')
      );
    }

    function removeTask(task) {
      TasksService.deleteTask(task).then(function(result) {
        $mdToast.show($mdToast.simple().textContent('Task Deleted!'));
        loadEverything(currentJobId);
      });
    }
    function removeNote(note) {
      // TODO show modal to get input?
      NoteService.deleteNote(note).then(function(result) {
        $mdToast.show($mdToast.simple().textContent('Note Deleted!'));
        loadEverything(currentJobId);
      });
      //ionicToast.show('removeTask() not implemented!', 'top', false, 2500);
    }

    function reportIssue() {
      // TODO show modal to get input?
      //ionicToast.show('reportIssue() not implemented!', 'top', false, 2500);
      $mdToast.show(
        $mdToast.simple().textContent('reportIssue() not implemented!')
      );
    }

    function setTaskCompletion(taskToUpdate, completion) {
      TasksService.setTaskCompletion(taskToUpdate, completion).then(function(
        task
      ) {
        console.log(task);
        taskToUpdate = task;
        // loadTasksByJobId(currentJobId);
      });
    }

    function setTaskCompletion(taskToUpdate, completion) {
      TasksService.setTaskCompletion(taskToUpdate, completion).then(function(
        task
      ) {
        // taskToUpdate = task;
        // loadTasksByJobId(currentJobId);
        loadEverything(currentJobId);
      });
    }

    function hasValidAddress(user) {
      if (vm.manualAddress) {
        if (_.has(user, 'address')) {
          var a = user.address;

          var valid =
            _.has(a, 'zipCode') &&
            _.has(a, 'state') &&
            _.has(a, 'city') &&
            _.has(a, 'streetAddress');

          if (valid) {
            valid =
              valid &&
              !(_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress)) &&
              !(_.isNull(a.city) || _.isEmpty(a.city)) &&
              !(_.isNull(a.state) || _.isEmpty(a.state)) &&
              !(_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
          }

          return valid;
        }
        return false;
      } else {
        if (user.location) {
          return true;
        } else {
          return false;
        }
      }
    }

    function addressEntered(user) {
      if (vm.manualAddress) {
        if (_.has(user, 'address')) {
          var a = user.address;

          var empty =
            (_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress)) &&
            (_.isNull(a.city) || _.isEmpty(a.city)) &&
            (_.isNull(a.state) || _.isEmpty(a.state)) &&
            (_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
          if (empty) {
            //console.log("empty!!");
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        if (user.location == null) {
          return false;
        } else {
          return true;
        }
      }
    }

    function toggleManual() {
      if (vm.manualAddress == true) {
        vm.manualAddress = false;
      } else {
        vm.manualAddress = true;
      }
    }

    function saveEditedJob() {
      var data = vm.editJobData;

      if (addressEntered(data)) {
        if (hasValidAddress(data)) {
          if (!vm.manualAddress) {
            //console.log("NOT MANUAL");
            //console.log("DATA RETURNED", data);
            data.address = {};
            //console.log(data.location.split(","));
            data.address.streetAddress = data.location.split(',')[0];
            data.address.city = data.location.split(',')[1].trim();
            data.address.state = data.location
              .split(',')[2]
              .trim()
              .split(' ')[0]
              .trim();
            data.address.zipCode = data.location
              .split(',')[2]
              .trim()
              .split(' ')[1]
              .trim();

            data.address.geocoords = {};
            data.address.geocoords.lng = data.geocoords.lng;
            data.address.geocoords.lat = data.geocoords.lat;

            // console.log(data.locationDetails);
            //console.log(data.address.streetAddress,data.address.city,data.address.state,data.address.zipCode);
            /*var postal_code_object = data.locationDetails.address_components.filter(function(object) {
                                          return object.types == "postal_code";

                                      });

                                      data.address.zipCode = postal_code_object[0].long_name;
                                      console.log(data);*/
          } else {
            data.address.geocoords = {};
          }

          //console.log(vm.job);
          //console.log(vm.job.locationDetails);
          //console.log("Create");
          // Boarder.getCurrent().$promise
          //.then(function (user) {
          //console.log(user.accountId);
          //  vm.editedJobData.accountId = parseInt(user.accountId);
          Job.upsert(data).$promise.then(function(results) {
            console.log(results);

            //console.log(results);
            $mdToast.show($mdToast.simple().textContent('Job Edited!'));
            $mdDialog.cancel();
            loadEverything(currentJobId);

            // $state.reload();
          });
          //});
        } else {
          $mdToast.show($mdToast.simple().textContent('Invalid Address'));
        }
      } else {
        if (data.address) {
          data.address.streetAddress = '';
          data.address.city = '';
          data.address.zipCode = '';
          data.address.state = '';
          if (data.address.geocoords) {
            data.address.geocoords.lat = '';
            data.address.geocoords.lng = '';
          }
          data.address = null;
        }

        //Boarder.getCurrent().$promise
        //  .then(function (user) {
        //console.log(user.accountId);
        //    vm.editedJobData.accountId = parseInt(user.accountId);
        Job.upsert(data).$promise.then(function(results) {
          //console.log(results);
          $mdToast.show($mdToast.simple().textContent('Job Edited!'));
          $mdDialog.cancel();
          loadEverything(currentJobId);

          // $state.reload();

          //      if (callbackFn) {
          //        callbackFn()
          //  }
          //});
        });
      }

      //vm.editedJobData
      //vm.modal.hide();

      // sanity checks (for backend) which won't take a null address...
      // vm.data.number = vm.data.number.toString();
    }

    function hasAvatar(employee) {
      if (_.has(employee, 'avatarUrl')) {
        return !(_.isNull(employee.avatarUrl) || _.isEmpty(employee.avatarUrl));
      }
      return false;
    }

    function getAuthenticatedAvatarUrl(employee) {
      if (hasAvatar(employee)) {
        return (
          employee.avatarUrl +
          '?access_token=' +
          AuthService.getAccessToken() +
          '&accountId=' +
          $rootScope.getActiveAccount().id
        );
      }
    }

    function hasMobileNumber(employee) {
      return _.has(employee, 'mobilePhone')
        ? employee.mobilePhone !== null
        : false;
    }

    function hasValidAddress(job) {
      if (_.has(job, 'address')) {
        var a = job.address;

        var valid =
          _.has(a, 'zipCode') &&
          _.has(a, 'state') &&
          _.has(a, 'city') &&
          _.has(a, 'streetAddress');

        if (valid) {
          valid =
            valid &&
            !(_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress)) &&
            !(_.isNull(a.city) || _.isEmpty(a.city)) &&
            !(_.isNull(a.state) || _.isEmpty(a.state)) &&
            !(_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
        }

        return valid;
      }
      return false;
    }

    function isMe(boarderId) {
      return boarderId === AuthService.getUser().id;
    }

    function showModalToAssignEmployees() {
      vm.data = { selectedDate: new Date() };

      // update our employee list anytime user chooses a new date
      $scope.$watch('vm.data.selectedDate', function(newDate, oldDate) {
        // load fresh list of employees
        EmployeesService.getAllEmployeesWithAllPartialsForJobDay(
          vm.job.id,
          newDate
        ).then(function(employees) {
          // ensure the ones already assigned appear as checked in the UI
          _.forEach(employees, function(e) {
            e.checked = e.partials.length > 0 ? true : false;
          });
          vm.employees = employees;
        });
      });

      // show modal
      openModalForTemplate(
        'app/scheduleboard/jobs/assign-employees-modal.html'
      );
    }

    // TO TEST
    function showModalToAssignEquipment() {
      vm.data = { selectedDate: new Date() };

      // update our employee list anytime user chooses a new date
      $scope.$watch('vm.data.selectedDate', function(newDate, oldDate) {
        // load fresh list of equipment
        // see if vm.job.partials has a partial for today
        // if it does, then iterate through
        // set equipment.checked = true
        // push the equipment onto array
        // OR OR OR
        // for each piece of equipment
        // if _.find(vm.job.partials[today], function compareIds) based on equipment ID is not undefined
        // set that piece of equipment's checked value to true
        // else
        // set that piece of equipment's checked value to false
      });

      // show modal
      openModalForTemplate(
        'app/scheduleboard/jobs/assign-equipment-modal.html'
      );
    }

    function showDialogToEditNote(currentNote) {
      // fetch any data we might need
      vm.noteFormData = _.cloneDeep(currentNote);

      $mdDialog
        .show({
          bindToController: true,
          locals: {
            noteFormData: vm.noteFormData,
            currentJobId: vm.currentJobId,
            addNote: vm.addNote,
            closeDialog: vm.closeDialog,
            vm: vm
          },
          controller: angular.noop,
          controllerAs: 'vm',
          templateUrl: 'app/scheduleboard/jobs/note-dialog.tmpl.html',
          clickOutsideToClose: true
        })
        .then(
          function() {
            $mdToast.show($mdToast.simple().textContent('Note Edited!'));
          },
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );
      // show modal
      //openModalForTemplate('app/scheduleboard/jobs/new-job-note-modal.html');
    }

    function showDialogToAddNote() {
      vm.noteFormData = {
        jobId: currentJobId,
        body: ''
      };
      $mdDialog
        .show({
          bindToController: true,
          locals: {
            noteFormData: vm.noteFormData,
            currentJobId: vm.currentJobId,
            addNote: vm.addNote,
            closeDialog: vm.closeDialog,
            vm: vm
          },
          controller: angular.noop,
          controllerAs: 'vm',
          templateUrl: 'app/scheduleboard/jobs/note-dialog.tmpl.html',
          clickOutsideToClose: true
        })
        .then(
          function() {
            $mdToast.show($mdToast.simple().textContent('Note Created!'));
          },
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );

      // show modal
      //openModalForTemplate('app/scheduleboard/jobs/new-job-note-modal.html');
    }

    // NOTE: There were unresolved issues getting the action sheet to close, no matter where I put
    // return true in the buttonClicked function, so this is not currently being used

    function showDialogToAddTask() {
      // fetch any data we might need
      vm.taskFormData = {
        complete: false,
        jobId: currentJobId,
        startDate: new Date()
      };

      $mdDialog
        .show({
          bindToController: true,
          locals: {
            taskFormData: vm.taskFormData,
            addTask: vm.addTask,
            closeDialog: vm.closeDialog,
            currentJobId: vm.currentJobId
          },
          controller: angular.noop,
          controllerAs: 'vm',
          templateUrl: 'app/scheduleboard/jobs/task-dialog.tmpl.html',
          clickOutsideToClose: true
        })
        .then(
          function() {
            $mdToast.show($mdToast.simple().textContent('Task Created!'));
          },
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );
    }

    function showDialogToEditTask(currentTask) {
      // fetch any data we might need
      vm.taskFormData = _.cloneDeep(currentTask);

      $mdDialog
        .show({
          bindToController: true,
          locals: {
            taskFormData: vm.taskFormData,
            addTask: vm.addTask,
            closeDialog: vm.closeDialog,
            currentJobId: vm.currentJobId
          },
          controller: angular.noop,
          controllerAs: 'vm',
          templateUrl: 'app/scheduleboard/jobs/task-dialog.tmpl.html',
          clickOutsideToClose: true
        })
        .then(
          function() {
            $mdToast.show($mdToast.simple().textContent('Task Created!'));
          },
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );
    }

    function showDialogToEditJob() {
      // copy the existing object so uncommitted changes aren't saved
      vm.editJobData = JSON.parse(JSON.stringify(vm.job));

      // prep the right formats for the input fields
      vm.editJobData.number = Number(vm.editJobData.number);
      vm.editJobData.scheduledStartDate = new Date(
        vm.editJobData.scheduledStartDate
      );
      vm.editJobData.actualStartDate = new Date(vm.editJobData.actualStartDate);

      $mdDialog
        .show({
          bindToController: true,
          locals: {
            editJobData: vm.editJobData,
            closeDialog: vm.closeDialog,
            saveEditedJob: vm.saveEditedJob,
            manualAddress: vm.manualAddress,
            addressSelected: vm.addressSelected,
            toggleManual: vm.toggleManual
          },
          controller: angular.noop,
          controllerAs: 'vm',
          templateUrl: 'app/scheduleboard/jobs/job-detail-edit.tmpl.html',
          clickOutsideToClose: true
        })
        .then(
          function() {
            //$mdToast.show($mdToast.simple().textContent('Job Edited!'));
            //$mdDialog.hide();
          },
          function() {
            $scope.status = 'You cancelled the dialog.';
          }
        );
    }

    function hideDialog() {
      $mdDialog.hide();
    }
    function closeDialog() {
      $mdDialog.cancel();
    }
    function openModalForTemplate(template_path) {
      $mdDialog
        .show({
          scope: $scope,
          clickOutsideToClose: true,
          preserveScope: true, // do not forget this if use parent scope
          templateUrl: template_path,
          parent: angular.element(document.body),
          targetEvent: ev,
          fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(function() {
          console.log('You said the information was "' + answer + '".');
        });
    }
  }
})();
