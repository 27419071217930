(function () {
    'use strict';

    angular
    .module('scheduleboard.toolbar')
    .directive('sbToolbar', sbToolbar);

  /** @ngInject */
    function sbToolbar() {
        var directive = {
            restrict: 'E',
            templateUrl: 'app/scheduleboard/toolbar/toolbar.tmpl.html',
            scope: {
                creationDate: '='
            },
            controller: 'ToolbarController',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

    /** @ngInject */
   /* function ToolbarController(moment) {
      var vm = this;

      // "vm.creationDate" is available by directive option "bindToController: true"
      vm.relativeDate = moment(vm.creationDate).fromNow();
    } */
    }

})();
