(function() {
    'use strict';

    angular
        .module('scheduleboard.employees')
        .config(moduleConfig);

    /* @ngInject */
    function moduleConfig() {


    }
})();
