(function() {
    'use strict';

    angular
        .module('scheduleboard.schedule')
        .controller('PartialCreateDialogController', PartialCreateDialogController);

    /* @ngInject */
    PartialCreateDialogController.inject = ['$scope', '$log', 'PartialsService', '$mdToast', 'EmployeesService', '$mdDialog', 'weekDays', '$mdDateRangePicker', '$mdStepper', 'BoarderService', 'EquipmentService'];

    function PartialCreateDialogController($scope, $log, PartialsService, $mdToast, EmployeesService, $mdDialog, weekDays, $mdDateRangePicker, $mdStepper, BoarderService, EquipmentService) {

        var vm = this;
        var tmpToday = new Date();
        vm.allEmployees = [];
        vm.selectedEmployees = [];
        vm.allEquipment = [];
        vm.selectedEquipment = [];

        // $log.log('PartialCreateDialogController :: fetching employees...')
        EmployeesService.getAllEmployees(true).then(function(emps) {
            // $log.log('PartialCreateDialogController :: Received list of ' + emps.length + ' employees')
            vm.allEmployees = emps;
        });

        // $log.log('PartialCreateDialogController :: fetching equipment...')
        EquipmentService.getAllEquipment(true).then(function(equip) {
            // $log.log('PartialCreateDialogController :: Received list of ' + equip.length + ' equipment')
            vm.allEquipment = equip;
        });

        vm.weekDays = weekDays;
        // $log.log('PartialCreateDialogController :: initializing with weekdays:', vm.weekDays);

        vm.maxDate = new Date(vm.weekDays[Object.keys(vm.weekDays).length - 1]);
        vm.minDate = new Date(vm.weekDays[0]);
        vm.onlyWeekdaysPredicate = onlyWeekdaysPredicate;
        vm.addEmployees = addEmployees;
        vm.addEquipment = addEquipment;
        vm.previous = previous;
        vm.submit = submit;
        vm.myDate = '';


        vm.toggle = function(item, list) {
            var idx = list.indexOf(item);
            if (idx > -1) {
                list.splice(idx, 1);
            } else {
                list.push(item);
            }
        };

        vm.exists = function(item, list) {
            return list.indexOf(item) > -1;
        };

        vm.mdLocalizationMap = {
            'Mon': 'Mon*',
            'This Week': 'Current Week',
        };

        vm.selectedRange = {
            selectedTemplate: false,
            selectedTemplateName: '',
            dateStart: vm.minDate,
            dateEnd: vm.maxDate,
            showTemplate: false,
            fullscreen: false
        };

        vm.onSelect = function(scope) {
            // $log.log('PartialCreateDialogController :: onSelect() received', $scope.selectedRange.selectedTemplateName);
            return $scope.selectedRange.selectedTemplateName;
        };

        vm.isDisabled = function($date) {
            var day = moment($date).day();
            if (day == 6 || day == 0) {
                return true;
            } else {
                return false;
            }
        }

        vm.mdCustomTemplates = [{
                name: "Last 3 Months",
                dateStart: new Date((new Date()).setMonth(tmpToday.getMonth() - 3)),
                dateEnd: new Date()
            },
            {
                name: "Last 6 Months",
                dateStart: new Date((new Date()).setMonth(tmpToday.getMonth() - 6)),
                dateEnd: new Date()
            }
        ];

        vm.pick = function($event, showTemplate) {
            // $log.log('PartialCreateDialogController :: pick()');
            $scope.selectedRange.showTemplate = showTemplate;
            $mdDateRangePicker.show({
                targetEvent: $event,
                model: $scope.selectedRange
            }).then(function(result) {
                if (result) $scope.selectedRange = result;
            })
        };

        vm.close = close;

        function close() {
            $mdDialog.cancel();
        }

        function addEmployees() {
            // $log.log('PartialCreateDialogController :: addEmployees(): ', vm.selectedEmployees);
            var stepper = $mdStepper('add-work-stepper');
            stepper.next();
        }

        function addEquipment() {
            // $log.log('PartialCreateDialogController :: addEquipment(): ', vm.selectedEmployees);
            var stepper = $mdStepper('add-work-stepper');
            stepper.next();
        }

        function submit() {
            // $log.log('PartialCreateDialogController :: submit()');
            var workData = {};
            workData.range = vm.selectedRange;
            workData.crew = vm.selectedEmployees;
            workData.equipment = vm.selectedEquipment;
            $mdDialog.hide(workData);
        }

        function previous() {
            // $log.log('PartialCreateDialogController :: previous()');
            var stepper = $mdStepper('add-work-stepper');
            stepper.back();
        }

        function onlyWeekdaysPredicate(date) {
            var day = date.getDay();
            return day === 1 || day === 2 || day === 3 || day === 4 || day === 5;
        };
    }
})();