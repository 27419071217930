/* recommended */
/* spinner.directive.js */

/**
 * @desc spinner directive that can be used anywhere across apps at a company named Acme
 * @example <div acme-shared-spinner></div>
 */
angular
    .module('scheduleboard.jobs')
    .directive('jobCard', jobCard);

function jobCard() {
    return {
      restrict: 'E',
      templateUrl: 'app/scheduleboard/jobs/jobCard.tmpl.html',
      scope: {
        job: '=',
        theme: '@'
      },
      controller: "JobCardController",
      controllerAs: "vm"
    }
}