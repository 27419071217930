(function () {
    'use strict';

    angular
        .module('scheduleboard.services')
        .factory('ImageService', ImageService);

    ImageService.inject = ['$window'];

    function ImageService($window) {
        var service = {
            getUrlForSmallAvatarImage: getUrlForSmallAvatarImage,
            getUrlForLargeAvatarImage: getUrlForLargeAvatarImage,
            getUrlForScreenFillImage: getUrlForScreenFillImage,
            getUrlForThumbnailImage: getUrlForThumbnailImage
        };

        return service;

        // FUNCTIONS //////////////

        function getUrlForSmallAvatarImage(imgUrl) {
            return getUrlForAvatarImage(imgUrl, 40, 40);
        }

        function getUrlForLargeAvatarImage(imgUrl) {
            return getUrlForAvatarImage(imgUrl, 150, 150);
        }

        // returns a url that will
        // 1. find the face in the image
        // 2. create a circular thumbnail from it
        // 3. size it to resolution desired
        function getUrlForAvatarImage(imgUrl, w_px, h_px) {
            if (imgUrl) {
                // these are the image transforms for cloudinary
                // https://cloudinary.com/documentation/image_transformations#fetching_images_from_remote_locations
                // w_250    width 250px
                // h_250    height 250px
                // c_thumb  create thumbnail (requires gravity param - such as g_face)
                // g_face   finds face and centers image on it
                // r_max    full radiues - creates circle image
                // dpr_2.0  device to pixel ratio - 2 is good for retina displays
                const cloudinaryTransform = "w_250,h_250,c_thumb/" + "w_" + w_px + ",h_" + h_px + ",r_max/dpr_2.0";
                return buildCloudinaryUrlWithTransform(imgUrl, cloudinaryTransform);
            }
            return "";
        }

        function getUrlForScreenFillImage(imgUrl) {
            return getUrlForScaledImage(imgUrl, $window.innerWidth, $window.innerHeight);
        }

        function getUrlForScaledImage(imgUrl, max_width_px, max_height_px) {
            if (imgUrl) {
                // these are the image transforms for cloudinary
                // https://cloudinary.com/documentation/image_transformations#fetching_images_from_remote_locations
                // dpr_2.0      device to pixel ratio - 2 is good for retina displays
                // q_auto:eco  sets quality to decent, reduces download size

                const maxWidthStr = Number.isInteger(max_width_px) ? "w_" + max_width_px : "";
                const maxHeightStr = Number.isInteger(max_height_px) ? "h_" + max_height_px : "";

                var cloudinaryTransform = "c_limit,dpr_2.0,q_auto:eco";
                cloudinaryTransform += maxWidthStr ? "," + maxWidthStr : "";
                cloudinaryTransform += maxHeightStr ? "," + maxHeightStr : "";

                return buildCloudinaryUrlWithTransform(imgUrl, cloudinaryTransform);
            }
            return "";
        }

        function getUrlForThumbnailImage(imgUrl) {
            return getUrlForFilledImage(imgUrl, 140, 140);
        }

        function getUrlForFilledImage(imgUrl, max_width_px, max_height_px) {
            if (imgUrl) {
                // these are the image transforms for cloudinary
                // https://cloudinary.com/documentation/image_transformations#fetching_images_from_remote_locations
                // w_140    width 140px
                // c_fill   scales images to fill dimensions
                // g_auto   crops the image in a "smart" fashion
                // dpr_2.0  device to pixel ratio - 2 is good for retina displays
                // q_auto:eco  sets quality to decent, reduces download size

                const maxWidthStr = Number.isInteger(max_width_px) ? "w_" + max_width_px : "";
                const maxHeightStr = Number.isInteger(max_height_px) ? "h_" + max_height_px : "";

                var cloudinaryTransform = "c_fill,g_auto,dpr_2.0,q_auto:eco";
                cloudinaryTransform += maxWidthStr ? "," + maxWidthStr : "";
                cloudinaryTransform += maxHeightStr ? "," + maxHeightStr : "";

                return buildCloudinaryUrlWithTransform(imgUrl, cloudinaryTransform);
            }
            return "";
        }

        function buildCloudinaryUrlWithTransform(originalAvatarUrl, transformStr) {
            if (originalAvatarUrl && transformStr) {
                // we need to insert the transform prior to the PATH of the image
                // https://res.cloudinary.com/schedule-board/image/upload/<transformStr>/v1531362137/avatars/jxxmlepqqrcuwfz7u3ry.png
                const strToLookFor = 'image/upload/';
                return originalAvatarUrl.replace(strToLookFor, strToLookFor + transformStr + '/');
            }
            return originalAvatarUrl;
        }
    }
})();
