(function () {
    'use strict';

    angular
        .module('scheduleboard.equipment')
        .controller('EquipmentDialogController', EquipmentDialogController);

    EquipmentDialogController.$inject = ['$mdDialog', '$scope', '$state', '$log', 'Boarder', 'AuthService', '$mdToast', 'EquipmentService', 'callbackFn'];

    function EquipmentDialogController($mdDialog, $scope, $state, $log, Boarder, AuthService, $mdToast, EquipmentService, callbackFn) {

        var vm = this;
        // var job = passedJob;

        // FUNCTION DECLARATIONS

        vm.hide = hide;
        vm.submit = submit;
        vm.equipment = {};

        function hide() {
            $mdDialog.hide();
        }

        vm.cancel = function () {
            $mdDialog.cancel();
        };

        function close() {
            $mdDialog.cancel();
        }

        function submit() {
            if (vm.equipment.name) {
                EquipmentService.addEquipment(vm.equipment).then(function (results) {
                    $mdToast.show($mdToast.simple().textContent('Equipment Added!'));
                    $mdDialog.cancel();

                    if (callbackFn) {
                        callbackFn();
                    }
                });
            } else {
                $mdToast.show($mdToast.simple().textContent('You must enter a name for your equipment'));
            }
            /* if(hasValidAddress(dialog.job)) {

                if (dialog.new) {
                    $log.log("Create");
                    Boarder.getCurrent().$promise
                        .then(function (user) {
                            //$log.log(user.accountId);
                            dialog.job.accountId = parseInt(user.accountId);
                            Job.updateOrCreate(dialog.job).$promise.then(function (results) {
                                $log.log(results);
                                $mdToast.show($mdToast.simple().textContent('Job Created!'));
                                $mdDialog.cancel();
                                $state.reload();
                    });
                        });

                } else {
                    Job.updateOrCreate(dialog.job).$promise.then(function (results) {
                        $log.log(results);
                        $mdToast.show($mdToast.simple().textContent('Job Updated!'));
                        $mdDialog.cancel();
                        $state.reload();

                    });

                }
            }else{
                $mdToast.show($mdToast.simple().textContent("Invalid Address"));

            } */
        }

        function hasValidAddress(job) {
            if (_.has(job, 'address')) {
                var a = job.address;

                var valid = _.has(a, 'zipCode')
                         && _.has(a, 'state')
                         && _.has(a, 'city')
                         && _.has(a, 'streetAddress');

                if (valid) {
                    valid = valid
                            && !(_.isNull(a.streetAddress) || _.isEmpty(a.streetAddress))
                            && !(_.isNull(a.city) || _.isEmpty(a.city))
                            && !(_.isNull(a.state) || _.isEmpty(a.state))
                            && !(_.isNull(a.zipCode) || _.isEmpty(a.zipCode));
                }

                return valid;
            }
            return false;
        }
    }
})();
