(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('_', _);

    _.inject = ['$window'];
    function _($window) {
        return $window._; // assumes underscore/lodash has already been loaded on the page
    }
})();