(function () {
    'use strict';

    angular
        .module('scheduleboard.schedule')
        .controller('ScheduleCardController', ScheduleCardController);

    /* @ngInject */
    ScheduleCardController.inject = ['$scope', 'PartialsService','$mdToast','EmployeesService','EquipmentService','$log', '$mdDialog','CrewService'];
    function ScheduleCardController($scope, PartialsService,$mdToast,EmployeesService, EquipmentService, $log, $mdDialog,CrewService) {

        var vm = this;
        vm.partial=$scope.partial;
        vm.job = $scope.job;
        vm.date = $scope.date;
        vm.jobList = $scope.jobList;
        //console.log(vm.jobList);
        vm.partialDropCallback = partialDropCallback;
        vm.openDeleteDialog = openDeleteDialog;
        vm.copyPartialForTomorrow = copyPartialForTomorrow;
        vm.openMenu = openMenu;
        vm.removeEmployeeFromPartial = removeEmployeeFromPartial;
        vm.removeEquipmentFromPartial = removeEquipmentFromPartial;

        vm.openDelayDialog = openDelayDialog;
        //$log.log("CARD DATA" , vm.partial, vm.job,vm.date);
        //console.log(vm.partialDropCallback);
        //console.log(vm.job);

        function removeEmployeeFromPartial(employee){
            //vm.partial.crew.pop(employee);
            var index = vm.partial.crew.indexOf(employee);
            //vm.partial.crew.pop(index);
            console.log(index);
            var deleted = vm.partial.crew.splice(index,1);

            PartialsService.removeAllEmployeesFromJobDay(vm.partial.jobId,moment(vm.partial.day)).then(function(answer){
               PartialsService.assignEmployeesToJobDay(vm.partial.crew,vm.partial.jobId,moment(vm.partial.day)).then(function(newCrew){
                console.log(newCrew);
            });

            });

        }
        function removeEquipmentFromPartial(equipment){
            //vm.partial.crew.pop(employee);
            var index = vm.partial.equipment.indexOf(equipment);
            //vm.partial.crew.pop(index);
            console.log(index);
            var deleted = vm.partial.equipment.splice(index,1);

            PartialsService.removeAllEquipmentFromJobDay(vm.partial.jobId,moment(vm.partial.day)).then(function(answer){
               PartialsService.assignEquipmentToJobDay(vm.partial.equipment,vm.partial.jobId,moment(vm.partial.day)).then(function(newEquipment){
                console.log(newEquipment);
            });

            });

        }

        function openMenu($mdMenu, ev) {
            var originatorEv = ev;
            $mdMenu.open(ev);
    };


        function partialDropCallback(event, index,type, item, external,dropEffect,callback){
            console.log("partialDropCallback",event, index,type, item, external,dropEffect,callback);
            var type = type;
            var partialId= event.toElement.getAttribute('partial');
            var resourceId = item['id'];
            console.log("RESOURCE ID", resourceId);

            if(type == 'employee-resource'){
                                EmployeesService.getEmployeeById(resourceId).then(function(employee) {

                                    PartialsService.assignEmployeesToJobDay([employee], vm.job.id, moment(vm.date).toISOString()).then(function (results) {
                                        console.log("Employee Add:: Results :: ", results);
                                        $mdToast.show($mdToast.simple().textContent('Employee Added!'));

                                        vm.partial.crew.push(employee);

                                        //vm.partial.equipment.append(results);
                                        //vm.partial.crew.append(employee);
                                        //console.log(vm.partial);

                                    }, function (err) {
                                        $mdToast.show($mdToast.simple().textContent('There Was an error!'));

                                    });
                                });




            }else if(type == 'equipment-resource'){
                EquipmentService.getEquipmentById(resourceId).then(function(equipment){
                    PartialsService.assignEquipmentToJobDay([equipment], vm.job.id, vm.date).then(function (results) {

                        console.log("Equipment Add:: Results :: ", results);
                        $mdToast.show($mdToast.simple().textContent('Equipment Added!'));
                        //console.log(vm.partial);
                        vm.partial.equipment.push(equipment)
                    });

                },function(err){
                        $mdToast.show($mdToast.simple().textContent('There Was an error!'));
                        });

                console.log("equipment",item);

            }
            else{
                console.log("JUST LOGGING" ,item);
            }




        }

        function openDelayDialog(partial){
            console.log("called!");
                $mdDialog.show({
                controller: "PartialDelayDialogController as vm",
                templateUrl: 'app/scheduleboard/schedule/partial-delay-dialog.tmpl.html',
                clickOutsideToClose:true,
                locals: {partial: partial}

            })
                .then(function(date) {
                    PartialsService.delayPartial(partial,date);
                    //$scope.status = 'You delayed partial(s)!';
                }, function() {
                    //$scope.status = 'You cancelled the dialog.';
                });

        }

        function openDeleteDialog(partial){
                        $mdDialog.show({
                controller: "PartialDeleteDialogController as vm",
                templateUrl: 'app/scheduleboard/schedule/partial-delete-dialog.tmpl.html',
                clickOutsideToClose:true,
                locals: {partial: partial, jobList:vm.jobList}

            })
                .then(function(deleted) {
                    $scope.status = 'You deleted partial(s)!';
                }, function() {
                    $scope.status = 'You cancelled the dialog.';
                });

        }

        function getNextDay(today){
            var day = today.day();
            var tomorrow = '';
            if(day == 5){
                tomorrow = moment(today).add(3,"days");
            }else if(day == 6){
                tomorrow = moment(today).add(2,"days");

            }else {
                tomorrow = moment(today).add(1,"days");
            }

            return tomorrow;
        }
        function copyPartialForTomorrow(){
            var today = moment(vm.partial.day);
            var tomorrow = getNextDay(today);

            PartialsService.getPartialsForJobAndDate(tomorrow.format("YYYY-MM-DD"),vm.partial.jobId).then(function(answer){
                console.log("ANSWER", answer);
                if(answer.length === 0){
                    PartialsService.getPartialById(vm.partial.id).then(function(partial){
                        var newPart = partial;
                        newPart.day = tomorrow.toISOString();
                        delete newPart.id;
                        PartialsService.upsertPartial(newPart).then(function(newPartial){
                            console.log(newPartial);
                            if(partial.equipment.length > 0) {
                                PartialsService.assignEquipmentToJobDay(partial.equipment, newPartial.id, moment(newPartial.day)).then(function(){
                                    newPartial.equipment=partial.equipment;
                                },function(err){
                                    console.log("error", err)
                                });
                            }
                            if(partial.crew.length > 0){
                                PartialsService.assignEmployeesToJobDay(partial.crew, newPartial.id, moment(newPartial.day)).then(function(crew){
                                    newPartial.crew = partial.crew;
                                },function(err){
                                    console.log("error", err)
                                });
                            }

                        //vm.jobList[vm.partial.jobId].days[vm.partial.day.format("YYYY-MM-DD")]="";
                        //vm.partial=updated;
                        vm.jobList[newPartial.jobId].days[moment(newPartial.day).format("YYYY-MM-DD")]=newPartial;
                            console.log(vm.jobList);
                    },function(err){
                        console.log("there was an error during upsert", err);
                    });
                    }, function(err){});
                }else{
                    $mdToast.show($mdToast.simple().textContent('There is already work for this job tomorrow!'));
                }
            }, function(err){
               console.log("ERROR",err);
            });

        }
    }
})();