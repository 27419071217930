/* recommended */
/* spinner.directive.js */

/**
 * @desc spinner directive that can be used anywhere across apps at a company named Acme
 * @example <div acme-shared-spinner></div>
 */
angular
    .module('scheduleboard.schedule')
    .directive('scheduleCard', scheduleCard);

function scheduleCard() {
    return {
      restrict: 'E',
      templateUrl: 'app/scheduleboard/schedule/scheduleCard.tmpl.html',
      scope: {
        job: '=',
        partial: '=',
        date: '=',
        theme: '@',
        color: '=',
        jobList: '='
      },
      controller: "ScheduleCardController",
      controllerAs: "vm"
    }
}