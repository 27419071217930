(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('LocalNotificationService', LocalNotificationService);

    LocalNotificationService.inject = ['$state', '$rootScope', '$ionicPlatform', '$localStorage', '$cordovaLocalNotification', 'AuthService', 'UserPrefsService', 'USER_PREFS', 'PartialsService'];
    function LocalNotificationService($state, $rootScope, $ionicPlatform, $localStorage, $cordovaLocalNotification, AuthService, UserPrefsService, USER_PREFS, PartialsService) {
        var scheduleDelayedNotification;
        
        var service = {
            init: init,
            isEnabled: isEnabled,
            enableNotifications: enableNotifications,
            disableNotifications: disableNotifications,
            scheduleWorkReminderNotification: scheduleWorkReminderNotification,
            clearAllReminderNotifications: clearAllReminderNotifications
        };
        
        return service;

        ////////////////

        // must wait until cordova is loaded before we define the scheduler function
        function initFunctions() {
            $ionicPlatform.ready(function() {
                if (window.cordova && window.cordova.plugins) {
                    scheduleDelayedNotification = function (id, datetime, title, text, data) {
                        console.log("LocalNotificationService :: scheduleDelayedNotification");
                        if (isEnabled()) {
                            $cordovaLocalNotification.schedule({
                                id: id,
                                title: title,
                                text: text,
                                data: data,
                                at: datetime
                            }).then(function (result) {
                                // ...
                            });
                        }
                    };

                    // do something when user clicks on local notification(s)
                    $rootScope.$on('$cordovaLocalNotification:click', function (event, notification, state) {
                        console.log("user clicked notification: " + JSON.stringify(notification, null, 2));
                        // redirect user to the partial detail page for this job
                        $state.go('tab.partial-detail', {"partialId" : notification.id}, {reload: true});
                    });
                }
            });
        }

        // this is called remotely (in app.js) on $ionicPlatform.ready() or $ionicPlatform.on("resume")
        function init() {
            if (window.cordova && window.cordova.plugins){
                console.log("LocalNotificationService :: init");

                // make sure the actual functions are created, since the real device/cordova is now running
                initFunctions();

                // only run if this is on a real mobile device and we're enabled
                if ($rootScope.isRealDevice && AuthService.isAuthenticated() && isEnabled()){
                    var lastCheck = UserPrefsService.getPreference(USER_PREFS.lastNotificationCheckAt, moment().subtract(2, 'hours'));
                    var anHourAgo = moment().subtract(1, 'hours');

                    console.log("last checked jobs at: " + lastCheck.toISOString());

                    // only check if we haven't in the past hour
                    if (+lastCheck < +anHourAgo){
                        // clear any existing notifications
                        clearAllReminderNotifications();

                        // fetch and schedule new ones
                        scheduleNotificationsForNextNDays(4);
                    }
                }
            }
        }

        function isEnabled() {
            // check to see if user has enabled local reminder notifications, with a default setting of false
            return UserPrefsService.getPreference(USER_PREFS.localNotificationsEnabled, false);
        }

        function scheduleNotificationsForNextNDays(numDays){
            console.log("LocalNotificationService :: scheduleNotificationsForNextNDays");

            // fetch the partials and schedule notifications
            if (window.cordova && window.cordova.plugins){
                for (var i=1; i <= numDays; i++){
                    var dateStr = moment().add(i, 'days').format("YYYY-MM-DD");
                    PartialsService.getPartialsForDate(dateStr).then(function(partials){
                        // set the current time as the last time we checked for fresh info to notify on
                        UserPrefsService.setPreference(USER_PREFS.lastNotificationCheckAt, moment());

                        // schedule a reminder for each partial
                        partials.forEach( scheduleWorkReminderNotification );
                    });
                }
            } else {
                console.info("LocalNotificationService :: scheduleNotificationsForNextNDays detected no real device");
            }
        };

        function enableNotifications(){
            console.log("LocalNotificationService :: enableNotifications()");
            UserPrefsService.setPreference(USER_PREFS.localNotificationsEnabled, true);
            
            // check to see if app has permission to register notifications
            if (!$cordovaLocalNotification.hasPermission()){
                $cordovaLocalNotification.registerPermission();
            }
            
            scheduleNotificationsForNextNDays(5);
        };

        function disableNotifications(){
            console.log("LocalNotificationService :: disableNotifications()");
            UserPrefsService.setPreference(USER_PREFS.localNotificationsEnabled, false);
            clearAllReminderNotifications();
        };

        function scheduleWorkReminderNotification(partial){
            if (window.cordova && window.cordova.plugins && partial){
                console.log("LocalNotificationService :: scheduleWorkReminderNotification: " + partial);

                // TODO filter / do not schedule based on whether partial is confirmed?

                // check to see if we have permission (from user/OS) to do notifications
                if (!$cordovaLocalNotification.hasPermission()){
                    $cordovaLocalNotification.registerPermission();
                }

                // schedule the notification IF it is going to be in the future
                var now = moment();
                var notificationDatetime = moment(partial.day).subtract(1, 'days').add(14, 'hours').add(30, 'minutes');

                if (+now < +notificationDatetime) {
                    console.log ("LocalNotificationService :: scheduleWorkReminderNotification: scheduling reminder for partial.id " + partial.id + ", related to job.id " + partial.jobId + " on " + notificationDatetime);
                    scheduleDelayedNotification(
                        partial.id,
                        // reminder set for 2:30 PM local time the day prior to the work being performed
                        notificationDatetime.toDate(),
                        "Reminder",
                        // "You're working " + partial.job.name + " (Job #" + partial.job.job_number + ") tomorrow",
                        "You're working " + partial.job.name + " (Job #" + partial.job.job_number + ") tomorrow",
                        {}
                    );
                } else {
                    console.log ("LocalNotificationService :: scheduleWorkReminderNotification: too late to schedule reminder notification for partial " + partial.job.job_number);
                }
            }
        };

        function clearAllReminderNotifications(){
            // TODO verify that this works
            if (window.cordova && window.cordova.plugins) {
                $cordovaLocalNotification.cancelAll(
                    function() {
                        console.log("LocalNotificationService :: cleared all local reminder notifications");
                    }
                );
            }
        };

    }
})();