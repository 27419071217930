(function() {
    'use strict';

    angular
        .module('scheduleboard.schedule')
        .controller('ResourceDialogController', ResourceDialogController);

    /* @ngInject */
    ResourceDialogController.inject = ['$scope', 'PartialsService', '$mdToast', 'EmployeesService', '$mdDialog', 'partial', 'canPartialStillBeEdited', 'canEditPartials'];

    function ResourceDialogController($scope, PartialsService, $mdToast, EmployeesService, $mdDialog, partial, canPartialStillBeEdited, canEditPartials) {

        var vm = this;
        //vm.weekDays = weekDays;
        vm.partial = partial;
        vm.canEditPartials = canEditPartials;
        vm.canPartialStillBeEdited = canPartialStillBeEdited;
        console.log(vm.partial);
        //console.log("IN THE CONTROLLER");
        //console.log(vm.weekDays);
        //vm.maxDate = new Date(vm.weekDays[Object.keys(vm.weekDays).length-1]);

        vm.removeEquipmentFromPartial = removeEquipmentFromPartial;
        vm.removeEmployeeFromPartial = removeEmployeeFromPartial;

        function removeEmployeeFromPartial(employee) {
            //vm.partial.crew.pop(employee);
            var index = vm.partial.crew.indexOf(employee);
            //vm.partial.crew.pop(index);
            console.log(index);
            var deleted = vm.partial.crew.splice(index, 1);

            PartialsService.removeAllEmployeesFromPartial(vm.partial).then(function(answer) {
                if (vm.partial.confirmed == true) {
                    PartialsService.notifyEmployeeDrop(partial, employee);
                }
                PartialsService.assignEmployeesToPartial(vm.partial.crew, vm.partial.id).then(function(newCrew) {
                    vm.partial.confirmed = false;
                    console.log(newCrew);
                });
            });
        }

        function removeEquipmentFromPartial(employee) {
            //vm.partial.crew.pop(employee);
            var index = vm.partial.equipment.indexOf(employee);
            //vm.partial.crew.pop(index);
            console.log(index);
            var deleted = vm.partial.equipment.splice(index, 1);

            PartialsService.removeAllEquipmentFromPartial(vm.partial).then(function(answer) {
                PartialsService.assignEquipmentToPartial(vm.partial.equipment, vm.partial.id).then(function(newEquip) {
                    console.log(newEquip);
                    vm.partial.confirmed = false;
                });
            });

        }

        vm.cancel = function() {
            $mdDialog.cancel();
        };
        
        vm.closeDialog = function() {
            $mdDialog.hide();
        }
    }
})();