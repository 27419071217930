(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('CrewService', CrewService);

    CrewService.inject = ['$q', '$http', '$timeout', 'AuthService', 'API', 'Crew'];
    function CrewService($q, $http, $timeout, AuthService, API, Crew) {
        var service = {
            deleteCrew: deleteCrew,
            getAllCrews: getAllCrews,
            getCrewById: getCrewById,
            upsertCrew: upsertCrew
        };
        
        return service;

        ////////////////

        function deleteCrew(crewObj){
            return Crew.delete(crewObj).$promise;
        };

        function getAllCrews(){
            return Crew.find().$promise;
        };

        function getCrewById(id){
            return Crew.findById({ id: id }).$promise;
        };

        function upsertCrew(crew){
            if (angular.isDefined(crew)){
                var where = {where: {"and" : [{"Date": crew.Date},
                                              {"partialId": crew.partialId},
                                              {"boarderId": crew.boarderId}]}};
                                              
                return Crew.upsertWithWhere(where, crew).$promise;
            }
        };

    }
})();