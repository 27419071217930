(function() {
'use strict';

    angular
        .module('scheduleboard.services')
        .factory('PartialEquipmentService', PartialEquipmentService);

    PartialEquipmentService.inject = ['$q', '$http', '$timeout', 'AuthService', 'API', 'PartialEquipment'];
    function PartialEquipmentService($q, $http, $timeout, AuthService, API, PartialEquipment) {
        var service = {
            deletePartialEquipment: deletePartialEquipment,
            getAllPartialEquipment: getAllPartialEquipment,
            getPartialEquipmentById: getPartialEquipmentById,
            upsertPartialEquipment: upsertPartialEquipment
        };
        
        return service;

        ////////////////

        function deletePartialEquipment(peObj){
            return PartialEquipment.delete(peObj).$promise;
        };

        function getAllPartialEquipment(){
            return PartialEquipment.find().$promise;
        };

        function getPartialEquipmentById(id){
            return PartialEquipment.findById({ id: id }).$promise;
        };

        function upsertPartialEquipment(pe){
            if (angular.isDefined(pe)){
                var where = {where: {"and" : [{"Date": pe.Date},
                                              {"partialId": pe.partialId},
                                              {"equipmentId": pe.equipmentId}]}};
                                              
                return PartialEquipment.upsertWithWhere(where, pe).$promise;
            }
        };

    }
})();