(function() {

'use strict';

    angular
        .module('scheduleboard.resources')
        .controller('PartialAddDialogController', PartialAddDialogController);
        // ----- ControllerFunction -----
        PartialAddDialogController.$inject = ['$mdDialog', '$scope','$state','Boarder','AuthService', 'Job','JobsService','PartialsService','$mdToast', '$timeout', 'jobId'];

        /* @ngInject */
        function PartialAddDialogController($mdDialog, $scope,$state,Boarder,AuthService, Job, JobsService, PartialsService, $mdToast, $timeout, jobId) {


            var vm = this;
            vm.date = moment();
            vm.partial = {};
            vm.manualAddress = false;
            //console.log($mdStepper);
            //function declarations

            vm.hide = hide;
            vm.submit = submit;


            function hide() {
                $mdDialog.hide();
            }


            vm.cancel = function () {

                $mdDialog.cancel();
            };

            function close() {
                $mdDialog.cancel();
            }

            function updateString(){
                vm.partial.day = vm.date.toISOString();
            }
            function submit() {

                //console.log(vm.job);
                //console.log(vm.job.locationDetails);
                //console.log(vm.date);
                vm.partial.day = moment(vm.partial.day).startOf("day").toISOString();
                console.log(vm.partial.day);
                vm.partial.jobId = jobId;
                //vm.partial.crew = [];
                //vm.partial.equipment = [];
                PartialsService.upsertPartial(vm.partial).then(function (results) {
                    $mdToast.show($mdToast.simple().textContent('Work Added!'));
                    $mdDialog.cancel();
                    //$state.reload();
                });

            }
        }

})();